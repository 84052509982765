export const abbreviationWeek = [
  {
    key: 'ABBREVIATION_MONDAY',
  },
  {
    key: 'ABBREVIATION_TUESDAY',
  },
  {
    key: 'ABBREVIATION_WEDNESDAY',
  },
  {
    key: 'ABBREVIATION_THURSDAY',
  },
  {
    key: 'ABBREVIATION_FRIDAY',
  },
  {
    key: 'ABBREVIATION_SATURDAY',
  },
  {
    key: 'ABBREVIATION_SUNDAY',
  },
];

export const LECTURE = {
  ROUND_LISTEN_TYPES: ['offline', 'roundOnline', 'consulting'],
};
