import styled from 'styled-components';

const StyledTextInput = styled.input.attrs({ type: 'text' })`
  background-color: #ffffff;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.5rem;
  height: 2.5rem;
  padding: 0.6875rem 1.25rem 0.5625rem;
  width: 100%;
  font-size: 0.875rem;
`;

const TextInputContainer = styled.div`
  padding-left: 2.5rem;

  @media (max-width: 480px) {
    padding-left: 0;
  }
`;

const ShortText = ({ register, name, placeholder = '내용', isRequired }) => {
  return (
    <TextInputContainer>
      <StyledTextInput
        name={name}
        {...register(name, {
          required: isRequired,
        })}
        placeholder={placeholder}
      />
    </TextInputContainer>
  );
};

const StyledTextArea = styled.textarea`
  background-color: #ffffff;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.5rem;
  height: 10rem;
  padding: 0.6875rem 1.25rem 0.5625rem;
  width: 100%;
  font-size: 0.875rem;
`;
const LongText = ({ register, name, placeholder = '내용', isRequired }) => {
  return (
    <TextInputContainer>
      <StyledTextArea
        name={name}
        {...register(name, {
          required: isRequired,
        })}
        placeholder={placeholder}
      />
    </TextInputContainer>
  );
};

export { ShortText, LongText };
