import { useCallback, useEffect } from 'react';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import CancelIcon from '../../../Assets/Images/icon-cancel-black.png';
import ResetIcon from '../../../Assets/Images/button-refresh.png';

const LecturesFilterModal = ({
  categoryConstant,
  currentTopCategory,
  currentTopCategoryOptions,
  modalSelectedCategoryOptions,
  selectedCategoryOptions,
  setModalSelectedCategoryOptions,
  onClickTopCategory,
  onClickApplyModalSelectedCategoryOptions,
  onClickResetModalSelectedCategoryOptions,
  onClickCloseMobileFilterModal,
}) => {
  const onClickStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onClickToggleModalSelectedCategoryOptions = useCallback(
    (option) => {
      // 이미 선택된 Option인지 체크
      const hasOptionIndex = modalSelectedCategoryOptions.findIndex(
        (o) => o.type === option.type && o.id === option.id,
      );

      if (hasOptionIndex > -1) {
        // Delete
        modalSelectedCategoryOptions.splice(hasOptionIndex, 1);
        setModalSelectedCategoryOptions([...modalSelectedCategoryOptions]);
      } else {
        // Add
        setModalSelectedCategoryOptions((prev) => [...prev, option]);
      }
    },
    [modalSelectedCategoryOptions, setModalSelectedCategoryOptions],
  );

  useEffect(() => {
    setModalSelectedCategoryOptions([...selectedCategoryOptions]);
  }, [selectedCategoryOptions, setModalSelectedCategoryOptions]);

  return (
    <div className="search_lectures_modal_wrap" onClick={onClickStopPropagation}>
      <div className="lectures_categories_wrap">
        <span className="close_options_modal_btn" onClick={onClickCloseMobileFilterModal}>
          <img src={CancelIcon} alt="close options modal" />
        </span>
        <div className="top_categories lectures_categories">
          <ul>
            {categoryConstant?.map((category, index) => (
              <li
                key={category.key}
                className={currentTopCategory === index ? 'isActive' : ''}
                onClick={() => onClickTopCategory(index, true)}
              >
                <FormattedMessage id={category.key} />
              </li>
            ))}
          </ul>
        </div>
        <div className={`middle_categories lectures_categories`}>
          <ul>
            {currentTopCategoryOptions.map((option) => (
              <li
                key={`middle_${option.key}`}
                className={
                  modalSelectedCategoryOptions.some((c) => c.key === option.key && c.id === option.id) ? 'isActive' : ''
                }
                onClick={() => onClickToggleModalSelectedCategoryOptions(option)}
              >
                <FormattedMessage id={option.key} />
              </li>
            ))}
          </ul>
        </div>

        <div className="selected_categories lectures_categories">
          <span className="reset_btn btn" onClick={onClickResetModalSelectedCategoryOptions}>
            <FormattedMessage id="RESET" />
            <img src={ResetIcon} alt="category_reset_icon" />
          </span>

          <span className="submit_btn btn" onClick={onClickApplyModalSelectedCategoryOptions}>
            <FormattedMessage id="MEET_YOUR_CRITERIA" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default LecturesFilterModal;
