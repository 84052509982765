import { useEffect, useState } from 'react';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import CancelIcon from '../../../Assets/Images/icon-cancel-black.png';
import ResetIcon from '../../../Assets/Images/button-refresh.png';
import CheckIcon from '../../../Assets/Images/icon-checkbox-checked.png';
import UncheckIcon from '../../../Assets/Images/icon-checkbox-unchecked.png';
import { GET } from '../../Utils/fetch';

const RecordsFilterModal = ({
  currentFilterGroup,
  artistList,
  agencyList,
  changeFilterGroup,
  params,
  applyFilters,
  onClickClose,
  total,
}) => {
  const [arrAgencies, setArrAgencies] = useState([]);
  const [arrArtists, setArrArtists] = useState([]);
  const [totalCount, setTotalCount] = useState(total || 0);

  useEffect(() => {
    params.artists && setArrArtists(params.artists.split(',').map(Number));
    params.agencies && setArrAgencies(params.agencies.split(',').map(Number));
  }, []);

  useEffect(() => {
    const fetchData = async () =>
      await GET({
        url: '/auth/records',
        params: {
          ...params,
          agencies: arrAgencies.join(','),
          artists: arrArtists.join(','),
        },
        header: {
          EJE_API_KEY: 'auth',
        },
      });
    fetchData().then((res) => setTotalCount(res.count));
  }, [arrAgencies, arrArtists, params, total]);

  const printFilters = () => {
    switch (currentFilterGroup) {
      case 'artist':
        return artistList.map((artist) => (
          <li
            key={artist.id}
            onClick={() => {
              if (arrArtists.includes(artist.id)) {
                setArrArtists(arrArtists.filter((id) => id !== artist.id));
              } else {
                setArrArtists([...arrArtists, artist.id]);
              }
            }}
          >
            {arrArtists.includes(artist.id) ? (
              <img src={CheckIcon} alt="check" />
            ) : (
              <img src={UncheckIcon} alt="uncheck" />
            )}
            <div>{artist.text}</div>
          </li>
        ));
      case 'agency':
        return agencyList.map((agency) => (
          <li
            key={agency.id}
            onClick={async () => {
              if (arrAgencies.includes(agency.id)) {
                setArrAgencies(arrAgencies.filter((id) => id !== agency.id));
              } else {
                setArrAgencies([...arrAgencies, agency.id]);
              }
            }}
          >
            {arrAgencies.includes(agency.id) ? (
              <img src={CheckIcon} alt="check" />
            ) : (
              <img src={UncheckIcon} alt="uncheck" />
            )}
            <div>{agency.text}</div>
          </li>
        ));
      default:
        return '';
    }
  };

  return (
    <div className="search_records_modal_wrap">
      <div className="records_filters_wrap">
        <span className="close_options_modal_btn" onClick={onClickClose}>
          <img src={CancelIcon} alt="close options modal" />
        </span>
        <div className="top_filters records_filters">
          <ul>
            {[
              { key: 'artist', text: '아티스트' },
              { key: 'agency', text: '기획사' },
            ].map((filter, index) => (
              <li
                key={filter.key}
                onClick={() => changeFilterGroup(filter.key)}
                className={currentFilterGroup === filter.key ? 'isActive' : ''}
              >
                <FormattedMessage id={filter.text} />
              </li>
            ))}
          </ul>
        </div>
        <div className={`middle_filters records_filters`}>
          <ul>{printFilters()}</ul>
        </div>

        <div className="selected_filters records_filters">
          <span
            className="reset_btn btn"
            onClick={() => {
              setArrArtists([]);
              setArrAgencies([]);
            }}
          >
            <FormattedMessage id="RESET" />
            <img src={ResetIcon} alt="filter_reset_icon" />
          </span>

          <span
            className="submit_btn btn"
            onClick={() =>
              applyFilters({
                ...params,
                agencies: arrAgencies.join(','),
                artists: arrArtists.join(','),
              })
            }
          >
            <FormattedMessage
              id="{totalCount}개의 앨범 크레딧 보기"
              values={{
                totalCount,
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default RecordsFilterModal;
