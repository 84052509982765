import MyPage from '../../../Commons/Layouts/MyPage';
import { useMediaQuery } from 'react-responsive';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GET } from '../../../Commons/Utils/fetch';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import ClassTitleIcon from '../../../Assets/Images/class-icon.png';
import Loading from '../../../Commons/Components/Loading';
import LectureItem from '../../../Commons/Components/LectureItem';
import { useSelector } from 'react-redux';
import Pagination from '../../../Commons/Components/Pagination';
import queryString from 'query-string';
import HeartIcon from '../../../Commons/Components/HeartIcon';

const LikeLectures = (props) => {
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      sessionStorage.setItem('login_redirect', '/my/like-lectures');
      history.push('/login');
    }
  }, [isLoggedIn, history]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [lectureList, setLectureList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const params = useMemo(
    () => ({
      page: parseInt(page) || 1,
      limit: 6,
    }),
    [page],
  );

  const getLectures = useCallback(async () => {
    try {
      setIsLoading(true);
      const { success, classes } = await GET({
        url: '/classes/me/like',
        params,
        header: { EJE_API_KEY: localStorage.getItem('eje_token') },
      });

      if (success) {
        setLectureList(classes.rows);
        setTotalCount(classes.count);

        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params]);

  const onClickLikeLectureFilter = useCallback((listenType, category) => {
    const pathname = {
      online: '/online',
      roundOnline: '/online',
      offline: '/offline',
      consulting: '/offline',
      foreigner: '/foreigner',
    }[listenType];
    const categoryInfo = {
      company: [],
      field: [],
      process: [],
    };
    categoryInfo[category.type].push(category.id);

    history.push({
      pathname,
      search: `?page=1&sort=new${categoryInfo.company.length > 0 ? '&company=' + categoryInfo.company : ''}${
        categoryInfo.field.length > 0 ? '&field=' + categoryInfo.field : ''
      }${categoryInfo.process.length > 0 ? '&process=' + categoryInfo.process : ''}`,
    });
  }, []);

  // Paginate
  const onClickPaginate = useCallback(
    (number) => {
      history.push(`${location.pathname}?page=${number}`);
    },
    [location, history],
  );

  useEffect(() => {
    getLectures();
  }, [params]);

  useEffect(() => {
    const locationSearch = queryString.parse(location.search);
    setPage(parseInt(locationSearch.page) || 1);
  }, [location]);

  return (
    <MyPage>
      <div className="like__lectures__warp">
        <div className="inner__wrap">
          <div className="header__wrap">
            <h3>
              <FormattedMessage id="MY_PAGE_LIKE_LECTURES" />
            </h3>
            <div className="header__renew__btn" onClick={getLectures}>
              <span>새로고침 </span>
              <div className="renew__btn__icon__wrap">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>reload</title>
                  <path
                    fill="#9d9d9d"
                    d="M2 12C2 16.97 6.03 21 11 21C13.39 21 15.68 20.06 17.4 18.4L15.9 16.9C14.63 18.25 12.86 19 11 19C4.76 19 1.64 11.46 6.05 7.05C10.46 2.64 18 5.77 18 12H15L19 16H19.1L23 12H20C20 7.03 15.97 3 11 3C6.03 3 2 7.03 2 12Z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="lectures__content">
            <div className="lecture__list">
              {isLoading ? (
                <Loading />
              ) : lectureList.length > 0 ? (
                lectureList.map((lecture) => (
                  <LectureItem
                    key={lecture.id}
                    item={lecture}
                    onClickAddFilterOption={(category) => {
                      onClickLikeLectureFilter(lecture.listen_type, category);
                    }}
                    history={history}
                    isLoggedIn={isLoggedIn}
                    onClickLikeInMyPage={() => {
                      setLectureList((prev) => prev.filter((item) => item.id !== lecture.id));
                    }}
                  />
                ))
              ) : (
                <div className="empty__like__lectures__wrap">
                  <strong>
                    <FormattedMessage id="MY_PAGE_EMPTY_LIKE_LECTURES" />
                  </strong>
                  <button
                    onClick={() => {
                      history.push('/online');
                    }}
                  >
                    <FormattedMessage id="MY_PAGE_EMPTY_LIKE_LECTURES_BTN" />
                  </button>
                </div>
              )}
              {!isLoading &&
                lectureList.length % (isMobile ? 2 : 3) !== 0 &&
                Array.from({ length: (isMobile ? 2 : 3) - (lectureList.length % (isMobile ? 2 : 3)) }, (_, i) => (
                  <div key={i} className="lecture_item" />
                ))}
            </div>

            {lectureList.length > 0 && (
              <Pagination
                total={totalCount}
                limit={params.limit}
                currentPage={parseInt(params.page)}
                paginate={onClickPaginate}
              />
            )}
          </div>
        </div>
      </div>
    </MyPage>
  );
};

export default LikeLectures;
