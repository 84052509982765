import { LOGIN, LOGOUT, SET_LECTURE_LIKE_LIST } from '../Actions/user';

const initialStates = {
  isLoggedIn: false,
  userInfo: null,
  lectureLikeList: [],
};

const reducers = (state = initialStates, action) => {
  const { type } = action;
  switch (type) {
    case LOGIN: {
      const logintype = localStorage.getItem('logintype') || '';

      if (logintype) {
        localStorage.removeItem('logintype');
      }

      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.payload,
      };
    }

    case LOGOUT: {
      localStorage.removeItem('eje_token');

      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
      };
    }

    case SET_LECTURE_LIKE_LIST: {
      return {
        ...state,
        lectureLikeList: action.lectureLikeList,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
