import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0 0.75rem 2.5rem;
  gap: 1rem;

  @media (max-width: 480px) {
    padding-left: 0;
  }
`;

const StyledCheckboxLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1.25rem;

  > span {
    margin-bottom: -0.125rem;
  }
`;

const StyledCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  outline: none;
  cursor: pointer;

  width: 100%;
  max-width: 1.125rem;
  height: 1.125rem;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  aspect-ratio: 1/1;

  &:not(:checked) {
    background-color: #f8f9fa;
  }

  &:checked {
    background-color: #f24462;
    border-color: #f24462;

    &:after {
      content: '';
      display: block;
      position: relative;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 0.375rem;
      height: 0.5rem;
      border-bottom: 0.125rem solid #ffffff;
      border-right: 0.125rem solid #ffffff;
    }
  }
`;

const StyledTextInput = styled.input.attrs({ type: 'text' })`
  background-color: #ffffff;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: auto;
  max-width: 20rem;
  padding: 0.6875rem 1.25rem 0.5625rem;
`;

const CheckboxOption = ({ id: id, content: label, is_etc: isEtc, register, name, isRequired, watchGroup = [] }) => {
  const inputName = isEtc ? `${name}Text` : name;
  const isEtcChecked = watchGroup && watchGroup.includes(id + '');
  return (
    <>
      <StyledCheckboxLabel>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <StyledCheckboxInput
            value={id}
            {...register(name, {
              required: isRequired,
            })}
          />
          <span>{label}</span>
        </div>
        {isEtc && (
          <StyledTextInput
            name={inputName}
            placeholder="기타 내용을 입력하세요"
            {...register(inputName, {
              required: isRequired && isEtcChecked,
            })}
            style={{ marginLeft: '1.5rem' }}
          />
        )}
      </StyledCheckboxLabel>
    </>
  );
};

const CheckboxGroup = ({ options, register, name, isRequired, watch, setValue }) => {
  const watchGroup = watch(name);
  const list = `${name}SelectedList`;

  useEffect(() => {
    if (watchGroup) {
      const selectedOptions = options.filter((option) => watchGroup.includes(option.id + ''));
      const selectedOptionText = selectedOptions.map((option) => option.content).join(', ');
      const selectedList = [];
      selectedOptions.forEach((option) => {
        selectedList.push({
          value: option.id,
          text: option.content,
        });
      });
      setValue(list, selectedList);
    }
  }, [watchGroup]);

  return (
    <StyledCheckboxContainer>
      {options.map((option) => (
        <CheckboxOption
          key={`${name}-option-${option.id}`}
          {...option}
          register={register}
          name={name}
          isRequired={isRequired}
          watchGroup={watchGroup}
        />
      ))}
      <input type="hidden" name={list} {...register(list)} />
    </StyledCheckboxContainer>
  );
};

export default CheckboxGroup;
