import '../index.scss';

function Information() {
  return (
    <div className="payment_term">
      <h1 className="page-title">Consent to the Provision of Personal Information to Third Parties</h1>
      <div className="page-body">
        <p>
          Users’ personal information shall be used by the Company to the extent notified in this privacy policy but
          shall not be used beyond the aforesaid extent or provided to third parties, without the prior consent of
          users, except as the Company is required by law or otherwise to provide such personal information to
          investigation agencies, etc. for investigational purposes in accordance with the methods and procedures
          prescribed by applicable laws
        </p>
        <p>
          The Company has provided user’s personal information to third parties, as follows, to perform its obligation
          to provide the Services:
        </p>
        <p>
          <a href="https://www.notion.so/13b9a699b6ad4f3caf9b0ec37b3f0842">No subject</a>
        </p>
      </div>
    </div>
  );
}

export default Information;
