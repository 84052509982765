import StarIcon from '@mui/icons-material/Star';
import { Rating, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import * as PropTypes from 'prop-types';

import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import Popup from '../../../../../Commons/Components/Popup';
import Popup from 'reactjs-popup';
import swal from 'sweetalert';
import DetailIcon from '../../../../../Assets/Images/chatting.png';

import CouponIcon from '../../../../../Assets/Images/coupon.png';
import closeIcon from '../../../../../Assets/Images/icon-cancel-black.png';
import Loading from '../../../../../Commons/Components/Loading';
import { POST, PUT } from '../../../../../Commons/Utils/fetch';
import { abbreviationWeek } from '../../constant.js';
import './index.scss';
import survey from '../../../../Survey';

const LectureRow = ({ userClassId, lecture, originLectures, columns }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const showLectureLists = originLectures.filter((originLecture) => originLecture.FK_class_id === lecture.FK_class_id);

  return lecture.FK_class.listen_type === 'offline' || lecture.FK_class.listen_type === 'consulting' ? (
    <OfflineRow
      userClassId={userClassId}
      lecture={lecture}
      originLectures={originLectures}
      showLectureLists={showLectureLists}
      userInfo={userInfo}
      columns={columns}
    />
  ) : (
    <OnlineRow
      lecture={lecture}
      originLectures={originLectures}
      showLectureLists={showLectureLists}
      userInfo={userInfo}
      columns={columns}
    />
  );
};

async function postCertificate(lecture, userInfo) {
  const {
    id: FK_user_class_id,
    FK_user_id,
    FK_class: { title: lectureName },
    start_date: lectureStartDate,
    end_date: lectureEndDate,
  } = lecture;
  const { name: userName } = userInfo;
  const body = {
    FK_user_class_id,
    FK_user_id,
    lectureName,
    lectureStartDate,
    lectureEndDate,
    userName,
  };
  if (lecture.FK_class.listen_type === 'offline') {
    body.lectureRound = lecture.FK_class_offline_schedule.class_round;
  } else if (lecture.FK_class.listen_type === 'roundOnline') {
    body.lectureRound = lecture.FK_class_online_schedule.class_round;
  } else {
    body.lectureRound = 0;
  }
  try {
    const certificate = await POST({
      url: '/class/certificate',
      body,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });
    return certificate;
  } catch (error) {
    console.error(error);
  }
  return undefined;
}

async function getCertificate(lecture, userInfo) {
  if (lecture.is_certificate_file) {
    window.open(lecture.certificate);
  } else if (!lecture.is_certificate_file && lecture.certificate) {
    window.open(`/certificate/${lecture.certificate}`);
  } else {
    const { success, code } = await postCertificate(lecture, userInfo);
    if (success) {
      window.open(`/certificate/${code}`);
    } else {
      swal({
        title: 'Error',
        text: 'Something went wrong',
        icon: 'error',
      });
    }
  }
}

function CertificateButton({ lecture, userInfo = {}, isChild = false }) {
  const acceptRate = lecture.completion_rate !== null ? +lecture.completion_rate >= +lecture.FK_class.pass_rate : true;
  const afterEndDate = dayjs().isAfter(dayjs(lecture.end_date).add(1, 'day'));
  const isCertTypeFile = lecture.is_certificate_file;
  const isCompletion = isCertTypeFile || (!isChild && acceptRate && afterEndDate); // 수료 기준 통과여부
  const statusPostSurvey = lecture.survey_post_done; // 설문조사 여부 -1:미응답, 0:설문없음, 1:응답

  const newPreSurvey = lecture.FK_class.Class_Surveys?.find((survey) => survey.timing === 'before');
  const newPostSurvey = lecture.FK_class.Class_Surveys?.find((survey) => survey.timing === 'after');

  let buttonText = lecture.FK_class.auto_certificate ? '미완료' : '-';

  const getButtonText = (isSurveyCompleted, isCertTypeFile) => {
    if (isSurveyCompleted) {
      return isCertTypeFile || lecture.FK_class.auto_certificate ? '발급완료' : '-';
    }
    return lecture.FK_class.auto_certificate ? '미완료' : '-';
  };

  const isCompletedPreCondition = newPreSurvey ? newPreSurvey.isCompleted : true;

  if (isCompletion) {
    // INFO: legacy 설문조사 기능 기반
    if (!!lecture.FK_class.survey_post || !!lecture.FK_class.survey_pre) {
      if (statusPostSurvey === -1) {
        // 설문조사 미응답
        buttonText = '신청';
      } else if (statusPostSurvey === 0 || statusPostSurvey === 1) {
        // 설문조사 없음
        if (lecture.FK_class.host === 'gov') {
          if (isCertTypeFile) {
            buttonText = '발급완료';
          } else {
            buttonText = '발급중';
          }
        } else {
          buttonText = '발급완료';
        }
      }
    } else if (isCompletedPreCondition) {
      // INFO: 신규 설문조사 기반
      if (newPostSurvey) {
        buttonText = getButtonText(newPostSurvey.isCompleted, isCertTypeFile);
      } else {
        buttonText = getButtonText(true, isCertTypeFile);
      }
    }
  }
  /**
   * 수료 기준을 통과한 경우,
   *  설문조사가 있는 경우,
   *    설문 참여 OK?
   *      파일인 경우,
   *        파일 있음?
   *          확인
   *        파일 없음?
   *          대기
   *      파일이 아닌 경우,
   *        확인
   *    설문 참여 No?
   *      신청
   *  설문조사가 없는 경우,
   *    파일인 경우,
   *      파일 있음?
   *        확인
   *      파일 없음?
   *        대기
   *    파일이 아닌 경우,
   *      확인
   * 수료 기준을 통과하지 못 한 경우, *
   *  - *
   */
  return lecture.FK_class.id === 485 ? (
    <span> - </span>
  ) : (
    <span
      onClick={() =>
        statusPostSurvey === -1
          ? swal({
              title: '이수증 발급 신청',
              text: '설문조사를 완료한 분들만 이수증 발급 신청이 가능합니다.(약 1일 소요)\n',
              buttons: ['취소', '설문 참여 및 발급 신청'],
              closeOnClickOutside: false,
            }).then((willTakeSurvey) => {
              if (willTakeSurvey) {
                window.open(lecture.FK_class.survey_post);
              }
            })
          : getCertificate(lecture, userInfo)
      }
      className={
        buttonText === '발급완료' || buttonText === '신청' ? 'pass' : buttonText === '발급중' ? 'wait' : 'fail'
      }
    >
      {buttonText}
    </span>
  );
}

CertificateButton.propTypes = {
  lecture: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  isChild: PropTypes.bool,
};

function OnlineRow({ lecture, isChild, showLectureLists, userInfo, columns }) {
  const isPackage = lecture.FK_class.class_type === 'package';
  const schedule =
    lecture.FK_class.listen_type === 'roundOnline'
      ? lecture.FK_class_online_schedule
      : lecture.FK_class.ClassOnlineSchedule;

  const [open, setOpen] = useState(false);

  const onClickToggle = useCallback(() => setOpen((open) => !open), []);

  const openClassroom = useCallback((lecture) => {
    if (lecture?.survey_pre_done === -1) {
      swal({
        title: '사전 설문조사를 완료하셨습니까?',
        buttons: [
          {
            text: '아니오',
            value: null,
            visible: true,
            closeModal: true,
          },
          {
            text: '예',
            value: true,
            visible: true,
            closeModal: true,
          },
        ],
        closeOnClickOutside: false,
      }).then((value) => {
        if (value) {
          window.open(`/classroom/${lecture.id}/${lecture.FK_class.id}`);
        } else {
          if (lecture?.FK_class?.survey_pre) {
            window.open(`/classroom/${lecture.id}/${lecture.FK_class.id}`);
            window.open(lecture.FK_class.survey_pre);
          } else {
            swal({
              title: 'Error',
              text: 'Something went wrong',
              icon: 'error',
            });
          }
        }
      });
    } else {
      window.open(`/classroom/${lecture.id}/${lecture.FK_class.id}`);
    }
  });

  const childLectures = useMemo(
    () =>
      isPackage
        ? lecture.FK_class.ClassSingle_ClassPackages?.map(
            (ClassSingle_ClassPackage) => ClassSingle_ClassPackage.FK_class_single,
          ) || []
        : [],
    [isPackage, lecture],
  );
  const onClickMaterial = useCallback(() => {
    PUT({
      url: `/class/me/${lecture.id}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });
  }, [lecture.id]);

  let columnIndex = 0;
  return (
    <>
      <tr className={'lecture_row lecture_row--online' + ` lecture_row--${lecture.class_status}`}>
        <td className="lecture_status" style={columns[columnIndex++].style}>
          {isChild || <FormattedMessage id={'LECTURE_STATUS_' + lecture.class_status.toUpperCase()} />}
        </td>
        <td className="lecture_title" style={columns[columnIndex++].style}>
          {lecture.FK_class?.title}
        </td>
        {!isPackage && (
          <td className="lecture_option" style={columns[columnIndex++].style}>
            {schedule && schedule.title ? schedule.title : '-'}
          </td>
        )}
        <td className="lecture_due" style={columns[columnIndex++].style}>
          <Tooltip
            placement="top"
            arrow
            title={
              <ul>
                {isChild ||
                  showLectureLists.map((lecture, idx) => (
                    <li key={idx}>
                      {`${idx + 1}회차 수강 기간 : ${lecture.start_date || '-'} ~ ${lecture.end_date || '-'}`}
                    </li>
                  ))}
              </ul>
            }
          >
            <span>
              {isChild || `${lecture.start_date || '-'}`}
              {isChild || (lecture.start_date && <br />)}
              {isChild || ` ~ ${lecture.end_date || '-'}`}
            </span>
          </Tooltip>
        </td>
        <td className="lecture_remain_days" style={columns[columnIndex++].style}>
          {isChild ||
            (lecture.count_left_day ? (
              <>
                {lecture.count_left_day}
                <FormattedMessage id="DAY" />
              </>
            ) : (
              '-'
            ))}
        </td>
        <td className="lecture_attendance_rate" style={columns[columnIndex++].style}>
          {isChild || `${lecture.completion_rate ?? 0}%`}
        </td>
        <td
          className="lecture_short_btn"
          style={{
            ...columns[columnIndex++].style,
            ...(lecture.class_status === 'ongoing' ? {} : { color: 'lightgray' }),
          }}
        >
          {isPackage ? (
            <span open={open} onClick={onClickToggle} />
          ) : lecture.class_status === 'ongoing' ? ( // <Link
            //   to={{
            //     pathname: `/classroom/${lecture.id}`,
            //     state: { targetClassId: lecture.FK_class.id }
            //   }}
            // >
            //   <FormattedMessage id="MY_PAGE_LECTURES_SHORTCUTS" />
            // </Link>
            <button
              className="lecture_short_btn"
              style={{
                width: '110%',
              }}
              onClick={() => openClassroom(lecture)}
            >
              <FormattedMessage id="MY_PAGE_LECTURES_SHORTCUTS" />
            </button>
          ) : (
            '-'
          )}
        </td>
        <td
          className="lecture_short_btn"
          style={{
            ...columns[columnIndex++].style,
            ...(lecture.class_status === 'ongoing' && lecture.FK_class.url_attached_file ? {} : { color: 'lightgray' }),
          }}
        >
          {isPackage ? (
            ''
          ) : lecture.class_status === 'ongoing' && lecture.FK_class.url_attached_file ? (
            <Link
              to={{
                pathname: lecture.FK_class.url_attached_file,
              }}
              target="_blank"
              onClick={onClickMaterial}
            >
              <FormattedMessage id="MY_PAGE_LECTURES_DOWNLOAD_FILE" />
            </Link>
          ) : (
            '-'
          )}
        </td>
        <td className="lecture_certificate" style={columns[columnIndex++]?.style}>
          <CertificateButton lecture={lecture} userInfo={userInfo} isChild={isChild} />
        </td>
        <td className="lecture_review" style={columns[columnIndex++]?.style}>
          {!isChild && lecture.FK_class.host !== 'gov' && (
            <WriteReviewBtn
              reviews={lecture.class_review}
              title={lecture.FK_class?.title}
              classId={lecture.FK_class.id}
              completionRate={lecture.completion_rate ?? 0}
              lectureType="online"
            />
          )}
        </td>
      </tr>
      {open &&
        childLectures.length > 0 &&
        childLectures.map((childLecture) => (
          <OnlineRow
            key={childLecture.id}
            lecture={{
              id: lecture.id,
              class_status: lecture.class_status,
              FK_class: childLecture,
            }}
            isChild
            columns={columns}
          />
        ))}
    </>
  );
}

const renderSurveyLink = (lecture, beforeSurvey, isDoneBeforeSurvey, afterSurvey, isDoneAfterSurvey, userClassId) => {
  if (lecture.class_status === 'pending' || lecture.class_status === 'ongoing') {
    if (beforeSurvey) {
      return isDoneBeforeSurvey ? (
        '완료(사전)'
      ) : (
        <Link
          target="_blank"
          to={{
            pathname: `/survey/${beforeSurvey.FK_survey_id}?classId=${lecture.FK_class.id}&userClassId=${userClassId}`,
          }}
        >
          사전 설문
        </Link>
      );
    } else {
      return '-';
    }
  } else if (afterSurvey) {
    return isDoneAfterSurvey ? (
      '완료(사후)'
    ) : (
      <Link
        target="_blank"
        to={{
          pathname: `/survey/${afterSurvey.FK_survey_id}?classId=${lecture.FK_class.id}&userClassId=${userClassId}`,
        }}
      >
        사후 설문
      </Link>
    );
  } else if (beforeSurvey && isDoneBeforeSurvey) {
    return '완료(사전)';
  } else {
    return '-';
  }
};

function OfflineRow({ userClassId, lecture, originLectures, showLectureLists, userInfo, columns }) {
  const now = dayjs();
  const isConsulting = lecture.FK_class.listen_type === 'consulting';
  const schedule = isConsulting ? lecture.FK_class_consulting_schedule : lecture.FK_class_offline_schedule;
  const scheduleRate =
    schedule && dayjs(schedule.start_date).isBefore(now)
      ? (now.diff(dayjs(schedule.start_date), 'day', true) /
          dayjs(schedule.end_date).diff(dayjs(schedule.start_date), 'day', true)) *
        100
      : -1;
  const abbr = schedule
    ? schedule.class_dates
        .split('')
        .reduce((prev, date, index) => {
          if (parseInt(date)) {
            prev.push(abbreviationWeek[index]);
          }

          return prev;
        }, [])
        .map((abb, index) => (
          <span key={abb.key}>
            <FormattedMessage id={abb.key} />
            {abb.length - 1 !== index && <span>,</span>}
          </span>
        ))
    : null;
  const onClickMaterial = useCallback(() => {
    PUT({
      url: `/class/me/${lecture.id}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });
  }, [lecture.id]);

  const beforeSurvey = lecture.FK_class.Class_Surveys.find((survey) => survey.timing === 'before');
  const isDoneBeforeSurvey = beforeSurvey ? beforeSurvey.isCompleted : false;
  const afterSurvey = lecture.FK_class.Class_Surveys.find((survey) => survey.timing === 'after');
  const isDoneAfterSurvey = afterSurvey ? afterSurvey.isCompleted : false;

  let columnIndex = 0;
  return (
    <tr className={'lecture_row lecture_row--offline' + ` lecture_row--${lecture.class_status}`}>
      <td className="lecture_status" style={columns[columnIndex++].style}>
        <FormattedMessage id={'LECTURE_STATUS_' + lecture.class_status.toUpperCase()} />
      </td>
      <td className="lecture_title" style={columns[columnIndex++].style}>
        {lecture.FK_class.title}
      </td>
      <td className="lecture_option" style={columns[columnIndex++].style}>
        {schedule && schedule.title ? schedule.title : '-'}
      </td>
      <td className="lecture_standard" style={columns[columnIndex++].style}>
        {schedule ? schedule.class_round : '-'}
        <FormattedMessage id="STANDARD" />
      </td>
      <td className="lecture_due" style={columns[columnIndex++].style}>
        <Tooltip
          placement="top"
          arrow
          title={
            <ul>
              {showLectureLists.map((lecture, idx) => (
                <li key={idx}>
                  {`${idx + 1}회차 수강 기간 : ${lecture.start_date || '-'} ~ ${lecture.end_date || '-'}`}
                </li>
              ))}
            </ul>
          }
        >
          <span>
            {`${lecture.start_date || '-'}`}
            {lecture.start_date && <br />}
            {` ~ ${lecture.end_date || '-'}`}
          </span>
        </Tooltip>
      </td>
      <td className="lecture_remain_days" style={columns[columnIndex++].style}>
        <p className="abbr">{abbr ? abbr : '-'}</p>
        <p>{`${schedule ? schedule.class_start_time : '-'} ~ ${schedule ? schedule.class_end_time : '-'}`}</p>
      </td>
      <td className="lecture_place" style={columns[columnIndex++].style}>
        {schedule?.FK_classroom
          ? `${schedule?.FK_classroom.location}`
          : schedule?.class_location
          ? schedule.class_location
          : '-'}
      </td>
      <td
        className="lecture_common_btn"
        style={{
          ...columns[columnIndex++].style,
          ...(lecture.class_status === 'pending' || lecture.class_status === 'ongoing'
            ? isDoneBeforeSurvey || !beforeSurvey
              ? { color: 'lightgray' }
              : {}
            : isDoneAfterSurvey || !afterSurvey
            ? { color: 'lightgray' }
            : {}),
        }}
      >
        {renderSurveyLink(lecture, beforeSurvey, isDoneBeforeSurvey, afterSurvey, isDoneAfterSurvey, userClassId)}
      </td>
      <td
        className="lecture_short_btn"
        style={{
          ...columns[columnIndex++].style,
          ...((lecture.class_status === 'ongoing' || lecture.FK_class_id === 465) && lecture.FK_class.url_attached_file
            ? {}
            : { color: 'lightgray' }),
        }}
      >
        {(lecture.class_status === 'ongoing' || lecture.FK_class_id === 465) && lecture.FK_class.url_attached_file ? (
          <Link
            to={{
              pathname: lecture.FK_class.url_attached_file,
            }}
            target="_blank"
            onClick={onClickMaterial}
          >
            <FormattedMessage id="MY_PAGE_LECTURES_DOWNLOAD_FILE" />
          </Link>
        ) : (
          '-'
        )}
      </td>
      <td className="lecture_certificate" style={columns[columnIndex++]?.style}>
        <CertificateButton lecture={lecture} userInfo={userInfo} />
      </td>
      <td className="lecture_review" style={columns[columnIndex++]?.style}>
        <WriteReviewBtn
          reviews={lecture.class_review}
          title={lecture.FK_class.title}
          classId={lecture.FK_class.id}
          completionRate={scheduleRate}
          lectureType="offline"
        />
      </td>
    </tr>
  );
}

function WriteReviewBtn(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      generalRating: 5,
      contentRating: 5,
      timeRating: 5,
      tutorRating: 5,
    },
  });
  const { formatMessage } = useIntl();
  const { reviews, title, classId, completionRate, lectureType } = props;

  const [generalPoint, setGeneralPoint] = useState(5);
  const [contentPoint, setContentPoint] = useState(5);
  const [timePoint, setTimePoint] = useState(5);
  const [tutorPoint, setTutorPoint] = useState(5);
  const [isSubmit, setIsSubmit] = useState(false);
  const ref = useRef();

  const onSubmit = async (data) => {
    if (isSubmit) {
      return;
    }
    setIsSubmit(true);
    await POST({
      url: `/class/${classId}/review`,
      body: data,
      header: { EJE_API_KEY: localStorage.getItem('eje_token') },
    })
      .then((res) => {
        return res;
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmit(false);
          window.location.reload();
        }, 2000);
      });
  };

  return reviews.filter((review) => review.status >= 1).length > 0 ? (
    <span className="btn write-review-btn complete">
      <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_COMPLETE" />
    </span>
  ) : (
    <>
      <button
        className="btn write-review-btn"
        style={{
          opacity: completionRate < (lectureType === 'offline' ? 100 : 30) ? 0.3 : 1,
        }}
        onClick={() => {
          if (completionRate < (lectureType === 'offline' ? 100 : 30)) {
            swal({
              icon: 'warning',
              text:
                lectureType === 'offline'
                  ? formatMessage({ id: 'REVIEW_WARNING_ENDDATE' })
                  : formatMessage({ id: 'REVIEW_WARNING_RATE' }),
            });
          } else {
            ref.current.open();
          }
        }}
      >
        <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW" />
      </button>
      <Popup ref={ref} className="write-review-popup-wrapper" modal>
        <img className="popup-close" src={closeIcon} alt="close" onClick={() => ref.current.close()} />
        <form className="write-review-popup" onSubmit={handleSubmit(onSubmit)}>
          <h3 className="write-review-popup-title">
            <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_TITLE" />
          </h3>
          <div className="write-review-popup-banner">
            <img src={CouponIcon} alt="banner" />
            <span>
              <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_BANNER" />
            </span>
          </div>
          <div className="write-review-popup-content">
            <h4 className="write-review-popup-content-title">{title}</h4>
            <div className="write-review-popup-content-form-fieldset">
              <div className="write-review-popup-content-general">
                <h5>
                  <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_TITLE" />
                </h5>
                <input
                  type="hidden"
                  {...register('generalRating', {
                    required: true,
                    value: generalPoint,
                  })}
                />
                <Rating
                  value={generalPoint}
                  emptyIcon={<StarIcon fontSize="inherit" />}
                  onChange={(event, newValue) => {
                    setGeneralPoint(newValue ?? 1);
                    setValue('generalRating', newValue ?? 1);
                  }}
                />
                <textarea
                  className={`write-review-popup-content-general-textarea ${errors.generalComment ? 'error' : ''}`}
                  aria-invalid={errors.generalComment ? 'true' : 'false'}
                  placeholder={formatMessage({
                    id: 'MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_PLACEHOLDER',
                  })}
                  {...register('generalComment', {
                    required: true,
                  })}
                />
              </div>
              <div className="write-review-popup-content-detail">
                <div className="write-review-popup-content-detail-title">
                  <span>
                    <img src={DetailIcon} alt="detail" />
                  </span>
                  <p>
                    <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_HEADER" values={{ br: <br /> }} />
                  </p>
                </div>
                <div className="write-review-popup-content-detail-content">
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_CONTENT_QUESTION',
                      }),
                    }}
                  />
                  <input
                    type="hidden"
                    {...register('contentRating', {
                      required: true,
                      value: contentPoint,
                    })}
                  />
                  <Rating
                    value={contentPoint}
                    emptyIcon={<StarIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      setContentPoint(newValue ?? 1);
                      setValue('contentRating', newValue ?? 1);
                    }}
                  />
                  {contentPoint > 0 && contentPoint < 3 && (
                    <textarea
                      placeholder={formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                      })}
                      {...register('contentComment', {
                        required: true,
                      })}
                      className={`${errors.contentComment ? 'error' : ''}`}
                      aria-invalid={errors.contentComment ? 'true' : 'false'}
                    />
                  )}
                </div>
                <div className="write-review-popup-content-detail-time">
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TIME_QUESTION',
                      }),
                    }}
                  />
                  <input
                    type="hidden"
                    {...register('timeRating', {
                      required: true,
                      value: timePoint,
                    })}
                  />
                  <Rating
                    value={timePoint}
                    emptyIcon={<StarIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      setTimePoint(newValue ?? 1);
                      setValue('timeRating', newValue ?? 1);
                    }}
                  />
                  {timePoint > 0 && timePoint < 3 && (
                    <textarea
                      placeholder={formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                      })}
                      {...register('timeComment', {
                        required: true,
                      })}
                      className={`${errors.timeComment ? 'error' : ''}`}
                      aria-invalid={errors.timeComment ? 'true' : 'false'}
                    />
                  )}
                </div>
                <div className="write-review-popup-content-detail-tutor">
                  <div
                    className="question"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TUTOR_QUESTION',
                      }),
                    }}
                  />
                  <input
                    type="hidden"
                    {...register('tutorRating', {
                      required: true,
                      value: tutorPoint,
                    })}
                  />
                  <Rating
                    value={tutorPoint}
                    emptyIcon={<StarIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                      setTutorPoint(newValue ?? 1);
                      setValue('tutorRating', newValue ?? 1);
                    }}
                  />
                  {tutorPoint > 0 && tutorPoint < 3 && (
                    <textarea
                      placeholder={formatMessage({
                        id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                      })}
                      {...register('tutorComment', {
                        required: true,
                      })}
                      className={`${errors.tutorComment ? 'error' : ''}`}
                      aria-invalid={errors.tutorComment ? 'true' : 'false'}
                    />
                  )}
                </div>
                <div className="write-review-popup-content-detail-comments">
                  <div className="question">
                    <strong>
                      <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_QUESTION" />
                    </strong>
                  </div>
                  <textarea
                    className="write-review-popup-content-general-textarea"
                    placeholder={formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_PLACEHOLDER',
                    })}
                    {...register('suggestionComment')}
                    className={`${errors.suggestionComment ? 'error' : ''}`}
                    aria-invalid={errors.suggestionComment ? 'true' : 'false'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`write-review-popup-error-alert ${Object.keys(errors).length > 0 ? '' : 'hidden'}`}>
            <span>
              <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_FORM_ERROR" />
            </span>
          </div>

          <button type="submit" className="write-review-popup-content-button">
            {isSubmit ? <Loading /> : <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT" />}
          </button>
        </form>
      </Popup>
    </>
  );
}

export default LectureRow;
