const ko = {
  ID_TITLE: '엔터잡에듀',
  LOGIN_TITLE: '엔터잡에듀 로그인',
  LOGIN_KAKAO: '카카오로 시작하기',
  LOGIN_NAVER: '네이버로 시작하기',
  LOGIN_GOOGLE: '구글로 시작하기',
  LOGIN_FACEBOOK: '페이스북으로 시작하기',
  LOGIN_EMAIL: '계정 로그인',
  LOGIN: '로그인',
  SIGNUP_TITLE: '엔터잡에듀 회원가입',
  SIGNUP_MESSAGE: '즉시 사용할 수 있는 2000 포인트를 바로 드려요!',
  SIGNUP_KAKAO: '카카오로 시작하기',
  SIGNUP_GOOGLE: '구글로 시작하기',
  SIGNUP_NAVER: '네이버로 시작하기',
  SIGNUP_FACEBOOK: '페이스북으로 시작하기',
  SIGNUP_TERMS: '계속하면 엔터잡에듀 {tos} 및 {privacy}에 동의하게 됩니다.',
  SIGNUP_FORM_TITLE: '회원가입',
  SIGNUP_FORM_MESSAGE: '가입완료 후 2000 할인 쿠폰을 지급해드려요!',
  SIGNUP_FORM_SUB_MESSAGE: '(영업일 기준 24시간 이내)',
  SIGNUP_FORM_PROFILE_IMAGE: '프로필 사진',
  SIGNUP_FORM_NICKNAME: '닉네임 (필수)',
  SIGNUP_FORM_NICKNAME_PLACEHOLDER: '10자 이내로 작성해주세요.(한글/영어)',
  SIGNUP_FORM_CHECK_OVERLAP: '중복 확인',
  SIGNUP_FORM_EMAIL: '이메일 (필수)',
  SIGNUP_FORM_EMAIL_PLACEHOLDER: '예) enterjobedu@k-lab.me',
  SIGNUP_FORM_PHONE_NUMBER: '휴대폰 (필수)',
  SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER: '‘-‘ 없이 숫자만 입력해주세요.',
  SIGNUP_FORM_SEND_VERIFICATION_CODE: '인증번호 발송',
  SIGNUP_FORM_AUTHENTICATE: '인증 하기',
  SIGNUP_FORM_AUTHENTICATE_PLACEHOLDER: '인증번호를 입력하세요.',
  SIGNUP_FORM_TERMS: '혜택 수신 동의 (필수)',
  SIGNUP_FORM_TERMS_MESSAGE: '엔터잡에듀의 다양한 소식을 받아볼 수 있습니다.',
  SIGNUP_FORM_TERMS_AGREEMENT: '동의',
  SIGNUP_FORM_TERMS_DISAGREE: '비동의',
  SIGNUP_FORM_COMPLETED: '가입완료',
  HEADER_USER_NAV_LOGIN: '로그인',
  HEADER_USER_NAV_LOGOUT: '로그아웃',
  HEADER_USER_NAV_SIGNUP: '회원가입',
  HEADER_USER_NAV_MY_PAGE: '마이페이지',
  HEADER_USER_NAV_INQUIRY: '1:1 문의',
  HEADER_USER_NAV_NOTICE: '공지사항',
  HEADER_USER_NAV_EVENT: '이벤트',
  HEADER_USER_NAV_BLOG: '블로그',
  HEADER_INSTRUCTOR_INTRODUCTION: '강사 소개',
  HEADER_GLOBAL_NAV_ONLINE: 'Online Class',
  HEADER_GLOBAL_NAV_OFFLINE: 'Offline Class',
  HEADER_GLOBAL_NAV_FOREIGNER: 'Foreigner Only',
  HEADER_GLOBAL_NAV_GOV: '🎖 정부지원교육',
  FOOTER_NAV_ABOUT_US: '회사소개',
  FOOTER_NAV_INSTRUCTOR_RECRUITMENT: '강사모집',
  FOOTER_NAV_FAQ: 'FAQ',
  FOOTER_NAV_PRIVACY_STATEMENT: '개인정보취급방침',
  FOOTER_NAV_TERMS: '이용약관',
  FOOTER_COMPANY_REGISTRATION_NUMBER: '사업자 등록번호 138-85-33405',
  FOOTER_BUSINESS_REPORT_NUMBER: '통신판매업 신고번호 2021-서울광진-2052',
  FOOTER_LIFELONG_EDUCATION_FACILITY: '평생교육시설 경기도 고양시 일산동구 중앙로 1193, 626호 (원격 609)',
  FOOTER_LOGO: '케이랩컴퍼니',
  FOOTER_COMPANY: '(주)케이랩컴퍼니',
  FOOTER_DELEGATE: '공동대표 김형규, 이상환',
  FOOTER_INFORMATION_MANAGER: '정보관리책임자 이상환',
  FOOTER_ADDRESS: '주소 서울특별시 광진구 자양로 129, 9층',
  FOOTER_EMAIL: 'Contact enterjobedu@k-lab.me / +8210-9866-8640',
  FOOTER_COPYRIGHT: 'COPYRIGHT © (주)케이랩컴퍼니 ALL RIGHTS RESERVED',
  FOOTER_CHANNEL: '엔터잡에듀 채널',
  FOOTER_CHANNEL_LIST_COMMUNITY: 'COMMUNITY',
  FOOTER_CHANNEL_LIST_BLOG: 'BLOG',
  FOOTER_CHANNEL_LIST_YOUTUBE: 'YOUTUBE',
  FOOTER_CHANNEL_LIST_FACEBOOK: 'FACEBOOK',
  FOOTER_CHANNEL_LIST_INSTAGRAM: 'INSTAGRAM',
  FOOTER_SERVICE_CENTER: '고객센터',
  FOOTER_INQUIRY: '문의하기',
  FOOTER_INQUIRY_TIME: '평일 오전 10시 ~ 오후 6시 (주말, 공휴일 제외)',
  MAIN_ONLINE_TITLE: 'BEST 지금 가장 인기있는 온라인 강의예요!',
  MAIN_OFFLINE_TITLE: '놓치지 마세요! 지금 모집 중인 오프라인 강의',
  MAIN_VIDEO_TITLE: '이렇게만 해보세요! 단계별 취업 전략 알려드려요.',
  MAIN_EVENT_TITLE: 'ONLY 회원을 위한 이벤트',
  ORDER_COMPLETED_MESSAGE: '{deadline}로 결제를 완료해주세요.',
  ORDER_COMPLETED_MESSAGE_DEADLINE: '3일 이내',
  MY_PAGE_LECTURES: '내 강의실',
  MY_PAGE_LECTURES_COLUMN_STATUS: '수강 상태',
  MY_PAGE_LECTURES_COLUMN_TITLE: '강의명',
  MY_PAGE_LECTURES_COLUMN_OPTION: '옵션',
  MY_PAGE_LECTURES_COLUMN_GENERATION: '기수',
  MY_PAGE_LECTURES_COLUMN_PERIOD: '수강 기간',
  MY_PAGE_LECTURES_COLUMN_DATE: '강의 일시',
  MY_PAGE_LECTURES_COLUMN_PLACE: '강의 장소',
  MY_PAGE_LECTURES_COLUMN_SURVEY: '설문',
  MY_PAGE_PAYMENT: '결제 내역',
  MY_PAGE_PAYMENT_MESSAGE:
    '결제내역은 {lastMonth} 내역이 기본적으로 보여지며, 이전 내역은 날짜를 변경하여 조회 가능합니다.',
  MY_PAGE_PAYMENT_MESSAGE_LAST_MONTH: '최근 6개월간',
  MY_PAGE_PAYMENT_SELECTED_COUPON: '할인쿠폰을 선택해주세요.',
  MY_PAGE_PAYMENT_COUPON_DISABLED: '무료 강의 쿠폰 선택 불가',
  MY_PAGE_COUPON: '쿠폰 내역',
  MY_PAGE_LIKE_LECTURES: '찜한 강의',
  MY_PAGE_EMPTY_LIKE_LECTURES: '찜한 강의가 없습니다.',
  MY_PAGE_EMPTY_LIKE_LECTURES_BTN: '강의리스트 보기',
  MY_PAGE_PROFILE: '회원 정보 수정',
  MY_PAGE_INQUIRY: '1:1 문의',
  MY_PAGE_HELLO_USER: '반가워요, {user}님!',
  MY_PAGE_AVAILABLE_COUPON: '사용 가능한 쿠폰 {number}장',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_TITLE: '결제 확인서',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_MESSAGE:
    '결제 시 기재하신 이메일 주소로 ‘KG이니시스’를 통해\r\r\n영수증이 자동 발송됩니다.\r\r\n스팸 메일함도 함께 확인 부탁드립니다.\r\r\n\r\r\n확인이 되지 않을 경우 1:1문의를 통해\r\r\n문의주시면 상세 안내드리겠습니다.',
  MY_PAGE_PAYMENT_MODAL_REFUND_TITLE: '환불 신청',
  MY_PAGE_PAYMENT_MODAL_REFUND_MESSAGE:
    '환불신청 또는 환불금액 관련 문의는 1:1문의를\r\r\n    통해 문의주시면 상세히 안내드리겠습니다.',
  MY_PAGE_PAYMENT_MODAL_INFO_TITLE: '결제 정보 확인',
  PLACEHOLDER_LOGIN_EMAIL: '이메일 또는 아이디를 입력해주세요.',
  PLACEHOLDER_LOGIN_PASSWORD: '비밀번호를 입력해주세요.',
  PLACEHOLDER_COUPON_NUMBER: '쿠폰 번호를 입력해주세요.',
  COMMON_CONFIRM: '확인',
  COMMON_LINK_TO_MAIN: '메인페이지로 바로가기',
  COMMON_LINK_TO_MAIN_2: '메인 페이지로 이동',
  COMMON_LINK_TO_MY_LECTURES: '내 강의실로 이동',
  COMMON_LINK_TO_INQUIRY: '1:1 문의 바로가기',
  COMMON_PAYMENT: '지금 등록하기',
  COMMON_REFERENCE: '조회하기',
  COMMON_LINK_TO_SIGNUP: '회원가입 하기',
  COMMON_READ_MORE: '자세히 보기',
  COMMON_COUPON_ENROLLMENT: '쿠폰 등록하기',
  COMMON_COUPON_CHECK_HISTORY: '적용 가능 강좌 확인하기',
  PAYMENT_SUCCESSFULLY_COMPLETED: '결제가 성공적으로 완료되었습니다.',
  CHECK_MY_LECTURE_ROOM: '내 강의실에서 구매한 강의 정보를 확인해보세요!',
  NOT_A_MEMBER_YET: '아직 회원이 아니신가요?',
  FORGOT_MEMBER_INFORMATION: '회원 정보를 잊으셨나요?',
  ORDER_WILL_BE_CANCELED: '기간이 지나면 주문 취소 처리됩니다.',
  CATEGORIES_TITLE_COMPANY: '기업별',
  CATEGORIES_TITLE_FIELD: '산업별',
  CATEGORIES_TITLE_PROCESS: '취업과정별',
  CATEGORIES_COMPANY_SM: 'SM',
  CATEGORIES_COMPANY_JYP: 'JYP',
  CATEGORIES_COMPANY_YG: 'YG',
  CATEGORIES_COMPANY_CJ: 'CJ ENM',
  CATEGORIES_COMPANY_HYBE: 'HYBE',
  CATEGORIES_COMPANY_KAKAO: 'KAKAO',
  CATEGORIES_FIELD_MUSIC: '음악',
  CATEGORIES_FIELD_MOVIE: '영화',
  CATEGORIES_FIELD_BROADCAST: '방송',
  CATEGORIES_FIELD_PERFORMANCE: '공연',
  CATEGORIES_PROCESS_INTRODUCTORY: '입문',
  CATEGORIES_PROCESS_TASK: '직무',
  CATEGORIES_PROCESS_DOCUMENT: '서류',
  CATEGORIES_PROCESS_PERSONALITY: '인적성',
  CATEGORIES_PROCESS_INTERVIEW: '면접',
  CATEGORIES_ETC: '기타',
  CATEGORY_COMPANY_1: 'SM',
  CATEGORY_COMPANY_2: 'JYP',
  CATEGORY_COMPANY_3: 'YG',
  CATEGORY_COMPANY_4: 'CJ ENM',
  CATEGORY_COMPANY_5: 'HYBE',
  CATEGORY_COMPANY_6: 'KAKAO',
  CATEGORY_COMPANY_7: '기타',
  CATEGORY_FIELD_1: '음악',
  CATEGORY_FIELD_2: '영화',
  CATEGORY_FIELD_3: '방송',
  CATEGORY_FIELD_4: '공연',
  CATEGORY_FIELD_5: '기타',
  CATEGORY_PROCESS_1: '입문',
  CATEGORY_PROCESS_2: '직무',
  CATEGORY_PROCESS_3: '서류',
  CATEGORY_PROCESS_4: '인적성',
  CATEGORY_PROCESS_5: '면접',
  CATEGORY_PROCESS_6: '기타',
  NEW: '최신순',
  VIEW: '조회순',
  SELLING: '판매순',
  RESET: '초기화',
  EDIT_MEMBER_INFORMATION: '회원 정보 수정',
  GO_YOUTUBE: 'GO YOUTUBE',
  DISCOUNT: '할인',
  ONLINE_CLASS: '온라인 클래스',
  OFFLINE_CLASS: '오프라인 클래스',
  FOREIGNER_ONLY: 'Foreigner ONLY',
  DUE: '까지',
  MORE: '더보기',
  ADVANCE_RESERVATION_COUNTING: '{n}일 뒤 마감',
  ADVANCE_RESERVATION_COUNTING_ONE: '1일 뒤 마감',
  ADVANCE_RESERVATION_TODAY: '오늘 마감',
  POSSESSION: '보유',
  DONE_EXPIRES: '완료/만료',
  FIND_ID_PASSWORD: '아이디 / 비밀번호 찾기',
  NAME_OF_BANK: '은행명',
  ACCOUNT_HOLDER: '예금주',
  ACCOUNT_NUMBER: '계좌번호',
  DEPOSIT_DEADLINE: '입금 기한',
  DEPOSIT_INFORMATION: '입금 정보',
  DEPOSIT_ACCOUNT: '입금 계좌',
  ORDER_NUMBER: '주문번호',
  ORDER_DATE: '주문일',
  PAYMENT_DATE: '결제일',
  REFUND_DATE: '환불일',
  AMOUNT: '금액',
  TOTAL_AMOUNT: '총 금액',
  USE_COUPON: '쿠폰 사용',
  ORDER_COMPLETED: '주문 완료',
  APPLY_FOR_A_REFUND: '환불 신청',
  ISSUANCE_OF_PAYMENT_CONFIRMATION: '결제 확인서 발급',
  CONFIRM_PAYMENT_INFORMATION: '결제 정보 확인',
  TOTAL_AMOUNT_DUE: '총 결제 예정 금액',
  CONFIRM_OF_ORDER_DETAIL_AND_AGREE: '주문 내용 확인 및 결제 동의',
  PAYMENT_COMPLETED: '결제 완료',
  REFUND_COMPLETED: '환불 완료',
  PAYMENT_WAITING: '결제 대기',
  REFUND_WAITING: '환불 대기',
  AMOUNT_OF_PAYMENT: '결제 금액',
  DISCOUNT_AMOUNT: '할인 금액',
  ORIGINAL_PRICE: '정가',
  PAYMENT_LIST: '결제 목록',
  PAYMENT_METHOD: '결제 수단',
  REFUND_POLICY: '엔터잡에듀 환불정책',
  CONSENT_TO_PROVISION_OF_PERSONAL_INFORMATION_TO_THIRD_PARTIES: '개인정보 제3자 정보 제공 동의',
  AGREE_TO_THE_TERMS_OF_USE_FOR_PAYMENT_AGENCY_SERVICE: '결제대행 서비스 이용약관 동의',
  PURCHASE_CONFIRMATION_AGREEMENT: '구매확인 동의',
  SIGNUP_FORM_ALERT_SUCCESS_NICKNAME: '사용가능한 닉네임입니다.',
  SIGNUP_FORM_ALERT_FAIL_NICKNAME: '이미 존재하는 닉네임입니다.\r\r\n다른 닉네임을 사용해주세요.',
  SIGNUP_FORM_ALERT_SUCCESS_SEND: '인증번호가 발송되었습니다.',
  SIGNUP_FORM_ALERT_SUCCESS_CHECK_CERTIFICATION: '본인 인증이 완료되었습니다.',
  SIGNUP_FORM_ALERT_FAIL_CHECK_CERTIFICATION: '발송된 인증 번호와 일치하지 않습니다.',
  SIGNUP_FORM_CONFIRM_MODAL_TITLE: '환영합니다🎉',
  SIGNUP_FORM_CONFIRM_MODAL_MESSAGE: '엔터잡에듀 회원가입이\r\r\n완료되었습니다!',
  SIGNUP_FORM_CONFIRM_MODAL_LINK_TO_MAIN: '엔터잡에듀 둘러보기',
  SIGNUP_FORM_FILE_SIZE: '10MB 이하 파일만 등록할 수 있습니다.\r\r\n\r\r\n현재파일 용량 : {size} MB',
  HEADER_USER_NAV_ADMIN: '관리자',
  MOBILE_MAIN_VIDEO_TITLE: '이렇게만 해보세요!\r\r\n단계별 취업 전략 알려드려요.',
  DETAIL_TABS_CLASS_INTRODUCTION: '클래스 소개',
  DETAIL_TABS_CURRICULUM: '커리큘럼',
  DETAIL_TABS_REVIEW: '리뷰',
  DETAIL_SAMPLE_VIDEO_MESSAGE: '실제 강의 내용을\r\r\n무료로 체험해보세요!',
  DETAIL_CHAPTER_MESSAGE: '우리 강의는\r\r\n이렇게 구성되어 있어요!',
  DETAIL_REVIEW_MESSAGE: '총 {total}개의\r\r\n후기가 있는 강의예요!',
  DETAIL_INSTRUCTOR: '강사',
  TUTOR_COMPANIES: '경력',
  DETAIL_CLASS_TIME: '수강 일수',
  DETAIL_CLASS_TIME_DAYS: '{day}일',
  DETAIL_NUMBER_OF_LECTURES: '강의 수',
  DETAIL_TOTAL_NUMBER_OF_LECTURES: '{total} 강',
  DETAIL_LECTURE_SATISFACTION: '강의 만족도',
  DETAIL_LECTURE_SCHEDULE: '강의 일정',
  PAYMENT_IMPLEMENTS_FREE: '무료 결제',
  PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD: '신용(체크) 카드',
  PAYMENT_IMPLEMENTS_V_BANK: '가상계좌',
  PAYMENT_IMPLEMENTS_KAKAOPAY: '카카오페이',
  PAYMENT_IMPLEMENTS_TOSS: '토스',
  PAYMENT_IMPLEMENTS_PAYPAL: 'Paypal',
  PAYMENT_COUPON_NONE_OPTIONS: '적용 가능한 쿠폰이 존재하지 않습니다. :(',
  PAYMENT_ERROR_REQUEST: '결제 요청에 실패했습니다.',
  PAYMENT_ERROR: '결제에 실패했습니다.',
  PAYMENT_ERROR_COUPON_1: '존재하지 않는 쿠폰입니다.',
  PAYMENT_ERROR_COUPON_2: '이미 만료된 쿠폰입니다.',
  PAYMENT_ERROR_COUPON_3: '쿠폰 발급이 불가합니다. 1:1문의를 남겨주세요',
  PAYMENT_ERROR_COUPON_4: '이미 등록한 쿠폰입니다.',
  ORDER_COMPLETED_VBANK:
    '\r\r\n가상 계좌 발급이 정상 처리되었습니다.\r\r\n\r\r\n{vbank_name} {vbank_num}\r\r\n입금금액: {paid_amount}원\r\r\n가상계좌 예금주: {vbank_holder}\r\r\n\r\r\n본 가상계좌는 {vbank_date} 까지 유효합니다.',
  ORDER_COMPLETED_PENDING: '결제 상태가 원활하지 않습니다. 다시 시도해주세요.',
  MY_PAGE_LECTURES_COLUMN_REMAIN_DAYS: '남은 일수',
  MY_PAGE_LECTURES_COLUMN_ATTENDANCE_RATE: '수강률',
  MY_PAGE_LECTURES_COLUMN_OPTION: '옵션',
  MY_PAGE_LECTURES_COLUMN_LECTURE_ROOM: '강의실',
  MY_PAGE_LECTURES_SHORTCUTS: '바로가기',
  MY_PAGE_WISH_LIST: '찜하기',
  MY_PAGE_PAYMENT_MESSAGE_MOBILE:
    '- 결제내역은 {lastMonth} 내역이 기본적으로 보여지집니다.\r\r\n- 이전 내역은 날짜를 변경하여 조회 가능합니다.',
  MY_PAGE_POINT: '포인트 내역',
  MY_PAGE_PROFILE_MESSAGE: '회원 정보 수정은\r\r\n1:1 문의를 통해 남겨주세요!',
  MY_PAGE_COUPON_MODAL_TITLE: '본 쿠폰은 아래 강의에 적용됩니다.',
  MY_PAGE_COUPON_MODAL_MOBILE_TITLE: '본 쿠폰은\r\r\n아래 강의에 적용\r\r\n됩니다.',
  LECTURE_STATUS_ONGOING: '수강중',
  LECTURE_STATUS_PENDING: '수강 예정',
  LECTURE_STATUS_END: '수강 종료',
  STANDARD: '기',
  PACKAGE: '[패키지]',
  DAY: '일',
  MY_PAGE_NONE_LECTURE: '내게 필요한 강의를 지금 바로 확인해보세요!',
  MY_PAGE_NONE_PAYMENT: '결제내역이 없습니다. 문의 사항은 1:1문의를 이용해주세요.',
  MY_PAGE_NONE_COUPON: '사용 가능한 쿠폰이 없습니다.',
  MY_PAGE_NOT_EXISTENCE_COUPON: '존재하지 않는 쿠폰 입니다. 쿠폰 번호를 확인해주세요!',
  LANG_KO: '한국어',
  LANG_EN: '영어',
  LANG_JA: '일본어',
  LANG_VI: '베트남어',
  PAYMENT: '결제',
  GO_TO_CLASSROOM: '강의실 바로가기',
  GO_TO_CLASSLIST: '강의목록 바로가기',
  CURRENCY_KOR: '원',
  MAKE_A_PAYMENT: '지금 등록하기',
  MAKE_A_WAITING: '오픈 알림 신청 👆🏻',
  MAKE_A_WAITING_TEXT: '대기 신청을 진행하시겠습니까? \n\n* 오픈 시 알림톡 또는 메일로 안내드립니다.',
  WAITING_SUCCESS: '대기 신청이 완료되었습니다.',
  WAITING_FAIL_ALREADY: '이미 대기 신청하신 강의입니다. \n\n* 조금만 기다려주시면 곧 연락드리겠습니다.',
  WAITING_ALREADY_PURCHASED: '이미 결제(또는 결제 중)하신 강의입니다.',
  ABBREVIATION_MONDAY: '월',
  ABBREVIATION_TUESDAY: '화',
  ABBREVIATION_WEDNESDAY: '수',
  ABBREVIATION_THURSDAY: '목',
  ABBREVIATION_FRIDAY: '금',
  ABBREVIATION_SATURDAY: '토',
  ABBREVIATION_SUNDAY: '일',
  MEET_YOUR_CRITERIA: '조건에 맞는 강의 보기',
  PROFILE_IMAGE_ALT: '프로필 이미지',
  FOLD: '접기',
  PAYMENT_FAIL: '결제 실패',
  CLASSROOM_ATTACHED_FILE: '강의 자료',
  CLASSROOM_DOWNLOAD: '다운로드하기',
  CLASSROOM_CHAPTER: '강의 목차',
  CLASSROOM_COMPLETE_RATE: '수강률',
  CLASSROOM_ATTENDED_TIME: '수강 시간',
  CLASSROOM_CLASS_TIME: '강의 시간',
  LOGIN_ALERT_UNAUTH: '이메일 또는 비밀번호를 확인해주세요.',
  LOGIN_FACEBOOK_ERROR: '페이스북 로그인에 실패했습니다.',
  LOGIN_VALIDATE_ERROR: '이메일 또는 비밀번호를 확인해주세요.',
  SOCIAL_LOGIN_ERROR: '소셜 로그인 과정에서 알 수 없는 오류가 발생했습니다.',
  SIGNUP_FROM_INTRO: '엔터테인먼트의 지금과 앞으로를 위해,\r\n엔터잡에듀와 함께해요.',
  SIGNUP_FORM_USERNAME: '이름 (필수)',
  SIGNUP_FORM_USERNAME_PLACEHOLDER: '본인의 이름을 작성해주세요. (한글/영어)',
  SIGNUP_FORM_AUTHENTICATE_ERROR: '6자리 인증번호를 확인해주세요.',
  SIGNUP_FROM_ALERT_FILE_UPLOAD_ERROR_MESSAGE: '10MB 이하 파일만 등록할 수 있습니다.',
  SIGNUP_FORM_SUCCESS_NICKNAME: '사용가능한 닉네임입니다.',
  SIGNUP_FORM_NICKNAME_VALIDATION_ERROR_MESSAGE: '한글, 영어를 활용해서 최대 10자로 작성해주세요.',
  SIGNUP_FORM_FAIL_NICKNAME: '이미 사용중인 닉네임입니다.',
  SIGNUP_FORM_FAIL_EMAIL: '이메일 양식에 맞추어 작성해주세요.',
  ID_SIGNUP_ERROR_1: '이미 가입된 휴대폰 번호 입니다.',
  ID_SIGNUP_ERROR_2: '인증번호 발송을 재시도 해주세요.',
  HEADER_LOGGED_IN_USER_MESSAGE: '{user}님 우리 함께 목표를 향해 가요!',
  DETAIL_LECTURE_NOT_LOGGED_IN_USER: '지금 등록하기 위해, 우선 로그인해주세요.',
  GENERAL_NOT_LOGGED_IN_USER: '진행하기 위해, 우선 로그인해주세요.',
  DETAIL_LECTURE_NOT_SELECT_SCHEDULE: '강의 일정을 선택해주세요.',
  DETAIL_LECTURE_NOT_SELLING: '판매불가 상품입니다. 1:1문의 주시면 문의사항에 답변드리겠습니다.',
  PAYMENT_ERROR_2: '결제에 실패하였습니다. 에러 내용:',
  PAYMENT_ALREADY_BEEN_PAID: '이미 결제한 강의입니다.',
  PAYMENT_NOT_PAID_MESSAGE: '0원 결제가 불가합니다ㅠㅠ! 1:1 문의 주시면 안내 도와드리겠습니다.',
  ID_COUPON_ERROR_MEESGAE_1: '존재하지 않는 쿠폰입니다.',
  ID_COUPON_ERROR_MEESGAE_2: '이미 만료된 쿠폰입니다.',
  ID_COUPON_ERROR_MEESGAE_3: '쿠폰 발급이 불가합니다. 1:1문의를 남겨주세요',
  ID_COUPON_ERROR_MEESGAE_4: '이미 등록한 쿠폰입니다.',
  MY_PAGE_PROFILE_MESSAGE_1: '회원님의 소중한 정보 수정은\r\n엔터잡에듀 운영진이\r\n직접 해드리고 있어요!',
  MY_PAGE_PROFILE_MESSAGE_2: '1:1 문의 남겨주시면 빠르게 답변 드리겠습니다.',
  MY_PAGE_PROFILE_INQUIRY: '1:1 문의 남기기',
  MY_PAGE_PROFILE_SIGNOUT: '회원 탈퇴하기',
  MY_PAGE_SIGNOUT_MESSAGE: '"진짜 탈퇴하시겠어요? 너무 아쉬워요."',
  MY_PAGE_SIGNOUT_TERM_1: ' 지금 탈퇴하시면 수강 예정이거나, 현재 수강 중인 강의를 더 이상 들을 수 없습니다.',
  MY_PAGE_SIGNOUT_TERM_2: '지금 탈퇴하시면, 보유하신 쿠폰도 모두 사라집니다. 재가입하셔도 복구해드릴 수 없습니다.',
  MY_PAGE_SIGNOUT_TERM_3: '이전에 작성하신 리뷰 등의 활동은 별도로 삭제되지 않습니다.',
  MY_PAGE_SIGNOUT_TERM_AGREE: '위 회원 탈퇴 유의사항을 확인하였으며, 동의합니다.',
  MY_PAGE_SIGNOUT_FORM: '제가 엔터잡에듀를 탈퇴하는 이유는요…',
  MY_PAGE_SIGNOUT_CONFIRM: '탈퇴 완료하기',
  MY_PAGE_SIGNOUT_POLICY: '탈퇴한 계정으로는 \r\n30일 간 가입이 어렵습니다!',
  MY_PAGE_NONE_EXPIRED_COUPON: '완료/만료 된 쿠폰 내역이 없습니다',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD1: '스팸 메일함도 함께 확인',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD2: '1:1문의를 통해',
  MY_PAGE_PAYMENT_MODAL_REFUND_BOLD: '1:1문의를 통해',
  COMMON_CANCEL: '취소',
  DEVELOP_MESSAGE: '엔터잡에듀 서비스 준비중입니다.',
  USED: '사용 완료',
  EXPIRED: '사용 기간 만료',
  GO_TO_SERVICE_CENTER: '고객센터 바로가기',
  ERROR_GENERAL: '알 수 없는 오류 발생',
  ERROR_NETWORK: '네트워크 연결 상태를 확인해주세요.',
  ID_SIGNUP_ERROR_3: '이미 가입된 이메일 입니다.',
  ID_SIGNUP_ERROR_4: '답변 이메일 보내기를 실패했습니다.',
  ID_SIGNUP_ERROR_5: '이미 가입한 유저입니다.',
  ID_SIGNUP_ERROR_6: '탈퇴 후 30일이 경과해야 재가입이 가능합니다.',
  SIGNUP_FORM_INTERESTED_COMPANY: '관심 기업 (필수)',
  SIGNUP_FORM_INTERESTED_COMPANY_SELECT: '기업을 선택해주세요. (다중 선택)',
  SIGNUP_FORM_INTERESTED_COMPANY_ETC: '기타(직접 입력)',
  SIGNUP_FORM_INTERESTED_COMPANY_OTHER: '기타 기업 (필수)',
  SIGNUP_FORM_INTERESTED_COMPANY_OTHER_PLACEHOLDER: '기타 기업을 입력해주세요.',
  SIGNUP_FORM_COUNTRY: '국가정보 (필수)',
  MY_PAGE_LECTURES_SINGLE: '단일 강의',
  MY_PAGE_LECTURES_PACKAGE: '패키지 강의',
  마감임박: '마감임박',
  '이미 마감된 강의입니다': '이미 마감된 강의입니다',

  'Album Credit DB': 'Album Credit DB',
  '국내에서 발매된 {totalCount}개 앨범 크레딧 정보 집합소!{br}KPOP을 세계로 알리고 있는 Staff 정보를 확인해보세요.':
    '국내에서 발매된 {totalCount}개 앨범 크레딧 정보 집합소!{br}KPOP을 세계로 알리고 있는 Staff 정보를 확인해보세요.',
  RELEASE_DATE_DESC: '최신 발매순',
  RELEASE_DATE_ASC: '과거 발매순',
  '전체 {totalCount}개': '전체 {totalCount}개',
  기획사: '기획사',
  아티스트: '아티스트',
  '{totalCount}개의 앨범 크레딧 보기': '{totalCount}개의 앨범 크레딧 보기',
  발매일: '발매일',
  '아티스트와 관련된 다른 앨범 정보도 궁금하지 않으세요?': '아티스트와 관련된 다른 앨범 정보도 궁금하지 않으세요?',
  '앨범 크레딧 "이분들의 땀과 노력이 담겼어요!"': '앨범 크레딧 {br}"이분들의 땀과 노력이 담겼어요!"',
  '다른 앨범 정보도 궁금하지 않으세요?': '다른 앨범 정보도 궁금하지 않으세요?',
  MY_PAGE_LECTURES_COLUMN_REVIEW: '수강 후기',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_TITLE: '수강 후기 작성',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_BANNER:
    '수강후기 작성 시, 영업일 기준 1일 이내에 1,000원 할인 쿠폰을 넣어드려요!',
  MY_PAGE_LECTURES_WRITE_REVIEW_COMPLETE: '완료',
  MY_PAGE_LECTURES_WRITE_REVIEW: '작성',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_TITLE: '총평',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_PLACEHOLDER:
    '강의에 대한 전반적인 의견을 작성해주세요! (100bytes 이상)\r\n작성해주신 내용은 해당 강의 구매를 고민하는 엔터잡에듀 회원분들께 큰 도움이 됩니다.',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_HEADER:
    '아래 항목은 엔터잡에듀 콘텐츠 개선 및 보완을 위해 준비 됐습니다. {br}엔터잡에듀 스탭만 확인 가능한 비공개 항목이므로, 안심하고 답변 주세요!',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_CONTENT_QUESTION:
    '<strong>수업 내용 만족도</strong> : 콘텐츠 구성, 정보의 정확성, 주제 적합성 등에 만족하시나요?',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TIME_QUESTION:
    '<strong>수업 시간 만족도</strong> : 수업 개설 시간, 강사의 수업시간 준수 여부, 강의 전체 러닝 타임 등은 만족스러우셨나요?',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TUTOR_QUESTION:
    '<strong>강사 만족도</strong> : 강사의 지식, 경험, 전달력 등을 기준으로 평가해주세요!',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER:
    '만족스럽지 못했던 부분에 대해 구체적으로 작성해 주시면, 더 나은 방향으로 개선하는 데 큰 도움이 됩니다. (100bytes 이상)',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_QUESTION:
    '엔터잡에듀에서 추가로 개설하면 좋겠다고 생각되는 콘텐츠나 기능이 있으신가요?',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_PLACEHOLDER:
    '엔터테인먼트 회사 취업 준비 중 정보 수집이 어려워 엔터잡에듀에서 대신 찾아주면 좋겠다 싶은 부분이나,\r\n현직자를 통해 듣고 싶은 이야기 주제 등을 기재해주세요!\r\n여러분의 고민 해결을 위해 직접 발로 뛰는 엔터잡에듀가 될게요.',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT: '작성 완료',
  MY_PAGE_LECTURES_WRITE_REVIEW_FORM_ERROR: '필수 정보를 모두 입력해주세요.',
  REVIEW_WARNING_RATE: '30% 이상 수강해야 작성할 수 있습니다.',
  REVIEW_WARNING_ENDDATE: '수강 종료일 이후에 작성할 수 있습니다.',
  COMMON_CLOSE: '닫기',
  COMMON_ALWAYS_CLOSE: '다시 보지 않기',
  COMMON_1DAY_CLOSE: '오늘 하루 보지 않기',
  SEARCH_INPUT_PLACEHOLDER: '관심 직무, 기업 등을 검색해보세요.',
  SEARCH_RECENT_KEYWORDS_TITLE: '최근 검색어',
  COMMON_DELETE_ALL: '전체 삭제',
  SEARCH_RECENT_KEYWORD_EMPTY: '최근 검색어가 없습니다.',
  SEARCH_RESULT_HEADER: '{keyword}에 대한 검색 결과',
  SEARCH_RESULT_EMPTY_KEYWORD: '{keyword}에 대한 검색 결과가 없습니다.',
  SEARCH_RESULT_EMPTY_DESC:
    '띄어쓰기를 확인해보세요.<br />단어의 철자가 정확한지 확인해 보세요.<br />한글을 영어로 혹은 영어를 한글로 입력했는지 확인해보세요.<br />다른 검색어를 사용해보세요.',
  SEARCH_RECOMMEND_KEYWORDS_TITLE: '추천 검색어',
  SEARCH_RECOMMEND_CONTENTS_LECTURES: '지금 인기 있는 강의도 놓치지 마세요!',
  SEARCH_RECOMMEND_CONTENTS_ALBUMS: '추천! 최근 발매된 앨범 크레딧을 확인해보세요.',
  MY_PAGE_LECTURES_COLUMN_ATTACHED_FILE: '강의자료',
  MY_PAGE_LECTURES_DOWNLOAD_FILE: '다운로드',
  MY_PAGE_PROFILE_NAME: '이름(필수)',
  MY_PAGE_PROFILE_NICKNAME: '닉네임(필수)',
  MY_PAGE_PROFILE__PHONE_NUMBER: '휴대폰',
  MY_PAGE_SAVE: '저장',
  MY_PAGE_PROFILE_EMAIL: '이메일',
  MY_PAGE_PROFILE_PHONE: '전화번호',
  MY_PAGE_LABEL_IS_MARKETING_ON: '혜택 수신 동의(필수)',
  MY_PAGE_LABEL_IS_MARKETING_ON_MESSAGE: '할인 이벤트, 쿠폰 발급 등의 알림을 받으시고 혜택을 놓치지 마세요.',
  MY_PAGE_INTERESTED_COMPANIES: '관심기업',
  MY_PAGE_INTERESTED_COMPANIES_MESSAGE: '관심 기업과 관련된 혜택을 받아보세요. (수정 즉시 적용)',
  MY_PAGE_INTERESTED_COMPANIES_EDIT: '관심기업 수정',
  MY_PAGE_PROFILE_UPDATE_SUCCESS: '정보가 수정되었습니다.',
  MY_PAGE_LECTURES_COLUMN_CERTIFICATE: '이수증',
  NO_LECTU비RES: '콘텐츠 준비중입니다.',
  HEADER_USER_NAV_ORG_MGMT: '조직 관리',
  MY_PAGE_ORG_MGMT: '조직 관리',
  MY_PAGE_ORG_MGMT_MEMBERS: '구성원 관리',
  MY_PAGE_ORG_MGMT_LECTURES: '강의 관리',
  LIST: '목록',
  NOTICE_DETAIL_ATTACH: '첨부파일',
  LECTURE_SELLING_COUNT: '{count}명 수강',
  REQUEST_FOR_CLASS: '신청하기 👆🏻',
};

export default ko;
