import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GET } from '../../../Commons/Utils/fetch';
import View from './View';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'online', // online, offline
      currentSubTab: 'single', // single, package
      isLoading: true,
      hasCompulsory: false,
      lectures: [],
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      sessionStorage.setItem('login_redirect', `/my/lectures`);
      this.props.history.push('/login');
    }
    this.loadLectures(this.state.currentTab);
  }

  filterDuplicate(arr) {
    const lectureIdList = arr.map((lecture) => {
      return lecture.FK_class_id;
    });

    let duplicateIdList = lectureIdList.map((lectureId) => {
      const isDup = lectureIdList.indexOf(lectureId) !== lectureIdList.lastIndexOf(lectureId);

      if (isDup) {
        return lectureId;
      }
      return null;
    });

    duplicateIdList = duplicateIdList.filter((duplicateId) => duplicateId !== null);

    duplicateIdList = [...new Set(duplicateIdList)];

    duplicateIdList.forEach((duplicateId) => {
      let duplicatearr = arr.map((lecture) => {
        if (lecture.FK_class_id === duplicateId) {
          return lecture;
        }
        return null;
      });
      duplicatearr = duplicatearr.filter((lecture) => lecture !== null);
      let count = 0;
      arr = arr.map((lecture) => {
        if (lecture.FK_class_id === duplicateId) {
          count++;
          if (count === 1) {
            return duplicatearr[duplicatearr.length - 1];
          } else {
            return null;
          }
        }
        return lecture;
      });
      arr = arr.filter((lecture) => lecture !== null);
    });

    return arr;
  }

  loadLectures(type) {
    this.setState({ isLoading: true }, async () => {
      const originLectures = [];
      originLectures.push(...(await this.loadSingleLectures(type)));
      const compulsoryLectures = await this.loadCompulsoryLectures();
      originLectures.push(...compulsoryLectures);
      if (type === 'online') {
        originLectures.push(...(await this.load3rdLectures(type)));
        originLectures.push(...(await this.loadPackageLectures()));
      } else if (type === 'offline') {
        originLectures.push(...(await this.load3rdLectures(type)));
        originLectures.push(...(await this.loadSingleLectures('consulting')));
      }
      // const lectures = this.filterDuplicate(originLectures);

      this.setState({
        lectures: this.sortLectures(originLectures),
        isLoading: false,
        hasCompulsory: compulsoryLectures.length > 0,
        originLectures,
      });
    });
  }

  sortList(list, key, type) {
    return list.sort((a, b) => {
      if (type === 'asc') {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });
  }

  sortLectures(lectures) {
    lectures.forEach((lecture) => {
      lecture.start_date_order = new Date(lecture.start_date).getTime();
      lecture.end_date_order = new Date(lecture.end_date).getTime();
      lecture.permission_review_order =
        lecture.completion_rate < (lecture.FK_class.listen_type === 'offline' ? 100 : 30) ? 0 : 1;
    });
    const ongoings = lectures.filter((lecture) => lecture.class_status === 'ongoing');
    const pendings = lectures.filter((lecture) => lecture.class_status === 'pending');
    const ends = lectures.filter((lecture) => lecture.class_status === 'end');

    return [].concat(
      this.sortList(ongoings, 'end_date_order', 'asc'),
      this.sortList(pendings, 'start_date_order', 'desc'),
      this.sortList(ends, 'start_date_order', 'desc'),
    );
  }

  loadSingleLectures(type) {
    return GET({
      url: `/classes/me/single/${type}`,
      header: { EJE_API_KEY: localStorage.getItem('eje_token') },
      params: { page: 1, limit: 1000 }, // temp
    })
      .then((result) => {
        return result.userClasses.rows;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  loadCompulsoryLectures() {
    return GET({
      url: `/classes/me/single/compulsory`,
      params: { page: 1, limit: 1000 }, // temp
    })
      .then((result) => {
        return result.userClasses.rows.map((lecture) => {
          return {
            ...lecture,
            FK_class: {
              ...lecture.FK_class,
              listen_type: 'compulsory',
            },
          };
        });
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  load3rdLectures(type) {
    return GET({
      url: `/classes/me/single/${type}`,
      header: { EJE_API_KEY: localStorage.getItem('eje_token') },
      params: { page: 1, limit: 1000, host: 'gov' }, // temp
    })
      .then((result) => {
        return result.userClasses.rows;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  loadPackageLectures() {
    return GET({
      url: `/classes/me/package`,
      header: { EJE_API_KEY: localStorage.getItem('eje_token') },
      params: { page: 1, limit: 1000 }, // temp
    })
      .then((result) => {
        return result.userClasses.rows;
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  onClickTab = (tab) => {
    this.setState({ currentTab: tab }, () => {
      this.loadLectures(tab);
    });
  };

  onClickSubTab = (subtab) => {
    this.setState({ currentSubTab: subtab });
  };

  render() {
    return <View {...this.state} onClickTab={this.onClickTab} onClickSubTab={this.onClickSubTab} />;
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(Container);
