import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import MobileHeader from '../Components/MobileHeader';
import MyNav from '../Components/MyNav';

const MyPage = ({ children }) => {
  const { orgId } = useParams();
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const userInfo = useSelector((state) => state.user.userInfo);
  const targetOrganization = useMemo(() => {
    return orgId;
  }, [orgId]);
  const organizations = useMemo(
    () => (userInfo?.Organizations?.length > 0 ? userInfo?.Organizations : []),
    [userInfo?.Organizations],
  );
  const hasOrganization = useMemo(() => organizations.length > 0, [organizations]);
  const hasOrgMgmtRole = useMemo(
    () => organizations.find((org) => org.role === 'supervisor' || org.role === 'manager'),
    [organizations],
  );

  const organization = useMemo(
    () => organizations.find((org) => +org.id === +targetOrganization),
    [organizations, targetOrganization],
  );

  useEffect(() => {
    if (isMobile && pathname.includes('/organization')) {
      swal('', 'PC에서만 이용 가능합니다.', 'warning', {
        button: '확인',
      }).then(() => {
        replace('/my');
      });
    }
  }, [isMobile, pathname, replace]);

  return (
    <div>
      {isMobile ? <MobileHeader /> : <Header />}
      <div className="my_page_content_wrap">
        <MyNav
          hasOrgMgmtRole={hasOrgMgmtRole}
          hasOrganization={hasOrganization}
          organizations={organizations}
          targetOrganization={targetOrganization}
        />
        <div className="my_page_content">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default MyPage;
