import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import DropdownLeftIcon from '../../../Assets/Images/icon-dropdown-left.png';
import './index.scss';

const MobileHeader = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const navList = useMemo(
    () => [
      {
        to: '/organization',
        key: 'MY_PAGE_ORG_MGMT',
      },
      {
        to: '/my/lectures',
        key: 'MY_PAGE_LECTURES',
      },
      {
        to: '/my/payment',
        key: 'MY_PAGE_PAYMENT',
      },
      {
        to: '/my/coupon',
        key: 'MY_PAGE_COUPON',
      },
      {
        to: '/my/like-lectures',
        key: 'MY_PAGE_LIKE_LECTURES',
      },
      {
        to: '/my/profile',
        key: 'MY_PAGE_PROFILE',
      },
      {
        href: 'https://enterjobedu.channel.io/',
        key: 'MY_PAGE_INQUIRY',
      },
    ],
    [],
  );

  const currentPageKey = navList.find((nav) => nav.to === pathname)?.key || '';

  const onClickHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className="my_page_mobile_header">
      <img src={DropdownLeftIcon} alt="go_back" onClick={onClickHistoryBack} />
      {currentPageKey && <FormattedMessage id={currentPageKey} />}
    </div>
  );
};

export default MobileHeader;
