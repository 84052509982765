import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import DropdownLeftIcon from '../../Assets/Images/icon-dropdown-left.png';
import Footer from '../../Commons/Components/Footer';
import LectureItem from '../../Commons/Components/LectureItem';
import Meta from '../../Commons/Components/Meta';
import Pagination from '../../Commons/Components/Pagination';

import Main from '../../Commons/Layouts/Main';
import { GET } from '../../Commons/Utils/fetch';
import RecordItem from '../../Pages/KpopDBDetail/components/record';

import './index.scss';

export default function SearchResultPage() {
  const intl = useIntl();
  const metaInfo = {
    title: '통합검색 | 엔터테인먼트 미디어 콘텐츠 취업 | 엔터잡에듀',
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [recentSearch, setRecentSearch] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const location = useLocation();
  const history = useHistory();

  const { q = '', category = 'lectures', page = 1 } = queryString.parse(location.search);
  const searchHistory = localStorage.getItem('searchHistory');

  useEffect(() => {
    if (searchHistory) {
      setRecentSearch(JSON.parse(searchHistory));
    }
  }, [searchHistory]);

  const updateRecentSearch = useCallback((text) => {
    if (text !== '') {
      setRecentSearch((prev) => {
        if (prev.includes(text)) {
          return prev;
        }
        localStorage.setItem('recentSearch', JSON.stringify([text, ...prev]));
        return [text, ...prev];
      });
    }
  }, []);

  useEffect(() => {
    if (q) {
      if (searchHistory) {
        const searchHistoryList = JSON.parse(searchHistory);
        if (!searchHistoryList.includes(q)) {
          searchHistoryList.push(q);
          localStorage.setItem('searchHistory', JSON.stringify(searchHistoryList));
        }
      } else {
        localStorage.setItem('searchHistory', JSON.stringify([q]));
      }
    }
  }, [recentSearch]);

  const [classes, setClasses] = useState([]);
  const [records, setRecords] = useState([]);
  const [recommendKeywords, setRecommendKeywords] = useState([]);
  const [recommendContents, setRecommendContents] = useState([]);

  useEffect(async () => {
    setSearchText(q);
    const cate = category === 'lectures' ? 'class' : 'record';
    try {
      const EJE_API_KEY = localStorage.getItem('eje_token') || 'auth';
      const actionType = sessionStorage.getItem('searchActionType') || 'visit';
      sessionStorage.setItem('searchActionType', 'refresh');
      const fetchData = await GET({
        url: '/auth/search',
        params: {
          query: getQueryText(q),
          page,
          cate,
          actionType,
        },
        header: {
          // EJE_API_KEY: localStorage.getItem('eje_token')
          EJE_API_KEY,
        },
      });
      setClasses(fetchData.classes);
      setRecords(fetchData.records);
      setList(category === 'lectures' ? fetchData.classes.list : fetchData.records.list);
      setTotalCount(category === 'lectures' ? fetchData.classes?.count || 0 : fetchData.records?.count || 0);
      const { classRecommends, recordRecommends } = await GET({
        url: '/auth/search/recommend',
        params: {
          cate: category === 'lectures' ? 'class' : 'record',
        },
        header: {
          EJE_API_KEY: 'auth',
        },
      });

      if (category === 'lectures') {
        setRecommendKeywords(classRecommends?.classRecommendWord || []);
        setRecommendContents(classRecommends?.classRecommendItem || []);
      } else {
        setRecommendKeywords(recordRecommends?.recordRecommendWord || []);
        setRecommendContents(recordRecommends?.recordRecommendItem || []);
      }
    } catch (error) {
      console.error(error);
    }
  }, [category, page, q]);

  const onClickPaginate = useCallback(
    (number) => {
      history.push(`${location.pathname}?category=${category}&q=${getQueryText(q)}&page=${number}`);
      window.scrollTo(0, 0);
    },
    [location, history],
  );

  function getQueryText(searchText) {
    return searchText.replace('&', '%26').replace('#', '%23').replace('?', '%3F').replace(',', '%2C').trim();
  }

  return isMobile && !q ? (
    <>
      <div className="mobile_search_page">
        <div className="mobile_header">
          <img src={DropdownLeftIcon} alt="go_back" onClick={() => history.go(-1)} />
          <div className="mobile_search_input_wrap">
            <input
              type="text"
              placeholder={intl.formatMessage({
                id: 'SEARCH_INPUT_PLACEHOLDER',
              })}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateRecentSearch(searchText);
                  sessionStorage.setItem('searchActionType', 'search');
                  history.push(`/search?category=${category}&q=${getQueryText(searchText)}`);
                }
              }}
            />
          </div>
        </div>

        <div className="search_result_wrap">
          {recentSearch.length > 0 && (
            <>
              <div className="search_result_wrap_words">
                <div className="search_result_wrap_words_title_wrap">
                  <h3>
                    <FormattedMessage id="SEARCH_RECENT_KEYWORDS_TITLE" />
                  </h3>
                  <button
                    className="search_result_wrap_words_clear_btn"
                    onClick={() => localStorage.removeItem('searchHistory')}
                  >
                    <FormattedMessage id="COMMON_DELETE_ALL" />
                  </button>
                </div>
                <ul className="search_result_wrap_words_list">
                  {recentSearch.map((item) => (
                    <li key={item}>
                      <Link to={`/search?q=${getQueryText(item)}`}>{item}</Link>
                      <button
                        onClick={() => {
                          recentSearch.splice(recentSearch.indexOf(item), 1);
                        }}
                      >
                        delete
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="divider" />
            </>
          )}
          <div className="search_result_wrap_keywords">
            <h3>
              <FormattedMessage id="SEARCH_RECOMMEND_KEYWORDS_TITLE" />
            </h3>
            <ul className="search_result_wrap_keywords_list">
              {recommendKeywords.map(({ word, searchCategory: category }) => (
                <li key={word}>
                  <Link
                    to={`/search?q=${getQueryText(word)}`}
                    onClick={() => {
                      sessionStorage.setItem('searchActionType', 'search');
                    }}
                  >
                    <button>{word}</button>
                  </Link>
                </li>
              ))}
              <li style={{ width: '100%' }}></li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : isMobile && q ? (
    <>
      <div className="mobile_search_page">
        <div className="mobile_header">
          <img src={DropdownLeftIcon} alt="go_back" onClick={() => history.go(-1)} />
          <div className="mobile_search_input_wrap">
            <input
              type="text"
              value={searchText}
              placeholder={intl.formatMessage({
                id: 'SEARCH_INPUT_PLACEHOLDER',
              })}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  sessionStorage.setItem('searchActionType', 'search');
                  history.push(`/search?q=${getQueryText(searchText)}`);
                }
              }}
            />
          </div>
        </div>
        <div className="search_result_wrap">
          <div className="search_result_tabs">
            <Link
              className={category === 'lectures' ? 'active' : ''}
              to={{
                pathname: '/search',
                search: `?category=lectures&q=${getQueryText(searchText)}`,
              }}
            >
              Class ({classes?.count || 0})
            </Link>
            <Link
              className={category === 'albums' ? 'active' : ''}
              style={{ opacity: 0, pointerEvents: 'none' }}
              to={{
                pathname: '/search',
                search: `?category=albums&q=${getQueryText(searchText)}`,
              }}
            >
              Album Credit ({records?.count || 0})
            </Link>
          </div>
          <div className="search_result_list">
            {list.length > 0 ? (
              <div className="search_result_list_inner_wrap makers_list">
                {list.map((item, index) =>
                  category === 'lectures' ? (
                    <LectureItem key={index} item={item} isSearched={true} className="mb-6 md:mb-[60px]" />
                  ) : (
                    <RecordItem key={index} data={item} isSearched={true} />
                  ),
                )}
                {Array.from({ length: ((list.length % 4) - 4) * -1 }).map((_, i) => (
                  <div className="lecture_item" key={i} />
                ))}
              </div>
            ) : (
              <>
                <div className="not_found">
                  <h3>
                    <FormattedMessage
                      id="SEARCH_RESULT_EMPTY_KEYWORD"
                      values={{
                        keyword: <span className="search_text">{searchText}</span>,
                      }}
                    />
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'SEARCH_RESULT_EMPTY_DESC',
                      }),
                    }}
                  />
                </div>
                <div className="related_contents">
                  <strong>
                    <FormattedMessage id={`SEARCH_RECOMMEND_CONTENTS_${category.toUpperCase()}`} />
                  </strong>
                  <div className="search_result_list_inner_wrap makers_list">
                    {recommendContents?.map((item, index) =>
                      category === 'lectures' ? (
                        <LectureItem key={index} item={item} isSearched={true} className="mb-6 md:mb-[60px]" />
                      ) : (
                        <RecordItem key={index} data={item} isSearched={true} />
                      ),
                    )}
                  </div>
                </div>
              </>
            )}

            {totalCount > 20 && (
              <Pagination total={totalCount} limit={20} currentPage={parseInt(page)} paginate={onClickPaginate} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <Main>
      <Meta metaInfo={metaInfo} />
      <div className="search_page_wrap">
        <div className="inner_wrap">
          <h2>
            <FormattedMessage
              id="SEARCH_RESULT_HEADER"
              values={{
                keyword: <span className="search_text">{searchText}</span>,
              }}
            />
          </h2>
          <div className="search_result_wrap">
            <div className="search_result_tabs">
              <Link
                className={category === 'lectures' ? 'active' : ''}
                to={{
                  pathname: '/search',
                  search: `?category=lectures&q=${getQueryText(searchText)}`,
                }}
              >
                Class ({classes?.count || 0})
              </Link>
              <Link
                className={category === 'albums' ? 'active' : ''}
                style={{ opacity: 0, pointerEvents: 'none' }}
                to={{
                  pathname: '/search',
                  search: `?category=albums&q=${getQueryText(searchText)}`,
                }}
              >
                Album Credit ({records?.count || 0})
              </Link>
            </div>
            <div className="search_result_list">
              {list.length > 0 ? (
                <div className="search_result_list_inner_wrap makers_list">
                  {list.map((item, index) =>
                    category === 'lectures' ? (
                      <LectureItem key={index} item={item} isSearched={true} className="mb-6 md:mb-[60px]" />
                    ) : (
                      <RecordItem key={index} data={item} isSearched={true} />
                    ),
                  )}
                  {Array.from({ length: ((list.length % 4) - 4) * -1 }).map((_, i) => (
                    <div className="lecture_item" key={i} />
                  ))}
                </div>
              ) : (
                <>
                  <div className="not_found">
                    <h3>
                      <FormattedMessage
                        id="SEARCH_RESULT_EMPTY_KEYWORD"
                        values={{
                          keyword: <span className="search_text">{searchText}</span>,
                        }}
                      />
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: 'SEARCH_RESULT_EMPTY_DESC',
                        }),
                      }}
                    />
                  </div>
                  <div className="related_contents">
                    <strong>
                      <FormattedMessage id={`SEARCH_RECOMMEND_CONTENTS_${category.toUpperCase()}`} />
                    </strong>
                    <div className="search_result_list_inner_wrap makers_list">
                      {recommendContents?.map((item, index) =>
                        category === 'lectures' ? (
                          <LectureItem key={index} item={item} isSearched={true} className="mb-6 md:mb-[60px]" />
                        ) : (
                          <RecordItem key={index} data={item} isSearched={true} />
                        ),
                      )}
                    </div>
                  </div>
                </>
              )}

              {totalCount > 20 && (
                <Pagination total={totalCount} limit={20} currentPage={parseInt(page)} paginate={onClickPaginate} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
