import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { GET, POST } from '../../../Commons/Utils/fetch';
import View from './View';
import { injectIntl } from 'react-intl';

class Container extends PureComponent {
  state = {
    isLoading: false,
    couponList: [],
    currentType: 'all',
    couponCode: '',
  };

  constructor(props) {
    super(props);

    this.tabList = [
      {
        type: 'all',
      },
      {
        type: 'expired',
      },
    ];

    this.token = localStorage.getItem('eje_token');
  }

  onChangeCouponCode = (e) => {
    const value = e.target.value;
    this.setState({
      couponCode: value,
    });
  };

  getCoupons = async (type = 'all') => {
    try {
      this.setState({
        isLoading: true,
      });

      const params = {
        page: 1,
        limit: 100,
      };

      const { userCoupons } = await GET({
        url: '/benefit/coupons/me',
        params,
        header: { EJE_API_KEY: this.token },
      });

      let usedAndExpiredCoupons = [];
      const holdCoupons = userCoupons.rows.filter((row) => {
        if (row.status === 'available' || row.status === 'prev') return true;
        usedAndExpiredCoupons.push(row);
        return false;
      });

      this.setState({
        isLoading: false,
        couponList: type === 'all' ? holdCoupons : usedAndExpiredCoupons,
      });
    } catch (error) {
      console.error(error);
    }
  };

  onClickCouponTabs = (type) => {
    if (this.state.currentType === type) return;

    this.setState({
      currentType: type,
    });

    this.getCoupons(type);
  };

  onClickEnrollmentCoupon = async (code) => {
    const body = {
      code,
    };

    try {
      await POST({
        url: '/benefit/coupon/me',
        body,
        header: { EJE_API_KEY: this.token },
      })
        .then((res) => {
          if (res.success) {
            this.getCoupons();
          } else {
            swal({
              icon: 'error',
              text: this.props.intl.formatMessage({
                id: res.message,
              }),
            });
          }
        })
        .finally(() => {
          this.setState({
            couponCode: '',
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      sessionStorage.setItem('login_redirect', `/my/coupon`);
      this.props.history.push('/login');
    }

    this.getCoupons();
  }

  render() {
    const { isLoading, couponList, currentType, couponCode } = this.state;
    const { onClickEnrollmentCoupon, onClickCouponTabs, onChangeCouponCode } = this;
    return (
      <View
        isLoading={isLoading}
        tabList={this.tabList}
        couponList={couponList}
        currentType={currentType}
        couponCode={couponCode}
        onChangeCouponCode={onChangeCouponCode}
        onClickCouponTabs={onClickCouponTabs}
        onClickEnrollmentCoupon={onClickEnrollmentCoupon}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Container));
