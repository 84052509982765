import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import DropdownLeftIcon from '../../../Assets/Images/icon-dropdown-left.png';
import GrayDropdownRightIcon from '../../../Assets/Images/icon-dropdown-right-gray.png';
import DropdownRightIcon from '../../../Assets/Images/icon-dropdown-right.png';
import ProfileDefaultImage from '../../../Assets/Images/profile-custom.png';
import VoucherIcon from '../../../Assets/Images/voucher.png';
import { logout } from '../../../Commons/Store/Actions/user';
import { GET } from '../../../Commons/Utils/fetch';
import { boardList, myPageList } from './constant';
import './index.scss';
import { setGnb } from '../../../Commons/Store/Actions/gnb';
import { URL } from '../../../Commons/Utils/constant';

const MyPageNav = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const history = useHistory();

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userInfo = useSelector((state) => state.user.userInfo);
  const ejeToken = localStorage.getItem('eje_token');
  const isAdmin = userInfo && userInfo.role === 'administrator' && ejeToken;
  const hasOrgMgmtRole = userInfo?.Organizations?.filter(
    (org) => org.role === 'supervisor' || org.role === 'manager',
  ).length;

  const [availableCoupons, setAvailableCoupons] = useState(0);
  const [recentNoticeCount, setRecentNoticeCount] = useState(0);

  const onClickHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const getUserCoupon = useCallback(async () => {
    try {
      const params = {
        page: 1,
        limit: 100,
      };

      const { userCoupons } = await GET({
        url: '/benefit/coupons/me',
        params,
        header: {
          EJE_API_KEY: ejeToken,
        },
      });

      const couponCount = userCoupons.rows.filter((row) => row.status === 'available').length;

      setAvailableCoupons(couponCount);

      // 최근 공지사항 개수 가져오기
      const {
        data: { count },
      } = await GET({
        url: '/auth/posts/recent-count',
        params: {
          boardCode: 'notice',
        },
        header: {
          EJE_API_KEY: ejeToken,
        },
      });

      setRecentNoticeCount(count);
    } catch (error) {
      console.error(error);
    }
  }, [ejeToken]);

  const getGnbList = async () => {
    try {
      const { success, data } = await GET({
        url: '/auth/system/gnbs',
      });

      if (success) {
        dispatch(setGnb(data.rows));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickLogout = useCallback(async () => {
    dispatch(logout());
    await getGnbList();

    history.push('/login');
  }, [dispatch, history]);

  useEffect(() => {
    getUserCoupon();
  }, [getUserCoupon]);

  useEffect(() => {
    // 모바일 전용 페이지이므로, 모바일 아닐 시 메인으로 리다이렉션
    if (!isMobile) {
      history.push('/');
    }

    if (!isLoggedIn) {
      history.push('/login');
    }
  }, [isMobile, isLoggedIn, history]);

  const handleImageError = useCallback((e) => {
    e.target.src = ProfileDefaultImage;
  }, []);

  return (
    <div className="my_nav_page">
      <div className="mobile_header">
        <img src={DropdownLeftIcon} alt="go_back" onClick={onClickHistoryBack} />
      </div>

      <div className="mobile_user_nav_content">
        {isLoggedIn && (
          <div className="mobile_user_info">
            <img
              className="profile_img"
              src={(userInfo.image_profile || ProfileDefaultImage)?.toCloudFrontURL()}
              onError={handleImageError}
              alt="profile_img"
            />
            <div className="user_desc">
              <h4>
                <FormattedMessage
                  id="MY_PAGE_HELLO_USER"
                  values={{
                    user: userInfo.nickname,
                  }}
                />
              </h4>
              <p>
                <img className="voucher" src={VoucherIcon} alt="voucher_icon" />
                <FormattedMessage
                  id="MY_PAGE_AVAILABLE_COUPON"
                  values={{
                    number: availableCoupons, // 사용가능한 쿠폰
                  }}
                />
                <img src={GrayDropdownRightIcon} alt="dropdown_right_icon" />
              </p>
            </div>
          </div>
        )}

        <ul className="mobile_user_nav">
          {isAdmin && (
            <li>
              <a href={`${URL.ADMIN}?key=${ejeToken}`} target="blank">
                <FormattedMessage id="HEADER_USER_NAV_ADMIN" />
                <img src={DropdownRightIcon} alt="dropdown_right_icon" />
              </a>
            </li>
          )}
          {hasOrgMgmtRole && (
            <li>
              <Link to="/organization">
                <FormattedMessage id="HEADER_USER_NAV_ORG_MGMT" />
                <img src={DropdownRightIcon} alt="dropdown_right_icon" />
              </Link>
            </li>
          )}
          {myPageList.map((option) =>
            option.href ? (
              <li key={option.href}>
                <a href={option.href} target="_blank" rel="noreferrer">
                  <FormattedMessage id={option.key} />
                  <img src={DropdownRightIcon} alt="dropdown_right_icon" />
                </a>
              </li>
            ) : (
              <li key={option.to}>
                <Link to={option.to}>
                  <FormattedMessage id={option.key} />
                  <img src={DropdownRightIcon} alt="dropdown_right_icon" />
                </Link>
              </li>
            ),
          )}
          {boardList.map((option) => (
            <li key={option.to}>
              <Link to={option.to}>
                <span>
                  <FormattedMessage id={option.key} />
                  {recentNoticeCount > 0 && <span className="recent-notice-count">{recentNoticeCount}</span>}
                </span>
                <img src={DropdownRightIcon} alt="dropdown_right_icon" />
              </Link>
            </li>
          ))}
          <li onClick={onClickLogout} className="logout">
            <FormattedMessage id="HEADER_USER_NAV_LOGOUT" />
            <img src={DropdownRightIcon} alt="dropdown_right_icon" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MyPageNav;
