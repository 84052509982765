import '../index.scss';

function Otherpay() {
  return (
    <div className="payment_term">
      <h1 id="69628d19-bb58-4a33-a3b4-3f195fde40d9" className="page-title">
        결제대행 서비스 이용약관
      </h1>
      <div className="page-body">
        <div id="65d77046-0e52-4983-8032-565ee638c9d8">
          1. 전자금융거래 이용약관
          <div className="indented">
            <div id="18dbaa20-f6ee-4506-b7c3-1660b8fdc057" className="">
              <p id="0b85fa65-4ecc-4c9f-bb23-c485b27b8312" className="">
                <strong>제1조(목적)</strong>
              </p>
              <p id="b3f577cb-6db4-441d-8f2f-bd08344d7a88" className="">
                이 약관은 주식회사 케이지이니시스(이하 ‘회사’라 합니다)가 제공하는 전자지급결제대행서비스 및
                결제대금예치서비스를 이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에 관한 기본적인 사항을
                정함을 목적으로 합니다.
              </p>
              <p id="60caf4bc-81d8-4d49-9de2-f0bfde1887f1" className="">
                <strong>제2조</strong>
                <strong>(용어의 정의)</strong>
              </p>
              <p id="38eb2edc-b1ca-4f65-9a06-ef7bf9496eda" className="">
                이 약관에서 정하는 용어의 정의는 다음과 같습니다. 1. ‘전자금융거래’라 함은 회사가 전자적 장치를 통하여
                전자지급결제대행서비스 및 결제대금예치서비스 (이하 ‘전자금융거래 서비스’라고 합니다)를 제공하고,
                이용자가 회사의 종사자와 직접 대면하거나 의사소 통을 하지 아니하고 자동화된 방식으로 이를 이용하는
                거래를 말합니다.2. ‘전자지급수단’이라 함은 전자자금이체, 직불전자지급수단, 선불전자지급수단, 전자화폐,
                신용카드, 전자채 권 그 밖에 전자적 방법에 따른 지급 수단을 말합니다.3. ‘전자지급결제대행서비스’라 함은
                전자적 방법으로 재화의 구입 또는 용역의 이용에 있어서 지급결제정보 를 송신하거나 수신하는 것 또는 그
                대가의 정산을 대행하거나 매개하는 서비스를 말합니다.4. ‘결제대금예치서비스’라 함은 이용자가 재화의 구입
                또는 용역의 이용에 있어서 그 대가(이하 ‘결제대금’이 라 한다)의 전부 또는 일부를 재화 또는 용역(이하
                ‘재화 등’이라 합니다)을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서비스 이용
                확인시점까지 결제대금을 예치하는 서비스를 말합니다.5. ‘이용자’라 함은 이 약관에 동의하고 회사가 제공하는
                전자금융거래 서비스를 이용하는 자를 말합니다.6. ‘접근매체’라 함은 전자금융거래에 있어서 거래지시를
                하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 수단 또는 정보로서 전자식 카드 및
                이에 준하는 전자적 정보(신용카드번호를 포함한다), ‘전자서명법’상의 인증서, 회사에 등록된 이용자번호,
                이용자의 생체정보, 이상의 수단이나 정보 를 사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서
                정하고 있는 것을 말합니다.7. ‘거래지시’라 함은 이용자가 본 약관에 의하여 체결되는 전자금융거래계약에
                따라 회사에 대하여 전자금 융거래의 처리를 지시하는 것을 말합니다.8. ‘오류’라 함은 이용자의 고의 또는
                과실 없이 전자금융거래가 전자금융거래계약 또는 이용자의 거래지시 에 따라 이행되지 아니한 경우를
                말합니다.9. ‘정보통신망’이라 함은 전기통신설비를 이용하거나 전기통신설비와 컴퓨터 및 컴퓨터의 이용기술을
                활용 하여 정보를 수집∙가공∙검색∙송신 또는 수신하는 정보통신체제를 말합니다.
              </p>
              <p id="6ce4f698-7e05-4a4c-9a91-becf9c6832c6" className="">
                본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 전자금융거래법 등 관계 법령에 따릅니다.
              </p>
              <p id="30124fbc-7263-48cd-84a8-68f5e6215f1e" className="">
                <strong>제3조</strong>
                <strong>(약관의 명시</strong>
                <strong>및 변경)</strong>
              </p>
              <p id="d07afcc9-2cb0-498f-a6db-834c5a79441a" className="">
                1. 회사는 이용자가 전자금융거래 서비스를 이용하기 전에 이 약관을 게시하고 이용자가 이 약관의 중요한
                내용을 확인할 수 있도록 합니다.2. 회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에 의하여 본
                약관의 사본을 이용자에게 교부합니다.3. 회사가 약관을 변경하는 때에는 그 시행일 1개월 전에 변경되는
                약관을 회사가 제공하는 전자금융거래 서비스 이용 초기화면 및 회사의 홈페이지에 게시함으로써 이용자에게
                공지합니다.
              </p>
              <p id="d510255d-2e98-412a-b954-643a2aba779e" className="">
                <strong>제4조</strong>
                <strong>(전자지급결제</strong>
                <strong>대행서비스의 종류)</strong>
              </p>
              <p id="8ff33b53-1952-46bb-b1e3-6111f8c23628" className="">
                회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다
              </p>
              <p id="4963c989-0eba-4523-b3ea-399d935d8887" className="">
                1. 신용카드결제대행서비스 : 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우
                로서, 회사가 전자결제시스템을 통하여 신용카드 지불정보를 송,수신하고 결제대금의 정산을 대행하거나
                매개하는 서비스를 말합니다.2. 계좌이체대행서비스 : 이용자가 결제대금을 회사의 전자결제시스템을 통하여
                금융기관에 등록 정산을 대행하거나 매개하는 서비스를 말합니다. 한 자신의 계좌에서 출금하여 원하는 계좌로
                이체할 수 있는 실시간 송금 서비스를 말합니다.3. 가상계좌서비스 : 이용자가 결제대금을 현금으로 결제하고자
                경우 회사의 전자결제시스템을 통하여 자동 으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의
                지급이 이루어지는 서비스를 말합니다.4. 기타 : 회사가 제공하는 서비스로서 지급결제수단의 종류에 따라
                ‘휴대폰 결제대행서비스’, ‘ARS결제대행 서비스’, ‘상품권결제대행서비스’등이 있습니다.
              </p>
              <p id="b29891ca-ad70-465d-b5e2-488d6dc7167e" className="">
                <strong>제5조</strong>
                <strong>(결제대금예치</strong>
                <strong>서비스의 내용)</strong>
              </p>
              <p id="cb035d85-ee49-4c11-a496-4e59cc31498b" className="">
                1. 이용자(이용자의 동의가 있는 경우에는 재화 등을 공급받을 자를 포함합니다. 이하 본 조에서 같습니다)는
                재화 등을 공급받은 사실을 재화 등을 공급받은 날부터 3영업일 이내에 회사에 통보하여야 합니다.2. 회사는
                이용자로부터 재화 등을 공급받은 사실을 통보받은 후 회사와 통신판매업자간 사이에서 정한 기일 내에
                통신판매업자에게 결제대금을 지급합니다.3. 회사는 이용자가 재화 등을 공급받은 날부터 3영업일이 지나도록
                정당한 사유의 제시없이 그 공급 받은 사실을 회사에 통보하지 아니하는 경우에는 이용자의 동의없이
                통신판매업자에게 결제대금을 지급할 수 있습니다.4. 회사는 통신판매업자에게 결제대금을 지급하기 전에
                이용자에게 결제대금을 환급받을 사유가 발생한 경우에는 그 결제대금을 소비자에게 환급합니다.5. 회사는
                이용자와의 결제대금예치서비스 이용과 관련된 구체적인 권리,의무를 정하기 위하여 본 약관과는 별도로
                결제대금예치서비스이용약관을 제정할 수 있습니다.
              </p>
              <p id="c8c77f19-0843-4c5b-b96d-33fde240ef4e" className="">
                <strong>제6조</strong>
                <strong>(이용시간)</strong>
              </p>
              <p id="134b1330-3b9c-424a-9bab-bb7a320928fe" className="">
                1. 회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를 제공함을 원칙으로 합니다.단, 금융기관
                기타 결제수단 발행업자의 사정에 따라 달리 정할 수 있습니다.2. 회사는 정보통신설비의 보수, 점검 기타
                기술상의 필요나 금융기관 기타 결제수단 발행업자의 사정에 의하여 서비스 중단이 불가피한 경우, 서비스 중단
                3일 전까지 게시가능한 전자적 수단을 통하여 서비스 중단 사실을 게시한 후 서비스를 일시 중단할 수
                있습니다.다만, 시스템 장애복구, 긴급한 프로그램 보수, 외부요인 등 불가피한 경우에는 사전 게시없이
                서비스를 중단할 수 있습니다
              </p>
              <p id="b0761b1a-ed17-43f8-b17c-dd32d9f79d96" className="">
                <strong>제7조</strong>
                <strong>(접근매체의</strong>
                <strong>선정과 사용</strong>
                <strong>및 관리)</strong>
              </p>
              <p id="9e29e98b-3c2a-4af1-a590-a60d3e0b4a44" className="">
                1. 회사는 전자금융거래 서비스 제공 시 접근매체를 선정하여 이용자의 신원, 권한 및 거래지시의 내용 등을
                확인할 수 있습니다. 해당정보를 이용하지 않으며, 기 요구 시에는 즉각 폐기하여 어떠한 용도로도 사용할 수
                없도록 합니다.2. 이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공
                할 수 없습니다.3. 이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며,
                접근매체의 도용이 나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.회사는 이용자로부터
                접근매체의 분실이나 도난 등의 통지를 받은 때에는 그 때부터 제3자가 그 접근 매 체를 사용함으로 인하여
                이용자에게 발생한 손해를 배상할 책임이 있습니다.유출에 주의하여 주시기 바랍니다.
              </p>
              <p id="bcd2f245-e26a-4204-94cb-87969a0d14e2" className="">
                <strong>제8조</strong>
                <strong>(거래내용의 확인)</strong>
              </p>
              <p id="6e755c04-438f-4284-a74b-f2eee59c6295" className="">
                1. 회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의 거래내용(이용자의 ‘오류정정 요구 사실 및
                처리결과에 관한 사항’을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이 있는 경우에는 요청을 받은
                날로부터 2주 이내에 모사전송 등의 방법으로 거래내용에 관한 서면을 교부합니다.2. 회사가 이용자에게
                제공하는 거래내용 중 거래계좌의 명칭 또는 번호, 거래의 종류 및 금액, 거래 상대방 을 나타내는 정보,
                거래일자, 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보와 해당 전자금융 거래와 관련한 전자적
                장치의 접속기록은 5년간, 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한 기록, 전자지급수단 이용시
                거래승인에 관한 기록, 이용자의 오류정정 요구 사실 및 처리결과에 관한 사항은 1년간의 기간을 대상으로
                합니다.3. 이용자가 제1항에서 정한 서면교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수
                있습니다.– 주소 : (04517) 서울특별시 중구 통일로 92 케이지타워 14,15층 ㈜케이지이니시스– 이메일 주소 :
                sm@kggroup.co.kr– 전화번호 : 1588-4954
              </p>
              <p id="b12c7aee-e0fb-436b-ad87-1fc150a7594c" className="">
                <strong>제9조</strong>
                <strong>(오류의 정정 등)</strong>
              </p>
              <p id="44ade404-4e05-41ef-a8f6-9afed9bc5d63" className="">
                1. 이용자는 전자금융거래 서비스를 이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구할
                수 있습니다.2. 회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후
                정정요구를 받 은 날부터 2주 이내에 그 결과를 이용자에게 알려 드립니다.3. 이용자는 전자금융거래 서비스를
                이용함에 있어 오류가 있음을 안 때에는 회사에 대하여 그 정정을 요구 할 수 있습니다.4. 회사는 전항의
                규정에 따른 오류의 정정요구를 받은 때에는 이를 즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에
                그 결과를 이용자에게 알려 드립니다.
              </p>
              <p id="af7ba58e-3386-4535-b3df-6a6e1a1403d2" className="">
                <strong>제10조</strong>
                <strong>(회사의 책임)</strong>
              </p>
              <p id="c8628609-cb44-400e-9b38-fc6dfdb60f0f" className="">
                1. 회사는 다음 각호의 사유 발생으로 인하여 ‘이용자’에게 손해가 발생하였을 경우 이에 대한 배상 책임이
                있습니다.– 접근매체의 위조나 변조로 발생한 사고– 계약체결 또는 거래지시의 전자적 전송이나 처리 과정에서
                발생한 사고– 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망’에 침입하여 거짓이나 그 밖의 부정한
                방법으로 획득한 접근매체의 이용으로 발생한 사고2. 본 조 제1항에도 불구하고 다음 각 호의 사유로 발생한
                사고에 대해선, 그 책임의 전부 또는 일부를 ‘이용자’가 부담합니다– 법인(‘중소기업기본법’ 제2조 제2항에
                의한 소기업을 제외합니다)인 ‘이용자’에게 손해가 발생한 경우로 서 ‘회사’가 사고를 방지하기 위하여
                보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우– ‘이용자’가
                제7조 제2항을 위반하여 접근매체를 제3자에게 대여하거나 사용을 위임하거나 양도 또는 담보 목적으로 제공한
                경우– 제3자가 권한 없이 ‘이용자’의 접근매체를 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수
                있었음에도 불구하고 ‘이용자’가 자신의 접근매체를 누설 또는 노출하거나 방치한 경우– 제7조 제1항의 따른
                확인 외에 보안강화를 위하여 전자금융거래 시 ‘회사’가 요구하는 추가적인 보안조 치를 요구하였음에도
                불구하고 ‘이용자’가 정당한 사유 없이 이를 거부하여 ‘회사’의 정보통신망에 침입 하여 거짓이나 그 밖의
                부정한 방법으로 사고가 발생한 경우– 이용자가 제3호에 따른 추가적인 보안조치에 사용되는 접근매체에 대하여
                다음 각 목의 어느 하나에 해 당하는 행위를 하여 ‘회사’의 정보통신망에 침입하여 거짓이나 그 밖의 부정한
                방법으로 사고가 발생한 경우가. 누설 또는 방치한 행위나. 제3자에게 대여하거나 그 사용을 위임한 행위 또는
                양도나 담보의 목적으로 제공한 행위
              </p>
              <p id="96709ac8-46f5-4832-8914-d30cc4f3c4ad" className="">
                <strong>제11조</strong>
                <strong>(전자지급거래</strong>
                <strong>계약의 효력)</strong>
              </p>
              <p id="f056d6c3-c2fd-450d-b2fd-b3fb51529f91" className="">
                1. 회사는 이용자의 거래지시가 전자지급거래에 관한 경우 그 지급절차를 대행하며, 전자지급거래에 관한
                거래지시의 내용을 전송하여 지급이 이루어지도록 합니다.2. 회사는 이용자의 전자지급거래에 관한 거래지시에
                따라 지급거래가 이루어지지 않은 경우 수령한 자금을 이용자에게 반환하여야 합니다.
              </p>
              <p id="b1a77e21-a1bd-499d-926b-32d11133fccc" className="">
                <strong>제12조</strong>
                <strong>(거래지시의</strong>
                <strong>철회)</strong>
              </p>
              <p id="6715a972-d614-49d1-be75-594eaa17fca8" className="">
                1. 이용자는 전자지급거래에 관한 거래지시의 경우 지급의 효력이 발생하기 전까지 거래지시를 철회할 수
                있습니다.2. 전항의 지급의 효력이 발생 시점은 다음 각 호의 사유를 말합니다.– 전자자금이체의 경우에는
                거래지시된 금액의 정보에 대하여 수취인의 계좌가 개설되어 있는 금융기관 의 계좌이체 원장에 입금기록이
                끝난 때– 그 밖의 전자지급 수단으로 지급하는 경우에는 거래지시된 금액의 정보가 수취인의 계좌가 개설되어
                있 는 금융기관의 전자적 장치에 입력이 끝난 때3. 이용자는 지급의 효력이 발생한 경우에는 전자상거래
                등에서의 소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법 또는 본 약관 제5조에서 정한 바에 따라
                결제대금을 반환받을 수 있습니다.
              </p>
              <p id="0ec6e6f1-dbf6-453b-8055-bb55c911ae5d" className="">
                <strong>제13조</strong>
                <strong>(전자지급결제</strong>
                <strong>대행 서비스</strong>
                <strong>이용 기록의</strong>
                <strong>생성 및 보존)</strong>
              </p>
              <p id="c7abab1f-3338-48eb-a744-6bb8f67e72f6" className="">
                1. 회사는 이용자가 전자금융거래의 내용을 추적, 검색하거나 그 내용에 오류가 발생한 경우에 이를 확인하
                거나 정정할 수 있는 기록을 생성하여 보존합니다.2. 전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류
                및 보존방법은 제8조 제2항에서 정한 바에 따릅 니다.
              </p>
              <p id="0d11a7c5-9e68-47af-9b56-7b49878ca543" className="">
                <strong>제14조</strong>
                <strong>(분쟁처리 및</strong>
                <strong>분쟁조정)</strong>
              </p>
              <p id="d9c6937a-a875-409d-99f0-aaa2ff265632" className="">
                회사는 전자금융거래 서비스를 제공함에 있어서 취득한 이용자의 인적사항, 이용자의 계좌, 접근매체 및
                전자금융거래의 내용과 실적에 관한 정보 또는 자료를 이용자의 동의를 얻지 아니하고 제3자에게
                제공,누설하거나 업무상 목적 외에 사용하지 아니합니다. 다만 「금융실명거래 및 비밀보장에 관한 법률」
                제4조 제1항 단서의 규정에 따른 경우 및 그 밖에 다른 법률에서 정하는 바에 따른 경우에는 그러하지
                아니하다.
              </p>
              <p id="31e52d0b-a0e9-43a4-a2ae-4c704fa5d5e5" className="">
                <strong>제15조</strong>
                <strong>(전자금융 거래정보의 제공금지)</strong>
              </p>
              <p id="a815309f-9e4c-439c-8e24-94dde3890c5f" className="">
                1. 이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여 전자금융거래 서비스 이용과 관련한 의견 및 불만 의
                제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.– 담당자 : RM팀– 연락처(전화번호, FAX) :
                3430-5847, 3430-5889– E-mail : inirm@kggroup.co.kr2. 이용자가 회사에 대하여 분쟁처리를 신청한 경우에는
                회사는 15일 이내에 이에 대한 조사 또는 처리 결과 를 이용자에게 안내합니다.3. 이용자는 ‘금융감독기구의
                설치 등에 관한 법률’ 제51조의 규정에 따른 금융감독원의 금융분쟁조정위원회 나 ‘소비자보호법’ 제31조
                제1항의 규정에 따른 소비자보호원에 회사의 전자금융거래 서비스의 이용과 관련한 분쟁조정을 신청할 수
                있습니다.
              </p>
              <p id="603c07c7-eab3-428b-9893-d3ba3cba9775" className="">
                <strong>제16조</strong>
                <strong>(회사의 안정성 확보 의무)</strong>
              </p>
              <p id="3c18a8a2-0d05-45e3-b13e-f8ca1461c9ab" className="">
                회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로 전자적 전송이나 처리를
                위한 인력, 시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여 금융감독위원회가 정하는 기준을
                준수합니다.
              </p>
              <p id="fef5fde1-def9-427e-85d3-2dd85693b2f0" className="">
                <strong>제17조</strong>
                <strong>(약관 외 준칙 및 관할)</strong>
              </p>
              <p id="ec49e911-2f15-417a-aad1-0650715273e6" className="">
                1. 이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한
                법률, 통신판매에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에 따릅니다.2. 회사와
                이용자간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.부칙 (2010년 4월 12일)– 최초
                시행일자 : 2007년 1월 1일– 변경 공고일자 : 2019년 4월 23일– 변경 시행일자 : 2019년 5월 23일
              </p>
            </div>
          </div>
          <div id="70d99235-20f8-424c-8a06-4cf7d651a732" className="">
            2. 개인정보 수집 및 이용 동의
            <div className="indented">
              <p id="05fa1bcc-5ab8-4ee3-a1f5-099d8567b332" className="">
                [개인정보의 수집 및 이용목적]
              </p>
              <p id="261e8df7-c3fd-4ea7-95e7-558e5d0d7781" className="">
                다음의 목적을 위하여 개인정보를 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지
                않습니다.
              </p>
              <p id="1378bd27-935c-47f0-82a4-9cc98bf732d4" className="">
                <strong>1) 전자금융거래서비스 제공에 관한 결제 정보 등 개인정보 수집</strong>
              </p>
              <p id="12f30065-1f54-455b-b17a-3a316b2234d0" className="">
                · 이용자가 구매한 재화나 용역의 대금 결제 및 현금영수증 발행· 결제 과정 중 본인 식별, 인증, 실명확인 및
                이용자가 결제한 거래의 내역을 요청하는 경우 응대 및 확인· 이용자가 결제한 거래의 취소 또는 환불, 상품
                배송 등 전자상거래 관련 서비스 제공· 이용자가 결제한 대금의 청구 및 수납· 전자금융거래 및
                통신과금서비스이용 불가능한 이용자(미성년자 등)와 불량, 불법 이용자의 부정 이용 방지· 서비스 제공 및
                관련 업무 처리에 필요한 동의 또는 철회 등 의사확인· 회사가 제공하는 결제 서비스 알림 메시지 및 메일
                발송· 고객 불만 및 민원처리· 이용자의 테이블오더 결제
              </p>
              <p id="709b3a00-b266-417d-aecf-716f07de1c3c" className="">
                <strong>2) 전자결제서비스 계약 체결</strong>· 서비스 제공 계약 체결 관련 상담· 서비스 제공 계약 조건
                확인을 위한 본인식별 및 실명확인
              </p>
              <p id="cb819382-78d6-43e2-a6d8-a24402591735" className="">
                <strong>3) 오프라인결제 계약 채결</strong>· 서비스 이용 및 계약 안내, 기타 문의사항 처리 등 고객 상담·
                기타 업무 제휴 등 다양한 의견 청취
              </p>
              <p id="0502f12f-45f4-457a-8f6d-b4557f3e98ae" className="">
                <strong>4) 업무 제휴</strong>· 신규 가맹 계약, 사업 제휴 제안, 광고 제휴, 투자 문의 등 다양한 분야의
                의견청취 및 비즈니스 참여
              </p>
              <p id="ef7e0f24-e00d-4b1e-9c4f-0373a5cc1e98" className="">
                <strong>5) 고객 상담 및 민원처리</strong>· 고객 및 가맹점 문의, 서비스 문의 (추가 및 변경), 분쟁 해결,
                제안, 부조리 제보 등 민원업무
              </p>
              <p id="891facd5-f794-4032-9774-56474050a7ea" className="">
                <strong>6) 렌탈 서비스 결제 및 계약자 본인확인</strong>· 렌탈 서비스 계약, 해피콜 진행 및 계약자
                본인확인, 렌탈료 청구 및 결제· 이용자의 신용도 판단정보
              </p>
              <p id="8bad8213-0cc7-41a2-86c0-cfcbd02fade4" className="">
                <strong>7) 채용 관리</strong>· 입사지원, 입사와 관련된 문의 응답, 취업보호 대상자의 확인 채용단계 우대
                조건 부여· 지원인력 보존 및 인재 풀 등록
              </p>
              <p id="0d128b46-492b-425b-b0dd-3665d0523760" className="">
                <strong>8) 신규 서비스 개발 및 인구통계학적 특성에 따른 통계자료 활용</strong>· 이용 빈도 파악 및
                통계학적 특성에 따른 서비스 제공· 신규 서비스 개발 및 통계학적 특성 분석· 서비스의 유효성 확인, 접속빈도
                파악, 회원의 서비스이용에 대한 통계자료 활용
              </p>
              <p id="f4fcc5f8-f42f-4e61-af22-7534eb4d417e" className=""></p>
              <p id="7dda6b91-af31-48cf-b59f-a0d61e977928" className="">
                이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기 합니다. 단, 아래와
                같이 전자금융거래법, 전자상거래 등에서의 소비자 보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률,
                신용정보의 이용 및 보호에 관한 법률 관련 법령에 의하여 보존할 필요가 있는 경우 관련 법령에서 정한 일정한
                기간 동안 개인정보를 보존합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
                아래와 같습니다.
              </p>
              <p id="f81c28ef-fdfc-44b7-a713-b2e7a9c40d74" className="">
                · 건당 1만원 초과 전자금융거래에 관한 기록보존이유 : 전자금융거래법보존기간 : 5년
              </p>
              <p id="5cb730da-d304-40a6-a82b-72187707f46b" className="">
                · 건당 1만원 이하 전자금융거래에 관한 기록보존이유 : 전자금융거래법보존기간 : 1년
              </p>
              <p id="56cdc00d-277b-49ba-b905-5cf2a9ef3642" className="">
                · 계약 또는 청약 철회 등에 관한 기록보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률보존기간 : 5년
              </p>
              <p id="ffacfef4-818f-42a1-9835-9a715f4fba62" className="">
                · 대금결제 및 재화 등의 공급에 관한 기록보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률보존기간 :
                5년
              </p>
              <p id="f3d69490-1730-4889-9672-39c7f6447eff" className="">
                · 소비자의 불만 또는 분쟁 처리에 관한 기록보존이유 : 전자상거래 등에서의 소비자보호에 관한 법률보존기간
                : 3년
              </p>
              <p id="2c0f381d-d96e-4e72-ae18-c34cf2563452" className="">
                · 전자결제 신청을 통한 서비스 제공 계약 체결 관련 정보보존이유 : 상담 이력 확인 및 분쟁 발생에 대한
                소명자료 활용보존기간 : 상담완료 후 6개월
              </p>
              <p id="6e59cde5-769b-4f43-a3d9-ad98a8030420" className="">
                · 문자(SMS/LMS) 및 카카오톡 비즈메세지에 대한 발송 기록보존이유 : 통신사실 확인자료 활용보존기간 :
                메시지 발송 후 1년
              </p>
              <p id="8c9462a5-3f87-45c2-ba40-c9193b79b540" className="">
                · 오프라인결제 계약 채결 및 문의 관련 수집되는 정보보존이유 : 제안, 투자 이력 확인 및 검토결과
                회신보존기간 : 문의 접수 후 1년
              </p>
              <p id="64b28098-dbba-4554-808d-231df6bce50f" className="">
                · 업무제휴 문의를 통한 제휴 및 투자 제안자의 정보보존이유 : 문의 이력 확인 및 응대보존기간 : 문의 접수
                후 1년
              </p>
              <p id="c21814de-4275-4fc9-93df-8ea2979d1217" className="">
                · 입사지원을 통한 입사지원자의 정보보존이유 : 지원인력 보존 및 인재풀 등록보존기간 : 1년
              </p>
              <p id="2f6aadf4-367b-42f9-bbd1-451379771b7b" className="">
                · 렌탈서비스 결제 및 계약자 본인확인보존이유 : 렌탈 서비스 계약, 해피콜 진행 및 계약자 본인확인,
                민원처리보존기간 : 계약 종료 시까지
              </p>
              <p id="1ff1c67b-1f31-4b8a-bc35-33b8b1593194" className="">
                · 신용도 판단정보 조회보존이유 : 신용정보의 이용 및 보호에 관한 법률보존기간 : 상거래관계가 종료된
                날부터 5년까지.
              </p>
              <p id="cb1e000a-27d6-47d6-8091-0062522029c8" className="">
                단, 법령에 별도 규정이 있을 경우 그 시점까지
              </p>
              <p id="ca074b1d-84f5-4137-9073-894209292f51" className=""></p>
            </div>
          </div>
          <div id="5dd76dff-00a6-48f6-9b6a-1cd78c61c97d" className="">
            3. 개인정보 제 3자 제공 동의
            <div className="indented">
              <p id="b1e8fe3e-41e7-475b-9721-2c4cfa805e2e" className="">
                회사는 이용자의 개인정보를 본 개인정보처리방침에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이
                동 범위를 초과하여 이용하거나 이용자의 개인 정보를 제3자에게 제공하지 않습니다.
              </p>
              <p id="9bfd9cfd-c50b-4ccc-a70e-d8d1cf916d62" className="">
                다만, 관련 법령에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 등에 개인정보를
                제공하여야 하는 경우는 예외로 합니다. 회사의 서비스 이행을 위하여 개인정보를 제3자에게 제공하고 있는
                경우는 다음과 같습니다.
              </p>
              <div id="fa1396e8-0c12-4be6-8171-81eb655ce273" className="collection-content">
                <table className="collection-content">
                  <thead>
                    <tr>
                      <th>제공목적</th>
                      <th>제공받은 자</th>
                      <th>개인정보의 항목</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr id="c5b5b4ba-2c0f-463d-a6b8-361ffd37d5b8">
                      <td className="cell-title">신용카드 결제</td>
                      <td className="cell-^imh">
                        – 8개 카드사: 국민, 비씨, 롯데, 삼성, NH농협, 현대, 신한, KEB하나– 4개 시중은행: 신한, SC제일,
                        씨티, 하나– 8개 특수은행: 농협, 기업, 국민, 저축, 수협, 신협, 우체국, 새마을금고– 7개 지방은행:
                        대구, 부산, 경남, 광주, 전북, 조흥, 제주– 1개 전업카드사: 우리– 12개 VAN사: (주)코밴,
                        KIS정보통신, NICE정보통신, 브이피㈜, 한국신용카드결제(주), 퍼스트데이터코리아, ㈜케이에스넷,
                        스타VAN, 제이티넷, KICC, 스마트로,NHN한국사이버결제 주식회사
                      </td>
                      <td className="cell-srZp">거래정보 (카드번호, 고객명, 생년월일,전화번호)※비씨카드:IP포함</td>
                    </tr>
                    <tr id="d05d25a7-26c7-49d8-9b4a-1b8877874ba8">
                      <td className="cell-title">계좌이체 결제</td>
                      <td className="cell-^imh">
                        – 금융결제원– 경남/광주/국민/기업/농협/대구/부산/산업/새마을금고/
                        수협/신한/신협/우리/우체국/전북/제주/KEB하나/씨티/SC제일은행/ 산림조합/카카오뱅크/케이뱅크(주)–
                        유안타/현대/미래에셋/한투/우리투자/하이투자/HMC투자/
                        SK/대신/하나대투/신한금융/동부/유진투자/메리츠/신영/ 삼성/한화/대우증권– 쿠콘
                      </td>
                      <td className="cell-srZp">
                        거래정보 (계좌번호, 고객명, 생년월일)※금융결제원 주민번호 미 제공※공인인증서 서명 검증 및
                        신원확인 시 주민번호 제공
                      </td>
                    </tr>
                    <tr id="36f40748-ba51-463a-8106-55769fa4e079">
                      <td className="cell-title">간편결제</td>
                      <td className="cell-^imh">
                        ㈜카카오페이, ㈜에스에스지닷컴, 엔에치엔페이코㈜, 롯데맴버스㈜, 케이뱅크㈜, 삼성전자㈜,
                        ㈜비바리퍼블리카
                      </td>
                      <td className="cell-srZp">거래정보 (전화번호, 상품명)</td>
                    </tr>
                    <tr id="ebab4410-6e15-4622-885f-9fb9506736cc">
                      <td className="cell-title">가상계좌 결제</td>
                      <td className="cell-^imh">
                        – 국민/농협/우리/신한/KEB하나/기업/우체국/부산/경남/대구/ 수협/씨티/삼성증권/SC/광주/전북–
                        (주)비바리퍼블리카
                      </td>
                      <td className="cell-srZp">
                        거래정보 (가상 계좌번호)※(주)비바리퍼블리카: 은행, 금액, 휴대폰번호,가상계좌 예금주(명) 포함
                      </td>
                    </tr>
                    <tr id="73682309-cec6-4d3e-9918-59b94a21d26a">
                      <td className="cell-title">해외카드 (글로벌서비스 포함)</td>
                      <td className="cell-^imh">VISA, MASTER, JCB, AMEX, Diners, unionpay, 알리바바, 텐센트</td>
                      <td className="cell-srZp">거래정보(카드번호, 고객명, 생년월일, 전화번호)</td>
                    </tr>
                    <tr id="ffe4e613-83e7-49ac-abc7-2640e9929956">
                      <td className="cell-title">현금영수증 발행</td>
                      <td className="cell-^imh">국세청</td>
                      <td className="cell-srZp">거래정보(주민번호, 휴대폰번호, 고객명 등)</td>
                    </tr>
                    <tr id="35bcc41a-055b-4e9c-ad6e-740c31a6b660">
                      <td className="cell-title">본인확인 인증</td>
                      <td className="cell-^imh">SKT, KT, LGU+, KG모빌리언스</td>
                      <td className="cell-srZp">성명, 생년월일, 성별, 휴대폰번호, 내외국인 식별정보, CI, DI</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p id="02b46d18-5423-4588-a8aa-00c7b1067a19" className=""></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otherpay;
