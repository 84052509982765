import { Button, Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Popup from '../../../../../../Commons/Components/Popup';
import ChapterList from '../Chapter/List';
import StudentListWrap from '../Students/List';
import LectureSummary from './Summary';

const LectureItem = (properties) => {
  const { orgId, lecture, fetchOrgLectureDetail } = properties;
  const [showPopup, setShowPopup] = useState(false);
  const [detail, setDetail] = useState(null);
  const {
    student: { list: studentList },
  } = lecture || { student: {} };
  const doneStudentList = useMemo(
    () => studentList.filter((student) => student.isDone).map((student) => student.name),
    [studentList],
  );
  const failStudentList = useMemo(
    () => studentList.filter((student) => student.isFail).map((student) => student.name),
    [studentList],
  );
  const warningStudentList = useMemo(
    () => studentList.filter((student) => student.isWarn).map((student) => student.name),
    [studentList],
  );
  const readyStudentList = useMemo(
    () => studentList.filter((student) => student.isReady).map((student) => student.name),
    [studentList],
  );
  const ongoingStudentList = useMemo(
    () =>
      studentList
        .filter((student) => !(student.isDone || student.isFail || student.isWarn || student.isReady))
        .map((student) => student.name),
    [studentList],
  );

  const handlePopup = useCallback(() => {
    if (!showPopup) {
      fetchOrgLectureDetail({
        orgId,
        lectureId: lecture.id,
        mapping_id: lecture.mappingId,
        student: true,
      }).then((res) => {
        setDetail(res);
      });
    } else {
      setDetail(null);
    }
    // setShowPopup((prev) => !prev)
  }, [fetchOrgLectureDetail, lecture, orgId, showPopup]);
  useEffect(() => {
    setShowPopup(!!detail);
  }, [detail]);
  useEffect(() => {
    if (!showPopup) {
      setDetail(null);
    }
  }, [showPopup]);

  const downloadDetail = useCallback(() => {
    swal({
      text: '준비중입니다.',
      icon: 'warning',
    });
  }, []);
  return (
    <li key={lecture.id}>
      <div className="lecture-title-wrap">
        <span className="lecture-title">{lecture.title}</span>
      </div>
      <div className="lecture-schedule-wrap">
        <span className="lecture-schedule-start">
          {lecture.startDatetime} ~ {lecture.endDatetime}
        </span>
      </div>
      <div className="lecture-completion-rate-wrap">
        <span>{lecture.completionRate}%</span>
      </div>
      <div className="lecture-status-wrap">
        <div className="lecture-status-ongoing">
          <Tooltip title={ongoingStudentList.join(', ')} placement="top-start" arrow>
            <span className="color-block">{lecture.student?.count?.ongoing}</span>
          </Tooltip>
        </div>
        <div className="lecture-status-fail">
          <Tooltip title={failStudentList.join(', ')} placement="top-start" arrow>
            <span className="color-block">{lecture.student?.count?.fail}</span>
          </Tooltip>
        </div>
        <div className="lecture-status-done">
          <Tooltip title={doneStudentList.join(', ')} placement="top-start" arrow>
            <span className="color-block">{lecture.student?.count?.done}</span>
          </Tooltip>
        </div>
      </div>
      <div className="lecture-action-wrap">
        <Button size="small" className="lecture-action-button" onClick={handlePopup} disableRipple>
          관리
        </Button>
      </div>
      <Popup
        open={showPopup}
        onClose={handlePopup}
        className="lecture-detail-popup"
        modal
        lockScroll
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {detail && (
          <section>
            <div className="lecture-popup-header">
              <div className="empty-block"></div>
              <div className="lecture-popup-header-title-wrap">
                <h5>강의 관리</h5>
                <strong>- {detail.title} -</strong>
              </div>
              <div className="lecture-popup-header-action-wrap">
                {/* <Button
                 variant="contained"
                 size="small"
                 disableRipple
                 disableElevation
                 onClick={downloadDetail}
                 >
                 XLSX 다운로드
                 </Button> */}
              </div>
            </div>
            <div className="lecture-popup-body">
              <LectureSummary lecture={detail} />
              <StudentListWrap
                lectureTitle={detail.title}
                lectureEndDatetime={detail.endDatetime}
                students={detail.student}
                setDetail={setDetail}
                lectureId={detail.id}
                mapping_id={lecture.mappingId}
                {...properties}
              />
              <section className="lecture-classes">
                <div className="lecture-classes-header">
                  <h5>강의 목록</h5>
                </div>
                <div className="lecture-classes-body">
                  <ChapterList chapters={detail.chapters || []} />
                </div>
              </section>
            </div>
          </section>
        )}
      </Popup>
    </li>
  );
};

export default LectureItem;
