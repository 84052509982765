export default function TermsOfUse(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">이용약관</h2>
      <article className="page sans">
        <div className="page-body">
          <h3>
            <strong>제 1장 총 칙</strong>
          </h3>
          <p>
            <strong>제1조 [목적]</strong>
          </p>
          <p>
            본 약관은(주)케이랩컴퍼니(이하 &quot;회사&quot;)가 이용 고객(이하 &#x27;회원&#x27;)간에 회사가 제공하는
            교육정보서비스(이하 &quot;서비스&quot;)의 가입조건 및 이용과 관련하여 회사와 회원 사이에 권리ㆍ의무 및
            책임사항 등을 규정함을 목적으로 합니다.
          </p>
          <p></p>
          <p>
            <strong>제2조 [정의]</strong>
          </p>
          <div>
            ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <div className="indented">
              <p>
                1.&quot;이용자&quot;라 함은 &quot;회사&quot;의 웹사이트에 접속하여 본 약관에 따라 &quot;회사&quot;가
                제공하는 &quot;콘텐츠&quot; 및 제반서비스를 이용하는 &quot;회원&quot; 및 &quot;비회원&quot;을 말합니다.
              </p>
              <p>
                2.&quot;회원&quot;이라 함은 회사의 웹사이트에 접속하여 본 약관에 동의 함으로써 회사와 이용계약을
                체결하고 아이디(ID)를 부여받은 자로서 회사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있는 자를
                말합니다.
              </p>
              <p>
                3.&quot;콘텐츠&quot;라 함은 회사 웹사이트에서 제공하는 온라인 강좌 및 기타 관련정보를 의미함으로서,
                정보통신망이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는
                부호ㆍ문자ㆍ음성ㆍ음향ㆍ이미지 또는 영상 등으로 표현된 자료 또는 정보를 말합니다.
              </p>
              <p>
                4.&quot;아이디(ID)&quot;라 함은 회원의 식별 및 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자
                또는 숫자의 조합을 말합니다.
              </p>
              <p>
                5.&quot;비밀번호(PASSWORD)&quot;라 함은 서비스 이용 시, 아이디와 일치되는 회원임을 확인하고, 회원
                개인정보 보호를 위하여, 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.
              </p>
              <p>
                6.&quot;전자우편(Email)&quot;이라 함은 인터넷을 통한 우편 혹은 전기적 매체를 이용한 우편을 말합니다.
              </p>
              <p>
                7.&quot;운영자(관리자)&quot;라 함은 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람
                또는 회사를 말합니다.
              </p>
              <p>
                8.&quot;회원의 게시물&quot;이라 함은 회사의 서비스가 제공되는 웹사이트에 회원이 올린 글, 이미지, 각종
                파일 및 링크, 각종 덧글 등의 정보를 의미합니다.
              </p>
            </div>
          </div>
          <p></p>
          <p>② 전항 각호에 해당하는 정의 이외의, 기타 용어의 정의에 대하여는 거래 관행 및 관계 법령에 따릅니다.</p>
          <p></p>
          <p>
            <strong>제3조 [회사 신원정보 등의 제공]</strong>
          </p>
          <p>
            회사는 회사의 상호, 대표자 성명, 주소, 대표전화, 팩스전송번호, 전자우편주소, 사업자등록번호, 통신판매업
            신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 웹사이트 초기화면에 게시합니다.
          </p>
          <p></p>
          <p>
            <strong>제4조 [약관의 효력 및 변경]</strong>
          </p>
          <p>① 이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.</p>
          <p>
            ② 이 약관은 회사의 웹사이트(
            <a href="http://www.webheasd.co.kr/" target="_blank" rel="noreferrer">
              enterjobedu.co.kr
            </a>
            이하 &#x27;엔터잡에듀 서비스&#x27;)에 온라인으로 공시됨으로써 효력이 발생되고, 합리적인 사유가 발생할 경우
            회사는 관계법령에 위배되지 않는 범위에서 이 약관을 변경할 수 있습니다.
          </p>
          <p>
            ③ 개정약관도 엔터잡에듀 서비스에 온라인으로 공시됨으로써 효력이 발생됩니다. 회사는 약관을 변경할 경우 지체
            없이 이를 공시하여야 하고, 회원의 권리나 의무 등에 관한 중요사항을 개정할 경우에는 사전에 공시하여야 합니다.
          </p>
          <p>
            ④ 이 약관에 동의하는 것은 정기적으로 엔터잡에듀 서비스를 방문하여 약관의 변경사항을 확인하는 것에 동의함을
            의미합니다. 변경된 약관을 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
          </p>
          <p>
            ⑤ 약관에 동의하지 않는 회원은 탈퇴(해지)를 요청할 수 있으며, 약관의 효력이 발생된 날로부터 7일 이후까지
            거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우는 약관에 동의한 것으로 간주됩니다.
          </p>
          <p></p>
          <p>
            <strong>제5조 [약관과 기타 준칙]</strong>
          </p>
          <p>① 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 서비스별 안내라 함)와 함께 적용됩니다.</p>
          <p>
            ② 본 약관에 명시되지 않은 사항에 대해서는 &quot;약관의 규제에 관한 법률&quot;, &quot;정보통신망 이용촉진 및
            정보보호 등에 관한 법률&quot;, &quot;전자상거래 등에서의 소비자보호에 관한 법률&quot; 및 기타 관련법령의
            규정에 따릅니다.
          </p>
          <p></p>
          <h3>
            <strong>제 2 장 회원가입</strong>
          </h3>
          <p>
            <strong>제6조 [회원가입]</strong>
          </p>
          <p>
            ① 회원으로 가입하여 회사 서비스의 이용을 희망하는 자는 약관의 내용을 숙지한 후 동의함을 표시하고, 회사가
            제시하는 소정의 회원가입 양식에 관련사항을 기재하여 회원가입을 신청하여야 합니다.
          </p>
          <p>
            ② 회사는 전항에 따라 회원이 온라인 회원가입 신청양식에 기재하는 모든 회원정보를 실제 데이터인 것으로
            간주합니다.
          </p>
          <p>
            ③ 실명이나 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 본 약관의 관련 규정에 따라 서비스
            사용에 제한을 받을 수 있습니다.
          </p>
          <div>
            ④ 회사는 본조 제1항에 따른 이용자의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각
            호에 해당하는 신청에 대하여는 승낙을 하지 않을 수 있으며, 승낙 이후라도 취소할 수 있습니다.
            <div className="indented">
              <p>1. 이용자의 귀책사유로 인하여 승인이 불가능한 경우</p>
              <p>2. 타인의 명의 또는 개인정보를 도용하는 경우</p>
              <p>3. 허위의 정보를 제공하는 경우</p>
              <p>4. 중복된 아이디를 사용하는 경우</p>
              <p>5. 회사가 제시하는 회원가입 신청양식에 관련 내용을 기재하지 않은 경우</p>
              <p>6. 이전에 회사 이용약관 또는 관계법령을 위반하여 회원자격이 상실되었던 경우</p>
              <p>7. 기타 본 약관 및 관계법령을 위반하는 경우</p>
              <p>
                8. 회원가입 신청양식에 기재되어 회사에 제공되는 개인정보(ID, 비밀번호, 주소 등)가 선량한 풍속 기타
                사회질서에 위배되거나 타인을 모욕하는 경우
              </p>
            </div>
          </div>
          <p></p>
          <p>
            <strong>제7조 [서비스의 제공 및 변경]</strong>
          </p>
          <div>
            ① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
            <div className="indented">
              <p>1. 회원을 위한 온라인교육 콘텐츠 서비스, 맞춤 서비스, 오프라인 교육 콘텐츠</p>
              <p>2. 기타 회사가 자체 개발하거나 다른 회사와의 협력계약을 통해 회원들에게 제공할 일체의 서비스</p>
            </div>
          </div>
          <p>
            ② 회사는 변경될 서비스의 내용 및 제공일자를 엔터잡에듀 서비스를 통해 공지하고 서비스를 변경하여 제공할 수
            있습니다.
          </p>
          <p></p>
          <p>
            <strong>제8조 [개인정보의 보호 및 사용]</strong>
          </p>
          <p>① 회사는 고객의 개인정보를 보호하고 존중합니다.</p>
          <p>
            ② 회사는 이용신청 시 고객이 제공하는 정보, 각종 이벤트 참가를 위하여 고객이 제공하는 정보, 기타 서비스 이용
            과정에서 수집되는 정보 등을 통해 고객에 관한 정보를 수집하며, 수집된 고객의 정보는 본 이용계약의 이행과 본
            이용계약상의 서비스 제공을 위한 목적으로 사용됩니다.
          </p>
          <div>
            ③ 회사는 서비스 제공과 관련하여 지득한 고객의 신상정보를 본인의 승낙 없이 제3자에게 누설할 수 없습니다.
            다만, 다음의 각 호의 경우에는 그러하지 아니합니다.
            <div className="indented">
              <p>1. 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우</p>
              <p>
                2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여
                제공하는 경우
              </p>
              <p>3. 관계 법령에 의하여 수사상 목적으로 정해진 절차와 방법에 따라 관계기관의 요구가 있는 경우</p>
              <p>4. 다른 법률에 특별한 규정이 있는 경우</p>
              <p>5. 정보통신윤리위원회가 관계법령에 의하여 요청 경우</p>
              <p>6. 회원에게 보다 전문적이고 다양한 서비스를 제공하기 위한 경우</p>
            </div>
          </div>
          <p>
            ④ 회사는 회원에게 제3항의 전문적이고 다양한 서비스를 제공하기 위해 자체적으로 TM을 하거나, 외부전문 사업자와
            제휴하여 공동으로 서비스(TM, SMS, 메일 등 광고성 정보 발송)를 제공할 수 있습니다.
          </p>
          <p>
            ⑤ 전문적인 지식, 경험과 상담이 요구되는 서비스의 경우 회원의 동의를 받아 외부전문사업자와 공동으로 서비스를
            제공하며 이때 해당전문사업자의 상호와 공유목적, 공유 정보를 명시합니다.
          </p>
          <div>
            ⑥ 외부전문사업자와의 공동서비스를 제공함에 있어 회원의 성명, 연락처 등 공동서비스에 필요한 최소한의 정보가
            공유될 수 있고, 공유되는 정보는 아래 각호와 같이 엄격히 보호 관리됩니다.
            <div className="indented">
              <p>1. 공유되는 정보는 해당 전문서비스 이외 어떠한 다른 용도로도 사용 되지 않습니다.</p>
              <p>
                2. 서비스 제공 과정에서 해당 전문서비스에 대해 회원이 동의의사를 밝히지 않거나 사전에 거부의사를 밝힐
                경우 최소한의 정보도 전문 사업자와 공유하지 않습니다.
              </p>
            </div>
          </div>
          <p>⑦ 개인정보 이용에 관한 회원의 동의는 본 약관에 동의하는 것으로 갈음할 수 있습니다.</p>
          <p>
            ⑧ 회원은 언제든 원할 경우 회사에 제공한 개인정보의 수집과 이용에 관한 동의를 철회할 수 있고, 위 동의의
            철회는 해지 신청을 하는 것으로 이루어 집니다.
          </p>
          <p>⑨ 개인정보보호와 관련된 보다 자세한 사항은 개인정보취급방침을 참조하시기 바랍니다.</p>
          <p></p>
          <p>
            <strong>제9조 [회원정보의 변경]</strong>
          </p>
          <p>
            ① 회원은 회사 웹사이트 &quot;정보수정&quot; 페이지에서 언제든지 자신의 개인정보를 열람하고 수정할 수
            있습니다.
          </p>
          <p>② 회원이 전항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</p>
          <p></p>
          <p>
            <strong>제10조 [회원의 ‘아이디’ 및 ‘비밀번호’의 관리에 대한 의무]</strong>
          </p>
          <p>
            ① 회원은 아이디와 비밀번호에 대한 관리책임이 있으며, 이를 타인에게 공개하여 제3자가 이용하도록 하여서는
            안됩니다.
          </p>
          <p>
            ② 회원은 자신의 아이디 및 비밀번호가 유출되어 제3자에 의해 사용되고 있음을 인지한 경우, 즉시 회사에 알려야
            합니다.
          </p>
          <p>
            ③ 회사는 전항의 경우 회원의 개인정보보호 및 기타 서비스 부정이용행위 방지 등을 위하여 회원에게 비밀번호의
            변경 등 필요한 조치를 요구할 수 있으며, 회원은 회사의 요구가 있는 즉시 회사의 요청에 성실히 응해야 합니다.
          </p>
          <p>
            ④ 회사는 회원이 본 조 제2항 및 제3항에 따른 의무를 성실히 이행하지 않아 발생한 불이익에 대하여 책임지지
            않습니다.
          </p>
          <p></p>
          <p>
            <strong>제11조 [회원탈퇴 및 자격 상실 등]</strong>
          </p>
          <p>① 회원은 언제든지 서비스 이용을 중단하고 탈퇴할 수 있습니다.</p>
          <p>
            ② 회사는 회원이 본 약관 또는 관계법령을 위반하는 경우, 서비스 이용을 제한하거나 회원자격을 상실 시킬 수
            있습니다.
          </p>
          <p>③ 본 조항에 따른 해지 시 회원은 이용계약 종료(회원 탈퇴) 시 포인트, 할인 쿠폰은 소멸됩니다.</p>
          <p>
            ④ 회사는 회원탈퇴 시 회원으로부터 제공받은 정보를 관계 법령과 개인정보 처리방침 등에 따라 삭제 또는
            파기하거나 일정 기간동안 보관합니다. 다만, 회원이 회원탈퇴 시까지 작성한 게시물은 삭제되지 않으며 회원탈퇴
            후 회사에 대하여 그 게시물의 삭제를 요구할 수 없습니다.
          </p>
          <p></p>
          <h3>
            <strong>제 3 장 서비스 이용계약</strong>
          </h3>
          <p>
            <strong>제12조 [회사의 의무]</strong>
          </p>
          <p>
            ① 회사는 특별한 사정이 없는 한 회원이 희망한 서비스 이용 개시일에 서비스를 제공하고 계속적이고 안정적으로
            서비스를 제공해야 합니다.
          </p>
          <p>② 회사는 개인정보 보호를 위한 보안시스템을 구축하고 개인정보취급방침을 공시하고 준수합니다.</p>
          <p>
            ③ 회사는 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우 적절한 조치를 취해야 하고, 즉시
            처리하기 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해야 합니다.
          </p>
          <div>
            ④ 회사는 아래 각 호의 1에 해당하는 사유가 발생하는 경우, 서비스를 중단할 수 있습니다.
            <div className="indented">
              <p>1. 시스템 정기점검, 증설 및 교체가 필요한 경우</p>
              <p>2. 긴급한 시스템 점검, 증설 및 교체가 필요한 경우</p>
              <p>3. 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 경우</p>
              <p>
                4. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할
                경우
              </p>
            </div>
          </div>
          <p>
            ⑤ 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한 기간 등을 회원에게
            알려야 합니다.
          </p>
          <p>
            ⑥ 회사는 회사가 통제할 수 없는 사유로 인한 서비스 중단의 경우(시스템관리자의 고의 또는 과실 없는 디스크장애,
            시스템다운 등)에 사전통지가 불가능하며 타인(PC통신회사, 기간통신사업자 등)의 고의, 과실로 인한 시스템중단
            등의 경우에는 통지의 의무를 가지지 않습니다.
          </p>
          <p></p>
          <p>
            <strong>제13조 [회원의 의무]</strong>
          </p>
          <p>
            ① 회사의 명시적 동의가 없는 한 회원의 이용권한은 회원 개인에 한정되며, 타인에게 양도, 증여하거나 이를 담보로
            제공할 수 없습니다.
          </p>
          <p>
            ② 회원은 주소, 연락처, 전자우편주소 등 이용계약사항의 변경이 있을 경우 해당 절차를 거쳐 즉시 이를 회사에
            알려야 합니다.
          </p>
          <p>
            ③ 회원은 본 약관과 관계법령 등 제반 규정 및 회사의 공지사항을 준수하여야 하며, 회사의 업무를 방해하거나
            회사의 명예를 손상시키는 행위를 해서는 안됩니다.
          </p>
          <p>
            ④ 회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 회사는 그 영업활동에 대한 책임을
            부담하지 않습니다. 또한 회원은 위와 같은 영업활동으로 회사에 손해를 입힐 경우 손해배상책임을 부담합니다.
          </p>
          <p>
            ⑤ 회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여,
            담보제공 할 수 없습니다.
          </p>
          <p>⑥ 회원은 회사 및 제 3자의 지적재산권을 침해해서는 안됩니다.</p>
          <div>
            ⑦ 회사는 회원이 다음 각 호의 행위를 할 경우 당해 회원의 서비스 이용제한 등 적절한 제한조치를 할 수 있습니다.
            <div className="indented">
              <p>
                1. 회원이 이용신청 또는 변경 시, 허위사실을 기재하거나, 자신 또는 다른 회원의 ID 및 개인정보를
                이용ㆍ공유하는 경우
              </p>
              <p>2. 회원이 기타 불법적인 방법으로 회원가입을 한 사실이 확인된 경우</p>
              <p>3. 회원이 회사 또는 제3자의 권리나 저작권을 침해하는 경우</p>
              <p>
                4. 회원이 회사에서 제공하는 서비스를 이용하여 상품 또는 용역을 판매하는 영업활동 등의 상행위를 하는 경우
              </p>
              <p>5. 회원이 다른 이용자의 서비스 이용을 방해하거나, 회사의 운영진, 직원 또는 관계자를 사칭하는 경우</p>
              <p>
                6. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시,
                전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
              </p>
              <p>
                7. 회사의 서비스 운영을 고의로 방해 하거나 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를
                전송하는 경우
              </p>
              <p>
                8. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시,
                전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
              </p>
              <p>9. 회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위</p>
              <p>10. 회사 및 타인의 명예를 훼손하거나 모욕하는 경우</p>
              <p>11. 관계법령 및 기타 본 약관에서 규정한 사항을 위반한 경우</p>
            </div>
          </div>
          <p></p>
          <p>
            <strong>제14조 [서비스 이용요금]</strong>
          </p>
          <p>① 유료 서비스의 이용 요금 및 결제 방식은 해당 서비스 및 결제 페이지에 명시되어 있는 내용에 따릅니다.</p>
          <p>
            ② 회사는 사이버머니(쿠폰 및 포인트)에 관한 정책을 회사 운영 상황에 따라 변경할 수 있으며 이는 통합 사이트에
            게시합니다.
          </p>
          <p></p>
          <p>
            <strong>제15조 [환불정책]</strong>
          </p>
          <div>
            ① 회원은 환불 또는 변경을 유ㆍ무선의 방법을 통하여 회사에 그 의사를 표시하여야 하며, 회사는 환불요청을
            접수하고 회원의 요청과 환불규정 확인 후, 최대한 신속하게 환불하여 드립니다.
            <div className="indented">
              <div>
                1.환불
                <div className="indented">
                  <div>
                    (1) <strong>온라인 강의 수강료 환불규정</strong>
                    <div className="indented">
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          결제일로부터 7일 이내 미수강시 100% 환불이 됩니다. 단, 고객님의 귀책사유로 환불 시, 가상계좌의
                          경우 금융수수료 500원을 제하고 환불해드립니다.
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          이용시간 종료 전에 환불 신청을 한 경우, 실제 결제 금액 - 실제 재생한 강의 수에 해당하는
                          금액으로 환불됩니다.
                          <p>
                            (실제 재생한 강의 수에 해당하는 금액 : 수강한 강의의 결제 금액 / 강의 구성 수 * 학습 강의
                            수)
                          </p>
                          <p>
                            단, 강의수강 신청 후 전체 수강기간 중 1/2이 지나면, 관련 법령에 의거하여 환불이 불가합니다.
                          </p>
                          <p>
                            ※ 강의 수강 후 환불은 관련 법령에 의거 아래와 같습니다. (학원 설립, 운영 및 과외교습에 관련
                            법률) : 강의 가격에서 실제 강의를 수강한 부분 + 강의자료를 다운받으신 경우
                            강의자료비(구매금액의 50%)를 제하고 환불됩니다.
                          </p>
                          <p>
                            ex. 5만원에 해당하는 강의(총강좌수 10강)를 3강 수강 및 강의자료 다운로드 후 환불할 경우,
                            50,000 - 15,000(=50,000 / 10강 * 3강) - 25,000(5만원 강의에 대한 강의자료비) = 10,000원
                          </p>
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          패키지, 프리패스 등 특별 기획 상품의 경우 환불정책이 다를 수 있으니 꼭 확인부탁드립니다.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p></p>
                  <div>
                    (2) <strong>오프라인 강의 수강료 환불규정</strong>
                    <div className="indented">
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          수강료 전액 반환은 개강일 수업시작일 기준으로 7일 전까지는 100% 환불 가능하며, 그 이후부터는
                          위약금 10%가 발생합니다.
                          <p>
                            ※ 사전 연락 없이 강의 불참 후 환불 요청시 환불 고객님의 귀책사유로 환불 시, 가상계좌의 경우
                            금융수수료 500원을 제하고 환불해드립니다. (폐강은 제외)불가능합니다.
                          </p>
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          고객님의 귀책사유로 환불 시, 카드결제의 경우 3%의 수수료를 제하고 환불해드립니다.(폐강은 제외)
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          환불시, 실제로 결제한 금액(할인 제외)을 기준으로 환불 처리됩니다.
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          개강 후 반환은 관련 법령에 의거 아래와 같습니다. (학원 설립, 운영 및 과외교습에 관련 법률)
                          <p>(개강 후 반환은 실제 수강여부와 관계없이 일할공제 또는 분수공제 합니다.)</p>
                          <p></p>
                          <p>[수강 개월이 1개월인 경우]</p>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              총 수업의 1/3 경과 전까지는 1/3 공제하고 나머지 2/3 반환
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              총 수업의 1/2 경과 전까지는 1/2 공제하고 나머지 1/2 반환
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              <strong>총 수업의 1/2 이상 수강한 경우 반환 불가</strong>
                              <p>
                                <strong>ex. 총 4회 수업 중 2회까지 수업을 수강한 경우, 반환 불가</strong>
                              </p>
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              수업에 무단불참 시, 환불액 계산은 해당 수업을 참가한 것으로 간주하여 처리됩니다.
                            </li>
                          </ul>
                          <p></p>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              반환 사유가 발생한 월의 환불 액
                              <p>
                                (수강료 징수기간이 1개월 이내인 경우에 따라 산출된 수강료)와 나머지 월의 수강료 전액을
                                합산한 금액
                              </p>
                              <p>환불 예: 총 5주, 매주 월요일 2시간 총 10시간, 수업료 30만원인 경우,</p>
                              <ul className="bulleted-list">
                                <li style={{ listStyleType: 'square' }}>
                                  첫째달 8시간 등록 및 수업료 24만원, 둘째달 월 2시간 등록 및 수강료 6만원으로 간주
                                </li>
                              </ul>
                              <ul className="bulleted-list">
                                <li style={{ listStyleType: 'square' }}>
                                  첫째달 1/3 수업 경과 전 환불할 경우: 첫째달 수업료의 2/3 + 둘째달 수업료 전액 환불가능
                                </li>
                              </ul>
                              <ul className="bulleted-list">
                                <li style={{ listStyleType: 'square' }}>
                                  첫째달 1/2 수업 경과 전 환불할 경우: 첫째달 수업료의 1/2 + 둘째달 수업료 전액 환불가능
                                </li>
                              </ul>
                              <ul className="bulleted-list">
                                <li style={{ listStyleType: 'square' }}>
                                  <strong>
                                    첫째달 1/2 이상 수강 후 환불할 경우: 첫째달 수업료 환불불가 + 둘째달 수업료 전액
                                    환불가능
                                  </strong>
                                </li>
                              </ul>
                              <p>*처음 프로그램이 시작되는 주를 1주라고 기준으로 정하여 위와 같이 계산</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p></p>
                  <div>
                    (3) <strong>온라인 + 오프라인 강의 구성된 학원강의에 대한 환불규정</strong>
                    <div className="indented">
                      <p>[강의 수강 전]</p>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          수강료 전액 반환은 오프라인 강의 개강일 기준으로 7일 전까지는 100% 환불 가능하며, 그
                          이후부터는 위약금 10%가 발생합니다. ※ 사전 연락 없이 강의 불참 후 환불 요청시 환불
                          불가능합니다.
                        </li>
                      </ul>
                      <p>[강의 수강 후]</p>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          온라인 강의 + 오프라인 강의로 제공되는 온라인 강의를 수강 후, 오프라인 수업 개강 전 환불을
                          요청하는 경우 온라인 강의 가격을 제한 나머지 금액만 환불됩니다.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                          온라인 강의 + 오프라인 강의로 제공되는 오프라인 강의 수강 후, 온라인 강의 미수강 상태에서
                          환불을 요청하는 경우 오프라인 환불 규정에 따른 가격을 제한 나머지 금액만 환불됩니다.
                        </li>
                      </ul>
                      <p>[환급반]</p>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          환급반 수강 기간 도중 환불 신청을 한 경우 실제 결제 금액 - (오프라인) 강의 금액 - (온라인)
                          강의 금액으로 환불이 진행됩니다. <br />각 강의의 금액 산정은 아래와 같습니다.
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          (오프라인) <strong>첫기수</strong> 한달반 수업을 기준으로 환불 진행
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              총 수업의 1/3 경과 전까지는 1/3 공제하고 나머지 2/3 반환
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              총 수업의 1/2 경과 전까지는 1/2 공제하고 나머지 1/2 반환
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              <strong>총 수업의 1/2 이상 수강한 경우 반환 불가</strong>
                              <p>
                                <strong>ex. 총 4회 수업 중 2회까지 수업을 수강한 경우, 반환 불가</strong>
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          (온라인) 실제 결제 금액 - 실제 재생한 강의 수에 해당하는 금액으로 환불 진행
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'circle' }}>
                              (실제 재생한 강의 수에 해당하는 금액 : (수강한 강의의 결제 금액 – 오프라인 강의+컨설팅
                              금액) / 강의 구성 수 * 학습 강의 수) 단, 강의수강 신청 후 전체 수강기간 중 1/2이 지나면,
                              관련 법령에 의거하여 환불이 불가합니다.
                              <br />
                              (총 기간이 30일인 경우, 15일이 되는 시점부터 환불 불가)
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <p>※ 강의 수강 후 환불은 관련 법령에 의거 아래와 같습니다.</p>
                      <p>
                        (학원 설립, 운영 및 과외교습에 관련 법률) : 강의 가격에서 실제 강의를 수강한 부분 + 강의자료를
                        다운로드하신 경우 강의자료비(구매금액의 50%)를 제하고 환불됩니다.
                      </p>
                      <p className="indented">
                        <strong>
                          ex. 5만원에 해당하는 강의(총강좌수 10강)를 3강 수강 및 강의자료 다운로드 후 환불할 경우,
                          50,000 - 15,000(=50,000 / 10강 * 3강) - 25,000(5만원 강의에 대한 강의자료비) = 10,000원
                        </strong>
                      </p>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'disc' }}>
                          <strong>환급반에서 사용한 컨설팅 비용은 반환되지 않습니다.</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                2.변경
                <div className="indented">
                  <p>
                    환불조건에 부합하는 강좌를 타 강좌로 변경하는 경우, 직접변경은 불가능하며 환불과 동일한 조건으로
                    환불처리 후 재신청 하거나 고객센터로 문의하여야 합니다.
                  </p>
                </div>
              </div>
              <div>
                3.회사 사정에 의한 서비스의 중단
                <div className="indented">
                  <p>
                    회사가 기타 부득이한 사유로 서비스를 지속할 수 없을 경우에는 이를 회원에게 공지한 후 최대한 신속하게
                    환불하여 드립니다.
                  </p>
                </div>
              </div>
              <div>
                4.이용중지 및 강제탈퇴
                <div className="indented">
                  <p>
                    회원이 관계법령 및 본 약관의 규정을 위반하여 회사로부터 이용중지 및 강제탈퇴 처리가 되는 경우에는 본
                    조의 환불규정이 적용되지 않습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>
            ② 회사가 회원에게 무료로 지급한 쿠폰 및 충전비율 이상으로 주어진 쿠폰 금액에 대해서는 환불이 되지 않습니다.
          </p>
          <p>③ 제17조에 따라 이용제한이 될 경우 요금은 반환하지 않습니다.</p>
          <p></p>
          <p>
            <strong>제16조 [저작권]</strong>
          </p>
          <p>
            ① 회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다. 다만 회원은 서비스를 통해
            게시물을 제출, 게시함으로써 회사에 대하여 게시물에 대한 이용, 복사, 복제, 처리, 각색, 변경, 공개, 전송, 게재
            또는 배포할 수 있도록 사용을 허락하는 저작사용권(2차 저작사용권을 허여 할 수 있는 권리 포함)을 부여한 것으로
            간주합니다. 본 저작사용권은 회원이 서비스의 사용을 중단하거나, 통합사이트에 탈퇴한 후에도 존속하게 됩니다.
            단, 회사는 회원이 제공한 콘텐츠에 접근하거나 이를 삭제하는 방법을 제공할 수 있습니다.
          </p>
          <p>
            ② 회원은 회사가 제공하는 &quot;서비스&quot;를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 녹화, 복제,
            송신, 출판, 배포, 방송 기타 방법에 의하여 영리, 비영리의 목적으로 이용하거나 제3자에게 이용하게 할 수
            없습니다.
          </p>
          <p>
            ③ 회사가 제공하는 콘텐츠는 저작권법에 의해 보호를 받고 있으며, 일부 혹은 전체의 내용을 회사의 동의 없이
            무단으로 복제, 배포, 게시를 하는 행위는 저작권법에 위배됩니다.
          </p>
          <p>④ 회사의 저작물의 저작권을 침해하였을 경우, 손해배상을 청구할 수 있습니다.</p>
          <p></p>
          <p>
            <strong>제17조 [부정이용 금지 및 차단]</strong>
          </p>
          <div>
            ① 부정이용 식별방법 및 차단
            <div className="indented">
              <p>
                1. 회사는 회원의 서비스 이용 중에 수집ㆍ확인된 IP정보, 맥어드레스 등의 자료를 토대로, 서버를 통하여
                부정이용 여부를 분류ㆍ확인합니다.
              </p>
              <p>
                2. 회사는 회원이 서비스 이용 중에 복제프로그램을 실행시키거나 동일한 ID로 동시 접속을 하는 경우, 서비스
                이용 접속을 강제로 종료 시킵니다.
              </p>
            </div>
          </div>
          <p></p>
          <h3>제 4장 손해배상 및 기타사항</h3>
          <p>
            <strong>제18조 [손해배상]</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ① 회사가 제공하는 모든 서비스와 관련하여 회사의 책임 있는 사유로 인해 이용자에게 손해가 발생한 경우 회사는
              그 손해를 배상하여야 합니다. 단, 회사는 무료서비스의 장애 등으로 인한 손해에 대해서는 배상하지 않습니다.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ② 회원이 본 약관 규정에 위반한 행위로 회사 및 제3자에게 손해를 입히거나 회원의 책임 있는 사유에 의해 회사
              및 제3자에게 손해를 입힌 경우 회원은 그 손해를 배상하여야 합니다.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>③ 기타 손해배상의 방법, 절차 등은 관계법령에 따릅니다.</li>
          </ul>
          <p></p>
          <p>
            <strong>제19조 [면책조항]</strong>
          </p>
          <p>
            ① 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스
            제공에 대한 책임이 면제됩니다.
          </p>
          <p>
            ② 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우
            책임이 면제됩니다.
          </p>
          <p>
            ③ 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
          </p>
          <p>④ 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.</p>
          <p>
            ⑤ 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게
            기재하여 손해가 발생한 경우 책임을 지지 않습니다.
          </p>
          <p>
            ⑥ 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.
          </p>
          <p>
            ⑦ 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 회사는회원이
            서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
          </p>
          <p>
            ⑧ 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지
            않습니다.
          </p>
          <p>
            ⑨ 회사는 이용자 상호간 및 이용자와 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며,
            이로 인한 손해를 배상할 책임도 없습니다.
          </p>
          <p>⑩ 회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.</p>
          <p>
            ⑪ 회원이 게시하는 자료 가운데 불법 내지는 선량한 풍속 기타 사회질서에 반하는 내용에 대하여 회사는 어떠한
            책임도 지지 않으며, 사이트 내에서 회원이 게시하는 여하한 정보 및 이와 관련된 광고, 기타 정보 또는 제안의
            결과로서 취득하게 되는 어떠한 재화와 용역에 대하여도 그것의 가치, 용도, 광고 및 기타 관계법령 준수 등에 대한
            일체의 보증을 하지 않습니다.
          </p>
          <p></p>
          <p>
            <strong>제20조 [분쟁해결]</strong>
          </p>
          <p>
            ① 회사는 분쟁이 발생하였을 경우에 이용자가 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를
            취합니다. 다만, 신속한 처리가 곤란한 경우에 회사는 이용자에게 그 사유와 처리일정을 통보합니다.
          </p>
          <p>② 회사와 회원간 분쟁은 대한민국 법을 준거법으로 합니다.</p>
          <p>
            ③ 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회
            또는 전자거래분쟁조정위원회, 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
          </p>
          <p></p>
          <p>
            <strong>제21조 [상품쿠폰 및 포인트 이용]</strong>
          </p>
          <p>
            ① 회사는 재화 등을 구매하는 이용자에게 지정된 상품 구매 시 일정액 또는 일정비율을 할인 받을 수 있는 쿠폰을
            발급할 수 있습니다.
          </p>
          <p>
            ② 회사에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 어떠한 경우에도 이를 타인에게
            실질적으로 매매할 수 없습니다.
          </p>
          <p>③ 상품쿠폰은 현금으로 출금될 수 없으며, 표시된 유효기간이 만료되거나 이용계약이 종료되면 소멸합니다.</p>
          <p>
            ④ 상품쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며, 회원을 탈퇴한 경우 상품쿠폰은 소멸됩니다.
          </p>
          <p></p>
          <p>
            <strong>[부칙]</strong>
          </p>
          <p>본 약관은 2021년 9월 27일부터 적용됩니다. 종전 약관은 본 약관으로 대체됩니다.</p>
          <p>개정일자 이전 가입자 또한 개정된 이용 약관의 적용을 받습니다.</p>
        </div>
      </article>
    </div>
  );
}
