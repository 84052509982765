import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactPlayer from 'react-player';
import { useHistory, useRouteMatch } from 'react-router';
import { GET, POST, PUT } from '../../Commons/Utils/fetch';
import Images from './Images';
import './index.scss';
import UserClass from './UserClass';
import Survey from '../Survey';
import { Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

function Classroom(props) {
  const intl = useIntl();
  const history = useHistory();
  const {
    params: { id, targetClassId },
  } = useRouteMatch();

  // MARK: UserClass
  const [userClass, setUserClass] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [currentSurveyChapter, setCurrentSurveyChapter] = useState(null);
  const [isCompletedBeforeSurvey, setIsCompletedBeforeSurvey] = useState(false);
  const [isCompletedAfterSurvey, setIsCompletedAfterSurvey] = useState(false);

  useEffect(() => {
    GET({
      url: `/class/me/${id}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    }).then(({ userClass }) => {
      userClass.targetClassId = Number(targetClassId);
      const userClassInstance = new UserClass(userClass);
      setIsCompletedBeforeSurvey(userClassInstance.beforeSurvey ? userClassInstance.beforeSurvey?.isCompleted : true);
      setIsCompletedAfterSurvey(userClassInstance.afterSurvey?.isCompleted);
      return setUserClass(userClassInstance);
    });
  }, [id, targetClassId]);

  useEffect(() => {
    // 설문 완료 시 선택된 설문 제거
    if (isCompletedBeforeSurvey === true || isCompletedAfterSurvey === true) {
      setCurrentSurveyChapter(null);
    }

    // 수강 전 설문으로 위치시킴
    if (userClass?.beforeSurvey && isCompletedBeforeSurvey === false) {
      if (userClass.beforeSurvey.isCompleted) {
        setIsCompletedBeforeSurvey(true);
        setCurrentSection(userClass?.latestSection || userClass?.firstSection);
      } else {
        setCurrentSurveyChapter(userClass?.beforeSurvey);
      }
    } else if (
      // 수강 후 설문으로 위치시킴
      userClass?.afterSurvey &&
      userClass?.getIsCompletedAllChapters() &&
      isCompletedAfterSurvey === false
    ) {
      if (userClass.afterSurvey.isCompleted) {
        setCurrentSurveyChapter(null);
        setCurrentSection(userClass?.latestSection || userClass?.firstSection);
      } else {
        setCurrentSurveyChapter(userClass?.afterSurvey);
      }
    } else {
      // 수강 중인 섹션으로 위치시킴
      setCurrentSection(userClass?.latestSection || userClass?.firstSection);
    }
  }, [userClass, isCompletedBeforeSurvey, isCompletedAfterSurvey]);

  useEffect(() => {
    setAttendedTime(currentSection?.LearningProgresses[0]?.attended_time || 0);
    setShouldSeekToRecent(!currentSection?.LearningProgresses || !currentSection.LearningProgresses[0]?.is_completed);
    userClass?.setLatestSection(currentSection);
  }, [userClass, currentSection]);

  const onClickSection = useCallback(
    (chapterIndex, sectionIndex) => {
      const section = userClass.chapters[chapterIndex].ClassSections[sectionIndex];
      // FIXME
      // Temp code to init react-player instead of updating only configuration.
      // To fix a problem that tracks(except default track) have prev tracks' cue info.
      setCurrentSection(null);
      setCurrentSurveyChapter(null);
      setTimeout(() => setCurrentSection(section), 1);
    },
    [userClass],
  );

  const onClickMaterial = useCallback(() => {
    PUT({
      url: `/class/me/${id}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });
  }, [id]);

  // MARK: Player
  const player = useRef(null);
  const [autoPlay, setAutoPlay] = useState(false);
  const [attendedTime, setAttendedTime] = useState(0);
  const [prevPlayedSeconds, setPrevPlayedSeconds] = useState(null);
  const [shouldSeekToRecent, setShouldSeekToRecent] = useState(true);

  const userLang = localStorage.getItem('eje_lang');
  const trackLang = localStorage.getItem('eje_track_lang') || userLang;
  const tracks = useMemo(() => {
    if (currentSection) {
      return currentSection.FK_video
        ? currentSection.FK_video.Subtitles.sort((a, b) => (a.lang > b.lang ? 1 : -1)).map((Subtitle) => ({
            id: currentSection.id + Subtitle.lang,
            kind: 'subtitles',
            src: Subtitle.url,
            srcLang: Subtitle.lang,
            label: intl.formatMessage({
              id: 'LANG_' + Subtitle.lang.toUpperCase(),
            }),
            default: Subtitle.lang === trackLang,
          }))
        : '';
    } else {
      return [];
    }
  }, [intl, currentSection, trackLang]);

  const progressInterval = 10000;
  const updateProgress = useCallback(
    (progress) => {
      if (!currentSection) return;

      const index = userClass.sectionsAll.indexOf(currentSection);
      const nextSection = userClass.sectionsAll[index + 1];

      POST({
        url: `/class/progress`,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
        body: {
          ...progress,
          user_class_id: id,
          class_section_id: currentSection.id,
        },
      }).then(() => {
        if (progress.is_completed) {
          currentSection.LearningProgresses[0] = {
            ...currentSection.LearningProgresses[0],
            is_completed: true,
          };

          // 수강 완료 시 수강 후 설문조사로 이동
          if (
            !nextSection &&
            !!userClass.afterSurvey &&
            !isCompletedAfterSurvey &&
            userClass.getIsCompletedAllChapters()
          ) {
            setCurrentSection(null);
            setCurrentSurveyChapter(userClass.afterSurvey);
          }
        }
      });
    },
    [id, currentSection, userClass, isCompletedAfterSurvey],
  );

  const onReady = useCallback(() => {
    if (!autoPlay) {
      setAutoPlay(true);
    }

    if (shouldSeekToRecent) {
      setShouldSeekToRecent(false);
      const seconds = userClass.getSectionLastTime(currentSection);
      if (seconds && seconds !== player.current.getCurrentTime()) {
        player.current.seekTo(seconds);
      }
    }

    const videoElement = player.current.wrapper.children[0];
    if (!videoElement.textTracks.onchange) {
      videoElement.textTracks.onchange = (e) => {
        for (let i = 0; i < videoElement.textTracks.length; i++) {
          const track = videoElement.textTracks[i];
          if (track.mode === 'showing') {
            localStorage.setItem('eje_track_lang', track.language);
          }
        }
      };
    }
  }, [autoPlay, shouldSeekToRecent, userClass, currentSection, player]);

  const onSeek = useCallback(
    (playedSeconds) => {
      setPrevPlayedSeconds(playedSeconds);
      userClass.setSectionLastTime(currentSection, playedSeconds);
    },
    [userClass, currentSection],
  );

  const onProgress = useCallback(
    ({ playedSeconds }) => {
      const playbackRate = player.current.wrapper.children[0].playbackRate;
      const playtime = playedSeconds - prevPlayedSeconds;
      if (0 < playtime && playtime < (2 * progressInterval * playbackRate) / 1000) {
        const _attendedTime = attendedTime + playtime;
        setAttendedTime(attendedTime + playtime);
        updateProgress({ attended_time: _attendedTime });
      }
      userClass.setSectionLastTime(currentSection, playedSeconds);
      setPrevPlayedSeconds(playedSeconds);
    },
    [player, prevPlayedSeconds, attendedTime, updateProgress, userClass, currentSection],
  );

  const onEnd = useCallback(() => {
    updateProgress({ is_completed: true, attended_time: attendedTime });
    const index = userClass.sectionsAll.indexOf(currentSection);
    const nextSection = userClass.sectionsAll[index + 1];
    if (nextSection) {
      // FIXME
      // Temp code to init react-player instead of updating only configuration.
      // To fix a problem that tracks(except default track) have prev tracks' cue info.
      setCurrentSection(null);
      setTimeout(() => setCurrentSection(nextSection), 1);
    }
  }, [updateProgress, attendedTime, userClass, currentSection]);

  // 화면 높이 계산
  useEffect(() => {
    const calculateVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    calculateVH(); // 페이지 로드 시
    window.addEventListener('resize', calculateVH); // 화면 크기 변경 시

    return () => {
      window.removeEventListener('resize', calculateVH); // 언마운트 시 리스너 제거
    };
  }, []);

  return userClass ? (
    <div className="room-wrapper">
      <div className="room">
        <section>
          <div className="room__header">
            <button className="room__back-btn" onClick={() => window.close()}>
              <img src={Images.dropdownLeft} alt="back" />
            </button>
            <div className="room__header__title">{userClass.title}</div>
            <Infos userClass={userClass} />
            <Menus
              userClass={userClass}
              onClickMaterial={onClickMaterial}
              onClickSection={onClickSection}
              isCompletedBeforeSurvey={isCompletedBeforeSurvey}
              isCompletedAfterSurvey={isCompletedAfterSurvey}
              currentSurveyChapter={currentSurveyChapter}
              setCurrentSurveyChapter={setCurrentSurveyChapter}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
          </div>
          {currentSurveyChapter && (
            <div className="room__survey">
              <div>
                <Survey
                  surveyId={currentSurveyChapter.FK_survey_id}
                  classId={userClass.FK_class.id}
                  setIsCompleted={
                    currentSurveyChapter.timing === 'before' ? setIsCompletedBeforeSurvey : setIsCompletedAfterSurvey
                  }
                  userClassId={userClass.id}
                />
              </div>
            </div>
          )}
          {!currentSurveyChapter && (
            <div
              className="room__player"
              style={currentSection && currentSection.FK_video ? null : { backgroundColor: 'black' }}
            >
              {currentSection && currentSection.FK_video ? (
                <ReactPlayer
                  ref={player}
                  className="react-player"
                  url={currentSection.FK_video.video_url?.toCloudFrontURL()}
                  controls
                  playsinline
                  progressInterval={progressInterval}
                  config={{
                    file: {
                      hlsOptions: {
                        startLevel: -1,
                      },
                      tracks,
                      attributes: {
                        autoPlay,
                        controlsList: 'nodownload',
                        crossOrigin: 'anonymous',
                        onContextMenu: (e) => e.preventDefault(),
                      },
                    },
                  }}
                  onReady={onReady}
                  onSeek={onSeek}
                  onProgress={onProgress}
                  onEnded={onEnd}
                />
              ) : null}
            </div>
          )}
        </section>
        <Chapters
          userClass={userClass}
          onClickMaterial={onClickMaterial}
          onClickSection={onClickSection}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          currentSurveyChapter={currentSurveyChapter}
          setCurrentSurveyChapter={setCurrentSurveyChapter}
          isCompletedBeforeSurvey={isCompletedBeforeSurvey}
          isCompletedAfterSurvey={isCompletedAfterSurvey}
          isMobile={false}
        />
      </div>
    </div>
  ) : null;
}

function Infos(props) {
  const { userClass } = props;
  return (
    <div className="room__infos">
      <div className="room__info">
        <FormattedMessage id="CLASSROOM_COMPLETE_RATE" />
        <strong>{userClass.completion_rate || '0'}%</strong>
      </div>
      <hr />
      <div className="room__info">
        <FormattedMessage id="CLASSROOM_ATTENDED_TIME" />{' '}
        <strong>{formattedDuration(userClass.allAttendedTime)}</strong>
      </div>
      <hr />
      <div className="room__info">
        <FormattedMessage id="CLASSROOM_CLASS_TIME" />
        <strong>{formattedDuration(userClass.allVideoSeconds)}</strong>
      </div>
    </div>
  );
}

function Menus(props) {
  const {
    userClass,
    onClickMaterial,
    onClickSection,
    isCompletedBeforeSurvey,
    isCompletedAfterSurvey,
    currentSurveyChapter,
    setCurrentSurveyChapter,
    currentSection,
    setCurrentSection,
  } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const openMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openMenuRef.current && !openMenuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('touchstart', handleClickOutside, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('touchstart', handleClickOutside, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [openMenuRef, setOpenMenu]);

  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = 'hidden'; // 배경 스크롤 방지
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openMenu]);

  return (
    <div className="room__menus mobile" ref={openMenuRef}>
      <div className="room__menus__button--container">
        {openMenu ? (
          <MenuOpenIcon onClick={() => setOpenMenu(false)} />
        ) : (
          <MenuIcon onClick={() => setOpenMenu(true)} />
        )}
      </div>
      {openMenu && (
        <div className="room__menus__list">
          <section className="mobile">
            <div className="room__mobile__title">{userClass.FK_class.title}</div>
            <Infos userClass={userClass} />
          </section>
          <Chapters
            userClass={userClass}
            onClickMaterial={onClickMaterial}
            onClickSection={onClickSection}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            currentSurveyChapter={currentSurveyChapter}
            setCurrentSurveyChapter={setCurrentSurveyChapter}
            isCompletedBeforeSurvey={isCompletedBeforeSurvey}
            isCompletedAfterSurvey={isCompletedAfterSurvey}
            setOpenMenu={setOpenMenu}
            isMobile={true}
          />
        </div>
      )}
    </div>
  );
}

function Chapters(props) {
  const {
    userClass,
    onClickMaterial,
    onClickSection,
    isCompletedBeforeSurvey,
    isCompletedAfterSurvey,
    currentSurveyChapter,
    setCurrentSurveyChapter,
    currentSection,
    setCurrentSection,
    setOpenMenu,
    isMobile,
  } = props;

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <section className={isMobile ? 'mobile' : 'pc'}>
      {userClass.FK_class.url_attached_file && (
        <div className="room__file">
          <div className="room__file__title">
            <FormattedMessage id="CLASSROOM_ATTACHED_FILE" />
          </div>
          <a
            className="room__file__material"
            href={userClass.FK_class.url_attached_file}
            onClick={onClickMaterial}
            download
          >
            <FormattedMessage id="CLASSROOM_DOWNLOAD" />
            <img src={Images.download} alt="download" />
          </a>
        </div>
      )}
      <div className="room__list">
        {userClass.beforeSurvey && (
          <>
            <div className="room__list__title">
              <p>수강 전 설문조사</p>
            </div>
            <div className="room__chapters">
              <ChapterButton
                title={userClass.beforeSurvey.title}
                isCompleted={isCompletedBeforeSurvey}
                isCurrent={currentSurveyChapter?.id === userClass.beforeSurvey.id}
                onClick={() => {
                  setCurrentSurveyChapter(userClass.beforeSurvey);
                  setCurrentSection(null);
                }}
              />
            </div>
          </>
        )}
        <div className="room__list__title">
          <FormattedMessage id="CLASSROOM_CHAPTER" />
        </div>
        <div className="room__chapters">
          {userClass.FK_class.ClassChapters.map((ClassChapter, index) => (
            <Chapter
              {...ClassChapter}
              key={ClassChapter.id}
              number={index + 1}
              progresses={userClass.LearningProgresses}
              currentSection={currentSection}
              onClickSection={(sectionIndex) => {
                isMobile && setOpenMenu(false);
                onClickSection(index, sectionIndex);
              }}
              sectionDisabled={userClass.beforeSurvey && !isCompletedBeforeSurvey}
            />
          ))}
        </div>
        {userClass.afterSurvey && (
          <>
            <div className="room__list__title">
              <p>수강 후 설문조사</p>
            </div>
            <Tooltip
              open={openTooltip}
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
              PopperProps={{
                disablePortal: true,
                // style: { pointerEvents: 'none' },
              }}
              title={
                !(userClass.getIsCompletedAllChapters() && isCompletedBeforeSurvey)
                  ? '모든 강의 시청 완료 후 설문조사를 진행할 수 있습니다.'
                  : ''
              }
              placement="top"
              arrow
            >
              <div className="room__chapters" onClick={handleTooltipOpen}>
                <ChapterButton
                  title={userClass.afterSurvey.title}
                  isCompleted={isCompletedAfterSurvey}
                  isCurrent={currentSurveyChapter?.id === userClass.afterSurvey.id}
                  disabled={!(userClass.getIsCompletedAllChapters() && isCompletedBeforeSurvey)}
                  onClick={() => {
                    isMobile && setOpenMenu(false);
                    setCurrentSurveyChapter(userClass.afterSurvey);
                    setCurrentSection(null);
                  }}
                />
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </section>
  );
}

function Chapter(props) {
  const [folded, setFolded] = useState(!props.ClassSections.includes(props.currentSection));
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  useEffect(() => {
    setFolded((folded) => (folded ? !props.ClassSections.includes(props.currentSection) : folded));
  }, [props.ClassSections, props.currentSection]);

  const onClickFold = useCallback(() => setFolded((folded) => !folded), []);

  return (
    <>
      <Tooltip
        open={openTooltip}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        PopperProps={{
          disablePortal: true,
        }}
        title={props.sectionDisabled ? '수강 전 설문조사 완료 후 강의를 시청할 수 있습니다.' : ''}
        placement="top"
        arrow
        sx={{
          marginTop: '6px',
        }}
      >
        <div
          className={'room__chapter' + (props.sectionDisabled ? ' room__chapter--disabled' : '')}
          onClick={() => {
            handleTooltipOpen();
            onClickFold();
          }}
        >
          <div className="room__chapter__number">{props.number}</div>
          <div className="room__chapter__title">{props.title}</div>
          <div className="room__chapter__fold">
            {folded ? <img src={Images.dropdownDown} alt="unfold" /> : <img src={Images.dropdownUp} alt="fold" />}
          </div>
        </div>
      </Tooltip>
      {!folded && (
        <div className="room__sections">
          {props.ClassSections.map((ClassSection, index) => (
            <Section
              {...ClassSection}
              key={index}
              number={index + 1}
              chapterNumber={props.number}
              progress={ClassSection.LearningProgresses}
              current={ClassSection === props.currentSection}
              onClick={() => props.onClickSection(index)}
              disabled={props.sectionDisabled}
            />
          ))}
        </div>
      )}
    </>
  );
}

function ChapterButton(props) {
  return (
    <>
      <div
        className={
          'room__chapter' +
          (props.isCompleted ? ' room__chapter__button--done' : '') +
          (props.isCurrent ? ' room__chapter__button--current' : '') +
          (props.disabled ? ' room__chapter__button--disabled' : '')
        }
        onClick={props.disabled ? undefined : props.onClick}
      >
        <div className="room__chapter__title">
          {props.title}
          {props.isCompleted ? ' ✅' : ''}
        </div>
      </div>
    </>
  );
}

function Section(props) {
  const progress = props.progress && props.progress.length > 0 ? props.progress[0] : undefined;
  return (
    <div
      className={
        'room__section' +
        (progress?.is_completed ? ' room__section--done' : '') +
        (props.current ? ' room__section--current' : '') +
        (props.disabled ? ' room__section--disabled' : '')
      }
      onClick={props.disabled ? undefined : props.onClick}
    >
      <div className="room__section__title">{`${props.chapterNumber}-${props.number} ${props.title}`}</div>
      <div className="room__section__length">
        {props.FK_video ? formattedDuration(props.FK_video.video_length) : '0:00'}
      </div>
    </div>
  );
}

function formattedDuration(sec) {
  return toDuration(sec).format(sec >= 3600 ? 'h:mm:ss' : 'm:ss');
}

function toDuration(sec) {
  return moment.utc(moment.duration(sec, 's').asMilliseconds());
}

export default Classroom;
