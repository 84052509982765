import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as XLSX from 'sheetjs-style';
import './index.scss';
import List from './partials/Lecture/List';

const View = (properties) => {
  const { lectures, orgId, isLoading } = properties;
  const { count } = lectures || {};
  const { total = 0, close = 0, ongoing = 0, open = 0 } = count || {};
  const { list, title } = useMemo(
    () => ({
      total,
      close,
      ongoing,
      open,
      list: lectures?.list || [],
      title: lectures?.title || orgId,
    }),
    [close, lectures?.list, lectures?.title, ongoing, open, orgId, total],
  );

  const ExcelData = useMemo(() => {
    return list.map((lecture) => {
      const { id, completionRate, title, startDatetime, endDatetime, student } = lecture || {};
      const { total = 0, ongoing = 0, fail = 0, done = 0 } = student?.count || {};
      return {
        ID: id,
        강의명: title,
        시작일: startDatetime,
        종료일: endDatetime,
        '이수기준 수강율': String(completionRate).concat('%'),
        '전체 수강생 수': total,
        '수강 중': ongoing,
        미이수: fail,
        이수: done,
      };
    });
  }, [list]);

  const columnConfigs = useMemo(
    () => [
      { wch: 12 },
      { wch: 32 },
      { wch: 20 },
      { wch: 20 },
      { wch: 16 },
      { wch: 16 },
      { wch: 12 },
      { wch: 12 },
      { wch: 12 },
    ],
    [],
  );

  const rowConfigs = useMemo(() => [...list, {}].map((_) => ({ hpx: 30 })), [list]);

  const cellConfigs = useMemo(
    () => ({
      font: { sz: 12 },
      alignment: { vertical: 'center', horizontal: 'center' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    }),
    [],
  );

  const downloadLectures = useCallback(() => {
    console.log(ExcelData);
    const fields = Object.keys(ExcelData[0]);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(ExcelData, { header: fields });
    ws['!cols'] = columnConfigs;
    ws['!rows'] = rowConfigs;
    const allCells = Object.keys(ws).filter((key) => !key.includes('!'));
    allCells.forEach((cell) => {
      if (cell.includes('1') && cell.length === 2) {
        ws[cell].s = {
          ...cellConfigs,
          font: { ...cellConfigs.font, bold: true },
          fill: { fgColor: { rgb: 'FFD9D9D9' } },
        };
      } else {
        ws[cell].s = cellConfigs;
      }
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet2');
    XLSX.writeFile(wb, `${title || orgId}-강의관리-${dayjs().format('YYYY-MM-DD_HH-mm')}.xlsx`);
  }, []);

  return (
    !isLoading && (
      <div className="org-lecture-wrap">
        <div className="org-lecture-wrap-header">
          <h3 className="title">
            <FormattedMessage id="MY_PAGE_ORG_MGMT" /> - {title} - 강의 관리
          </h3>
          <Button variant="contained" color="primary" size="small" onClick={downloadLectures}>
            XLSX 다운로드
          </Button>
        </div>
        <List list={list} {...properties} />
      </div>
    )
  );
};

export default View;
