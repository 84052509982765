import useQueryString from '../../../Commons/Utils/useQueryString';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GET, POST } from '../../../Commons/Utils/fetch';
import { FormattedMessage, useIntl } from 'react-intl';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import DetailIcon from '../../../Assets/Images/chatting.png';
import { useForm } from 'react-hook-form';

import './index.scss';

const writeReview = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMobile = useMemo(() => {
    const mediaQuery = '(max-width: 480px)'; // 여기서 768px는 모바일 화면 크기를 의미합니다.
    return window.matchMedia(mediaQuery).matches;
  }, []);
  // 모바일에서만 접근 가능하도록 코드 반영 필요
  useEffect(() => {
    if (!isMobile) {
      swal({
        icon: 'error',
        title: '모바일에서만 접근 가능합니다.',
        timer: 2000,
      }).then(() => {
        window.location.replace('/');
      });
    }
  }, []);

  const { formatMessage } = useIntl();

  const { k: hash } = useQueryString();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      generalRating: 5,
      contentRating: 5,
      timeRating: 5,
      tutorRating: 5,
    },
  });
  const [lecture, setLecture] = useState(null);

  const [generalPoint, setGeneralPoint] = useState(5);
  const [contentPoint, setContentPoint] = useState(5);
  const [timePoint, setTimePoint] = useState(5);
  const [tutorPoint, setTutorPoint] = useState(5);
  const [isSubmit, setIsSubmit] = useState(false);
  const onSubmit = async (data) => {
    if (isSubmit) return;
    setIsSubmit(true);
    await POST({
      url: `/auth/review/write`,
      body: {
        ...data,
        hash,
      },
    })
      .then((res) => {
        if (res.success) {
          swal({
            icon: 'success',
            title: '리뷰가 등록되었습니다.',
            text: '엔터잡에듀 메인페이지로 이동합니다.',
            timer: 3000,
          }).then(() => {
            window.location.replace('/');
          });
        } else {
          swal({
            icon: 'error',
            title: '리뷰 등록에 실패했습니다.',
            timer: 2000,
          });
        }
      })
      .finally(() => {
        setIsSubmit(false);
      });
  };

  const getLectureData = useCallback(async () => {
    try {
      return await GET({
        url: `/auth/review/lecture/${hash}`,
      });
    } catch (error) {
      swal({
        icon: 'error',
        title: error.message,
        timer: 2000,
      }).then(() => {
        window.close() || window.location.replace('/');
      });
    }
  }, [hash]);

  useEffect(() => {
    // 서버에 lecture 정보를 요청함.
    getLectureData()
      .then((response) => {
        console.log(response);
        const { success, data } = response;
        if (success) {
          setLecture(data);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  }, [hash]);

  useEffect(() => {
    setIsLoaded(!!lecture);
  }, [lecture]);

  return (
    isLoaded &&
    isMobile && (
      <div className="review-write-form-container">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="form-title">{lecture?.classTitle}</h1>
          <div className="form-content">
            <div className="form-content-general">
              <h2>
                <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_TITLE" />
              </h2>
              <input
                type="hidden"
                {...register('generalRating', {
                  required: true,
                  value: generalPoint,
                })}
              />
              <Rating
                value={generalPoint}
                emptyIcon={<StarIcon fontSize="inherit" />}
                onChange={(event, newValue) => {
                  setGeneralPoint(newValue ?? 1);
                  setValue('generalRating', newValue ?? 1);
                }}
              />
              <textarea
                className={`form-content-general-textarea ${errors.generalComment ? 'error' : ''}`}
                aria-invalid={errors.generalComment ? 'true' : 'false'}
                placeholder={formatMessage({
                  id: 'MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_PLACEHOLDER',
                })}
                {...register('generalComment', {
                  required: true,
                })}
              />
            </div>
            <div className="form-content-detail">
              <div className="form-content-detail-title">
                <img src={DetailIcon} alt="detail" />
                <p>
                  <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_HEADER" values={{ br: <br /> }} />
                </p>
              </div>
              <div className="form-content-detail-content">
                <div
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_CONTENT_QUESTION',
                    }),
                  }}
                />
                <input
                  type="hidden"
                  {...register('contentRating', {
                    required: true,
                    value: contentPoint,
                  })}
                />
                <Rating
                  value={contentPoint}
                  emptyIcon={<StarIcon fontSize="inherit" />}
                  onChange={(event, newValue) => {
                    setContentPoint(newValue ?? 1);
                    setValue('contentRating', newValue ?? 1);
                  }}
                />
                {contentPoint > 0 && contentPoint < 3 && (
                  <textarea
                    placeholder={formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                    })}
                    {...register('contentComment', {
                      required: true,
                    })}
                    className={`${errors.contentComment ? 'error' : ''}`}
                    aria-invalid={errors.contentComment ? 'true' : 'false'}
                  />
                )}
              </div>
              <div className="form-content-detail-time">
                <div
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TIME_QUESTION',
                    }),
                  }}
                />
                <input
                  type="hidden"
                  {...register('timeRating', {
                    required: true,
                    value: timePoint,
                  })}
                />
                <Rating
                  value={timePoint}
                  emptyIcon={<StarIcon fontSize="inherit" />}
                  onChange={(event, newValue) => {
                    setTimePoint(newValue ?? 1);
                    setValue('timeRating', newValue ?? 1);
                  }}
                />
                {timePoint > 0 && timePoint < 3 && (
                  <textarea
                    placeholder={formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                    })}
                    {...register('timeComment', {
                      required: true,
                    })}
                    className={`${errors.timeComment ? 'error' : ''}`}
                    aria-invalid={errors.timeComment ? 'true' : 'false'}
                  />
                )}
              </div>
              <div className="form-content-detail-tutor">
                <div
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TUTOR_QUESTION',
                    }),
                  }}
                />
                <input
                  type="hidden"
                  {...register('tutorRating', {
                    required: true,
                    value: tutorPoint,
                  })}
                />
                <Rating
                  value={tutorPoint}
                  emptyIcon={<StarIcon fontSize="inherit" />}
                  onChange={(event, newValue) => {
                    setTutorPoint(newValue ?? 1);
                    setValue('tutorRating', newValue ?? 1);
                  }}
                />
                {tutorPoint > 0 && tutorPoint < 3 && (
                  <textarea
                    placeholder={formatMessage({
                      id: 'MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER',
                    })}
                    {...register('tutorComment', {
                      required: true,
                    })}
                    className={`${errors.tutorComment ? 'error' : ''}`}
                    aria-invalid={errors.tutorComment ? 'true' : 'false'}
                  />
                )}
              </div>
              <div className="form-content-detail-comments">
                <div className="question">
                  <strong>
                    <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_QUESTION" />
                  </strong>
                </div>
                <textarea
                  className="form-content-general-textarea"
                  placeholder={formatMessage({
                    id: 'MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_PLACEHOLDER',
                  })}
                  {...register('suggestionComment')}
                  className={`${errors.suggestionComment ? 'error' : ''}`}
                  aria-invalid={errors.suggestionComment ? 'true' : 'false'}
                />
              </div>
            </div>
          </div>

          <div className={`form-error-alert ${Object.keys(errors).length > 0 ? '' : 'hidden'}`}>
            <span>
              <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_FORM_ERROR" />
            </span>
          </div>

          <button type="submit" className="form-content-button">
            {isSubmit ? '' : <FormattedMessage id="MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT" />}
          </button>
        </form>
      </div>
    )
  );
};

export default writeReview;
