import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const GnbDropdownMenu = ({ item, isMobile }) => {
  const { pathname } = useLocation();
  const isEventMenu = item.editable === false && item.to.includes('/event');

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMobileMenuClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMenuClick = (event) => {
    event.preventDefault();
  };

  const handleClickDropdownLink = () => {
    if (isMobile) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          // 드롭다운 메뉴 영역 외부를 클릭한 경우
          setIsDropdownOpen(false);
        }
      };

      document.addEventListener('touchstart', handleClickOutside); // 터치 이벤트 등록

      return () => {
        document.removeEventListener('touchstart', handleClickOutside); // 컴포넌트 언마운트 시 이벤트 제거
      };
    }
  }, [dropdownRef, setIsDropdownOpen]);

  return (
    <li
      key={item.id}
      className={`dropdown__menu ${
        isMobile
          ? isDropdownOpen
            ? 'isActive'
            : ''
          : pathname.includes(isEventMenu ? '/event' : item.to)
          ? 'isActive'
          : ''
      }`}
      ref={dropdownRef}
    >
      {isMobile ? (
        <Link to="#" onClick={handleMobileMenuClick}>
          {item.menu_name}
        </Link>
      ) : (
        <Link to="#" onClick={handleMenuClick}>
          {item.menu_name}
        </Link>
      )}
      {/* TODO: event menu 형식 외의 드롭다운이 필요한 경우, 개선 필요 */}
      {isMobile ? (
        isDropdownOpen &&
        isEventMenu && (
          <ul className={`dropdown-list__menu ${isMobile ? 'mobile' : ''}`}>
            {item.exposePosts.map((post) => (
              <li key={post.id} className="dropdown-list">
                <Link to={`/event/${post.id}`} onClick={handleClickDropdownLink}>
                  {post.title}
                </Link>
              </li>
            ))}
          </ul>
        )
      ) : (
        <ul className="dropdown-list__menu">
          {isEventMenu &&
            item.exposePosts.map((post) => (
              <li key={post.id} className="dropdown-list">
                <Link to={`/event/${post.id}`}>{post.title}</Link>
              </li>
            ))}
        </ul>
      )}
    </li>
  );
};

export default GnbDropdownMenu;
