import './index.scss';
import { FormattedMessage } from 'react-intl';
import Loading from '../../../Commons/Components/Loading';
import CouponItem from '../../../Commons/Components/CouponItem';

const View = ({
  isLoading,
  tabList,
  couponList,
  currentType,
  couponCode,
  onChangeCouponCode,
  onClickCouponTabs,
  onClickEnrollmentCoupon,
}) => {
  return (
    <div className="coupon_content">
      <h3 className="title">
        <FormattedMessage id="MY_PAGE_COUPON" />
      </h3>

      <ul className="coupon_tabs">
        {tabList.map((list) => (
          <li
            key={list.type}
            className={list.type === currentType ? 'isActive' : ''}
            onClick={() => onClickCouponTabs(list.type)}
          >
            {/* 보유 */}
            {list.type === 'all' && <FormattedMessage id="POSSESSION" />}
            {/* 완료/만료 */}
            {list.type === 'expired' && <FormattedMessage id="DONE_EXPIRES" />}
          </li>
        ))}
      </ul>

      {currentType !== 'expired' && (
        <div className="coupon_input_form">
          <FormattedMessage id="PLACEHOLDER_COUPON_NUMBER">
            {(couponPlaceholder) => (
              <input type="text" placeholder={couponPlaceholder} value={couponCode} onChange={onChangeCouponCode} />
            )}
          </FormattedMessage>
          <span className="enrollment_btn" onClick={() => onClickEnrollmentCoupon(couponCode)}>
            <FormattedMessage id="COMMON_COUPON_ENROLLMENT" />
          </span>
        </div>
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <div className="coupon_list_wrap">
          {couponList.length > 0 ? (
            couponList.map((coupon, index) => <CouponItem key={index} coupon={coupon} />)
          ) : currentType === 'all' ? (
            <div className="none_list">
              <FormattedMessage id="MY_PAGE_NONE_COUPON" />
            </div>
          ) : (
            <div className="none_list">
              <FormattedMessage id="MY_PAGE_NONE_EXPIRED_COUPON" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default View;
