import '../index.scss';

function Buying() {
  return (
    <div className="payment_term">
      <h1 className="page-title">購入確認同意</h1>
      <div className="page-body">
        <p>注文商品の講義名、価格、配送情報等を確認し、これに同意します。</p>
      </div>
    </div>
  );
}

export default Buying;
