const HeartIcon = (props) => {
  const { color = '#FFFFFF', width = 16, height = 16, theme = 'outlined', shadowcolor = '#000000' } = props;

  let path;
  switch (theme) {
    case 'filled':
      path = (
        <path
          fillRule="evenodd"
          d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
          fill={color}
        ></path>
      );
      break;

    case 'outlined':
      path = (
        <>
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow dx="0.8" dy="1.1" stdDeviation="1.2" floodColor={shadowcolor} />
            </filter>
          </defs>
          <path
            d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"
            fill={color}
            filter="url(#shadow)"
          ></path>
        </>
      );
      break;

    default:
      break;
  }

  return (
    <svg
      {...props}
      color={color}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="-1.7 -2.4 20.5 20.5"
    >
      {path}
    </svg>
  );
};

export default HeartIcon;
