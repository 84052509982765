import { FormattedMessage } from 'react-intl';
import onClickOutside from 'react-onclickoutside';

function CouponOptionList({
  userCouponList,
  onClickStopPropagation,
  onClickSelectUserCoupon,
  onClickCouponSelectOptionsOutside,
}) {
  CouponOptionList.handleClickOutside = (e) => {
    if (!onClickCouponSelectOptionsOutside) return;
    onClickCouponSelectOptionsOutside(e);
  };

  if (userCouponList.length === 0) {
    return (
      <div className="coupon_select_box_list none_coupon">
        <FormattedMessage id="PAYMENT_COUPON_NONE_OPTIONS" />
      </div>
    );
  }

  const formatCurrency = (value, type) => (
    <>
      {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      {type === 'rate' ? '%' : <FormattedMessage id="CURRENCY_KOR" />}
    </>
  );

  return (
    <ul className="coupon_select_box_list" onClick={onClickStopPropagation}>
      {userCouponList.map((coupon, index) => (
        <li key={coupon.id} onClick={() => onClickSelectUserCoupon(index)}>
          {coupon.FK_coupon.name}
          <span style={{ marginLeft: '4px' }}>[{formatCurrency(coupon.FK_coupon.amount, coupon.FK_coupon.type)}]</span>
        </li>
      ))}
    </ul>
  );
}

export default onClickOutside(CouponOptionList, {
  handleClickOutside: () => CouponOptionList.handleClickOutside,
});
