import '../index.scss';

export default function PrivacyPolicy(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">개인정보 취급방침</h2>
      <article className="page sans">
        <div className="page-body">
          <p>
            (주)케이랩컴퍼니(이하 &quot;회사&quot; 또는 &quot;엔터잡에듀 서비스&quot;라 함)는
            정보통신망이용촉진및정보보호등에관한법률(이하 “정보통신망법”) 등 정보통신서비스제공자가 준수하여야 할 관련
            법령상의 개인정보보호 규정을 준수하며, 관련법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을
            다하고 있습니다.
          </p>
          <p>제 1장. 수집하는 개인정보의 항목 및 수집방법</p>
          <p>제 2장. 개인정보의 수집 및 이용목적</p>
          <p>제 3장. 개인정보 수집에 대한 동의</p>
          <p>제 4장. 개인정보의 보유 및 이용기간</p>
          <p>제 5장. 개인정보의 파기절차 및 방법</p>
          <p>제 6장. 이용자 및 법정대리인의 권리와 그 행사방법</p>
          <p>제 7장. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</p>
          <p>제 8장. 개인정보보호를 위한 기술적, 관리적 대책</p>
          <p>제 9장. 아동의 개인정보</p>
          <p>제 10장. 개인정보 관리책임자</p>
          <p>제 11장. 고객센터 안내</p>
          <p></p>
          <p>
            <strong>제 1장. 수집하는 개인정보의 항목 및 수집방법</strong>
          </p>
          <p>
            회사는 회원 가입, 서비스제공, 기타상담 등을 위해 개인정보를 수집하고 있으며, 또한 설문조사나 이벤트 시에
            집단적인 통계분석을 위해서나 경품발송을 위한 목적으로도 개인정보 기재를 요청할 수 있습니다. 그러나, 회사는
            이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적
            성향 및 범죄기록 등)는 가급적 수집하지 않으며 부득이하게 수집해야 할 경우 이용자들의 사전동의를 반드시
            구합니다. 이때에도 기입하신 정보는 해당서비스 제공이나 회원님께 사전에 밝힌 목적 이외의 다른 어떠한
            목적으로도 사용되지 않음을 알려드립니다.
          </p>
          <p></p>
          <p>① 수집하는 개인정보의 항목</p>
          <p>(1) 회원가입</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>필수항목 : 로그인 ID, 로그인 Password, e-mail 주소, 휴대전화번호</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              선택항목 : 성별, 주소, 전화번호, 학습목표, 직업, 관심분야, 응시분야, 응시시험 정보, 응시결과
            </li>
          </ul>
          <p></p>
          <p>(2) 서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              서비스 이용기록, 접속로그, 쿠키, 접속 IP 정보, 컴퓨터 기기 정보, 결제기록, 불량이용 기록
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              (모바일기기 이용 시) 단말기식별번호, 단말기OS정보, 이동통신회사, PUSH 수신여부
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>실명인증정보</li>
          </ul>
          <p></p>
          <p>(3) 유료 결제 이용시 수집하는 항목</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              신용카드정보, 은행계좌정보, 이동전화정보 등 대금결제에 필요한 정보등이 추가 수집될 수 있으며, 해당 부분은
              제3자(제휴업체, PG(Payment Gateway: 결제대행기관)사)에 제공될 수 있습니다.
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>신용카드 결제: 카드사코드, 승인번호 등 결제관련 기록</li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  계좌 이체: 거래은행 명, 계좌번호, 거래자 성명, 은행코드 등 결제관련 기록
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  휴대폰 결제: 휴대폰번호, 승인번호, 통신사 코드 등 결제관련 기록
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  기타: 우편번호, 주소, 연락처(배송상품이 포함 된 콘텐츠 구매 시)
                </li>
              </ul>
              <p>(회원가입에서 제공되지 않은 정보들이 추가적으로 수집될 수 있다는 것을 명시)</p>
            </li>
          </ul>
          <p></p>
          <p>(4) 부가서비스 및 이벤트 응모의 경우</p>
          <p>
            해당 서비스의 이용자에 한해서 별도의 동의를 받아 해당 서비스에 필요한 개인정보를 추가 수집할 수 있습니다.
          </p>
          <p></p>
          <p>② 수집하는 개인정보의 항목</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>홈페이지 또는 이메일을 통한 수집</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>전화, 팩스, 회사 내 신청서 등을 통한 오프라인 상에서의 수집</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>생성정보 수집 툴을 통한 자동수집</li>
          </ul>
          <p></p>
          <p>
            <strong>제 2장. 개인정보의 수집 및 이용목적</strong>
          </p>
          <p>회사는 아래와 같은 목적으로 개인정보를 수집합니다.</p>
          <p>① 서비스 제공에 관한 계약이행 및 서비스제공에 따른 요금정산</p>
          <p>② 컨텐츠 제공, 물품배송, 청구서 발송, 본인인증, 구매 및 요금결제, 금융서비스</p>
          <p>③ 회원관리</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              회원제 서비스 이용에 따른 본인 확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입 의사 확인
              연령 확인, 만 14세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리, 고지사항
              전달.
            </li>
          </ul>
          <p>④ 서비스 안내등 마케팅 및 광고</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              신규 서비스 개발 및 특화, 인구통계학적 특성에 따른 서비스 제공 및 광고게재, 접속 빈도 파악, 회원의 서비스
              이용에 대한 통계, 이벤트 등 광고성 정보 전달
            </li>
          </ul>
          <p></p>
          <p>
            <strong>제 3장. 개인정보 수집에 대한 동의</strong>
          </p>
          <p>
            회사는 회원님의 개인정보 수집에 대하여 동의를 받고 있으며, 회사 내의 회원가입 절차 중 이용약관 및
            개인정보취급방침에 개인정보 수집 동의절차를 마련해 두고 있습니다. 회원님께서 엔터잡에듀의 회원가입 페이지를
            통해 가입하신 경우, 개인정보 수집에 대해 동의한 것으로 봅니다.
          </p>
          <p></p>
          <p>
            <strong>제 4장. 개인정보의 보유 및 이용 기간</strong>
          </p>
          <div>
            ① 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다 단, 다음의 정보에
            대해서는 아래의 이유로 명시한 기간동안 보존합니다.
            <div className="indented">
              <p>(1) 회원탈퇴시 보존 개인정보</p>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'disc' }}>
                  보존항목 : 회원님께서 제공한 이름, 아이디, 이메일주소, 주소, 전화번호
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'disc' }}>
                  보존근거 : 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'disc' }}>보존기간 : 회원탈퇴 후 1년</li>
              </ul>
              <p>(2) 상거래 관련 보존 개인정보</p>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'disc' }}>보존항목 : 상거래이력</li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'disc' }}>보존근거 : 상법, 전자상거래등에서의 소비자보호에 관한 법률</li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'disc' }}>
                  보존기간 : 계약 또는 청약철회 등에 관한 기록 5년 / 대금결제 및 재화등의 공급에 관한 기록 5년 /
                  소비자의 불만 또는 분쟁처리에 관한 기록 3년
                </li>
              </ul>
            </div>
          </div>
          <p>
            ② 서비스 이용자의 개인정보는 그 수집 및 이용 목적(설문조사, 이벤트 등 일시적인 목적을 포함)이 달성되거나
            이용자가 직접 삭제 또는 회원 탈퇴한 경우에 재생할 수 없는 방법으로 파기됩니다.
          </p>
          <p></p>
          <p>
            <strong>제 5장. 개인정보의 파기절차 및 방법</strong>
          </p>
          <p>
            회원 탈퇴를 원하실 경우 1:1 문의를 통해 탈퇴가 가능하며, 등록된 모든 정보를 파기함으로 탈퇴 여부를 신중히
            고려 하셔야 합니다. 또한 탈퇴 후 재가입에 제약이 따를 수 도 있습니다. 회사의 개인정보 파기절차 및 방법은
            다음과 같습니다.
          </p>
          <p>① 파기절차</p>
          <p>
            (1) 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함)
            내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후
            파기됩니다.
          </p>
          <p>(2) 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.</p>
          <p>② 파기방법</p>
          <p>(1) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
          <p>(2) 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
          <p></p>
          <p>
            <strong>제 6장. 이용자 및 법정대리인의 권리와 그 행사방법</strong>
          </p>
          <p>
            ① 이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나
            수정할 수 있으며 가입해지를 요청할 수도 있습니다.
          </p>
          <p>
            ② 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘회원정보수정’(또는 ‘회원정보변경’ 등)을
            위해서는 로그인하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
          </p>
          <p>혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.</p>
          <p>
            ③ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는
            제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 지체
            없이 통지하여 정정이 이루어지도록 하겠습니다.
          </p>
          <p>
            ④ 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “개인정보의 보유 및 이용기간” 에
            명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
          </p>
          <p></p>
          <p>
            <strong>제 7장. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</strong>
          </p>
          <p>
            ① 쿠키(cookie)는 HTTP 서버에서 사용자 브라우저에게 보내는 조그마한 데이터 파일로써 회원님의 컴퓨터에
            저장됩니다. 회사는 이러한 쿠키(cookie)를 이용하여 회원님의 브라우저 형식이나 서비스 이용 형태를 얻게 되며,
            이러한 쿠키 정보를 바탕으로 회원님께 유용하고 보다 편리한 맞춤서비스를 제공하는데 사용하게 됩니다.
          </p>
          <p>
            ② 회원님은 쿠키에 대한 선택권을 가지고 있습니다. 회원님의 웹브라우저에서 옵션을 선택함으로써 모든 쿠키를
            허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을
            거부하실 경우 로그인 (Log-in) 이 필요한 회사의 모든 서비스는 이용하실 수 없게 됩니다.
          </p>
          <p></p>
          <p>
            <strong>제 8장. 개인정보보호를 위한 기술적, 관리적 대책</strong>
          </p>
          <p>
            ① 회원님의 개인정보는 비밀번호에 의해 보호되고 있습니다. 회원님 계정의 비밀번호는 오직 본인만이 알 수
            있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원님의
            비밀번호는 누구에게도 알려주면 안됩니다. 또한 작업을 마치신 후에는 로그아웃(log-out)하시고 웹브라우저를
            종료하는 것이 바람직합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우
            개인정보가 다른 사람에게 알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요합니다. 이용자 부주의나
            인터넷상의 문제로 인한 개인정보 유출에 대해서 회사는 책임을 지지 않습니다.
          </p>
          <p>
            ② 회사는 개인정보 취급 직원을 최소화하고 담당 직원의 수시교육을 통해 개인정보 보호 정책의 준수를 강조하고
            있습니다.
          </p>
          <p>③ 회사는 개인정보보호를 위한 기술적 관리적 대책을 다음과 같이 시행하고 있습니다.</p>
          <p>(1) 해킹 방지를 위한 방화벽과 보안 시스템에 만전을 기하고 있습니다.</p>
          <p>(2) 개인정보 송수신시 SSL 보안서버 인증서를 설치하여 정보를 보호합니다.</p>
          <p>
            (3) 개인정보에의 접근은 해당 업무 수행자, 업무 수행 시 개인정보 취급이 불가피한 자로 제한하여 그 이외의
            직원이 접근하지 못하도록 하고 있습니다.
          </p>
          <p></p>
          <p>
            <strong>제 9장. 아동의 개인정보</strong>
          </p>
          <p>
            회사는 원칙적으로 14세 미만 아동의 개인정보를 수집하지 않습니다. 다만 특별한 경우에 법정대리인의 동의를 얻어
            아동의 개인정보를 수집할 수 있습니다.
          </p>
          <p></p>
          <p>
            <strong>제 10장. 개인정보 관리 책임자</strong>
          </p>
          <p>
            회사 개인정보취급방침과 관련하여 의견이 있을 경우 메일을 주시면 접수 즉시 조치하고 처리결과를
            알려드리겠습니다. 개인정보관리책임자와 담당자는 아래와 같습니다.
          </p>
          <p>개인정보 관리 책임자</p>
          <p>이름 : 이상환</p>
          <p>소속 : (주)케이랩컴퍼니</p>
          <p>직위 : 공동대표</p>
          <p>전화 : 010-9866-8640</p>
          <p>E-mail : enterjobedu@k-lab.me</p>
          <p></p>
          <p>
            <strong>제 11장. 고객센터 안내</strong>
          </p>
          <p>회사는 민원 처리를 위해 고객센터를 아래와 같이 운영하고 있습니다.</p>
          <p>근무시간 : 평일 KST 10:00~18:00 (토, 일요일, 공휴일 휴무)</p>
          <p>카카오톡 : http://pf.kakao.com/_jyPFT/chat</p>
          <p>주소 : 서울시 광진구 자양로 129, 9층 / (주)케이랩컴퍼니 개인정보취급 담당자 앞</p>
          <p>기타문의 : 고객만족센터 페이지에서 확인</p>
          <p></p>
          <p>[부칙]</p>
          <p>현 개인정보취급방침의 수정, 변경이 있을 시 최소 7일 이전에 사이트 내 공지를 통하여 고지할 것입니다.</p>
          <p>본 개인정보취급방침 시행일자 : 2021년 09월 27일</p>
        </div>
      </article>
    </div>
  );
}
