import '../index.scss';

function Information() {
  return (
    <div className="payment_term">
      <h1 className="page-title">Đồng ý cung cấp thông tin cá nhân cho bên thứ 3</h1>
      <div className="page-body">
        <p>
          Công ty sử dụng thông tin cá nhân của người dùng trong phạm vi đã thông báo trong chính sách xử lý thông tin
          cá nhân này, và không sử dụng thông tin cá nhân của người dùng ngoài phạm vi này, hoặc cung cấp cho bên thứ 3
          mà không có sự đồng ý trước của người dùng.
        </p>
        <p>
          Tuy nhiên, ngoại trừ những trường hợp ngoại lệ cần cung cấp thông tin cá nhân cho những đơn vị như cơ quan
          điều tra theo quy định của những điều luật liên quan, hoặc theo quy trình, cách thức được quy định trên pháp
          luật để phục vụ công tác điều tra. Những trường hợp cần cung cấp thông tin cá nhân cho bên thứ 3 để tiến hành
          dịch vụ của công ty như sau:
        </p>
        <p>
          <a href="https://www.notion.so/13b9a699b6ad4f3caf9b0ec37b3f0842">Không có tiêu đề</a>
        </p>
      </div>
    </div>
  );
}

export default Information;
