import { SET_GNB } from '../Actions/gnb';

const initialStates = {
  gnbList: [],
};

const gnbReducers = (state = initialStates, action) => {
  const { type } = action;
  switch (type) {
    case SET_GNB:
      return {
        ...state,
        gnbList: action.list,
      };
    default:
      return state;
  }
};

export default gnbReducers;
