import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { GET } from '../../../Commons/Utils/fetch';
import View from './View';
import dayjs from 'dayjs';

class Container extends PureComponent {
  PAYMENT_IMPLEMENTS = [
    {
      key: 'PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD',
      pg: 'html5_inicis',
      pay_method: 'card',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_V_BANK',
      pg: 'html5_inicis',
      pay_method: 'vbank',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_KAKAOPAY',
      pg: 'html5_inicis',
      pay_method: 'kakaopay',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_TOSS',
      pg: 'html5_inicis',
      pay_method: 'tosspay',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_PAYPAL',
      pg: 'paypal',
      pay_method: 'card',
    },
  ];

  state = {
    paymentList: [],
    startDate: dayjs().subtract(6, 'M').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    isShowMessageModal: false,
    // type => "confirm": 결제 확인서 발급, "refund": 환불 신청, "info":
    messageType: null,
    currentModalItem: null,
  };

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      sessionStorage.setItem('login_redirect', '/my/payment');
      this.props.history.push('/login');
    }

    this.getPayment();
  }

  onClickOpenMessageModal = (type, item) => {
    this.setState({
      messageType: type,
      isShowMessageModal: true,
      currentModalItem: item,
    });
  };

  onClickCloseMessageModal = () => {
    if (!this.state.isShowMessageModal) return;

    this.setState({
      messageType: null,
      isShowMessageModal: false,
    });
  };

  getPayment = async () => {
    const token = localStorage.getItem('eje_token');

    const { startDate, endDate } = this.state;

    const params = {
      start_date: startDate,
      end_date: endDate,
    };

    const { transactions } = await GET({
      url: '/payments/me',
      params,
      header: { EJE_API_KEY: token },
    });

    this.setState({
      paymentList: transactions.rows,
    });
  };

  onClickStartDate = (date) => {
    this.setState({
      startDate: dayjs(date).format('YYYY-MM-DD'),
    });
  };

  onClickEndDate = (date) => {
    this.setState({
      endDate: dayjs(date).format('YYYY-MM-DD'),
    });
  };

  onClickInquire = () => {
    this.getPayment();
  };

  render() {
    const { paymentList, startDate, endDate, isShowMessageModal, messageType, currentModalItem } = this.state;
    const { onClickStartDate, onClickEndDate, onClickInquire, onClickOpenMessageModal, onClickCloseMessageModal } =
      this;
    return (
      <View
        PAYMENT_IMPLEMENTS={this.PAYMENT_IMPLEMENTS}
        paymentList={paymentList}
        startDate={startDate}
        endDate={endDate}
        isShowMessageModal={isShowMessageModal}
        messageType={messageType}
        onClickOpenMessageModal={onClickOpenMessageModal}
        onClickCloseMessageModal={onClickCloseMessageModal}
        onClickStartDate={onClickStartDate}
        onClickEndDate={onClickEndDate}
        onClickInquire={onClickInquire}
        currentModalItem={currentModalItem}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
