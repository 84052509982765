import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import View from './View';

class Container extends PureComponent {
  state = {
    isLoading: false,
    couponList: [],
    currentType: 'all',
    couponCode: '',
  };

  constructor(props) {
    super(props);
    this.token = localStorage.getItem('eje_token');
  }

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login');
    }
  }

  render() {
    const { isLoading } = this.state;
    return <View isLoading={isLoading} tabList={[]} />;
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Container));
