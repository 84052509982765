import { FormattedMessage } from 'react-intl';
import KakaoIcon from '../../../Assets/Images/icon-logo-kakaotalk.png';
import NaverIcon from '../../../Assets/Images/icon-logo-naver.png';
import GoogleLogin from 'react-google-login';
import GoogleIcon from '../../../Assets/Images/icon-logo-google.png';
import React, { useEffect } from 'react';
import './index.scss';
import { KEY } from '../../Utils/constant';

const LoginItem = (props) => {
  const { redirectURI } = props;

  const PLATFORM = {
    AUTH: 'auth',
    KAKAO: 'kakao',
    NAVER: 'naver',
    FB: 'facebook',
    GOOGLE: 'google',
  };

  const handleSMSLogin = (type, payload) => {
    const { KAKAO, NAVER, FB } = PLATFORM;

    if (redirectURI) sessionStorage.setItem('login_redirect', redirectURI);

    switch (type) {
      case KAKAO:
        window.Kakao.Auth.authorize({
          redirectUri: window.location.origin.replace('www.', '') + '/login/kakao',
        });

        break;
      case NAVER:
        const naverElement = document.getElementById('naverIdLogin');
        const naverBtn = naverElement && naverElement.firstChild ? naverElement.firstChild : undefined;
        if (naverBtn) naverBtn.click();

        break;
      case FB:
        window.FB.login((response) => {
          if (response.status === 'connected') {
            this.onResponseSMS(FB, response.authResponse);
          } else {
            swal({
              icon: 'error',
              text: this.props.intl.formatMessage({
                id: 'SOCIAL_LOGIN_ERROR',
              }),
            });
          }
        });

        break;
      default:
        break;
    }
  };

  const onResponseSMS = (type, response) => {
    const { GOOGLE, FB } = PLATFORM;

    if (redirectURI) sessionStorage.setItem('login_redirect', redirectURI);

    let callbackPlatformParam = {};
    switch (type) {
      case GOOGLE:
        const { tokenId } = response;
        if (tokenId) {
          callbackPlatformParam['tokenId'] = tokenId;
          location.href = `/login/google?tokenId=${tokenId}`;
        }
        break;
      case FB:
        // if (this.isMobile) return;
        const { accessToken } = response;
        if (accessToken) {
          callbackPlatformParam['access_token'] = accessToken;
          location.href = `/login/facebook?access_token=${accessToken}`;
        }
        break;

      default:
        break;
    }
  };

  const initNaverLogin = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: KEY.NAVER.CLIENT_ID,
      callbackUrl: `${process.env.REACT_APP_HOST}/login/naver`,
      isPopup: false,
      loginButton: { color: 'green', type: 3, height: '40' }, //버튼의 스타일, 타입, 크기를 지정
    });

    naverLogin.init();
  };

  useEffect(() => {
    initNaverLogin();
  }, []);

  return (
    <div className="login_btn_wrap">
      <div
        className="social_login_btn kakao"
        onClick={() => {
          handleSMSLogin('kakao');
        }}
      >
        <FormattedMessage id="LOGIN_KAKAO">
          {(login_kakao) => <img className="login_logo" src={KakaoIcon} alt={login_kakao} />}
        </FormattedMessage>
        <span>
          <FormattedMessage id="LOGIN_KAKAO" />
        </span>
      </div>

      <div style={{ display: 'none' }} id="naverIdLogin" />
      <div
        className="social_login_btn naver"
        onClick={() => {
          handleSMSLogin('naver');
        }}
      >
        <FormattedMessage id="LOGIN_NAVER">
          {(login_naver) => <img className="login_logo" src={NaverIcon} alt={login_naver} />}
        </FormattedMessage>
        <span>
          <FormattedMessage id="LOGIN_NAVER" />
        </span>
      </div>

      <GoogleLogin
        clientId="914816291724-gkq1mq4jnb0tvmmk43n07mgo05ojhd9g.apps.googleusercontent.com"
        render={(renderProps) => (
          <div className="social_login_btn google" onClick={renderProps.onClick}>
            <FormattedMessage id="LOGIN_GOOGLE">
              {(login_google) => <img className="login_logo" src={GoogleIcon} alt={login_google} />}
            </FormattedMessage>
            <span>
              <FormattedMessage id="LOGIN_GOOGLE" />
            </span>
          </div>
        )}
        buttonText="Login"
        onSuccess={(res) => {
          onResponseSMS('google', res);
        }}
        onFailure={(res) => {
          onResponseSMS('google', res);
        }}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
};

export default LoginItem;
