import { memo, useEffect, useMemo, useState } from 'react';
import './index.scss';
import PrevArrowIcon from '../../../Assets/Images/pagination-left.png';
import NextArrowIcon from '../../../Assets/Images/pagination-right.png';

const Pagination = ({ total, limit, currentPage, paginate }) => {
  const [currentPagingNumber, setCurrentPagingNumber] = useState(Number.parseInt((currentPage - 1) / 5) + 1);
  const [pageNumbers, setPageNumbers] = useState([]);

  const pageCount = Math.ceil(total / limit); // 몇 페이지가 필요한지 계산

  useEffect(() => {
    setPageNumbers([...new Array(Math.ceil(total / limit))].map((_, index) => index + 1));
  }, [total, limit]);

  const indexOfLast = currentPagingNumber * 5;
  const indexOfFirst = indexOfLast - 5;

  const isPrevAvailable = useMemo(() => {
    return indexOfFirst !== 0;
  }, [indexOfFirst]);

  const isNextAvailable = useMemo(() => {
    return pageCount > indexOfLast;
  }, [pageCount, indexOfLast]);

  return (
    <div className="pagination_wrap">
      {isPrevAvailable && (
        <span
          className="prev-arrow arrow"
          onClick={() => {
            setCurrentPagingNumber((prev) => prev - 1);
            paginate(indexOfFirst - 4);
          }}
        >
          <img src={PrevArrowIcon} alt="pagination_prev_arrow" />
        </span>
      )}
      <ul>
        {pageNumbers.slice(indexOfFirst, indexOfLast).map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? 'isActive' : ''}`}
            onClick={() => paginate(number)}
          >
            {number}
          </li>
        ))}
      </ul>
      {isNextAvailable && (
        <span
          className="next-arrow arrow"
          onClick={() => {
            setCurrentPagingNumber((prev) => prev + 1);
            paginate(indexOfLast + 1);
          }}
        >
          <img src={NextArrowIcon} alt="pagination_next_arrow" />
        </span>
      )}
    </div>
  );
};

export default memo(Pagination);
