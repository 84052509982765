import '../index.scss';

function Otherpay() {
  return (
    <div className="payment_term">
      <h1 className="page-title">Terms of Use- Payment Service</h1>
      <div className="page-body">
        <ol type="1" className="numbered-list" start="1">
          <li>Terms of Use-Electronic Financial Transaction</li>
        </ol>
        <p>
          All of the terms and conditions of the Terms of Use at
          <a href="https://www.inicis.com/terms">https://www.inicis.com/terms</a>
          shall be incorporated herein by reference.
        </p>
        <ol type="1" className="numbered-list" start="1">
          <li>Consent to the Collection and Use of Personal Information</li>
        </ol>
        <p>[Purposes of Collection and Use of Personal Information]</p>
        <p>
          Users’ personal information shall be processed for the following purposes. The personal information so
          processed shall not be used for any purposes other than those described below:
        </p>
        <p>
          <strong>
            1) Collection of personal information, including the payment information regarding the provision of
            electronic financial transaction services, etc.
          </strong>
        </p>
        <p>
          · To make payments for the goods or services purchased by users and issue cash receipts;· process
          identification of users, certification and verification of their real names in the course of making payments;
          confirm and provide the detail of transactions paid for by users, in response to users’ request; provide
          e-commerce related services (such as cancellation of transactions paid for by users or refund of payments,
          delivery of goods, etc.); request payment and collect payments made by users; prevent non-eligible users
          (including minors) and poorly qualified or unlawful users from using any electronic financial transactions and
          communications billing services;·confirm users’ intent as required in order to provide services and handle the
          relevant matters related thereto, including giving their consent or withdrawing consent; send messages and
          emails informing users of the payment services provided by the Company; handle customer complaints and
          grievances; and make payments for the table orders placed by users
        </p>
        <p>
          <strong>2) Execution of electronic payment service agreement;</strong>· To identify users and verify their
          real names to confirm the terms and conditions of the service agreements, in relation to the execution of such
          agreements
        </p>
        <p>
          <strong>3) Execution of offline payment agreement:</strong>·To provide customer consulting, including guidance
          concerning the use of services and relevant contracts, handling other inquiries, etc.; and collect diverse
          opinions, including business alliance
        </p>
        <p>
          <strong>4) Business alliance:</strong>· To collect opinions as to diverse areas, including new franchise
          contracts, proposals for business alliance, advertising alliance, investment inquiries, and participation in
          businesses of such areas
        </p>
        <p>
          <strong>5) Customer consulting and grievance handling: To h</strong>
          andle grievances, including inquiries from customers and franchises, service-related inquiries (additions and
          changes), settlement of disputes, proposals, reporting of mal practices, etc.
        </p>
        <p>
          <strong>6) Payment for rental services and identification of contractor:</strong>· To follow up rental service
          contracts and “Happy Calls”; identify contractors; charge and settle rental fees; and the information for
          determining the credit rating of users
        </p>
        <p>
          <strong>7) Recruitment management</strong>: Inquiries and response related to applications for employment and
          final employment; identify persons requiring protection of employment and grant them a privilege of
          preferential treatment at the final employment stage; and retain prospective employees and register with the
          corporate talent pool
        </p>
        <p>
          <strong>
            8) Development of new services and utilization of statistical data, according to demographic characteristics
          </strong>
          : To find out the frequency of use and provide services according to demographic characteristics; develop new
          services and analyze demographic characteristics; verify the adequacy of services; figure out access
          frequency; and utilize statistic data related to the use of services by Members
        </p>
        <p>
          In principle, users’ personal information shall be promptly destroyed by the Company without delay after the
          purpose of collection and use thereof has been achieved. Notwithstanding the foregoing, if required by
          applicable laws (such as the Electronic Financial Transactions Act, Act on the Consumer Protection in
          Electronic Commerce, Etc., Use and Protection of Credit Information Act, etc.), such personal information
          shall be retained for the period of time prescribed by the aforesaid applicable laws. In such a case, the
          information retained by the Company shall be stored solely for archival purposes for the retention periods
          described below:
        </p>
        <p>
          · Reasons for retaining records regarding electronic financial transactions exceeding 10,000 Korean Won per
          transaction: The retention period under the Electronic Financial Transactions Act: Five (5) years
        </p>
        <p>
          · Reasons for retaining records regarding electronic financial transactions of 10,000 Korean Won or less per
          transaction: The retention period under the Electronic Financial Transactions Act: One (1) year
        </p>
        <p>
          · Reasons for retaining records regarding the withdrawal of contracts or subscriptions: The retention period
          under the Act on the Consumer Protection in Electronic Commerce, Etc.: Five (5) years
        </p>
        <p>
          · Reasons for retaining records regarding payments and the supply of goods, etc.: The retention period under
          the Act on the Consumer Protection in Electronic Commerce, Etc.: Five (5) years
        </p>
        <p>
          · Reasons for retaining records regarding the handling of consumer complaints or disputes: The retention
          period under the Act on the Consumer Protection in Electronic Commerce, Etc.: Three (3) years
        </p>
        <p>
          · Reasons for retaining the information regarding the execution of electronic payment request service
          agreements: To confirm the consulting history and utilize as the supporting evidence explaining the cause of
          disputes Retention period: Six (6) months after the completion of consulting
        </p>
        <p>
          · Reasons for retaining records regarding the dispatch of text (SMS/LMS) and Kakao Talk messages for business
          purposes: To utilize as documents supporting the communications exchanged Retention period: One (1) year after
          the dispatch of messages
        </p>
        <p>
          · Reasons for retaining the information collected in relation to the execution of offline payment agreements
          and inquires made with respect thereto: To confirm investment proposals and investment history and to notify
          review results in response. Retention period: One (1) year after receipt of investment proposals
        </p>
        <p>
          · Reasons for retaining the information regarding the parties having proposed business alliance and investment
          by making inquiries for that purpose: To confirm their history of making inquiries and to respond thereto
          Retention period: One (1) year after receipt of inquiries
        </p>
        <p>
          · Reasons for retaining the information regarding applicants applying for employment: to retain prospective
          employees and to register with the corporate talent pool Retention period: One (1) year
        </p>
        <p>
          · Reasons for retaining records regarding the payments for rental services and identification of contractors:
          To follow up rental service contracts and “Happy Calls”; identify contractors; and handle grievances Retention
          period: Until the termination of contracts
        </p>
        <p>
          · Reasons for retaining the references to the information for determining the credit rating The retention
          period under the Use and Protection of Credit Information Act: Five (5) years from the date when business
          relationships have terminated, or until the date otherwise prescribed by applicable laws, if any
        </p>
      </div>
    </div>
  );
}

export default Otherpay;
