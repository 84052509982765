import { Box, Button, Fade, Modal, Tooltip } from '@mui/material';
import styled from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GET, POST } from '../../../Commons/Utils/fetch';
import { getKoreanPhoneNumber } from '../../PrevUserSignupForm';

const FormWrapper = styled(Box).attrs({})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  background-color: #ffffff;
  border-radius: 0.875rem;
  padding: 2rem;
  box-sizing: border-box;
  margin: 0 auto;
`;
const FadeContainer = styled(Fade).attrs({})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Form = styled.form.attrs({})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;
const PhoneFieldWrapper = styled.div.attrs({
  className: 'phone-field-wrapper',
})`
  display: flex;
  column-gap: 5px;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  justify-content: space-between;
  item-align: center;
  display: ${(props) => (props.props?.hideAuthCodeInput ? 'none' : '')};
`;
const InputField = styled.input.attrs({})`
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid #828282;
  outline: none;
  border-radius: 0.25rem;
  vertical-align: middle;
  text-align: center;
`;
const Header = styled.strong`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;
const Description = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const LoginDialog = ({ open, onClose, setIsVerified, setTutorInfo }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setFocus,
    setError,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      phone1: '010',
    },
  });
  const validPhoneNumber = (phoneNumber) => {
    // 010으로 시작하는 11자리 숫자 여야 함
    // 숫자가 아닌 문자가 포함되면 안됨
    const validPattern = /^010[0-9]{8}$/g;
    const isValid = validPattern.test(phoneNumber);
    return !isValid;
  };

  const [requestedAuthCode, setRequestedAuthCode] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const defaultRemainingTime = 180;
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
  const [hideAuthCodeInput, setHideAuthCodeInput] = useState(true);

  const phone = useMemo(() => {
    return `${watch('phone1')}${watch('phone2')}${watch('phone3')}`;
  }, [watch('phone1'), watch('phone2'), watch('phone3')]);
  const authCodeValue = useMemo(() => {
    return watch('authCode');
  }, [watch('authCode')]);
  const requestAuthCode = async () => {
    try {
      const params = {
        phone,
        //phone: `${watch('phone1')}${watch('phone2')}${watch('phone3')}`,
      };
      console.log(params);
      // code 요청
      const response = await GET({
        url: '/auth/getVerificationCode',
        params,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      const { success, message } = response;
      if (!success) {
        swal({
          title: '인증번호 요청 실패',
          text: message,
          icon: 'error',
        });
        return false;
      }
      console.log(response);
      setHideAuthCodeInput(false);
      setRequestedAuthCode(true);
      setRemainingTime(defaultRemainingTime);
      let time = remainingTime;
      const timer = setInterval(async () => {
        console.log('remainingTime', remainingTime);
        if (time === 1) {
          clearInterval(timer);
          setRequestedAuthCode(false);
          setRemainingTime(defaultRemainingTime);
          setValue('authCode', '');
        } else {
          await setRemainingTime(--time);
        }
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };
  const onSubmit = async (values) => {
    const data = {
      phone,
      authCode: authCodeValue,
    };

    try {
      const response = await POST({
        url: '/auth/tutor/login',
        body: data,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      const { success, message, token, info } = response;
      if (!success) {
        swal({
          title: '로그인 실패',
          text: message,
          icon: 'error',
        });
        return false;
      }

      // token을 localStorage에 저장
      localStorage.setItem('eje_tutor_token', token);
      setTutorInfo(info);
      setIsVerified(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {}}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FadeContainer in={true}>
        <FormWrapper>
          <Header>전화번호 인증</Header>
          <Description>전화번호 인증을 진행해주세요.</Description>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* 전화번호 입력, 전화번호는 010-1234-5678 형태이고 입력필드는 2개 010은 고정으로 한다. */}
            <PhoneFieldWrapper>
              <InputField
                autoComplete="off"
                maxLength={3}
                readOnly
                {...register('phone1', {
                  required: true,
                  maxLength: 3,
                  pattern: /[0-9]/g,
                  readOnly: true,
                })}
              />
              <InputField
                autoComplete="off"
                maxLength={4}
                {...register('phone2', {
                  required: true,
                  maxLength: 4,
                  pattern: /[0-9]/g,
                  validate: (value) => {
                    // pattern match
                    const validPattern = /[0-9]/g;
                    const isValid = validPattern.test(value) && value.length === 4;
                    isValid && setFocus('phone3');
                    return isValid;
                  },
                })}
                className={errors.phone2 ? 'error' : ''}
              />
              <InputField
                autoComplete="off"
                maxLength={4}
                {...register('phone3', {
                  required: true,
                  maxLength: 4,
                  pattern: /[0-9]/g,
                })}
              />
              <Button
                size="small"
                variant="contained"
                disabled={
                  watch('phone2')?.length !== 4 ||
                  watch('phone3')?.length !== 4 ||
                  validPhoneNumber(`${watch('phone1')}${watch('phone2')}${watch('phone3')}`) ||
                  requestedAuthCode
                }
                color="info"
                style={{ textWrap: 'nowrap', minWidth: '120px' }}
                onClick={requestAuthCode}
              >
                {requestedAuthCode ? remainingTime.formatVideoTime() : hideAuthCodeInput ? '인증번호 받기' : '재전송'}
              </Button>
            </PhoneFieldWrapper>
            <PhoneFieldWrapper props={{ hideAuthCodeInput }}>
              <InputField
                type="text"
                placeholder={hideAuthCodeInput ? '인증코드를 입력해주세요.' : '인증코드를 다시 받아주세요.'}
                disabled={!requestedAuthCode}
                {...register('authCode', {
                  required: true,
                  maxLength: 6,
                })}
              />
              <Button
                variant="contained"
                size="small"
                type="submit"
                disabled={!requestedAuthCode || !watch('authCode')}
                style={{ textWrap: 'nowrap', minWidth: '120px' }}
              >
                인증 및 로그인
              </Button>
            </PhoneFieldWrapper>
          </Form>
        </FormWrapper>
      </FadeContainer>
    </Modal>
  );
};

export default LoginDialog;
