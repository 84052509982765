import { useMemo } from 'react';
import ListWrap from '../ListWrap';

const ChapterList = ({ chapters }) => {
  const courses = useMemo(() => {
    const courses = [];
    chapters.map((chapter) => {
      chapter.sections.map((section) => {
        const { title, sec } = section.video || {};
        const titles = [chapter.title, section.title, title];
        courses.push({
          ...section,
          title: titles.filter((title) => title).join(' > '),
          sec,
        });
      });
    });
    console.log(courses);
    return courses;
  }, [chapters]);
  return <CourseList courses={courses || []} />;
};

export default ChapterList;

const CourseList = ({ courses }) => {
  if (!courses || courses.length === 0) {
    return null;
  }
  const header = [
    {
      key: 'idx',
      label: 'NO',
      style: {
        width: '32px',
      },
    },
    {
      key: 'thumbnail',
      label: '썸네일',
      style: {
        width: '160px',
        padding: '8px',
      },
    },
    {
      key: 'title',
      label: '제목',
      style: {
        width: '320px',
        textAlign: 'left',
      },
    },
    {
      key: 'sec',
      label: '길이',
      style: {
        width: '80px',
      },
    },
  ];

  console.log(courses);
  const list =
    courses?.map((item, index) => ({
      ...item,
      idx: index + 1,
      thumbnail: (
        <img
          src={item.image_thumb ? String(item.image_thumb).toCloudFrontURL() : ''}
          alt={item.title}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            maxHeight: '100px',
            objectFit: 'cover',
            borderRadius: '4px',
            aspectRatio: '6/5',
            backgroundColor: '#f5f5f5',
            border: '1px solid #e5e5e5',
          }}
        />
      ),
      sec: item.video?.sec?.formatVideoTime() || 0,
    })) || [];

  const properties = {
    header,
    list,
    useCheckbox: false,
  };
  return <ListWrap {...properties} />;
};
