import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../../../Store/Actions/user';
import { setGnb } from '../../../Store/Actions/gnb';
import { URL } from '../../../Utils/constant';
import { myPageList, boardList } from '../constant';
import { GET } from '../../../Utils/fetch';

function LoggedInMenu({ onClickLoggedInUserMenuOutside }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [recentNoticeCount, setRecentNoticeCount] = useState(0);
  const [recentEventCount, setRecentEventCount] = useState(0);

  const userInfo = useSelector((state) => state.user.userInfo);
  const ejeToken = localStorage.getItem('eje_token');
  const isAdmin = userInfo && userInfo.role === 'administrator' && ejeToken;
  const hasOrganization = userInfo?.Organizations?.length > 0;
  const orgMgmtList = userInfo?.Organizations?.filter((org) => org.role === 'supervisor' || org.role === 'manager');
  const hasOrgMgmtRole = orgMgmtList?.length > 0;
  const targetOrganization = hasOrgMgmtRole ? orgMgmtList[0] : {};

  LoggedInMenu.handleClickOutside = (e) => {
    if (!onClickLoggedInUserMenuOutside) {
      return;
    }
    onClickLoggedInUserMenuOutside(e);
  };

  const getGnbList = async () => {
    try {
      const { data } = await GET({
        url: '/auth/system/gnbs',
      });

      if (success) {
        dispatch(setGnb(data.rows));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickLogout = useCallback(async () => {
    dispatch(logout());
    await getGnbList();

    history.push('/login');
  }, [dispatch, history]);

  const onClickCloseStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  useEffect(async () => {
    const EJE_API_KEY = localStorage.getItem('eje_token') || 'auth';

    // 최근 공지사항 개수 가져오기
    const {
      data: { count },
    } = await GET({
      url: '/auth/posts/recent-count',
      params: {
        boardCode: 'notice',
      },
      header: {
        EJE_API_KEY,
      },
    });
    setRecentNoticeCount(count);

    // 최근 이벤트 개수 가져오기
    const {
      data: { count: eventCount },
    } = await GET({
      url: '/auth/posts/recent-count',
      params: {
        boardCode: 'event',
      },
      header: {
        EJE_API_KEY,
      },
    });
    setRecentEventCount(eventCount);
  }, []);

  return (
    <ul className="user_drop_down_menu" onClick={onClickCloseStopPropagation}>
      {isAdmin && (
        <li>
          <a href={`${URL.ADMIN}?key=${ejeToken}`} target="blank">
            <FormattedMessage id="HEADER_USER_NAV_ADMIN" />
          </a>
        </li>
      )}
      {hasOrgMgmtRole && (
        <li>
          <Link to={`/organization/${targetOrganization.id}/members`}>
            <FormattedMessage id="HEADER_USER_NAV_ORG_MGMT" />
          </Link>
        </li>
      )}
      {myPageList.map((option) =>
        option.href ? (
          <li key={option.href}>
            <a href={option.href} target="_blank" rel="noreferrer">
              <FormattedMessage id={option.key} />
            </a>
          </li>
        ) : (
          <li key={option.to}>
            <Link to={option.to}>
              <FormattedMessage id={option.key} />
            </Link>
          </li>
        ),
      )}
      {boardList.map((option) => (
        <li key={option.to} className="notice__list">
          <Link to={option.to}>
            <FormattedMessage id={option.key} />
            {option.type === 'notice' && recentNoticeCount > 0 && (
              <span className="recent-board-count">{recentNoticeCount}</span>
            )}
            {option.type === 'event' && recentEventCount > 0 && (
              <span className="recent-board-count">{recentEventCount}</span>
            )}
          </Link>
        </li>
      ))}
      <li onClick={onClickLogout}>
        <FormattedMessage id="HEADER_USER_NAV_LOGOUT" />{' '}
      </li>
    </ul>
  );
}

export default onClickOutside(LoggedInMenu, {
  handleClickOutside: () => LoggedInMenu.handleClickOutside,
});
