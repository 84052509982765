import dayjs from 'dayjs';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { GET, POST } from '../../Commons/Utils/fetch';
import updateState from '../../Commons/Utils/UpdateState';
import View from './View';
import { TRANSACTION } from '../../Commons/Utils/constant';
const { PAID, READY, FAILED } = TRANSACTION.TYPE.STATUS;

class Container extends PureComponent {
  PAYMENT_IMPLEMENTS = [
    {
      key: 'PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD',
      pg: 'html5_inicis',
      pay_method: 'card',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_V_BANK',
      pg: 'html5_inicis',
      pay_method: 'vbank',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_KAKAOPAY',
      pg: 'html5_inicis',
      pay_method: 'kakaopay',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_TOSS',
      pg: 'html5_inicis',
      pay_method: 'tosspay',
    },
  ];
  state = {
    isLoading: true,
    paymentData: null,
    paymentImplementIndex: null,
    agreementTerms: false,
    isValidation: false,
    totalApplyDiscountedPrice: 0, // 최종 결제 금액
  };

  constructor(props) {
    super(props);
  }

  /** Life Cycle */
  async componentDidMount() {
    if (!this.props.isLoggedIn) {
      const code = this.props.match.params.code;
      sessionStorage.setItem('login_redirect', `/payment/${code}`);
      this.props.history.push('/login');
    }
    await this.getPaymentData();
    await this.setPrices();
  }

  componentDidUpdate() {
    const { paymentImplementIndex, agreementTerms, totalApplyDiscountedPrice } = this.state;

    if (paymentImplementIndex !== null && agreementTerms) {
      this.setState({
        isValidation: true,
      });
    } else if (!totalApplyDiscountedPrice && agreementTerms) {
      this.setState({
        isValidation: true,
      });
    } else {
      this.setState({
        isValidation: false,
      });
    }
  }

  /** Fetchs */
  getPaymentData = async () => {
    const { code } = this.props.match.params;
    this.setState({ isLoading: true });

    const { data } = await GET({
      url: `/payment/addstudent/${code}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });

    if (!data) {
      await swal({
        icon: 'warning',
        text: '결제 정보를 확인할 수 없습니다.\n관리자에게 문의해주세요.',
      });
      this.props.history.replace('/');
      return;
    }

    await updateState(this, {
      paymentData: data,
      isLoading: false,
    });
  };

  setPrices = async () => {
    const { paymentData } = this.state;
    if (!paymentData) return;

    try {
      let discountedPrice = 0; // 강의 자체 할인률, 옵션가 적용된 금액

      // 할인률 적용된 강의 금액
      discountedPrice = this.getConvertedPrice(paymentData.price);
      this.setState({
        discountedPrice,
        totalApplyDiscountedPrice: discountedPrice, // 쿠폰 선택되지 않은 이상 강의 금액 = 결제 금액
        implementList: this.PAYMENT_IMPLEMENTS,
      });
    } catch (error) {
      console.error(error);
      await swal({
        icon: 'error',
        text: error.message,
      });

      this.props.history.goBack();
    }
  };

  postTransaction = async (body) => {
    try {
      const { transaction } = await POST({
        url: '/payment/transaction',
        body,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
      });

      if (transaction) {
        return transaction;
      }

      throw Error('transaction is not valid');
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  postUserClass = async (body) => {
    try {
      const { success } = await POST({
        url: '/payment/userclass',
        body,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
      });

      return success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  postFreeTransaction = async (body) => {
    try {
      const { transaction } = await POST({
        url: '/payment/transaction/free',
        body,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
      });

      if (transaction) {
        return transaction;
      }

      throw Error('transaction is not valid');
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  requestPayment = async () => {
    try {
      /** 결제 요청 데이터 EJE DB 추가 */
      const { paymentData, implementList } = this.state;

      const { pay_method, pg } = implementList[this.state.paymentImplementIndex];
      const payInfo = {
        pay_method,
        pg,
        paid_amount: this.state.totalApplyDiscountedPrice,
      };

      const transaction = await this.postTransaction(payInfo);

      if (!transaction) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR',
          }),
        });
        return;
      }

      const { merchant_uid, paid_amount } = transaction;

      const userClassBody = {
        merchant_uid,
        class_id: paymentData.FK_class.id,
        class_offline_schedule_id: paymentData.FK_offline_schedule.id,
      };

      const isSuccessUserClass = await this.postUserClass(userClassBody);

      if (!isSuccessUserClass) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR_REQUEST',
          }),
        });

        return;
      }

      /** Iamport 결제 요청 */
      /* 2. 결제 데이터 정의하기 */
      const iamportParam = {
        pg, // PG사
        pay_method, // 결제수단
        merchant_uid, // 주문번호
        name:
          (process.env.REACT_APP_ENV === 'DEV' ? '[DEV] ' : '') +
          this.state.paymentData.FK_class.title.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            '',
          ), // 주문명
        amount: paid_amount, // 결제금액
        buyer_email: paymentData.FK_user.email, // 구매자 이메일
        buyer_name: paymentData.FK_user.name || paymentData.FK_user.nickname, // 구매자 이름
        buyer_tel: paymentData.FK_user.phone, // 구매자 전화번호
        buyer_addr: '', // 구매자 주소
        buyer_postcode: '', // 구매자 우편번호
        m_redirect_url: `${process.env.REACT_APP_HOST}/payment/complete`,
        notice_url: `${process.env.REACT_APP_API_SERVER}/payment/iamport`,
      };

      if (pay_method === 'vbank') {
        iamportParam.vbank_due = dayjs().add(3, 'd');
      }

      /* 1. 가맹점 식별하기 */
      const IMP = window.IMP;
      IMP.init('imp92743153');

      /* 4. 결제 창 호출하기 */
      IMP.request_pay(iamportParam, (rsp) => {
        /* 3. 콜백 함수 정의하기 */
        if (rsp.success) {
          // 결제 완료 / 주문 완료 라우팅 => params(uid) 통해 완료 페이지에서 API 호출

          let status = FAILED;

          let vbackInfo;
          if (rsp.success) {
            if (rsp.vbank_num) {
              status = READY;
              const { vbank_num, vbank_name, vbank_holder } = rsp;
              vbackInfo = {
                vbank_num,
                vbank_name,
                vbank_holder,
              };
            } else {
              status = PAID;
            }
          } else {
            status = FAILED;
          }

          this.props.history.push(
            `/payment/complete?status=${status}&merchant_uid=${rsp.merchant_uid}${
              vbackInfo ? '&vbankInfo=' + JSON.stringify(vbackInfo) : ''
            }`,
          );
        } else {
          // 결제 에러 메세지
          // TODO: 디자인 요청
          swal({
            icon: 'error',
            text:
              this.props.intl.formatMessage({
                id: 'PAYMENT_ERROR_2',
              }) +
              ' ' +
              rsp.error_msg,
          });
        }
      });
    } catch (error) {
      console.error('결제 에러');
    }
  };

  requestPaymentForFree = async () => {
    try {
      /** 결제 요청 데이터 EJE DB 추가 */
      const { paymentData, classDiscountAmount, couponDiscountAmount } = this.state;

      const pg = 'html5_inicis',
        pay_method = 'card';

      const payInfo = {
        pg,
        pay_method,
        class_option_price: paymentData.FK_offline_schedule.option_price,
        paid_amount: this.state.totalApplyDiscountedPrice,
        class_id: paymentData.FK_class.id,
        class_offline_schedule_id: paymentData.FK_offline_schedule.id,
      };

      const transaction = await this.postFreeTransaction(payInfo);

      if (!transaction) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR',
          }),
        });
        return;
      }

      const { merchant_uid } = transaction;

      const userClassBody = {
        merchant_uid,
        class_id: paymentData.FK_class.id,
        class_offline_schedule_id: paymentData.FK_offline_schedule.id,
      };

      const isSuccessUserClass = await this.postUserClass(userClassBody);

      if (!isSuccessUserClass) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR_REQUEST',
          }),
        });
      } else {
        this.props.history.push(`/payment/complete?status=paid&merchant_uid=${merchant_uid}`);
      }
    } catch (error) {
      console.error('결제 에러');
    }
  };

  /** Events */
  onClickPaymentImplement = (implementIndex) => {
    this.setState({
      paymentImplementIndex: implementIndex,
    });
  };

  onClickToggleAgreementTerms = () => {
    this.setState((prevState) => ({
      ...prevState,
      agreementTerms: !prevState.agreementTerms,
    }));
  };

  onClickPayment = () => {
    const { totalApplyDiscountedPrice, discountedPrice } = this.state;
    const { userInfo } = this.props;
    const { role, permmission_level } = userInfo || {};
    const permissionLevel = role === 'user' ? permmission_level : 0;
    if (permissionLevel === -2 || (permissionLevel === -1 && discountedPrice <= 0)) {
      // 결제 불가
      swal({
        icon: 'error',
        title: '결제 불가',
        text: '관리자에게 문의해주세요.',
      });
      return;
    }

    if (totalApplyDiscountedPrice <= 0) {
      // TODO: 0원 결제 API 생성하여 반영하기(콘텐츠 지급 참고하여 로직 생성)

      this.requestPaymentForFree();
    }
    this.requestPayment();
  };

  onClickShowCouponOptions = () => {
    this.setState((prev) => ({
      ...prev,
      isShowCouponSelectOptions: !prev.isShowCouponSelectOptions,
    }));
  };

  onClickStopPropagation = (e) => {
    e.stopPropagation();
  };

  getConvertedPrice = (price) => {
    const { lecture } = this.state;
    let _price = Math.round(price / 100) * 100;
    if (lecture && lecture.listen_type === 'foreigner') {
      return Math.round(price);
    }
    return _price;
  };

  render() {
    const { onClickPaymentImplement, onClickToggleAgreementTerms, onClickPayment } = this;
    const {
      isLoading,
      paymentData,
      paymentImplementIndex,
      agreementTerms,
      isValidation,
      totalApplyDiscountedPrice,
      implementList,
    } = this.state;

    return (
      <View
        isLoading={isLoading}
        paymentData={paymentData}
        totalApplyDiscountedPrice={totalApplyDiscountedPrice}
        onClickPaymentImplement={onClickPaymentImplement}
        onClickToggleAgreementTerms={onClickToggleAgreementTerms}
        paymentImplementIndex={paymentImplementIndex}
        agreementTerms={agreementTerms}
        isValidation={isValidation}
        onClickPayment={onClickPayment}
        implementList={implementList}
        isLoggedIn={this.props.isLoggedIn}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    isLoggedIn: state.user.isLoggedIn,
  };
};

export default connect(mapStateToProps)(injectIntl(Container));
