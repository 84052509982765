export function getId(url) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match ? match[7] : null;
}

export function getThumbnail(url) {
  return `https://img.youtube.com/vi/${getId(url)}/0.jpg`;
}

export function getEmbed(url) {
  return `https://www.youtube.com/embed/${getId(url)}?`;
}
