if (!String.prototype.formatPhoneNumber) {
  String.prototype.formatPhoneNumber = function () {
    const input = String(this);
    const cleanInput = input.replaceAll(/\D/g, '');
    let result = '';
    const length = cleanInput.length;
    result = cleanInput.replace(/^82/, '0');
    let phoneNumber = '';
    if (result.length <= 3) {
      phoneNumber = result;
    } else if (result.length <= 7) {
      phoneNumber = `${result.slice(0, 3)}-${result.slice(3)}`;
    } else {
      phoneNumber = `${result.slice(0, 3)}-${result.slice(3, 7)}` + `-${result.slice(7, 11)}`;
    }
    result = phoneNumber;
    return result;
  };
}
