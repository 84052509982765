import { throttle } from 'lodash';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import VideoLockIcon from '../../Assets/Images/button-video-locked.png';
import VideoButtonIcon from '../../Assets/Images/button-video-play.png';
import CombinedIcon from '../../Assets/Images/combined-shape.png';
import CheckboxIcon from '../../Assets/Images/icon-checkbox-checked.png';
import UncheckboxIcon from '../../Assets/Images/icon-checkbox-unchecked.png';
import ClockIcon from '../../Assets/Images/icon-clock.png';
import ClassIntroIcon from '../../Assets/Images/icon-detail-curriculum.png';
import DropdownDownIcon from '../../Assets/Images/icon-dropdown-down.png';
import DropdownLeftIcon from '../../Assets/Images/icon-dropdown-left.png';
import DropdownUpIcon from '../../Assets/Images/icon-dropdown-up.png';
import FireIcon from '../../Assets/Images/icon-fire.png';
import FreePickIcon from '../../Assets/Images/icon-pink-1.png';
import VideoPickIcon from '../../Assets/Images/icon-pink-2.png';

import ReviewIcon from '../../Assets/Images/icon-review.png';
import ItemDefaultImage from '../../Assets/Images/item-default-image.png';
import RatingStarOff from '../../Assets/Images/rating-no-star.png';
import RatingStarOn from '../../Assets/Images/rating-star.png';
import Meta from '../../Commons/Components/Meta';
import Pagination from '../../Commons/Components/Pagination';
import Popup from '../../Commons/Components/Popup';
import ReviewItem from '../../Commons/Components/ReviewItem';
import YouTubeEmbed from '../../Commons/Components/YouTubeEmbed';
import Main from '../../Commons/Layouts/Main';
import { GET, POST, PUT } from '../../Commons/Utils/fetch';
import useQueryString from '../../Commons/Utils/useQueryString';
import * as youtube from '../../Commons/Utils/youtube';
import * as Models from '../../Models';
import { abbreviationWeek, LECTURE } from './constant';
import './index.scss';
import './quill.scss';
import swal from 'sweetalert';
import HeartIcon from '../../Commons/Components/HeartIcon';
import Swal from 'sweetalert2';
import { setLectureLikeList } from '../../Commons/Store/Actions/user';
import { DEFAULT_SEO_DESC } from '../../Commons/Utils/constant';

/**
 * ClassOnlineSchedule
 오직 온라인, 외국인 강의의 경우에만
 * ClassOfflineSchedule
 오직 오프라인 강의의 경우에만
 * ClassSingle_ClassPackages
 오직 패키지 강의의 경우에만
 FK_class_single
 패키지 강의에 속한 강의 입니다.
 ClassChapters
 toggle 했을 경우
 */

function pad(string) {
  return ('0' + string).slice(-2);
}

function formatSecondTime(second) {
  if (!second && second !== 0) {
    return null;
  }

  const date = new Date(second * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());

  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }

  return `${mm}:${ss}`;
}

const VideoItem = memo(({ video, number = null }) => {
  const time = formatSecondTime(video?.FK_video?.video_length || video.video_length);

  return (
    <div className="video_item">
      {video.url ? (
        <Popup
          trigger={
            <div className="video_item_thumb">
              <img src={VideoButtonIcon} alt="playIcon" />
              <img src={youtube.getThumbnail(video.url)?.toCloudFrontURL()} alt={video.title} />
            </div>
          }
          modal
        >
          <YouTubeEmbed title={video.title} src={youtube.getEmbed(video.url)} />
        </Popup>
      ) : (
        <div className="video_item_thumb">
          <img src={(video.image_thumb || ItemDefaultImage)?.toCloudFrontURL()} alt={video.title} />
          <div className="video_item_thumb_overlap">
            <img src={VideoLockIcon} alt="video_overlap_lock_icon" />
          </div>
        </div>
      )}
      <div className="video_item_info">
        <strong className="video_item_title">
          {number && (
            <span className="order">
              {number}-{video.order}.
            </span>
          )}
          {video.title}
        </strong>
        <span className="video_item_time">{time}</span>
      </div>
    </div>
  );
});

const PackageItem = ({ classItem, number, defaultIsActive = false }) => {
  const [isActive, setIsActive] = useState(defaultIsActive);
  const onClickToggleActive = useCallback(() => {
    setIsActive((prev) => !prev);
  }, []);

  return (
    <div className={`package_item ${isActive ? 'isActive' : ''}`}>
      <h3 className="package_item_title" onClick={onClickToggleActive}>
        <p>
          <span className="package_item_number">{number}</span>
          {classItem.title}
        </p>
        <img src={isActive ? DropdownUpIcon : DropdownDownIcon} alt="drop_down_package" />
      </h3>
      <div className="lecture_chapter_list isPackage">
        {classItem.ClassChapters.map((chapter, index) => (
          <div className="lecture_chapter_item" key={`${chapter.title}_${index}`}>
            <h4 className="chapter_title">
              <span className="order">{chapter.order < 10 ? '0' + chapter.order : chapter.order}</span>
              <span>{chapter.title}</span>
            </h4>
            <div className="chapter_section_list">
              {chapter.ClassSections.map((section, index) => (
                <VideoItem key={index} video={section} number={index + 1} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const lang = localStorage.getItem('eje_lang');

const View = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const permissionLevel = useSelector((state) => {
    const { userInfo } = state.user;
    const { role, permmission_level } = userInfo || {};
    return role === 'user' ? permmission_level : 0;
  });
  const isKorean = useSelector((state) => state.user.userInfo?.FK_country_id === 47);
  const intl = useIntl();
  const likeList = useSelector((state) => state.user.lectureLikeList);

  const wrapRef = useRef(null);
  const introRef = useRef(null);
  const curriculumRef = useRef(null);
  const reviewRef = useRef(null);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [lecture, setLecture] = useState(null);
  const [isFixed, setIsFixed] = useState(false);
  const [isNone, setIsNone] = useState(false);
  const [reviewActiveIndex, setReviewActiveIndex] = useState(-1);
  const [discountingPrice, setDiscountingPrice] = useState(0);
  const [isCanWaiting, setIsCanWaiting] = useState(false);

  // total review 관리
  const [reviews, setReviews] = useState([]);
  // pc/mobile 분리해서 보여지기 위한
  const [currentReviews, setCurrentReviews] = useState([]);
  const [reviewParams, setReviewParams] = useState({ page: 1, limit: 5 });

  // 강의 만족도(리뷰 평균)
  const [satisfaction, setSatisfaction] = useState([false, false, false, false, false]);

  // Offline Class Round
  const [selectedClassOfflineScheduleIndex, setSelectedClassOfflineScheduleIndex] = useState(null);
  // Consulting Class Round
  const [selectedClassConsultingScheduleIndex, setSelectedClassConsultingScheduleIndex] = useState(null);
  // RoundOnline Class Round
  const [selectedClassOnlineScheduleByRoundIndex, setSelectedClassOnlineScheduleByRoundIndex] = useState(null);

  const [showTimer, setShowTimer] = useState(false);
  const [timerText, setTimerText] = useState('');
  const [timerRemainSeconds, setTimerRemainSeconds] = useState(0);
  const [timerCount, setTimerCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [schedules, setSchedules] = useState([]);

  const [isLiked, setIsLiked] = useState(likeList.includes(+id));
  const [isHoveredLike, setIsHoveredLike] = useState(false);

  const days = useMemo(() => {
    return Math.floor((timerRemainSeconds - timerCount) / (60 * 60 * 24));
  }, [timerRemainSeconds, timerCount]);
  const hours = useMemo(() => {
    return Math.floor(((timerRemainSeconds - timerCount) % (60 * 60 * 24)) / (60 * 60));
  }, [timerRemainSeconds, timerCount]);
  const minutes = useMemo(() => {
    return Math.floor(((timerRemainSeconds - timerCount) % (60 * 60)) / 60);
  }, [timerRemainSeconds, timerCount]);
  const seconds = useMemo(() => {
    return Math.floor((timerRemainSeconds - timerCount) % 60);
  }, [timerRemainSeconds, timerCount]);

  const getLecture = useCallback(async () => {
    try {
      const { classModel = {} } = await GET({
        url: `/auth/class/${id}`,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token') || 'auth',
        },
      });

      if (!classModel) return;

      if (!classModel?.is_selling) {
        await swal({
          icon: 'warning',
          text: intl.formatMessage({
            id: 'DETAIL_LECTURE_NOT_SELLING',
          }),
        });
        history.goBack();
        return;
      }
      let originLecture;
      if (classModel) {
        classModel?.Reviews.forEach((review) => {
          review.isActive = false;
        });

        if (classModel?.Reviews.length > 0) {
          setReviews(classModel?.Reviews);
        }
        originLecture = new Models.Lecture(classModel);
        setLecture(originLecture);

        gtag('event', 'view_item', {
          items: [
            {
              id: classModel.id,
              name: classModel.title,
              price: classModel.price,
              brand: 'EJE',
              category: 'Class',
              variant: classModel.listen_type,
              list_name: classModel.listen_type,
              price_currency: classModel.listen_type === 'foreigner' ? 'USD' : 'KRW',
            },
          ],
        });
      }

      const averageSatisfaction =
        classModel.Reviews.reduce((prev, curr) => {
          prev += curr.rate;
          return prev;
        }, 0) / classModel.Reviews.length;

      const satisfaction = new Array(5).fill(false).map((arr, index) => index + 1 <= averageSatisfaction);

      // 강의 만족도 = Reviews total stars / Total Reviews;
      setSatisfaction(satisfaction);

      setIsLoading(false);
      setIsCanWaiting(!classModel.hasActiveSchedule && LECTURE.ROUND_LISTEN_TYPES.includes(classModel.listen_type));

      // 강의가 기수 강의인 경우
      if (LECTURE.ROUND_LISTEN_TYPES.includes(classModel.listen_type)) {
        const showSchedules = {
          ['offline']: classModel.ClassOfflineSchedules,
          ['roundOnline']: classModel.ClassOnlineScheduleByRounds,
          ['consulting']: classModel.ClassConsultingSchedules,
        }[classModel.listen_type];

        setSchedules(showSchedules || []);

        const availableSchedules = showSchedules.slice().filter((schedule) => {
          const { student_count, student_limit } = schedule;
          return student_count < student_limit || student_limit === 0;
        });

        // 최저가 옵션 선택된 상태로 설정
        const sortedAvailableSchedules = availableSchedules.slice().sort((a, b) => {
          const { option_price: aPrice } = a;
          const { option_price: bPrice } = b;
          return aPrice - bPrice;
        });
        const setIndex = {
          offline: setSelectedClassOfflineScheduleIndex,
          roundOnline: setSelectedClassOnlineScheduleByRoundIndex,
          consulting: setSelectedClassConsultingScheduleIndex,
        }[classModel.listen_type];

        setIndex(showSchedules.findIndex((schedule) => schedule.id === sortedAvailableSchedules[0].id));
        if (sortedAvailableSchedules.length > 0) {
          setDiscountingPrice(sortedAvailableSchedules[0].option_price);
        }

        // 초기 타이머 설정
        // 스케쥴 정렬(1. 노출 종료시간, 2. 타이머 노출여부)
        const sortedSchedules = availableSchedules.slice().sort((a, b) => {
          const dateDiff = new Date(a.show_end_datetime) - new Date(b.show_end_datetime);
          if (dateDiff === 0) {
            return b.show_timer - a.show_timer;
          }
          return dateDiff;
        });

        // 가장 먼저 노출 종료되는 스케쥴(선택 가능한 스케쥴 중)
        const firstEndSchedule = sortedSchedules[0];
        if (firstEndSchedule) {
          if (firstEndSchedule?.remain_time) {
            setShowTimer(firstEndSchedule?.show_timer || false);
            setTimerText(firstEndSchedule?.timer_text || '');
            setTimerRemainSeconds(firstEndSchedule.remain_time);

            const second = firstEndSchedule.remain_time % 60;
            const firstPercent = (second / 60) * 100;
            setProgress(firstPercent);
          }
        }
      } else {
        setShowTimer(classModel?.showTimer || false);
        setTimerText(classModel?.timerText || '');
        setTimerRemainSeconds(classModel?.discountEndRemainTime || 0);

        if (classModel?.discountEndRemainTime > 0) {
          const second = classModel?.discountEndRemainTime % 60;
          const firstPercent = (second / 60) * 100;
          setProgress(firstPercent);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, history, intl]);

  const postLectureViewLog = useCallback(async () => {
    const viewLogs = JSON.parse(localStorage.getItem('lecture_view_logs') || '{}');
    const now = new Date().getTime();

    // 오래된 항목(7일 이상 지난 것) 정리
    const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000;
    for (const [key, timestamp] of Object.entries(viewLogs)) {
      if (now - timestamp > SEVEN_DAYS_IN_MS) {
        delete viewLogs[key];
      }
    }

    // 현재 강의가 마지막 30분 이내에 조회되었는지 확인
    const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000;
    if (viewLogs[id] && now - viewLogs[id] < THIRTY_MINUTES_IN_MS) {
      return;
    }

    try {
      await POST({
        url: `/auth/class/${id}/view`,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token') || 'auth',
        },
      });

      // 현재 강의의 조회 로그 업데이트
      viewLogs[id] = now;
      localStorage.setItem('lecture_view_logs', JSON.stringify(viewLogs));
    } catch (error) {
      console.error('Failed to log lecture view:', error);
    }
  }, [id]);

  /** 다음 스케쥴의 일정에 맞춰 타이머 설정하는 함수 */
  const setTimerWithNextSchedule = useCallback(() => {
    // 노출 기한 지난 스케쥴 제거
    const showSchedules = schedules.filter((schedule) =>
      moment.utc(schedule.show_end_datetime).subtract(9, 'h').isAfter(moment()),
    );
    setSchedules(showSchedules);

    // 판매 완료된 강의 건너띄고 타이머 설정되도록 함 강의 건너띄고 타이머 설정되도록 함
    const availableSchedules = showSchedules.slice().filter((schedule) => {
      const { student_count, student_limit } = schedule;
      return student_count < student_limit || student_limit === 0;
    });

    // 마감(판매 완료)된 강의면 대기 신청 가능
    if (availableSchedules.length <= 0) {
      setIsCanWaiting(true);
      setLecture((prev) => {
        return new Models.Lecture({
          ...prev,
          hasActiveSchedule: false,
        });
      });
    }

    // 유저가 선택한 옵션이 노출 기한 지난 스케쥴이면 선택 해제
    const availableScheduleIds = availableSchedules.map((schedule) => schedule.id);
    if (lecture.listen_type === 'offline') {
      const offlineScheduleId = schedules[selectedClassOfflineScheduleIndex]?.id;
      if (!availableScheduleIds.includes(offlineScheduleId)) {
        setSelectedClassOfflineScheduleIndex(null);
        setDiscountingPrice(0);
      } else {
        setSelectedClassOfflineScheduleIndex(showSchedules.findIndex((schedule) => schedule.id === offlineScheduleId));
      }
    } else if (lecture.listen_type === 'roundOnline') {
      const onlineScheduleId = schedules[selectedClassOnlineScheduleByRoundIndex]?.id;
      if (!availableScheduleIds.includes(onlineScheduleId)) {
        setSelectedClassOnlineScheduleByRoundIndex(null);
        setDiscountingPrice(0);
      } else {
        setSelectedClassOnlineScheduleByRoundIndex(
          showSchedules.findIndex((schedule) => schedule.id === onlineScheduleId),
        );
      }
    } else if (lecture.listen_type === 'consulting') {
      const consultingScheduleId = schedules[selectedClassConsultingScheduleIndex]?.id;
      if (!availableScheduleIds.includes(consultingScheduleId)) {
        setSelectedClassConsultingScheduleIndex(null);
        setDiscountingPrice(0);
      } else {
        setSelectedClassConsultingScheduleIndex(
          showSchedules.findIndex((schedule) => schedule.id === consultingScheduleId),
        );
      }
    }

    // 스케쥴 정렬
    const sortedAvailableSchedules = availableSchedules.slice().sort((a, b) => {
      const dateDiff = new Date(a.show_end_datetime) - new Date(b.show_end_datetime);
      if (dateDiff === 0) {
        return b.show_timer - a.show_timer;
      }
      return dateDiff;
    });
    const nextSchedule = sortedAvailableSchedules[0];

    // 다음 스케쥴의 타이머로 변경
    if (nextSchedule) {
      const remainTime = moment
        .utc(nextSchedule.show_end_datetime)
        .add(1, 's')
        .diff(moment.utc().add(9, 'h'), 'second');

      // 다음 스케쥴의 타이머로 변경
      if (remainTime > 0) {
        setTimerCount(0);
        setShowTimer(nextSchedule?.show_timer || false);
        setTimerText(nextSchedule.timer_text || '');
        setTimerRemainSeconds(remainTime);

        const second = remainTime % 60;
        const firstPercent = (second / 60) * 100;
        setProgress(firstPercent);
      }
    } else {
      // 타이머 종료
      setShowTimer(false);
      setTimerCount(0);
      setTimerRemainSeconds(0);
    }
  }, [
    schedules,
    lecture,
    selectedClassConsultingScheduleIndex,
    selectedClassOfflineScheduleIndex,
    selectedClassOnlineScheduleByRoundIndex,
  ]);

  useEffect(() => {
    if (reviews?.length > 0) {
      setCurrentReviews([...reviews].splice((reviewParams.page - 1) * reviewParams.limit, reviewParams.limit));
    }
  }, [reviews]);

  useEffect(() => {
    getLecture();
    postLectureViewLog();
  }, []);

  useEffect(() => {
    if (timerRemainSeconds === 0) return;

    let timerCnt = 0;
    const timerCountInterval = setInterval(() => {
      setTimerCount((prev) => {
        timerCnt = prev !== timerRemainSeconds ? prev + 1 : prev;
        return timerCnt;
      });
      timerCount === timerRemainSeconds && clearInterval(timerCountInterval);
    }, 1000);

    const progressRefeat = setInterval(() => {
      setProgress((prev) => {
        return (prev <= 0 ? prev + 100 : prev) - (1 / (60 * 100)) * 100;
      });
    }, 10);

    /** 현재 할인 타이머 종료된 경우 */
    if (timerRemainSeconds - timerCount <= 0) {
      // 기수제 강의인 경우
      if (LECTURE.ROUND_LISTEN_TYPES.includes(classModel.listen_type)) {
        // 다음 스케쥴의 일정에 맞춰 타이머 설정
        setTimerWithNextSchedule();
      } else {
        // 일반 강의인 경우 할인 불가
        setLecture((prev) => {
          return new Models.Lecture({
            ...prev,
            isDiscountAvailable: false,
          });
        });
        // 타이머 종료
        setTimerCount(0);
        setTimerRemainSeconds(0);
      }
    }

    return () => {
      clearInterval(timerCountInterval);
      clearInterval(progressRefeat);
    };
  }, [timerRemainSeconds, timerCount, lecture]);

  /** 타이머 progress bar sync 맞추기 위함 */
  useEffect(() => {
    if (seconds % 3 === 0) {
      const secondsPercent = (seconds / 60) * 100;
      if (secondsPercent.toString().split('.')[0] !== progress.toString().split('.')[0]) {
        setProgress(secondsPercent);
      }
    }
  }, [seconds]);

  /** 다시 페이지로 돌아왔을 때(탭 변경) 타이머 실시간 반영하기 위함 */
  // 남은 시간(remain_time) 다시 구해서 설정해야 함
  useEffect(() => {
    if (showTimer) {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          // 기수 강의인 경우
          if (lecture.listen_type === 'offline' || lecture.listen_type === 'roundOnline') {
            setTimerWithNextSchedule();
          } else {
            // 일반 강의인 경우
            const remainTime = lecture.discount_end
              ? moment(`${lecture.discount_end} ${lecture.discount_end_time}`).add(1, 'm').diff(moment(), 'second')
              : 0;

            if (remainTime > 0) {
              setTimerCount(0);
              setTimerRemainSeconds(remainTime);
              setShowTimer(lecture?.showTimer || false);
              setTimerText(lecture?.timerText || '');

              const second = remainTime % 60;
              const firstPercent = (second / 60) * 100;
              setProgress(firstPercent);
            }
          }
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [schedules, lecture, showTimer]);

  // PC, 모바일 반응형 적으로 데이터 셋업하기 위함
  useEffect(() => {
    if (currentReviews.length < 1) return;

    const { page, limit } = reviewParams;

    if (isMobile) {
      setCurrentReviews([...reviews].splice(0, page * limit));
    } else {
      setCurrentReviews([...reviews].splice((page - 1) * 5, limit));
    }
  }, [isMobile]);

  const onClickMoreReviews = useCallback(
    (pageNumber) => {
      setReviewParams((prev) => ({
        ...prev,
        page: pageNumber,
      }));

      if (isMobile) {
        setCurrentReviews([...reviews].splice(0, pageNumber * reviewParams.limit));
      } else {
        setCurrentReviews(
          [...reviews].splice(pageNumber * reviewParams.limit - reviewParams.limit, reviewParams.limit),
        );
      }
    },
    [isMobile, reviews, reviewParams],
  );

  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (!wrapRef.current) return;

        if (
          window.scrollY < introRef.current?.offsetTop ||
          (window.scrollY >= introRef.current?.offsetTop && window.scrollY < curriculumRef.current?.offsetTop)
        ) {
          setCurrentTab(0);
        }

        if (window.scrollY >= curriculumRef.current?.offsetTop && window.scrollY < reviewRef.current?.offsetTop) {
          setCurrentTab(1);
        }

        if (window.scrollY >= reviewRef.current?.offsetTop) {
          setCurrentTab(2);
        }

        if (window.scrollY + document.documentElement.clientHeight > document.documentElement.scrollHeight - 300) {
          setIsNone(true);
        } else {
          setIsNone(false);
        }
      }, 300),
    [],
  );

  const onScroll = useCallback(() => {
    setIsFixed(introRef.current?.getBoundingClientRect().top < 56);
  }, [introRef]);

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', throttledScroll);
      window.removeEventListener('scroll', onScroll);
    };
  }, [isMobile, onScroll, throttledScroll]);

  const tabs = useMemo(
    () => [
      {
        value: 'intro',
        key: 'DETAIL_TABS_CLASS_INTRODUCTION',
      },
      {
        value: 'curriculum',
        key: 'DETAIL_TABS_CURRICULUM',
      },
      {
        value: 'review',
        key: 'DETAIL_TABS_REVIEW',
      },
    ],
    [],
  );

  const onClickCurrentTab = useCallback((index, value) => {
    setCurrentTab(index);
    let offset;

    if (value === 'intro') {
      offset = introRef.current.offsetTop;
    }

    if (value === 'curriculum') {
      offset = curriculumRef.current.offsetTop + 120;
    }

    if (value === 'review') {
      offset = reviewRef.current.offsetTop;
    }

    window.scrollTo(0, offset);
  }, []);

  const onClickToggleActive = useCallback(
    (index) => {
      if (reviewActiveIndex === index) {
        setReviewActiveIndex(-1);
      } else {
        setReviewActiveIndex(index);
      }
    },
    [reviewActiveIndex],
  );

  const onClickHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClickClosed = () => {
    swal({
      icon: 'warning',
      text: intl.formatMessage({
        id: '이미 마감된 강의입니다',
      }),
    });
  };
  const utm = useQueryString();
  const { search } = useLocation();
  useEffect(() => {
    if (search) {
      localStorage.setItem(
        'utm',
        JSON.stringify({
          ...utm,
          string: search,
        }),
      );
    }
  }, [search, utm]);

  const onClickRequestWaiting = useCallback(async () => {
    if (!isLoggedIn) {
      await swal({
        icon: 'warning',
        text: intl.formatMessage({
          id: 'GENERAL_NOT_LOGGED_IN_USER',
        }),
      });
      localStorage.setItem('prev_class_id', id);
      history.push(`/login${search}`);
    } else if (lecture.isPaidOrReadyUser) {
      swal({
        icon: 'warning',
        text: intl.formatMessage({
          id: 'WAITING_ALREADY_PURCHASED',
        }),
      });
    } else if (isCanWaiting && !lecture.isWaitingUser) {
      swal({
        title: intl.formatMessage({
          id: 'MAKE_A_WAITING',
        }),
        icon: 'info',
        text: intl.formatMessage({
          id: 'MAKE_A_WAITING_TEXT',
        }),
        buttons: {
          cancel: {
            text: '취소',
            value: null,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: '등록',
            value: true,
            visible: true,
            closeModal: true,
          },
        },
        onOpen: () => {
          const confirmButton = document.getElementsByClassName('swal-button--cancel');
          confirmButton.removeAttribute('autofocus');
        },
      }).then((value) => {
        if (!value) return;

        POST({
          url: `/classes/${lecture.id}/waiting`,
          header: {
            EJE_API_KEY: localStorage.getItem('eje_token'),
          },
        })
          .then((data) => {
            if (data.success) {
              swal({
                icon: 'success',
                text: intl.formatMessage({
                  id: 'WAITING_SUCCESS',
                }),
              }).then(() => {
                window.location.reload();
              });
            } else {
              swal({
                icon: 'error',
                text: intl.formatMessage({
                  id: 'ERROR_GENERAL',
                }),
              });
            }
          })
          .catch((err) => {
            if (err.status_code === 412) {
              swal({
                icon: 'warning',
                text: intl.formatMessage({
                  id: 'WAITING_FAIL_ALREADY',
                }),
              });
              return;
            }
            swal({
              icon: 'error',
              text: intl.formatMessage({
                id: 'ERROR_GENERAL',
              }),
            });
          });
      });
    } else {
      swal({
        icon: 'warning',
        text: intl.formatMessage({
          id: 'WAITING_FAIL_ALREADY',
        }),
      });
    }
  }, [isCanWaiting, isLoggedIn, intl, history, id, lecture]);

  const onClickLinkToPayment = useCallback(async () => {
    if (!isLoggedIn) {
      if (lecture.host !== 'gov') {
        await swal({
          icon: 'warning',
          text: intl.formatMessage({
            id: 'DETAIL_LECTURE_NOT_LOGGED_IN_USER',
          }),
        });
      } else {
        await swal({
          icon: 'warning',
          text: '신청하기 위해, 우선 로그인해주세요.',
        });
      }
      localStorage.setItem('prev_class_id', id);
      history.push(`/login${search}`);
    } else if (lecture.host === 'gov' && !isKorean) {
      await swal({
        icon: 'warning',
        text: 'Sorry! 🙇🙇\nThis is a course for Koreans only.',
      });
    } else if (lecture.host === 'gov') {
      if (LECTURE.ROUND_LISTEN_TYPES.includes(lecture.listen_type)) {
        const schedules = {
          offline: { list: lecture.ClassOfflineSchedules, index: selectedClassOfflineScheduleIndex },
          roundOnline: { list: lecture.ClassOnlineScheduleByRounds, index: selectedClassOnlineScheduleByRoundIndex },
          consulting: { list: lecture.ClassConsultingSchedules, index: selectedClassConsultingScheduleIndex },
        };

        const isValid = Object.values(schedules).some(({ list, index }) => {
          return list?.length > 0 && Number.isInteger(index);
        });

        if (!isValid) {
          swal({
            icon: 'warning',
            text: intl.formatMessage({
              id: 'DETAIL_LECTURE_NOT_SELECT_SCHEDULE',
            }),
          });
          return;
        }
      }

      const link = `/lecture/${id}/enrolment`;

      if (Number.isInteger(selectedClassOfflineScheduleIndex)) {
        history.push(
          `${link}?class_offline_schedule_id=${lecture.ClassOfflineSchedules[selectedClassOfflineScheduleIndex]?.id}${
            search ? '&' + search.replace('?', '&') : ''
          }`,
          {
            option_price: lecture.ClassOfflineSchedules[selectedClassOfflineScheduleIndex]?.option_price,
          },
        );
      } else {
        history.push(`${link}${search}`);
      }
    } else {
      if (LECTURE.ROUND_LISTEN_TYPES.includes(lecture.listen_type)) {
        const schedules = {
          offline: { list: lecture.ClassOfflineSchedules, index: selectedClassOfflineScheduleIndex },
          roundOnline: { list: lecture.ClassOnlineScheduleByRounds, index: selectedClassOnlineScheduleByRoundIndex },
          consulting: { list: lecture.ClassConsultingSchedules, index: selectedClassConsultingScheduleIndex },
        };

        const isValid = Object.values(schedules).some(({ list, index }) => {
          return list?.length > 0 && Number.isInteger(index);
        });

        if (!isValid) {
          swal({
            icon: 'warning',
            text: intl.formatMessage({
              id: 'DETAIL_LECTURE_NOT_SELECT_SCHEDULE',
            }),
          });
          return;
        }
      }

      const link = `/lecture/${id}/payment`;
      if (Number.isInteger(selectedClassOfflineScheduleIndex)) {
        history.push(
          `${link}?class_offline_schedule_id=${lecture.ClassOfflineSchedules[selectedClassOfflineScheduleIndex]?.id}${
            search ? '&' + search.replace('?', '&') : ''
          }`,
          {
            option_price: lecture.ClassOfflineSchedules[selectedClassOfflineScheduleIndex]?.option_price,
          },
        );
      } else if (Number.isInteger(selectedClassOnlineScheduleByRoundIndex)) {
        history.push(
          `${link}?class_online_schedule_id=${
            lecture.ClassOnlineScheduleByRounds[selectedClassOnlineScheduleByRoundIndex]?.id
          }${search ? '&' + search.replace('?', '&') : ''}`,
          {
            option_price: lecture.ClassOnlineScheduleByRounds[selectedClassOnlineScheduleByRoundIndex]?.option_price,
          },
        );
      } else if (Number.isInteger(selectedClassConsultingScheduleIndex)) {
        history.push(
          `${link}?class_consulting_schedule_id=${
            lecture.ClassConsultingSchedules[selectedClassConsultingScheduleIndex]?.id
          }${search ? '&' + search.replace('?', '&') : ''}`,
          {
            option_price: lecture.ClassConsultingSchedules[selectedClassConsultingScheduleIndex]?.option_price,
          },
        );
      } else {
        history.push(`${link}${search}`);
      }
    }
  }, [
    isLoggedIn,
    history,
    id,
    intl,
    lecture,
    selectedClassOfflineScheduleIndex,
    selectedClassOnlineScheduleByRoundIndex,
    selectedClassConsultingScheduleIndex,
  ]);

  const onClickLike = useCallback(async () => {
    if (!isLoggedIn) {
      Swal.fire({
        title: '로그인이 필요합니다.',
        text: '로그인 페이지로 이동하시겠습니까?',
        confirmButtonText: '확인',
        confirmButtonColor: '#F24462',
        showCancelButton: true,
        cancelButtonText: '취소',
        cancelButtonColor: '#A2A2A2',
        preConfirm: () => {
          sessionStorage.setItem('login_redirect', window.location.pathname);
          history.push('/login');
        },
      });
      return;
    }

    try {
      const { success } = await PUT({
        url: `/class/${lecture.id}/like`,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
        body: {
          is_like: !isLiked,
        },
      });

      if (success) {
        setIsLiked(!isLiked);
        dispatch(setLectureLikeList(isLiked ? likeList.filter((id) => id !== lecture.id) : [...likeList, lecture.id]));
        setIsHoveredLike(false);
      }
    } catch (error) {
      Swal.fire({
        title: '찜하기 실패',
        text: error.detail || '일시적인 오류로 인해 찜하기에 실패했습니다. 잠시 후 다시 시도해주세요.',
        confirmButtonText: '확인',
        confirmButtonColor: '#F24462',
      });
    }
  }, [history, isLoggedIn, isLiked, lecture]);

  if (isLoading) {
    return null;
  }

  const optionHandler = (schedule, index) => {
    const handlers = {
      offline: {
        indexState: selectedClassOfflineScheduleIndex,
        setIndex: setSelectedClassOfflineScheduleIndex,
      },
      roundOnline: {
        indexState: selectedClassOnlineScheduleByRoundIndex,
        setIndex: setSelectedClassOnlineScheduleByRoundIndex,
      },
      consulting: {
        indexState: selectedClassConsultingScheduleIndex,
        setIndex: setSelectedClassConsultingScheduleIndex,
      },
    };

    const { indexState, setIndex } = handlers[lecture.listen_type];

    if (indexState !== index) {
      setIndex(index);
      setDiscountingPrice(schedule.option_price);
    } else {
      setIndex(null);
      setDiscountingPrice(0);
    }
  };

  const metaInfo = lecture
    ? {
        title: `${lecture.title} | 엔터테인먼트 미디어 취업 엔터잡에듀`,
        description: lecture.description || lecture.class_introduction_for_search || DEFAULT_SEO_DESC,
        keywords:
          '엔터테인먼트, 미디어, 콘텐츠, 방송국, 영화, 공연, 음반유통사, 취업, kpop, SM, JYP, HYBE, 하이브, 빅히트, YG, 카카오, 카카오엔터테인먼트, CJENM, fnc, jtbc, 홍보, 마케팅, 팬마케팅, 콘텐츠마케팅, a&r, PD, 프로덕션, 매니지먼트, 매니저, 자기소개서, 이력서, 서류, 면접, 포트폴리오, 포트폴리오 제작, 대학생, 취업준비생, 취준생',
      }
    : null;

  const currency = lecture?.usesUSD ? '$' : <FormattedMessage id="CURRENCY_KOR" />;

  const selling_price = ({
    isDiscountAvailable = false,
    usesUSD = lecture?.usesUSD,
    price_original = 0,
    price_discounted = 0,
  }) => {
    return isDiscountAvailable
      ? (price_discounted + discountingPrice).toFormattedPrice()
      : usesUSD
      ? (Math.round(price_original) + discountingPrice).toFormattedPrice()
      : (Math.round(price_original / 100) * 100 + discountingPrice).toFormattedPrice();
  };

  const exposeCompanyTutors = lecture?.Class_Tutors.filter((tutor) => tutor.FK_tutor.is_company_expose);
  const tutorCompanyText =
    exposeCompanyTutors.length >= 2
      ? lecture?.tutor_company_text
      : exposeCompanyTutors[0]?.FK_tutor?.is_company_expose
      ? exposeCompanyTutors[0]?.FK_tutor?.company_name
      : null;

  const isOnlineScheduleAvailable =
    lecture.ClassOnlineSchedule &&
    ((lecture.ClassOnlineSchedule.student_limit !== 0 &&
      lecture.ClassOnlineSchedule.student_limit > lecture.ClassOnlineSchedule.student_count) ||
      lecture.ClassOnlineSchedule.student_limit === 0);

  const handleClickRequest = async (event) => {
    if (lecture.external_link) {
      window.open(lecture.external_link, '_blank');
    } else {
      event.preventDefault();
      const sellingPrice = selling_price({
        ...lecture,
        price_discounted: lecture.price_discounted,
      });

      if (permissionLevel === -2 || (permissionLevel === -1 && +sellingPrice === 0)) {
        swal({
          title: '수강 불가',
          text: '관리자에게 문의하세요.',
          icon: 'error',
        });
        return;
      }
      if (isCanWaiting) {
        await onClickRequestWaiting();
      } else {
        if (isOnlineScheduleAvailable || lecture.hasActiveSchedule) {
          await onClickLinkToPayment();
        } else {
          onClickClosed();
        }
      }
    }
  };

  return (
    <Main>
      {metaInfo && <Meta metaInfo={metaInfo} />}
      <div className="lecture_detail_wrap" ref={wrapRef}>
        <div className="inner_wrap">
          {lecture?.image_thumb && isMobile && (
            <div className="mobile_lecture_title_image_wrap">
              <img
                className="lecture_title_image"
                src={(lecture.image_thumb || ItemDefaultImage)?.toCloudFrontURL()}
                alt="main_image"
              />
              {lecture.leftDays >= 0 && (
                <span className="appointment">
                  {lecture.leftDays > 0 ? (
                    lecture.leftDays === 1 ? (
                      <FormattedMessage id="ADVANCE_RESERVATION_COUNTING_ONE" /> // 영어 단수형 번역 텍스트
                    ) : (
                      <FormattedMessage id="ADVANCE_RESERVATION_COUNTING" values={{ n: lecture.leftDays }} />
                    )
                  ) : lecture.leftDays === 0 ? (
                    <FormattedMessage id="ADVANCE_RESERVATION_TODAY" />
                  ) : null}
                </span>
              )}
            </div>
          )}

          {/* 모바일 헤더 */}
          {isMobile && isFixed && (
            <div className="mobile_header">
              <img src={DropdownLeftIcon} alt="go_back" onClick={onClickHistoryBack} />
              <p className="ellipsis">{lecture.title}</p>
            </div>
          )}

          <div className={'inner_content_wrap' + (isMobile ? ' isReverse' : '')}>
            <div className="lecture_detail_info info_wrap">
              {lecture?.image_thumb && (
                <div className="lecture_title_image_wrap">
                  <img
                    className="lecture_title_image"
                    src={(lecture.image_thumb || ItemDefaultImage)?.toCloudFrontURL()}
                    alt="main_image"
                  />

                  {lecture.leftDays >= 0 && (
                    <span className="appointment">
                      {lecture.leftDays > 0 ? (
                        lecture.leftDays === 1 ? (
                          <FormattedMessage id="ADVANCE_RESERVATION_COUNTING_ONE" /> // 영어 단수형 번역 텍스트
                        ) : (
                          <FormattedMessage id="ADVANCE_RESERVATION_COUNTING" values={{ n: lecture.leftDays }} />
                        )
                      ) : lecture.leftDays === 0 ? (
                        <FormattedMessage id="ADVANCE_RESERVATION_TODAY" />
                      ) : null}
                    </span>
                  )}
                </div>
              )}

              <ul className={`lecture_tabs ${isFixed ? 'isFixed' : ''} `}>
                {tabs.map((tab, index) => {
                  if (reviews.length < 1 && tab.value === 'review') return;

                  return (
                    <li
                      key={`${tab}_${index}`}
                      className={index === currentTab ? 'isActive' : ''}
                      onClick={() => onClickCurrentTab(index, tab.value)}
                    >
                      <FormattedMessage id={tab.key} />
                      {tab.key === 'DETAIL_TABS_REVIEW' && <span>{reviews.length}</span>}
                    </li>
                  );
                })}
              </ul>

              <div className="lecture_intro" ref={introRef}>
                <h3 className="intro_title">
                  <img src={ClassIntroIcon} alt="lecture_intro_detail_icon" />
                  <FormattedMessage id="DETAIL_TABS_CLASS_INTRODUCTION" />
                </h3>

                {/* Editor HTML Parsing */}
                <div
                  className="lecture_intro_description ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: lecture?.class_introduction?.toCloudFrontURL(),
                  }}
                ></div>
              </div>

              <div className="lecture_curriculum" ref={curriculumRef}>
                {/* Sample Video */}
                {lecture.ClassSampleVideos.length > 0 && (
                  <div className="lecture_sample_wrap">
                    <h3 className="sample_video_title video_title">
                      <img src={FreePickIcon} alt="sample_video_icon" />
                      <FormattedMessage id="DETAIL_SAMPLE_VIDEO_MESSAGE" />
                    </h3>
                    <div className="lecture_sample_list">
                      {lecture.ClassSampleVideos?.map((video, index) => (
                        <VideoItem key={`${video.title}_${index}`} video={video} />
                      ))}
                    </div>
                  </div>
                )}

                {/* Chapter/Package Video */}
                <div className="lecture_package_chapter_warp">
                  <h3 className="video_title">
                    <img src={VideoPickIcon} alt="chapter_or_package_icon" />
                    <FormattedMessage id="DETAIL_CHAPTER_MESSAGE" />
                  </h3>

                  {lecture.class_type === 'package' ? (
                    <div className="lecture_package_list">
                      {lecture?.ClassSingle_ClassPackages?.map((classItem, index) => (
                        <PackageItem
                          key={classItem.FK_class_single_id}
                          classItem={classItem.FK_class_single}
                          defaultIsActive={index === 0}
                          number={index + 1}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="lecture_chapter_list">
                      {lecture.ClassChapters.map((chapter, chapterIndex) => (
                        <div key={`${chapter.title}_${chapterIndex}`} className="lecture_chapter_item">
                          <h4 className="chapter_title">
                            <span className="order">
                              {chapterIndex + 1 < 10 ? '0' + (chapterIndex + 1) : chapterIndex + 1}
                            </span>
                            <span>{chapter.title}</span>
                          </h4>
                          <div className="chapter_section_list">
                            {chapter.ClassSections.map((section, sectionIndex) => (
                              <VideoItem
                                key={`${section.title}_${sectionIndex}`}
                                video={section}
                                number={chapterIndex + 1}
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {reviews.length > 0 && (
                <div className="lecture_review" ref={reviewRef}>
                  <h3 className="review_title">
                    <img src={ReviewIcon} alt="review_icon" />
                    <FormattedMessage id="DETAIL_REVIEW_MESSAGE" values={{ total: reviews.length || 0 }} />
                  </h3>

                  <div className="review_list">
                    {currentReviews.map((review, index) => (
                      <ReviewItem
                        key={`review_item-${index}`}
                        index={index}
                        review={review}
                        onClickToggleActive={onClickToggleActive}
                        activeIndex={reviewActiveIndex}
                      />
                    ))}
                  </div>

                  {isMobile ? (
                    reviews.length > reviewParams.page * reviewParams.limit && (
                      <span className="review_more_btn" onClick={() => onClickMoreReviews(reviewParams.page + 1)}>
                        <FormattedMessage id="MORE" />
                        <img src={DropdownDownIcon} alt="review_more_icon" />
                      </span>
                    )
                  ) : (
                    <Pagination
                      total={reviews.length}
                      limit={reviewParams.limit}
                      currentPage={reviewParams.page}
                      paginate={onClickMoreReviews}
                    />
                  )}
                </div>
              )}
            </div>

            <div className={`lecture_payment_info info_wrap ${!isMobile ? 'lecture_payment_info_pc' : ''}`}>
              <ul className="lecture_category">
                {lecture && (
                  <>
                    {lecture.Class_CategoryCompanies.map((category, index) => (
                      <li key={index}>
                        <FormattedMessage id={`CATEGORY_COMPANY_${category.FK_category.id}`} />
                      </li>
                    ))}
                    {lecture.Class_CategoryFields.map((category, index) => (
                      <li key={index}>
                        <FormattedMessage id={`CATEGORY_FIELD_${category.FK_category.id}`} />
                      </li>
                    ))}
                    {lecture.Class_CategoryProcesses.map((category, index) => (
                      <li key={index}>
                        <FormattedMessage id={`CATEGORY_PROCESS_${category.FK_category.id}`} />
                      </li>
                    ))}
                    {lecture.host === 'gov' &&
                      selling_price({
                        ...lecture,
                        price_discounted: lecture.price_discounted,
                      }) === '0' && <li>무료교육</li>}
                  </>
                )}
              </ul>

              <h3 className="lecture_title">{lecture?.title || ''}</h3>

              <div className="lecture_summary_info">
                {/** 강사 **/}
                <div>
                  <strong>
                    <FormattedMessage id="DETAIL_INSTRUCTOR" />
                  </strong>
                  <span>{lecture?.Class_Tutors?.map((tutor) => tutor.FK_tutor.name).join(', ')}</span>
                </div>

                {/** 강사 소속(회사, 경력) **/}
                {tutorCompanyText && (
                  <div>
                    <strong>
                      <FormattedMessage id="TUTOR_COMPANIES" />
                    </strong>
                    <span>
                      {tutorCompanyText}{' '}
                      {lecture?.Class_Tutors.length >= 2 && exposeCompanyTutors?.length === 1 ? '등' : ''}
                    </span>
                  </div>
                )}

                {/** 수강 일수 - Online / Foreigner */}
                {lecture?.listen_type !== 'offline' && (
                  <>
                    {lecture?.listen_type !== 'roundOnline' && (
                      <div>
                        <strong>
                          <FormattedMessage id="DETAIL_CLASS_TIME" />
                        </strong>
                        <span>
                          <FormattedMessage
                            id="DETAIL_CLASS_TIME_DAYS"
                            values={{
                              day:
                                lecture && lecture.ClassOnlineSchedule ? lecture.ClassOnlineSchedule.valid_days : '- ',
                            }}
                          />
                        </span>
                      </div>
                    )}
                    <div>
                      <strong>
                        <FormattedMessage id="DETAIL_NUMBER_OF_LECTURES" />
                      </strong>
                      <span>
                        <FormattedMessage
                          id="DETAIL_TOTAL_NUMBER_OF_LECTURES"
                          values={{
                            total: lecture ? lecture.countClassSection : '- ',
                          }}
                        />
                      </span>
                    </div>
                  </>
                )}

                {/** 강의 만족도 */}
                {lecture.host !== 'gov' && (
                  <div>
                    <strong>
                      <FormattedMessage id="DETAIL_LECTURE_SATISFACTION" />
                    </strong>
                    <span className="satisfaction">
                      {satisfaction.map((star, starIndex) => (
                        <img key={`review_start-${starIndex}`} src={star ? RatingStarOn : RatingStarOff} alt="rate" />
                      ))}
                      <span className="satisfaction_count" style={{ marginBottom: '-2px' }}>
                        ({satisfaction.filter((start) => start).length}/{satisfaction.length})
                      </span>
                    </span>
                  </div>
                )}

                {/** Offline || Consulting */}
                {(lecture?.listen_type === 'offline' || lecture?.listen_type === 'consulting') && (
                  <>
                    <div className="schedule">
                      <strong>
                        <FormattedMessage id="DETAIL_LECTURE_SCHEDULE" />
                        <img src={ClockIcon} alt="lecture_schedule" />
                      </strong>
                    </div>
                    <ul className="schedule_list">
                      {schedules.map((schedule, index) => {
                        const { start_date, class_start_time, student_limit, student_count } = schedule;
                        const isOverdue = moment().isAfter(`${start_date} ${class_start_time}`);
                        const statusStudents = // 'show' : 마감임박, false : 마감, true : 마감아님
                          student_limit === 0 || student_limit / student_count > 2.0
                            ? true
                            : student_limit <= student_count
                            ? false
                            : 'show';

                        if (!isOverdue)
                          return (
                            <li
                              key={schedule.id + schedule.start_date + schedule.end_date}
                              onClick={() => !isOverdue && statusStudents && optionHandler(schedule, index)}
                              className={!isOverdue && statusStudents ? '' : 'disAvailable'}
                            >
                              {(lecture?.listen_type === 'offline'
                                ? selectedClassOfflineScheduleIndex
                                : selectedClassConsultingScheduleIndex) === index ? (
                                <img src={CheckboxIcon} alt="uncheckbox" />
                              ) : (
                                <img src={UncheckboxIcon} alt="uncheckbox" />
                              )}
                              <div className={`timeline_wrap ${lang === 'en' || lang === 'vi' ? 'isColumn' : ''}`}>
                                <p className="timeline">
                                  <strong>
                                    [{schedule.class_round}기]&nbsp;
                                    {moment(schedule.start_date).format('YYYY.MM.DD')}~
                                    {moment(schedule.end_date).format('YYYY.MM.DD')}
                                  </strong>
                                  {!isOverdue && statusStudents === 'show' ? (
                                    <span className="deadline-wrap">
                                      <img src={FireIcon} alt="close to deadline" />
                                      <FormattedMessage id="마감임박" />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </p>
                                <div className="week_timeline">
                                  <p className="active_week">
                                    {schedule.class_dates.split('').map((date, index) => {
                                      return parseInt(date) ? (
                                        <span key={index} className="week">
                                          <FormattedMessage id={abbreviationWeek[index].key} />
                                        </span>
                                      ) : null;
                                    })}
                                  </p>
                                  <span>
                                    {schedule.class_start_time}~{schedule.class_end_time}
                                  </span>
                                  {schedule.title && <span>, {schedule.title}</span>}
                                  {!!schedule.option_price && (
                                    <span className="option_price-wrap">
                                      &nbsp;(
                                      {schedule.option_price > 0 && '+'}
                                      {schedule.option_price.toFormattedPrice()}
                                      {currency})
                                    </span>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                      })}
                    </ul>
                  </>
                )}

                {/** RoundOnline */}
                {lecture?.listen_type === 'roundOnline' && (
                  <>
                    <div className="schedule">
                      <strong>
                        <FormattedMessage id="DETAIL_LECTURE_SCHEDULE" />
                        <img src={ClockIcon} alt="lecture_schedule" />
                      </strong>
                    </div>
                    <ul className="schedule_list">
                      {schedules?.map((schedule, index) => {
                        const { start_date, class_start_time, student_limit, student_count } = schedule;
                        const isOverdue = class_start_time
                          ? moment().isAfter(`${start_date} ${class_start_time}`)
                          : moment().isAfter(`${start_date}`);

                        const statusStudents = // 'show' : 마감임박, false : 마감, true : 마감아님
                          student_limit === 0 || student_limit / student_count > 2.0
                            ? true
                            : student_limit <= student_count
                            ? false
                            : 'show';

                        if (!isOverdue)
                          return (
                            <li
                              key={schedule.id + schedule.start_date + schedule.end_date}
                              onClick={() => !isOverdue && statusStudents && optionHandler(schedule, index)}
                              className={!isOverdue && statusStudents ? '' : 'disAvailable'}
                            >
                              {selectedClassOnlineScheduleByRoundIndex === index ? (
                                <img src={CheckboxIcon} alt="uncheckbox" />
                              ) : (
                                <img src={UncheckboxIcon} alt="uncheckbox" />
                              )}
                              <div className={`timeline_wrap ${lang === 'en' || lang === 'vi' ? 'isColumn' : ''}`}>
                                <p className="timeline">
                                  <strong>
                                    {schedule.class_round ? `[${schedule.class_round}기] ` : ``}
                                    {moment(schedule.start_date).format('YYYY.MM.DD')}~
                                    {moment(schedule.end_date).format('YYYY.MM.DD')}
                                  </strong>
                                  {!isOverdue && statusStudents === 'show' ? (
                                    <span className="deadline-wrap">
                                      <img src={FireIcon} alt="close to deadline" />
                                      <FormattedMessage id="마감임박" />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </p>
                                <div className="week_timeline">
                                  {schedule.class_start_time && schedule.class_end_time && (
                                    <span>
                                      {schedule.class_start_time}~{schedule.class_end_time}
                                    </span>
                                  )}
                                  {schedule.class_start_time && schedule.class_end_time && schedule.title && (
                                    <span>,&nbsp;</span>
                                  )}
                                  {schedule.title && <span>{schedule.title}</span>}
                                  {!!schedule.option_price && (
                                    <span className="option_price-wrap">
                                      &nbsp;(
                                      {schedule.option_price > 0 && '+'}
                                      {schedule.option_price.toFormattedPrice()}
                                      {currency})
                                    </span>
                                  )}
                                </div>
                              </div>
                            </li>
                          );
                      })}
                    </ul>
                  </>
                )}

                {lecture.host !== 'gov' && (
                  <div className="lecture_payment_desc">
                    {lecture.isDiscountAvailable && (
                      <p>
                        <span className={`lecture_cost hasDiscount`}>
                          {lecture.price_original.toFormattedPrice()}
                          {currency}
                        </span>

                        <strong className="lecture_discount">
                          {Math.round(lecture.discount_rate)}%
                          <FormattedMessage id="DISCOUNT" />
                        </strong>
                        {lecture?.listen_type !== 'offline' &&
                          lecture.listen_type !== 'roundOnline' &&
                          lecture.ClassOnlineSchedule &&
                          lecture.ClassOnlineSchedule.student_limit !== 0 &&
                          lecture.ClassOnlineSchedule.student_limit > lecture.ClassOnlineSchedule.student_count &&
                          lecture.ClassOnlineSchedule.student_limit / lecture.ClassOnlineSchedule.student_count <=
                            2 && (
                            <span className="deadline-wrap">
                              <img src={FireIcon} alt="close to deadline" />
                              <FormattedMessage id="마감임박" />
                            </span>
                          )}
                      </p>
                    )}
                    <p>
                      <strong className="lecture_discounted_price">
                        {selling_price({
                          ...lecture,
                          price_discounted: lecture.price_discounted,
                        })}
                        {currency}
                        {!!discountingPrice && (
                          <span className="lecture_discount">
                            {discountingPrice > 0 && '+'}
                            {discountingPrice.toFormattedPrice()}
                            {currency} (할인가)
                          </span>
                        )}
                        {!lecture.isDiscountAvailable &&
                          lecture?.listen_type !== 'offline' &&
                          lecture.listen_type !== 'roundOnline' &&
                          lecture.ClassOnlineSchedule &&
                          lecture.ClassOnlineSchedule.student_limit !== 0 &&
                          lecture.ClassOnlineSchedule.student_limit > lecture.ClassOnlineSchedule.student_count &&
                          lecture.ClassOnlineSchedule.student_limit / lecture.ClassOnlineSchedule.student_count <=
                            2 && (
                            <span className="deadline-wrap">
                              <img src={FireIcon} alt="close to deadline" />
                              <FormattedMessage id="마감임박" />
                            </span>
                          )}
                      </strong>
                    </p>
                  </div>
                )}
              </div>

              <div className={`lecture-payment-wrap ${isMobile ? 'isFixed' : ''} ${isNone ? 'isNone' : ''}`}>
                {lecture.discount_start && lecture.discount_end && lecture.showTimer && (
                  <div
                    className="progress-bar"
                    style={{
                      height: '0.25rem',
                      backgroundColor: '#e9ecef',
                      borderRadius: '0.25rem',
                      width: '100%',
                      display: isMobile && !isNone ? 'block' : 'none',
                      position: 'fixed',
                      bottom: '3.75rem',
                      left: '0',
                      zIndex: '100',
                    }}
                  >
                    <div
                      className="progress-bar-inner"
                      style={{
                        width: `${progress}%`,
                        zIndex: '101',
                        height: '100%',
                        backgroundColor: '#f24462',
                        borderRadius: '0.25rem',
                      }}
                    />
                  </div>
                )}
                <div
                  className={`lecture_payment ${isMobile ? 'isFixed' : ''} ${isNone ? 'isNone' : ''}`}
                  style={{
                    paddingBottom: isMobile && !lecture.showTimer ? '0.75rem' : isMobile ? '0.125rem' : '0',
                  }}
                >
                  <div className="lecture_payment_btn_wrap">
                    <button
                      id="lecture_pay_click"
                      className={
                        isOnlineScheduleAvailable || lecture.hasActiveSchedule
                          ? 'lecture_payment_btn'
                          : isCanWaiting
                          ? 'lecture_payment_btn is-waiting'
                          : 'lecture_payment_btn is-closed'
                      }
                      onClick={
                        isOnlineScheduleAvailable || isCanWaiting || lecture.hasActiveSchedule
                          ? handleClickRequest
                          : undefined
                      }
                    >
                      {lecture.host === 'gov' ? (
                        <FormattedMessage id="REQUEST_FOR_CLASS" defaultMessage="신청하기 👆🏻" />
                      ) : isCanWaiting ? (
                        <>
                          <FormattedMessage id="MAKE_A_WAITING" defaultMessage="오픈 알림 신청 👆🏻" />
                        </>
                      ) : (
                        <>
                          <FormattedMessage id="MAKE_A_PAYMENT" />
                          <img src={CombinedIcon} alt="go_to_payment_icon" />
                        </>
                      )}
                    </button>
                    <div className="vertical-line"></div>
                    <div className="lecture_like_btn_wrap">
                      <div
                        className="lecture_like_btn"
                        onClick={onClickLike}
                        onMouseEnter={() => {
                          !isMobile && setIsHoveredLike(true);
                        }}
                        onMouseLeave={() => {
                          !isMobile && setIsHoveredLike(false);
                        }}
                      >
                        <HeartIcon
                          theme={isHoveredLike ? 'filled' : isLiked ? 'filled' : 'outlined'}
                          color={isHoveredLike ? '#EE7B8D' : isLiked ? '#F24462' : '#FFFFFF'}
                          width="37"
                          height="37"
                          shadowcolor="#00000080"
                        />
                      </div>
                    </div>
                  </div>
                  {showTimer && (
                    <Timer
                      isMobile={isMobile}
                      text={timerText}
                      days={days}
                      hours={hours}
                      minutes={minutes}
                      seconds={seconds}
                      startDate={lecture.discount_start}
                      endDate={lecture.discount_end}
                      progress={progress}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

const twoDigits = (n) => (n <= 9 ? `0${n}` : n);
const Timer = ({ text, days, hours, minutes, seconds, isMobile, startDate, endDate, progress }) => {
  return (
    <div className="timer-wrap">
      <div className="timer-data">
        <div className="timer-text">{text}</div>
        <div className="timer">
          <span className="days">D-{days}</span>
          <span className="d-day">
            {twoDigits(hours)}:{twoDigits(minutes)}:{twoDigits(seconds)}
          </span>
        </div>
      </div>
      <div className="progress-bar">
        <div
          className="progress-bar-inner"
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
      <style jsx="true">{`
        .timer-wrap .timer-data {
          display: flex;
          padding: 1rem 1.5rem 0.5rem;
          gap: 0.25rem;
          justify-content: space-between;
          width: 100%;
          font-size: 1rem;
          border-top: 1px solid #e5e5e5;
        }

        .timer-wrap .timer-data .timer-text {
          font-weight: 600;
          text-align: left;
          white-space: nowrap;
        }

        .timer-data .timer {
          font-weight: 900;
          display: flex;
          item-align: center;
          margin-top: -0.125rem;
          gap: 1rem;
          color: #d04a66;
          font-family: monospace;
        }

        .timer-data .timer .d-day {
          min-width: 5.5rem;
        }

        .timer-wrap .progress-bar {
          width: 100%;
          height: 0.25rem;
          background-color: #e5e5e5;
          border-radius: 0.25rem;
        }

        .timer-wrap .progress-bar-inner {
          height: 0.25rem;
          background-color: #f24462;
          border-radius: 0.25rem;
        }
      `}</style>
    </div>
  );
};

export default View;
