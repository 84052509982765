export const PAYMENT_IMPLEMENTS = [
  {
    key: 'PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD',
    pg: 'html5_inicis',
    pay_method: 'card',
  },
  {
    key: 'PAYMENT_IMPLEMENTS_V_BANK',
    pg: 'html5_inicis',
    pay_method: 'vbank',
  },
  {
    key: 'PAYMENT_IMPLEMENTS_KAKAOPAY',
    pg: 'html5_inicis',
    pay_method: 'kakaopay',
  },
  {
    key: 'PAYMENT_IMPLEMENTS_TOSS',
    pg: 'html5_inicis',
    pay_method: 'tosspay',
  },
  {
    key: 'PAYMENT_IMPLEMENTS_PAYPAL',
    pg: 'paypal',
    pay_method: 'card',
  },
];
