import React from 'react';
import { FormattedMessage } from 'react-intl';

const changeFormat = (date) => date?.replace(/-/g, '.');

const AlbumInfo = ({
  data: { thumbnail, title, Record_Artists, Record_Agencies, publishedDate },
  agencies,
  artists,
}) => {
  const pubDate = changeFormat(publishedDate);
  return (
    <div id="album-info-wrap" className="md:flex md:mb-16">
      <img
        src={thumbnail?.toCloudFrontURL() || 'https://picsum.photos/300'}
        alt="album-cover"
        className="w-screen md:w-[calc(25%-8px)] aspect-square md:mr-6 md:justify-self-start md:shadow-record-thumbnail"
      />
      <div
        id="album-info"
        className="pb-12 mb-8 md:w-full md:h-[100vw * 0.25 -8px] md:mb-0 md:pb-0 md:flex md:items-center border-b-black border-b-[3px]"
      >
        <div>
          <h2 className="font-bold mt-6 md:m-0 md:mb-2 text-base md:text-xl">{title}</h2>

          <a
            className="text-eje-pink text-sm md:text-base md:mb-4 w-fit"
            href={`/albumcredit-db?artists=${Record_Artists.map(({ FK_Artist }) => FK_Artist.id).join(',')}`}
          >
            {artists}
          </a>

          <div className="mt-4 text-sm">
            <p className="md:mb-2">
              <span className="w-[60px] mr-4">
                <FormattedMessage id="발매일" />
              </span>
              <span>{pubDate}</span>
            </p>
            <p>
              <span className="w-[60px] mr-4">
                <FormattedMessage id="기획사" />
              </span>
              <span>{agencies}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlbumInfo;
