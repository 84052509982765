import React from 'react';
import './index.scss';

export default function Loading() {
  return (
    <div className="Loading">
      <div className="loader">
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        {/* <div className="loader_text">
                    <div className="loader_t-text"></div>
                </div> */}
      </div>
    </div>
  );
}
