import '../index.scss';

function Buying() {
  return (
    <div className="payment_term">
      <h1 id="0f985c99-d9dc-4d35-945e-594c1125bb26" className="page-title">
        구매확인 동의
      </h1>
      <div id="ba29df36-52f2-43c2-bbf9-242c7faa9895" className="page-body">
        <p id="e57d81a7-c49d-4d4c-99a5-bd693fe8eab4" className="">
          주문상품의 강의명, 가격, 배송정보 등을 확인하였으며 이에 동의합니다.
        </p>
        <p id="8ba84ecb-0bdd-4019-9128-3a43b762c7c7" className=""></p>
      </div>
    </div>
  );
}

export default Buying;
