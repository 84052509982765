import onClickOutside from 'react-onclickoutside';
import { FormattedMessage } from 'react-intl';

function SortOptionList({ sortOptionConstant, onClickSortOptionOutside, onClickSetSortOption }) {
  SortOptionList.handleClickOutside = (e) => {
    if (!onClickSortOptionOutside) return;
    onClickSortOptionOutside(e);
  };

  return (
    <ul className="sort_select_box_options">
      {sortOptionConstant.map((option) => (
        <li key={option.key} onClick={(e) => onClickSetSortOption(e, option)}>
          <FormattedMessage id={option.key} />
        </li>
      ))}
    </ul>
  );
}

export default onClickOutside(SortOptionList, {
  handleClickOutside: () => SortOptionList.handleClickOutside,
});
