import { memo, useCallback } from 'react';
import moment from 'moment';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import RatingStarOn from '../../../Assets/Images/rating-star.png';
import RatingStarOff from '../../../Assets/Images/rating-no-star.png';
import DropdownDownIcon from '../../../Assets/Images/icon-dropdown-down.png';
import DropdownUpIcon from '../../../Assets/Images/icon-dropdown-up.png';
import ProfileDefaultImage from '../../../Assets/Images/profile-custom.png';

const ReviewItem = ({ review, activeIndex, index, onClickToggleActive }) => {
  const stars = new Array(5).fill(false).map((arr, index) => (index + 1 <= review.rate ? true : false));

  const isOldUser = !!review.old_nickname;

  const handleImageError = useCallback((e) => {
    e.target.src = ProfileDefaultImage;
  }, []);

  return (
    <div className="review_item">
      <div className="review_info">
        <img
          className="user_profile_image"
          src={isOldUser ? ProfileDefaultImage : review.FK_user?.image_profile || ProfileDefaultImage}
          onError={handleImageError}
          alt="profile_img"
        />
        <div className="review_user_info">
          {review.isBest ? <span className="best_badge">BEST </span> : ''}
          <p className="user_name">{isOldUser ? review.old_nickname : review.FK_user?.nickname}</p>
          <p className="lecture_info">{review.classTitle}</p>
          <p className="review_start">
            {stars.map((star, starIndex) => (
              <img key={`review_start-${starIndex}`} src={star ? RatingStarOn : RatingStarOff} alt="rate" />
            ))}
            <span className="review_date">{moment(review.createdAt).format('YYYY.MM.DD')}</span>
          </p>
          <span className="more_btn" onClick={() => onClickToggleActive(index)}>
            {activeIndex === index ? <FormattedMessage id="FOLD" /> : <FormattedMessage id="MORE" />}

            <img src={activeIndex === index ? DropdownUpIcon : DropdownDownIcon} alt="review_more" />
          </span>
        </div>
      </div>
      <div className="review_description">
        <div className={` ${activeIndex !== index ? 'ellipsis' : 'origin_text'}`}>{review.content}</div>
      </div>
    </div>
  );
};

export default memo(ReviewItem);
