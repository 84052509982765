// components/Meta
/* eslint-disable no-undefined */
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// const locales = {
//   en: 'en_US',
//   ko: 'ko_KR'
// };

const Meta = ({ metaInfo }) => {
  // const lang = locales[metaInfo.locale] || locales['en'];
  let { title, description, image, canonical, type, width, height, keywords } = metaInfo || {};
  title = title || '엔터테인먼트 미디어 콘텐츠 취업 | 대표 온라인 강의사이트 엔터잡에듀';
  description =
    description ||
    '국내 정상급 아티스트랑 일하고 싶다면? 엔터잡에듀 온라인 강의와 함께하세요. SM 하이브 YG CJENM 등 국내 엔터테인먼트 미디어 콘텐츠 취업 정보는 엔터잡에듀에서!';
  image = image === undefined ? 'https://assets.enterjobedu.co.kr/class/thumb/thumbnail-20220310.png' : `${image}`;
  canonical = `${process.env.REACT_APP_HOST}/${canonical || ''}`;
  type = type === undefined ? 'website' : type;
  width = image && (width || 1200);
  height = image && (height || 630);
  const { pathname } = useLocation();
  return (
    <Helmet titleTemplate="%s">
      {/* <html lang={lang} /> */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {image ? <link rel="image_src" href={image} /> : null}
      {image ? <meta itemprop="image" content={image} /> : null}

      <meta property="og:site_name" content="엔터잡에듀" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {canonical ? <meta property="og:url" content={canonical} /> : null}
      {/* <meta property="og:locale" content={locales[lang]} /> */}
      <meta property="og:type" content={type} />
      {image ? <meta property="og:image" content={image} /> : null}
      {width ? <meta property="og:image:width" content={width} /> : null}
      {height ? <meta property="og:image:height" content={height} /> : null}
      <meta property="fb:pages" content="엔터잡에듀" />

      {/* change type of twitter if there is no image? */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description ? <meta name="twitter:description" content={description} /> : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
      <meta name="twitter:site" content="@엔터잡에듀" />
      {/* {canonical ? (
        <link rel="alternate" href={canonical} hreflang={lang} />
      ) : null} */}
      {pathname.includes('/certificate') ? (
        <meta
          name="robots"
          content="noindex, nofollow, noarchive, nosnippet, noimageindex, noopensearch, noodp, notranslate, noyield, noerror, nohreflang"
        />
      ) : (
        ''
      )}
    </Helmet>
  );
};

export default Meta;
