import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as XLSX from 'sheetjs-style';
import './index.scss';

const View = (properties) => {
  const { members, router, history, orgId, isLoading } = properties;

  const { count, supervisor, manager, member } = members || {};
  const { total, warn, fail } = count || {};

  const ExcelHeader = useMemo(
    () => [
      {
        label: '권한',
        key: 'role,',
      },
      {
        label: '이름',
        key: 'name',
      },
      {
        label: '전화번호',
        key: 'phone',
      },
      {
        label: '이메일',
        key: 'email',
      },
      {
        label: '전체',
        key: 'total',
      },
      {
        label: '수강 중',
        key: 'ongoing',
      },
      {
        label: '미이수',
        key: 'fail',
      },
      {
        label: '이수',
        key: 'done',
      },
    ],
    [],
  );

  const allMembers = useMemo(() => {
    if (!members || !supervisor || !manager || !member) {
      return [];
    }
    console.log('supervisor', supervisor);
    console.log('manager', manager);
    console.log('member', member);
    return [
      { ...supervisor, role: 'Supervisor' },
      ...manager?.list.map((member) => ({
        ...member,
        role: 'Manager',
      })),
      ...member?.list.map((member) => ({
        ...member,
        role: 'Manager',
      })),
    ];
  }, [members, supervisor, manager, member]);

  const ExcelData = useMemo(() => {
    return allMembers.map((member) => {
      const { role, name, phone, email, lecture } = member;
      const { total, ready, ongoing, warn, fail, done = 0 } = lecture?.count || {};
      return {
        권한: role,
        이름: name,
        전화번호: String(phone).formatPhoneNumber(),
        이메일: email,
        '전체 강좌': total,
        '수강 중': ongoing,
        미이수: fail,
        이수: done,
      };
    });
  }, [allMembers]);

  const columnConfigs = useMemo(
    () => [{ wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }],
    [],
  );

  const rowConfigs = useMemo(() => [...allMembers, {}].map((_) => ({ hpx: 30 })), [allMembers]);

  const cellConfigs = useMemo(
    () => ({
      font: { sz: 12 },
      alignment: { vertical: 'center', horizontal: 'center' },
      border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      },
    }),
    [],
  );

  const downloadXLSX = useCallback(() => {
    const fields = Object.keys(ExcelData[0]);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(ExcelData, { header: fields });
    ws['!cols'] = columnConfigs;
    ws['!rows'] = rowConfigs;
    const allCells = Object.keys(ws).filter((key) => !key.includes('!'));
    allCells.forEach((cell) => {
      if (cell.includes('1') && cell.length === 2) {
        ws[cell].s = {
          ...cellConfigs,
          font: { ...cellConfigs.font, bold: true },
          fill: { fgColor: { rgb: 'FFD9D9D9' } },
        };
      } else {
        ws[cell].s = cellConfigs;
      }
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${members?.title || orgId}-${dayjs().format('YYYY-MM-DD_HH-mm')}.xlsx`);
  }, [ExcelData, cellConfigs, columnConfigs, members?.title, orgId, rowConfigs]);

  return isLoading ? (
    'Loading...'
  ) : (
    <div className="org-member-wrap">
      <div className="org-member-wrap-header">
        <h3 className="title">
          <FormattedMessage id="MY_PAGE_ORG_MGMT" /> - {members?.title || orgId} - 구성원 관리
        </h3>
        <Button variant="contained" color="primary" size="small" onClick={downloadXLSX}>
          XLSX 다운로드
        </Button>
      </div>
      <MemberListSection title="Supervisor" list={[supervisor]} showInfoGuide={true} />
      <MemberListSection title="Managers" list={manager.list} />
      <MemberListSection title="Members" list={member.list} />
    </div>
  );
};

export default View;

const MemberListSection = ({ title, list, showInfoGuide }) => {
  return (
    <section className="member-list-section">
      <div className="member-list-section-header">
        <h4>{title}</h4>
        {showInfoGuide && (
          <div className="member-lecture-info-wrap-guide">
            {/* <div className="member-lecture-info-total">
             <span className="color-block" />
             <span>전체</span>
             </div> */}
            <div className="member-lecture-info-ongoing">
              <span className="color-block" />
              <span>수강 중</span>
            </div>
            <div className="member-lecture-info-fail">
              <span className="color-block" />
              <span>미이수</span>
            </div>
            <div className="member-lecture-info-done">
              <span className="color-block" />
              <span>이수</span>
            </div>
          </div>
        )}
      </div>
      <MemberList list={list} />
    </section>
  );
};
const MemberList = ({ list }) => {
  return (
    <ul className="member-list-container">
      <li className="member-list-header">
        <div className="member-check-wrap">
          <input type="checkbox" />
        </div>
        <div className="member-name-wrap">
          <span>이름</span>
        </div>
        <div className="member-phone-wrap">
          <span>전화번호</span>
        </div>
        <div className="member-email-wrap">
          <span>이메일</span>
        </div>
        <div className="member-lecture-info-wrap">
          <span>수강 현황</span>
          <span style={{ fontWeight: 'normal', marginLeft: '4px', color: 'gray' }}>(갯수)</span>
        </div>
      </li>
      <li className="member-list-wrap">
        <ul className="member-list">
          {list.map((member) => (
            <li key={member.id}>
              <div className="member-info">
                <div className="member-check-wrap">
                  <input type="checkbox" />
                </div>
                <div className="member-name-wrap">
                  <span className="member-name">{member.name}</span>
                </div>
                <div className="member-phone-wrap">
                  <span className="member-phone">{String(member.phone).formatPhoneNumber()}</span>
                </div>
                <div className="member-email-wrap">
                  <span className="member-email">{member.email}</span>
                </div>
                <div className="member-lecture-info-wrap">
                  {/* <div className="member-lecture-info-total">
                   <span className="color-block">
                   {member.lecture?.count?.total}
                   </span>
                   </div> */}
                  <div className="member-lecture-info-ongoing">
                    <span className="color-block">{Number(member.lecture?.count?.ongoing)}</span>
                  </div>
                  <div className="member-lecture-info-fail">
                    <span className="color-block">{member.lecture?.count?.fail}</span>
                  </div>
                  <div className="member-lecture-info-done">
                    <span className="color-block">{member.lecture?.count?.done}</span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
};
