import Main from '../../Commons/Layouts/Main';
import TermsOfUse from '../../Commons/Components/Terms/TermsOfUse';

const TermsOfUsePage = (props) => {
  return (
    <Main>
      <TermsOfUse />
    </Main>
  );
};

export default TermsOfUsePage;
