import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Locales from '../../Locales';
import Certificate from '../../Pages/Certificate';
import Classroom from '../../Pages/Classroom';
import Complete from '../../Pages/Complete';
import Enrolment from '../../Pages/Enrolment';
import Home from '../../Pages/Home';
import KpopMakerDB from '../../Pages/KpopDB';
import KpopMakerDBDetail from '../../Pages/KpopDBDetail';
import LectureDetail from '../../Pages/LectureDetail';
import Lectures from '../../Pages/Lectures';

import Login from '../../Pages/LoginSystem/Login';
// MyPage
import MyCouponHistory from '../../Pages/MyPage/CouponHistory';
import MyLectures from '../../Pages/MyPage/Lectures';
import MyNavPage from '../../Pages/MyPage/MyNavPage';
import MyOrg from '../../Pages/MyPage/Organization';
import MyOrgAll from '../../Pages/MyPage/Organization/All';
import MyOrgLectures from '../../Pages/MyPage/Organization/Lectures';
import MyOrgMembers from '../../Pages/MyPage/Organization/Members';
import MyPaymentHistory from '../../Pages/MyPage/PaymentHistory';
import MyProfile from '../../Pages/MyPage/Profile';

import Payment from '../../Pages/Payment';
import PrevUserSignupForm from '../../Pages/PrevUserSignupForm/';
import Privacy from '../../Pages/Privacy';
import SearchResultPage from '../../Pages/SearchResult';
import SignupForm from '../../Pages/SignupForm';

// Footer
import TermsOfUse from '../../Pages/TermsOfUse';
import { login, setLectureLikeList } from '../Store/Actions/user';
import { GET } from '../Utils/fetch';

// Boards
// Notice
import NoticeDetail from '../../Pages/Boards/Notice/Detail';
import NoticeList from '../../Pages/Boards/Notice/List';
import EventDetail from '../../Pages/Boards/Event/Detail';
import EventList from '../../Pages/Boards/Event/List';
import BlogDetail from '../../Pages/Boards/Blog/Detail';
import BlogList from '../../Pages/Boards/Blog/List';
import Survey from '../../Pages/Survey';
import LikeLectures from '../../Pages/MyPage/LikeLectures';

import WriteReview from '../../Pages/Review/Write';

// Instructor
import TutorSettlements from '../../Pages/Tutor/Settlements/page';
import PaymentCurrentUser from '../../Pages/PaymentCurrentUser';
import Event from '../../Pages/Boards/Event/List';
import Meta from '../Components/Meta';
import InterestedCompanyPopup from '../Components/InterestedCompanyPopup';

function App() {
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [didTryLogIn, setDidTryLogIn] = useState(false);
  const showInterestedCompanyPopup = useSelector((state) => isLoggedIn && !state.user?.userInfo?.hasInterestedCompany);
  const getUser = useCallback(
    async (token) => {
      try {
        const { user } = await GET({
          url: `/user/login`,
          header: { EJE_API_KEY: token },
        });
        dispatch(login(user));

        const { lectureIds } = await GET({
          url: '/classes/me/like-list',
          header: { EJE_API_KEY: token },
        });
        dispatch(setLectureLikeList(lectureIds));
      } catch (error) {
        console.error(error);
      } finally {
        setDidTryLogIn(true);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    // path가 /tutor로 시작할 경우에는 실행하지 않는다.
    const token = localStorage.getItem('eje_token');
    if (token && window.location.pathname.split('/')[1] !== 'tutor') {
      getUser(token);
    } else {
      setDidTryLogIn(true);
    }
  }, [getUser]);

  useEffect(() => {
    if (document.documentElement.lang !== localStorage.getItem('eje_lang')) {
      document.documentElement.lang = localStorage.getItem('eje_lang');
    }
  }, [localStorage.getItem('eje_lang')]);

  return didTryLogIn ? (
    <IntlProvider locale={locale} messages={Locales[locale]}>
      <BrowserRouter>
        <Helmet>
          <meta charSet="utf-8" />
          <title>엔터잡에듀</title>
        </Helmet>

        <Switch>
          {/* <Route exact path="/" component={Develop} /> */}
          <Route exact path="/" component={Home} />
          <Route path="/online" component={Lectures} />
          <Route path="/offline" component={Lectures} />
          <Route path="/foreigner" component={Lectures} />
          <Route path="/gov-class" component={Lectures} />
          <Route exact path="/albumcredit-db/:id" component={KpopMakerDBDetail} />
          <Route path="/albumcredit-db" component={KpopMakerDB} />
          <Route path="/search" component={SearchResultPage} />

          <Route path="/notices" component={NoticeList} exact />
          <Route path="/notice/:id" component={NoticeDetail} exact />
          <Route path="/events" component={EventList} exact />
          <Route path="/event/:id" component={EventDetail} exact />
          <Route path="/blogs" component={BlogList} exact />
          <Route path="/blog/:id" component={BlogDetail} exact />

          <Route path="/certificate/:code" component={Certificate} />

          <Route exact path="/lecture/:id" component={LectureDetail} />
          <Route path="/lecture/:id/payment" component={Payment} />
          <Route path="/lecture/:id/enrolment" component={Enrolment} />
          <Route path="/payment/complete" component={Complete} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/login/:platform" component={Login} />
          <Route exact path="/signup" component={Login} />
          <Route exact path="/signup/:platform" component={Login} />
          <Route exact path="/signupForm" component={SignupForm} />
          <Route exact path="/prev/signupForm" component={PrevUserSignupForm} />

          <Route exact path="/survey/:surveyId" component={Survey} />

          {/* 모바일, 마이페이지 네비 페이지화 */}
          <Route exact path="/my" component={MyNavPage} />
          <Route exact path="/my/lectures" component={MyLectures} />
          <Route exact path="/my/payment" component={MyPaymentHistory} />
          <Route exact path="/my/coupon" component={MyCouponHistory} />
          <Route exact path="/my/like-lectures" component={LikeLectures} />
          <Route exact path="/my/profile" component={MyProfile} />
          <Route exact path="/organization/:orgId/members" component={MyOrgMembers} />
          <Route exact path="/organization/:orgId/lectures" component={MyOrgLectures} />
          <Route exact path="/organization/:orgId" component={MyOrg} />
          <Route exact path="/organization" component={MyOrgAll} />

          <Route exact path="/classroom/:id/:targetClassId" component={Classroom} />
          <Route path="/terms" component={TermsOfUse} />
          <Route path="/privacy" component={Privacy} />

          <Route exact path="/wr" component={WriteReview} />

          <Route exact path="/tutor/settlements" component={TutorSettlements} />

          {/* 개별 결제 페이지 */}
          <Route path="/payment/:code" component={PaymentCurrentUser} />

          {/* Redirect Not Found Page */}
          <Redirect from="/notice" to="/notices" exact />
          <Redirect from="/event" to="/events" exact />
          <Redirect from="/blog" to="/blogs" exact />

          <Redirect from="*" to={`${isLoggedIn ? '/' : '/login'}`} />
        </Switch>

        {showInterestedCompanyPopup && <InterestedCompanyPopup open={showInterestedCompanyPopup} />}
      </BrowserRouter>
    </IntlProvider>
  ) : null;
}

export default App;
