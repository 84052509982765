import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { GET, POST } from '../../Commons/Utils/fetch';
import Meta from '../../Commons/Components/Meta';
import Loading from '../../Commons/Components/Loading';
import { Star } from '@mui/icons-material';
import {
  ButtonContainer,
  Description,
  DraftButton,
  FormContainer,
  Layout,
  Logo,
  NextButton,
  PrevButton,
  QuestionBody,
  QuestionContainer,
  QuestionDescription,
  QuestionHeader,
  QuestionNumber,
  QuestionTitle,
  QuestionTitleContainer,
  Rubbish,
  SectionBody,
  SectionContainer,
  SectionHeader,
  SectionItem,
  SectionNumber,
  Sections,
  SectionTitle,
  SubmitButton,
  Title,
} from './styles';
import { useForm } from 'react-hook-form';
import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';
import { LongText, ShortText } from './TextGroup';
import swal from 'sweetalert2';
import queryString from 'query-string';
import { URL } from '../../Commons/Utils/constant';

const Survey = ({
  surveyId: passedSurveyId,
  classId: passedClassId,
  userClassId: passedUserClassId,
  setIsCompleted,
}) => {
  const location = useLocation();
  const history = useHistory();
  const queryStrings = queryString.parse(location.search);
  const classId = passedClassId || queryStrings.classId || location.state?.classId;
  const userClassId = passedUserClassId || queryStrings.userClassId || location.state?.userClassId;

  const [apiKey, _] = useState(localStorage.getItem('eje_token') || null);

  const [survey, setSurvey] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [endSectionIndex, setEndSectionIndex] = useState(1);
  const [lastDraftedAt, setLastDraftedAt] = useState(null);
  const carouselRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const pathname = window.location.pathname;
  const isClassroom = useMemo(() => pathname.startsWith('/classroom'), [pathname]);
  const surveyId = isClassroom ? passedSurveyId : useParams().surveyId.split('?')[0];
  const bySurveyGuide = location.state?.by_survey_guide;

  useEffect(() => {
    const fetchSurvey = async (id) => {
      try {
        return await GET({
          url: classId ? `/surveys/${id}?classId=${classId}` : `/surveys/${id}`,
          header: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            EJE_API_KEY: localStorage.getItem('eje_token') || 'auth',
          }, //
        });
      } catch (error) {
        setError('설문 데이터를 불러오는 중 오류가 발생했습니다.');
        return { success: false };
      }
    };

    const handleFetchSurvey = async () => {
      const apiKey = localStorage.getItem('eje_token');
      if (!apiKey) {
        // TODO: 로그인이 필요한 설문과 로그인이 필요하지 않은 설문을 구분할 필요 있음.
        swal
          .fire({
            title: '로그인이 필요합니다.',
            text: '로그인 페이지로 이동합니다.',
            icon: 'warning',
            showConfirmButton: false,
            timer: 2000,
          })
          .then(() => {
            sessionStorage.setItem('login_redirect', `${location.pathname}${location.search}`);
            window.location.href = `/login`;
          });
      }

      // id, surveyId 둘 중 하나는 반드시 있어야 한다.
      // 둘 다 이을 경우 id를 사용한다.
      // pathname이 /classroom으로 시작될 경우 surveyId를 사용한다.
      // pathname이 /survey로 시작될 경우 id를 사용한다.
      const checkId = isClassroom ? passedSurveyId : surveyId;

      if (checkId) {
        const { success, survey, draft, message, action } = await fetchSurvey(checkId);
        if (success) {
          const logo =
            survey.logo || 'https://assets.enterjobedu.co.kr/survey/logo/2439f289b53635510aa2404093e33f11.png';
          const start = dayjs(survey.startDatetime).format('YYYY-MM-DD HH:mm:ss');
          const end = dayjs(survey.endDatetime).format('YYYY-MM-DD HH:mm:ss');
          const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss');

          const isSurveyPeriod =
            classId || ((!survey.startDatetime || currentTime >= start) && (!survey.endDatetime || currentTime <= end));

          if (isSurveyPeriod) {
            const surveyData = {
              ...survey,
              logo,
              metaInfo: {
                title: `${survey.title} | 설문 | 엔터잡에듀`,
                description: survey.description,
                thumbnail: logo,
              },
            };
            setSurvey(surveyData);
            setEndSectionIndex(surveyData.sections.length - 1);
            setLoaded(true);
            const timer = setTimeout(() => {
              calculateHeight();
            }, 300);
          } else {
            setError('설문 기간이 아닙니다.');
          }

          if (draft) {
            setLastDraftedAt(draft.createdAt);

            swal
              .fire({
                title: '임시저장된 설문 내용이 있습니다.',
                text: '이어서 작성하시겠습니까?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: '네',
                cancelButtonText: '아니요',
                confirmButtonColor: '#F24462',
                iconColor: '#F24462',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  const draftValueObj = JSON.parse(draft.value);
                  const keys = Object.keys(draftValueObj);
                  keys.forEach((key) => {
                    setValue(key, draftValueObj[key]);
                  });
                }
              });
          }
        } else {
          setError('설문 데이터를 불러오는 중 오류가 발생했습니다.');
        }
      } else {
        alert('잘못된 접근입니다.');
        window.location.href = '/';
      }
    };

    handleFetchSurvey().then((r) => r);
  }, [surveyId]);

  const scrollToDiv = () => {
    const targetDiv = carouselRef.current;
    if (targetDiv) {
      targetDiv.scrollIntoView();
    }
  };

  const handleSectionChange = (index) => {
    setCurrentSectionIndex(index);
    !window.location.pathname.startsWith('/classroom') && scrollToDiv();
  };

  const handleDraft = async () => {
    await POST({
      url: `/surveys/${surveyId}/draft`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
      body: { classId, value: getValues() },
    })
      .then((r) => {
        swal.fire({
          title: '설문 내용이 임시저장되었습니다.',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
        });

        setLastDraftedAt(dayjs().format('YYYY-MM-DD HH:mm:ss'));
      })
      .catch((e) => e);
  };

  const QuestionNumberElement = ({ number, isRequired }) => {
    return (
      <QuestionNumber>
        <Star sx={{ color: '#F24462', fontSize: '0.625rem', opacity: isRequired ? '1' : '0' }} />
        <span>{number}</span>
      </QuestionNumber>
    );
  };

  const checkAccessibility = async () => {
    const result = await GET({
      url: classId ? `/surveys/${surveyId}/accessibility?classId=${classId}` : `/surveys/${surveyId}/accessibility`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });

    if (result.canParticipate) {
      return true;
    } else {
      swal
        .fire({
          title: '설문에 참여할 수 없습니다.',
          text: result.message,
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000,
        })
        .then(() => {
          window.location.replace('/');
        });

      return false;
    }
  };
  useEffect(async () => {
    await checkAccessibility();
  }, [apiKey]);

  const getSurveyResultObj = useCallback(async (data) => {
    const keys = Object.keys(data);
    const questionKeys = keys.filter((key) => !key.includes('Text') && !key.includes('SelectedList'));
    const selectedTextKeys = keys.filter((key) => key.includes('SelectedText'));
    const result = {};
    questionKeys.forEach((key) => {
      // result[key]가 없으면 생성
      if (!result[key]) {
        result[key] = data[key] === false || Array.isArray(data[key]) ? [] : {};
      }
      if (Array.isArray(data[key])) {
        const selectedList = data[`${key}SelectedList`] || [];
        result[key] = selectedList.map((item) => {
          if (item.text === '기타') {
            item.text = data[`${key}Text`];
          }
          return item;
        });
      } else {
        result[key].value = data[key];
      }
    });
    selectedTextKeys.forEach((key) => {
      const questionKey = key.replace('SelectedText', '');
      if (!result[questionKey]) {
        result[questionKey] = {};
      }
      if (Array.isArray(result[questionKey])) {
        result[questionKey].forEach((item) => {
          item.text = data[key];
        });
      } else {
        result[questionKey].text = data[`${questionKey}Text`] || data[`${questionKey}SelectedText`] || data[key];
      }
    });

    return result;
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = async (data) => {
    // 최종 confirm 창
    const confirmResult = await swal.fire({
      title: '제출하시겠습니까?',
      text: '제출 후 수정이 불가능합니다.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: '제출',
      cancelButtonText: '취소',
    });
    if (!confirmResult.isConfirmed) {
      return;
    }

    if (isSubmitting) return;
    setIsSubmitting(true);
    const keys = Object.keys(data);
    const questionKeys = keys.filter((key) => !key.includes('Text') && !key.includes('SelectedList'));
    const selectedTextKeys = keys.filter((key) => key.includes('SelectedText'));
    const result = {};
    questionKeys.forEach((key) => {
      // result[key]가 없으면 생성
      if (!result[key]) {
        result[key] = data[key] === false || Array.isArray(data[key]) ? [] : {};
      }
      if (Array.isArray(data[key])) {
        const selectedList = data[`${key}SelectedList`] || [];
        result[key] = selectedList.map((item) => {
          if (item.text === '기타') {
            item.text = data[`${key}Text`];
          }
          return item;
        });
      } else {
        result[key].value = data[key];
      }
    });
    selectedTextKeys.forEach((key) => {
      const questionKey = key.replace('SelectedText', '');
      if (!result[questionKey]) {
        result[questionKey] = {};
      }
      if (Array.isArray(result[questionKey])) {
        result[questionKey].forEach((item) => {
          item.text = data[key];
        });
      } else {
        result[questionKey].text = data[`${questionKey}Text`] || data[`${questionKey}SelectedText`] || data[key];
      }
    });

    const resultData = {
      surveyId: survey.id,
      result,
    };
    // TODO: 설문 결과 저장 로직
    const saveResult = await POST({
      url: `/surveys/${survey.id}/responses`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
      body: { classId, userClassId, answers: result },
    })
      .then((r) => r)
      .catch((e) => e);

    if (saveResult.success) {
      // TODO: 설문 결과 저장 성공 시 로직, classId가 있으면 updateState, 없으면 창닫거나 홈으로 이동
      if (classId) {
        // TODO: updateState 방안 필요
      }
      swal
        .fire({
          title: '설문 결과가 저장되었습니다.',
          text: '감사합니다.',
          icon: 'success',
          showConfirmButton: false,
          timer: 3000,
        })
        .then(() => {
          setIsSuccess(true);
          if (setIsCompleted) {
            setIsCompleted(true);
          } else {
            // TODO: 어떻게 동작할지 확인 필요
            // const { replace } = useHistory();
            return false;
            // replace('/');
            // or window.close();
          }
        });
    } else {
      swal.fire({
        title: '설문 결과 저장에 실패했습니다.',
        html: `${saveResult.message || ''}${
          saveResult.message ? '<br/><br/>' : ''
        }문제가 반복되면 관리자에게 문의해주세요.`,
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000,
      });
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isSuccess && !isClassroom) {
      window.location.replace('/');
    }
  }, [isSuccess]);

  // form error 로직
  const onError = (errors, e) => {
    console.log(errors, e);
    swal.fire({
      title: '필수 항목을 입력해주세요.',
      text: '필수 항목을 입력하지 않았습니다.',
      icon: 'warning',
      showConfirmButton: false,
      timer: 2000,
    });
  };
  const [currentHeight, setCurrentHeight] = useState('auto');
  const calculateHeight = useCallback(() => {
    const currentSection = document.getElementById(`section${currentSectionIndex}`);
    const currentSectionHeader = currentSection?.querySelector('.section-header');
    const currentSectionBody = currentSection?.querySelector('.section-body');
    const currentHeader = currentSection?.querySelector('.section-header');
    const currentSectionBodyChildren = currentSectionBody?.children;

    let height = 0;
    for (let i = 0; i < currentSectionBodyChildren?.length; i++) {
      height += currentSectionBodyChildren[i]?.clientHeight;
    }

    const headerHeight = currentSectionHeader?.clientHeight;
    const paddingHeight = 60;
    const gapHeight = 20 * (currentSectionBodyChildren?.length - 1);

    if (height > 0) {
      setCurrentHeight(`${height + headerHeight + paddingHeight + gapHeight}px`);
    } else {
      setCurrentHeight('100%');
    }
  }, [currentSectionIndex]);

  useEffect(() => {
    const timer = setTimeout(() => {
      calculateHeight();
    }, 100);
    const handleResize = () => {
      calculateHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, [currentSectionIndex, calculateHeight]);

  const sendKakaoNotification = () => {
    const authData = () => {
      const token = localStorage.getItem('eje_token');
      if (!token) {
        return {
          EJE_API_KEY: 'auth',
        };
      }
      return {
        EJE_API_KEY: token,
      };
    };

    fetch(`${URL.API_SERVER}/alimtalk/guide-survey`, {
      keepalive: true,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        ...authData(),
      },
      body: JSON.stringify({
        classId,
        surveyId,
      }),
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (bySurveyGuide) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    if (bySurveyGuide) {
      window.addEventListener('pagehide', sendKakaoNotification);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (bySurveyGuide) {
        window.addEventListener('pagehide', sendKakaoNotification);
      }
    };
  }, []);

  useEffect(() => {
    // 페이지 전환 감지
    const unblock = history.block((location, action) => {
      if (action === 'POP' && bySurveyGuide) {
        const confirmLeave = window.confirm('변경사항이 저장되지 않을 수 있습니다.');
        if (confirmLeave) {
          sendKakaoNotification();
          // 페이지 전환 허용
          return true;
        } else {
          // 페이지 전환 차단
          return false;
        }
      }
    });

    return () => {
      // 컴포넌트가 언마운트되면 차단 해제
      unblock();
    };
  }, [history]);

  return (
    <Layout isClassroom={window.location.pathname.startsWith('/classroom')}>
      {loaded ? (
        <FormContainer onSubmit={handleSubmit(onSubmit, onError)}>
          <Meta metaInfo={survey.metaInfo} />
          <Logo src={survey.logo} alt={survey.title} />
          <Title>{survey.title}</Title>
          <Description>{survey.description}</Description>
          <Rubbish>
            <Star sx={{ color: '#F24462', fontSize: '1rem', marginBottom: '-0.1875rem' }} />
            필수 입력 문항
          </Rubbish>
          <SectionContainer ref={carouselRef}>
            <Sections
              currentSectionIndex={currentSectionIndex}
              currentHeight={currentHeight}
              sectionCount={survey.sections.length}
            >
              {survey.sections.map((section, index) => {
                return (
                  <SectionItem
                    key={`section${index}-${section.title}`}
                    id={`section${index}`}
                    currentSectionIndex={currentSectionIndex}
                    sectionIndex={index}
                    sectionCount={survey.sections.length}
                  >
                    <SectionHeader>
                      <SectionNumber>{section.sort_order}</SectionNumber>
                      <SectionTitle>{section.title}</SectionTitle>
                    </SectionHeader>
                    <SectionBody>
                      {section.questions.map((question, qIndex) => {
                        return (
                          <QuestionContainer key={`question${question.id}-${question.content}`}>
                            <QuestionHeader>
                              <QuestionTitleContainer>
                                <QuestionNumberElement number={question.sort_order} isRequired={question.is_required} />
                                <QuestionTitle>{question.content}</QuestionTitle>
                              </QuestionTitleContainer>
                              {question.description && (
                                <QuestionDescription>{question.description}</QuestionDescription>
                              )}
                            </QuestionHeader>
                            <QuestionBody>
                              {question.FK_question_type?.code === 'singleChoice' && (
                                <RadioGroup
                                  options={question.options}
                                  register={register}
                                  name={`${question.id}`}
                                  layout={question.FK_choice_layout?.code}
                                  isRequired={question.is_required}
                                  watch={watch}
                                  setValue={setValue}
                                />
                              )}
                              {question.FK_question_type?.code === 'multipleChoice' && (
                                <CheckboxGroup
                                  options={question.options}
                                  register={register}
                                  name={`${question.id}`}
                                  isRequired={question.is_required}
                                  getValues={getValues}
                                  watch={watch}
                                  setValue={setValue}
                                />
                              )}
                              {question.FK_question_type?.code === 'shortText' && (
                                <ShortText
                                  register={register}
                                  name={`${question.id}`}
                                  placeholder={question.placeholder}
                                  isRequired={question.is_required}
                                />
                              )}
                              {question.FK_question_type?.code === 'longText' && (
                                <LongText
                                  register={register}
                                  name={`${question.id}`}
                                  placeholder={question.placeholder}
                                  isRequired={question.is_required}
                                />
                              )}
                            </QuestionBody>
                            <hr
                              style={{
                                borderTop: 'none',
                                borderBottom: '1px solid #E9ECEF',
                                paddingTop: '2.125rem',
                              }}
                            />
                          </QuestionContainer>
                        );
                      })}
                    </SectionBody>
                  </SectionItem>
                );
              })}
            </Sections>
            <ButtonContainer>
              {currentSectionIndex === 0 ? null : (
                <PrevButton
                  onClick={(event) => {
                    event.preventDefault();
                    handleSectionChange(currentSectionIndex - 1);
                  }}
                >
                  이전
                </PrevButton>
              )}
              <DraftButton
                onClick={(event) => {
                  event.preventDefault();
                  handleDraft();
                }}
              >
                임시저장
              </DraftButton>
              {currentSectionIndex < endSectionIndex ? (
                <NextButton
                  onClick={(event) => {
                    event.preventDefault();
                    handleSectionChange(currentSectionIndex + 1);
                  }}
                >
                  다음
                </NextButton>
              ) : null}
              {currentSectionIndex === endSectionIndex ? (
                <SubmitButton type="submit" issubmitting={isSubmitting} issuccess={isSuccess}>
                  제출
                </SubmitButton>
              ) : null}
            </ButtonContainer>

            {lastDraftedAt && (
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '0.75rem',
                  color: '#868e96',
                  marginTop: '0.5rem',
                  marginRight: '1.25rem',
                }}
              >
                마지막 임시저장: {dayjs(lastDraftedAt).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            )}
          </SectionContainer>
        </FormContainer>
      ) : (
        <Loading />
      )}
    </Layout>
  );
};

export default Survey;
