import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { em: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

ReactPixel.init('372428904574720', advancedMatching, options);

// For tracking page view
ReactPixel.pageView();

// For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.track(event, data);

// For tracking default events.
// ReactPixel.trackSingle('PixelId', event, data);

// For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackCustom(event, data);

// For tracking custom events.
// ReactPixel.trackSingleCustom('PixelId', event, data);

export default ReactPixel;
