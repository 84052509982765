import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import VideoPlayIcon from '../../Assets/Images/button-video-play.png';
import EventItemDefaultImage from '../../Assets/Images/event-item-default-image.png';
import ArrowLeftIcon from '../../Assets/Images/icon-dropdown-left.png';
import ArrowRightIcon from '../../Assets/Images/icon-dropdown-right.png';
import MoreBtnIcon from '../../Assets/Images/icon-dropdown-right.png';
import ItemDefaultImage from '../../Assets/Images/item-default-image.png';
import Meta from '../../Commons/Components/Meta';
import Popup from '../../Commons/Components/Popup';
import YouTubeEmbed from '../../Commons/Components/YouTubeEmbed';
import { URL } from '../../Commons/Utils/constant';
import * as youtube from '../../Commons/Utils/youtube';
import './index.scss';

dayjs.extend(utc);

const LectureItem = ({ item, isMobile = false }) => {
  const history = useHistory();

  const { FK_class } = item;

  const totalCategories = useMemo(() => {
    const classItem = FK_class
      ? [
          ...(FK_class?.Class_CategoryCompanies.map((Class_CategoryCompany) => {
            Class_CategoryCompany.FK_category.type = 'company';
            return Class_CategoryCompany;
          }) || []),
          ...(FK_class?.Class_CategoryFields.map((Class_CategoryField) => {
            Class_CategoryField.FK_category.type = 'field';
            return Class_CategoryField;
          }) || []),
          ...(FK_class?.Class_CategoryProcesses.map((Class_CategoryProcess) => {
            Class_CategoryProcess.FK_category.type = 'process';
            return Class_CategoryProcess;
          }) || []),
        ]
      : [];

    return classItem.length > 2 ? classItem.slice(0, 2) : classItem;
  }, [FK_class]);

  const totalTutors = useMemo(() => {
    const tutors = FK_class?.Class_Tutors || [];
    return tutors.length > 2 ? tutors.slice(0, 2) : tutors;
  }, [FK_class]);

  const onClickBanner = () => {
    const needPath = item.url?.includes(URL.HOST);
    const urlPathArr = item.url?.split('/');
    let toURL = null;
    if (item.url) {
      toURL = needPath ? urlPathArr.splice(3, urlPathArr.length).join('/') : item.url;
    } else if (FK_class?.id) {
      toURL = `/lecture/${FK_class?.id}`;
    }
    const needOpenWindow = !toURL?.includes(URL.HOST) && toURL?.includes('://');

    toURL && (needOpenWindow ? window.open(toURL) : history.push(toURL));
  };

  return (
    <div className="lecture_item">
      <div onClick={onClickBanner}>
        <div className="thumbnail_wrap">
          <img className="thumbnail" src={(item.image || ItemDefaultImage)?.toCloudFrontURL()} alt={item.title} />
        </div>

        <h4 className="lecture_item_title ellipsis">
          {item.title}
          {FK_class && FK_class.discount_rate >= 0.5 ? (
            <span className="discount">
              {Math.round(item.FK_class.discount_rate)}% <FormattedMessage id="DISCOUNT" />
            </span>
          ) : null}
        </h4>

        <div className={`lecture_item_description ${isMobile ? 'isRow' : ''}`}>
          <span className="lecture_item_lecture_item_tutors">
            {FK_class && totalTutors.map((tutor) => tutor.FK_tutor.name).join(', ')}
          </span>
          {FK_class && (
            <ul className="lecture_item_category">
              {totalCategories.map((Class_Category, index) => (
                <li key={`lecture_item_category-li-${index}`}>
                  <FormattedMessage
                    id={`CATEGORY_${Class_Category.FK_category.type.toUpperCase()}_${Class_Category.FK_category.id}`}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

// Youtube Item Component
const YoutubeItem = ({ item }) => {
  return (
    <Popup
      trigger={
        <div className="youtube_item">
          <div className="youtube_item_thumbnail">
            <img src={(youtube.getThumbnail(item.url) || ItemDefaultImage)?.toCloudFrontURL()} alt={item.title} />
            <img className="play_icon" src={VideoPlayIcon} alt="youtube_play_icon" />
          </div>
          <h4 className="youtube_item_title">{item.title}</h4>
          <p>{item.sub_title}</p>
        </div>
      }
      modal
    >
      <YouTubeEmbed title={item.title} src={youtube.getEmbed(item.url)} />
    </Popup>
  );
};

// Event Item Component
const EventItem = ({ item }) => {
  const { end_date } = item;
  const diff = end_date ? dayjs.utc(end_date).diff(dayjs(), 'days') : null;

  return (
    <div className="event_item">
      <a href={item.url} target="_blank" rel="noreferrer">
        <div className="event_item_thumbnail">
          <img src={(item.image || EventItemDefaultImage)?.toCloudFrontURL()} alt={item.title} />
        </div>
        <h4 className="event_item_title ellipsis">{item.title}</h4>
        {/* D-Day or Diff */}
        {end_date && diff >= 0 && <span className="dday">D-{diff === 0 ? 'Day' : diff}</span>}
      </a>
    </div>
  );
};

// Event Banner Slick Slider Prev/Next Arrow
const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <img src={ArrowLeftIcon} alt="prev" />
    </div>
  );
};

const NextArrow = ({ className, style, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <img src={ArrowRightIcon} alt="next" />
    </div>
  );
};

const View = ({
  event,
  offline,
  online,
  top,
  video,
  bottomBanner,
  isFixed,
  isMobile,
  currentTopImageSrc,
  currentBottomImageSrc,
  currentBottom,
  setCurrentTopImageSrc,
  setCurrentBottomImageSrc,
  setCurrentBottom,
}) => {
  // Main Banner Slick Options
  const mainBannerSettings = useMemo(
    () => ({
      dots: true,
      fade: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange(curr, next) {
        setCurrentTopImageSrc(next);
      },
    }),
    [setCurrentTopImageSrc],
  );

  const mobileClassBannerSettings = useMemo(
    () => ({
      infinite: true,
      speed: 200,
      variableWidth: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
    }),
    [],
  );

  // Mobile Youtube Banner Slick Options
  const mobileYoutubeBannerSettings = useMemo(
    () => ({
      infinite: true,
      speed: 200,
      variableWidth: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
    }),
    [],
  );

  // Event Banner Slick Options
  const eventBannerSettings = useMemo(
    () => ({
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '30px',
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      infinite: false,
      autoplay: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            arrows: true,
            autoplaySpeed: 3000,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
          },
        },
      ],
    }),
    [],
  );

  const bottomBannerSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 3000,
      beforeChange(currentSlider) {
        if (bottomBanner.length === currentSlider + 1) {
          setCurrentBottomImageSrc(0);
          setCurrentBottom(0);
        } else {
          setCurrentBottomImageSrc(currentSlider + 1);
          setCurrentBottom(currentSlider + 1);
        }
      },
    }),
    [bottomBanner, setCurrentBottomImageSrc, setCurrentBottom],
  );

  const metaInfo = {
    title: '엔터테인먼트 미디어 콘텐츠 취업 | 엔터잡에듀',
    description:
      '국내 정상급 아티스트랑 일하고 싶다면? 엔터잡에듀와 함께하세요. SM 하이브 YG CJENM 등 국내 엔터테인먼트 미디어 콘텐츠 취업 정보는 엔터잡에듀에서!',
    keywords:
      '엔터테인먼트, 미디어, 콘텐츠, 방송국, 영화, 공연, 음반유통사, 취업, kpop, SM, JYP, HYBE, 하이브, 빅히트, YG, 카카오, 카카오엔터테인먼트, CJENM, fnc, jtbc, 홍보, 마케팅, 팬마케팅, 콘텐츠마케팅, a&r, PD, 프로덕션, 매니지먼트, 매니저, 자기소개서, 이력서, 서류, 면접, 포트폴리오, 포트폴리오 제작, 대학생, 취업준비생, 취준생',
  };

  return (
    <>
      <Meta metaInfo={metaInfo} />
      <div className="home_wrap">
        {top.length > 0 && (
          <div className="banner_wrap">
            <div className="inner_wrap">
              <div className="banner_bg_wrap">
                <div
                  className="banner_bg"
                  style={{
                    backgroundImage: `url(${currentTopImageSrc?.toCloudFrontURL()})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'multiply',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    filter: 'blur(5px)',
                    transition: 'background 1s ease-in-out',
                  }}
                />
              </div>
              <Slider {...mainBannerSettings}>
                {top.map((item, index) => (
                  <div className="banner_item" key={`main_banner_${index}`}>
                    <div className="banner_item_info">
                      {item.url ? (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <h2 className="banner_item_title">{item.title}</h2>
                          <div className="banner_item_description">{item.sub_title}</div>
                        </a>
                      ) : (
                        <>
                          <h2 className="banner_item_title">{item.title}</h2>
                          <div className="banner_item_description">{item.sub_title}</div>
                        </>
                      )}

                      {item.url && (
                        <span className="go_class_btn">
                          <a href={item.url} target="_blank" rel="noreferrer">
                            {item.button_name}
                          </a>
                        </span>
                      )}
                    </div>

                    <div className="banner_item_image">
                      {item.url ? (
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img src={item.image?.toCloudFrontURL()} alt={item.title} />
                        </a>
                      ) : (
                        <img src={item.image?.toCloudFrontURL()} alt={item.title} />
                      )}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}

        <div className="home_content">
          <div className="inner_wrap">
            {online.length > 0 && (
              <div className="best_lectures lectures_wrap">
                <h2 className="lectures_title title">
                  <FormattedMessage id="MAIN_ONLINE_TITLE" />
                  <span className="more_btn">
                    <Link to="/online">
                      <FormattedMessage id="MORE" />
                      <img src={MoreBtnIcon} alt="more_btn" />
                    </Link>
                  </span>
                </h2>
                <div className="lecture_items">
                  {/* PC */}
                  {!isMobile &&
                    online.map((item, index) => <LectureItem item={item} key={`lecture-item-oninle-pc-${index}`} />)}

                  {/* Mobile */}
                  {isMobile && (
                    <Slider {...mobileClassBannerSettings}>
                      {online.map((item, index) => (
                        <LectureItem item={item} key={`lecture-item-oninle-mobile-${index}`} isMobile={true} />
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            )}

            {offline.length > 0 && (
              <div className="offline_lectures lectures_wrap">
                <h2 className="lectures_title title">
                  <FormattedMessage id="MAIN_OFFLINE_TITLE" />
                  <span className="more_btn">
                    <Link to="/offline">
                      <FormattedMessage id="MORE" />
                      <img src={MoreBtnIcon} alt="more_btn" />
                    </Link>
                  </span>
                </h2>
                <div className="lecture_items">
                  {/* PC */}
                  {!isMobile &&
                    offline.length > 0 &&
                    offline.map((item, index) => <LectureItem item={item} key={`lecture-item-offline-pc-${index}`} />)}

                  {/* Mobile */}
                  {isMobile && (
                    <Slider {...mobileClassBannerSettings}>
                      {offline.length > 0 &&
                        offline.map((item, index) => (
                          <LectureItem item={item} key={`lecture-item-offline-mobile-${index}`} isMobile={true} />
                        ))}
                    </Slider>
                  )}
                </div>
              </div>
            )}

            {video.length > 0 && (
              <div className="go_youtube_wrap">
                <h2 className="title">
                  {!isMobile && <FormattedMessage id="MAIN_VIDEO_TITLE" />}
                  {isMobile && <FormattedMessage id="MOBILE_MAIN_VIDEO_TITLE" />}
                  <span className="more_btn">
                    <a href="https://www.youtube.com/channel/UCL12m9yG__hYNtY-eueF85w" target="_blank" rel="noreferrer">
                      <FormattedMessage id="GO_YOUTUBE" />
                      <img src={MoreBtnIcon} alt="more_btn" />
                    </a>
                  </span>
                </h2>
                <div className="youtube_items">
                  {/* PC */}
                  {!isMobile && video.length > 0 && video.map((item, index) => <YoutubeItem key={index} item={item} />)}

                  {/* Mobile */}
                  {isMobile && (
                    <Slider {...mobileYoutubeBannerSettings}>
                      {video.length > 0 && video.map((item, index) => <YoutubeItem key={index} item={item} />)}
                    </Slider>
                  )}
                </div>
              </div>
            )}

            {event.length > 0 && (
              <div className="event_banner_wrap">
                <h2 className="title">
                  <FormattedMessage id="MAIN_EVENT_TITLE" />
                </h2>
                <Slider {...eventBannerSettings}>
                  {event.length > 0 && event.map((item, index) => <EventItem key={index} item={item} />)}
                </Slider>
              </div>
            )}
          </div>
        </div>

        {bottomBanner.length > 0 && (
          <div
            className={`bottom_event_banner ${isFixed ? 'isFixed' : ''}`}
            style={{
              backgroundImage: `url(${currentBottomImageSrc?.toCloudFrontURL()})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              transition: 'background 1s ease-in-out',
            }}
          >
            <div className="inner_wrap">
              <strong>{currentBottom.type_name}</strong>
              <Slider {...bottomBannerSettings}>
                {bottomBanner.map((item, index) => (
                  <div key={index}>
                    {item.url ? (
                      <a href={item.url} target="_blank" rel="noreferrer" className="ellipsis">
                        {item.title}
                      </a>
                    ) : (
                      <p className="ellipsis">{item.title}</p>
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default View;
