import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Popup from '../../../../../../Commons/Components/Popup';
import ListWrap from '../ListWrap';
import AddStudents from './Add';

const StudentListWrap = (properties) => {
  const {
    lectureTitle,
    students,
    fetchOrgLectureDetail,
    orgId,
    lectureId,
    mapping_id,
    postOrgLectureStudent,
    setDetail,
    deleteOrgLectureStudent,
    requestSendSMS,
    lectureEndDatetime,
  } = properties;
  const [checkedStudents, setCheckedStudents] = useState([]);
  const list = useMemo(() => {
    return students?.list || [];
  }, [students]);
  const removeStudents = useCallback(() => {
    swal({
      title: '강의에서 제외하시겠습니까?',
      text: `제외된 ${checkedStudents.length}명의 수강 기록은 다시 복구할 수 없습니다.`,
      icon: 'warning',
      buttons: ['취소', '제외'],
    }).then((choose) => {
      if (choose) {
        // TODO: remove students
        deleteOrgLectureStudent({
          org_id: orgId,
          class_id: lectureId,
          mapping_id,
          user_ids: checkedStudents.join(','),
        }).then(({ student: newStudents }) => {
          setDetail((prev) => ({
            ...prev,
            student: {
              ...prev.student,
              list: newStudents,
            },
          }));
          setCheckedStudents([]);
        });
        swal('제외되었습니다.', {
          icon: 'success',
          timer: 2000,
          buttons: false,
        });
        // TODO: fetch students
      }
    });
  }, [checkedStudents, deleteOrgLectureStudent, lectureId, mapping_id, orgId, setDetail]);

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [addTargetStudents, setAddTargetStudents] = useState([]);
  const [notLectureStudents, setNotLectureStudents] = useState([]);
  const addStudents = useCallback(async () => {
    const response = await fetchOrgLectureDetail({
      orgId,
      lectureId,
      mapping_id,
      student: false,
    });
    const { student: students } = response;
    if (students.length > 0) {
      setNotLectureStudents(
        students?.map((item) => ({
          ...item,
          phone: item.phone ? Number(item.phone).toFormattedPhoneNumber() : '',
        })) || [],
      );
    } else {
      swal('추가할 구성원이 없습니다.', {
        icon: 'info',
        timer: 2000,
        buttons: false,
      });
    }
  }, [fetchOrgLectureDetail, lectureId, mapping_id, orgId]);
  useEffect(() => {
    setShowAddPopup(!!notLectureStudents.length);
  }, [notLectureStudents]);
  const requestAddStudents = useCallback(() => {
    swal({
      title: '구성원을 강의에 추가하시겠습니까?',
      text: `${addTargetStudents.length}명의 구성원을 추가합니다.`,
      icon: 'warning',
      buttons: ['취소', '추가'],
    }).then((choose) => {
      if (choose) {
        // TODO: add students
        postOrgLectureStudent({
          org_id: +orgId,
          class_id: +lectureId,
          mapping_id: +mapping_id,
          user_ids: addTargetStudents.join(','),
        })
          .then((res) => {
            const { student: newStudents } = res;
            setDetail((prev) => ({
              ...prev,
              student: {
                ...prev.student,
                list: newStudents,
              },
            }));
          })
          .then(() => {
            setAddTargetStudents([]);
          })
          .catch((err) => {
            console.error(err);
          });
        swal('추가되었습니다.', {
          icon: 'success',
          buttons: false,
          timer: 2000,
        }).then(() => {
          setShowAddPopup(false);
          setAddTargetStudents([]);
          setNotLectureStudents([]);
        });
        // TODO: fetch students
      }
    });
  }, [addTargetStudents, lectureId, mapping_id, orgId, postOrgLectureStudent, setDetail]);

  const isNotDoneStudents = useMemo(() => {
    return list.filter((item) => item.status !== 'done');
  }, [list]);

  const sendSMS = useCallback(() => {
    swal({
      title: '문자를 발송하시겠습니까?',
      text: `이수 전인 수강생들에게 수강 독려 문자를 발송합니다.`,
      icon: 'warning',
      buttons: ['취소', '발송'],
    }).then((choose) => {
      if (choose) {
        requestSendSMS({
          students: isNotDoneStudents,
          lecture: {
            id: lectureId,
            title: lectureTitle,
            endDatetime: lectureEndDatetime,
          },
        })
          .then((res) => {
            const failNumbers = res.filter((item) => item.success === false).map((item) => item.failList[0]);
            const successCount = res.filter((item) => item.success === true).length;
            if (successCount > 0) {
              swal({
                title: '문자 발송이 완료되었습니다.',
                text: `발송된 문자 수: ${successCount}건`,
                icon: 'success',
                buttons: false,
                timer: 2000,
              });
            } else {
              swal({
                title: '문자 발송에 실패했습니다.',
                icon: 'error',
                buttons: false,
                timer: 2000,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            swal({
              title: '문자 발송에 실패했습니다.',
              icon: 'error',
              buttons: false,
              timer: 2000,
            });
          });
      }
    });
  }, [isNotDoneStudents, lectureId, lectureTitle, requestSendSMS]);

  return (
    <div className="lecture-students">
      <div className="lecture-students-header">
        <h5>수강생 목록</h5>
        <div className="lecture-students-header-action-wrap">
          {dayjs(lectureEndDatetime).isAfter(dayjs()) && (
            <>
              <Button
                variant="contained"
                size="small"
                disableRipple
                disableElevation
                onClick={sendSMS}
                className={`btn-send-sms ${isNotDoneStudents.length === 0 ? 'hide' : ''}`}
              >
                수강 독려
              </Button>
              <Button
                variant="contained"
                size="small"
                disableRipple
                disableElevation
                onClick={addStudents}
                className="btn-add"
              >
                수강생 추가
              </Button>
              <Button
                variant="contained"
                size="small"
                disableRipple
                disableElevation
                onClick={removeStudents}
                disabled={!checkedStudents.length}
                color="secondary"
                className={`btn-cancel ${!checkedStudents.length ? 'hide' : ''}`}
              >
                제거
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="lecture-students-body">
        <StudentList students={list} selectStudents={setCheckedStudents} />
      </div>
      <Popup
        open={showAddPopup}
        onClose={() => setShowAddPopup(false)}
        className="add-student-popup"
        modal
        closeOnDocumentClick={false}
      >
        <section>
          <div className="add-student-header"></div>
          <div className="add-student-body">
            <div className="add-student-target-list">
              <AddStudents
                students={notLectureStudents}
                addTargetStudents={addTargetStudents}
                selectStudents={setAddTargetStudents}
                setShowAddPopup={setShowAddPopup}
                requestAddStudents={requestAddStudents}
              />
            </div>
          </div>
        </section>
      </Popup>
    </div>
  );
};

const StudentList = ({ students, selectStudents }) => {
  if (!students || students.length === 0) {
    return null;
  }
  const header = [
    {
      key: 'id',
      label: 'ID',
      style: {
        width: '32px',
      },
    },
    {
      key: 'name',
      label: '이름',
      style: {
        width: '100px',
      },
    },
    {
      key: 'phone',
      label: '연락처',
      style: {
        width: '120px',
      },
    },
    {
      key: 'email',
      label: '이메일',
      style: {
        width: '160px',
        textAlign: 'left',
      },
    },
    {
      key: 'completionRate',
      label: '수강율',
      style: {
        width: '80px',
      },
    },
    {
      key: 'status',
      label: '이수여부',
      style: {
        width: '100px',
      },
    },
  ];

  const list =
    students?.map((item) => ({
      ...item,
      status: (() => {
        const obj = {
          done: '이수',
          warn: '수강중',
          fail: '미이수',
          ready: '수강중',
          ongoing: '수강중',
        };
        if (!!item.status) {
          return obj[item.status];
        }
      })(),
      completionRate: `${item.completionRate}%`,
      phone: Number(item.phone) > 0 ? Number(item.phone).toFormattedPhoneNumber() : '-',
    })) || [];

  const properties = {
    header,
    list,
    useCheckbox: true,
    selectStudents,
  };
  return <ListWrap {...properties} />;
};

export default StudentListWrap;
