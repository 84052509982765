export default function TermsOfUse(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">利用規約</h2>
      <article className="page sans">
        <div className="page-body">
          <p>
            <strong>第1章 総則</strong>
          </p>
          <p>
            <strong>第1条[目的]</strong>
          </p>
          <p>
            本規約は、（株）コネクサスラボ（以下「会社」という）と利用顧客（以下、「会員」という）間に会社が提供する教育情報サービス（以下「サービス」という）の登録条件および利用に関して、会社と会員の間の権利・義務および責任事項等を定めることを目的とします。
          </p>
          <p>
            <strong>第2条[定義]</strong>
          </p>
          <p>① 本規約における用語の定義は以下の通りです。</p>
          <p>
            1.「利用者」とは、会社の公式サイトにアクセスし、本規約に基づいて会社が提供するコンテンツおよび諸サービスを利用する会員および非会員をいいます。
          </p>
          <p>
            2.「会員」とは、会社の公式サイトにアクセスし、本規約に同意することにより会社と利用契約を締結し、IDを取得する者であって、会社が提供する情報およびサービスを継続的に利用できる者をいいます。
          </p>
          <p>
            3.「コンテンツ」とは、会社の公式サイトで提供するオンライン講座その他の関連情報を意味し、情報通信網の利用促進および情報保護等に関する法律第2条第1項第1号の規定による情報通信網で使用される記号・文字・音声・音響・画像または映像等で表現された資料または情報をいいます。
          </p>
          <p>
            4.「ID」とは、会員の識別およびサービス利用のために会員が決め、会社が承認する文字または数字の組み合わせをいいます。
          </p>
          <p>
            5.「パスワード（PASSWORD）」とは、サービスの利用にあたって、IDと一致する会員であることを確認し、会員の個人情報保護のために、会員が直接決めた文字または数字の組み合わせをいいます。
          </p>
          <p>
            6.「電子メール（Email）」とは、インターネットを通じたメッセージ、または電気的な媒体を利用したメッセージをいいます。
          </p>
          <p>
            7.「運用者（管理者）」とは、サービスの全体的な管理と円滑な運用のために会社が指定した者または会社をいいます。
          </p>
          <p>
            8.「会員の投稿」とは、会社のサービスが提供される公式サイトに会員が掲載した投稿、画像、各種ファイルおよびリンク、各種コメント等の情報をいいます。
          </p>
          <p>②前項各号に該当する定義以外のその他の用語の定義については、取引慣行および関係法令に従うものとします。</p>
          <p>
            <strong>第3条[会社情報等の提供]</strong>
          </p>
          <p>
            会社は、会社の商号、代表者の氏名、住所、代表電話、ファックス送信番号、電子メールアドレス、事業者登録番号、通信販売業申告番号、個人情報の管理責任者等を、利用者が簡単に確認できるように公式サイトの初期画面に掲示します。
          </p>
          <p>
            <strong>第4条[規約の効力および変更]</strong>
          </p>
          <p>①本規約は、サービスを利用しようとするすべての会員に対してその効力を生じます。</p>
          <p>
            ②本規約は、会社の公式サイト（
            <a href="http://www.webheasd.co.kr/">enterjobedu.co.kr</a>
            以下「エンタージョブエデュテサービス」という）にてオンラインで告示されることによりその効力を生じます。また、合理的な事由がある場合、会社は関係法令に反しない範囲内で本規約を変更することがあります。
          </p>
          <p>
            ③改正規約もエンタージョブエデュテサービスにてオンラインで告示されることによりその効力を生じます。会社は規約を変更する場合、遅滞なく、これを告知するものとし、会員の権利または義務等に関する重要な事項を改正する場合には、事前に告知するものとします。
          </p>
          <p>
            ④本規約に同意することは、定期的にエンタージョブエデュサービスを訪問し、規約の変更事項を確認することに同意したものとみなします。利用者が変更された規約を知らなくて生じる被害については、会社は責任を負いません。
          </p>
          <p>
            ⑤規約に同意しない会員は、退会（解除​​）を要請することができるとともに、規約の効力が生じた日から7日以内に拒否の意思を表明せずサービスを継続的に利用する場合は、規約に同意したものとみなします。
          </p>
          <p>
            <strong>第5条[規約およびその他の準則]</strong>
          </p>
          <p>
            ①本規約は、会社が提供する各サービスに関する利用案内（以下「サービス別案内」という）と同時に適用されます。
          </p>
          <p>
            ②本規約に明示されていない事項については、「規約の規制に関する法律」「情報通信網の利用促進および情報保護等に関する法律」「電子商取引等における消費者保護に関する法律」その他の関係法令の規定に従うものとします。
          </p>
          <p>
            <strong>第2章 会員登録</strong>
          </p>
          <p>
            <strong>第6条 [会員登録]</strong>
          </p>
          <p>
            ①会員として登録し、会社のサービス利用を希望する者は、規約の内容を熟知したうえで同意を表明するとともに、会社が提示する所定の会員登録フォームに関連事項を記載して会員登録を申請する必要があります。
          </p>
          <p>
            ②会社は、前項に基づき、会員がオンライン会員登録の申請フォームに記載するすべての会員情報を実際のデータとみなします。
          </p>
          <p>
            ③実名または実際の情報を記載しなかった会員は、法的な保護を受けることができないとともに、本規約の関連規定によりサービスの利用に制限を受けることがあります。
          </p>
          <p>
            ④会社は、本条第1項の規定による利用者の申請について会員登録を承諾することを原則とします。ただし、会社は、次の各号に該当する申請については承諾しない場合があります。また、承諾した後であっても、承諾を取り消すことができるものとします
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>利用者の責に帰すべき事由により承認ができない場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>他人の名義または個人情報を盗用した場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>虚偽の情報を提供した場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>重複したIDを使用した場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>会社が提示する会員登録申請フォームに関連内容を記載しなかった場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>過去に会社の利用規約または関係法令に違反し、会員資格が取り消されたことがある場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="7">
            <li>その他本規約および関係法令に違反した場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="8">
            <li>
              会員登録申請フォームに記載され会社に提供される個人情報（ID、パスワード、住所等）が善良な風俗その他社会秩序に反し、または他人を侮辱した場合
            </li>
          </ol>
          <p>
            <strong>第7条 [サービスの提供および変更]</strong>
          </p>
          <p>①会社は、会員に以下のサービスを提供します。</p>
          <ol type="1" className="numbered-list" start="1">
            <li>会員向けのオンライン教育コンテンツサービス、カスタマイズ型サービス、オフライン教育コンテンツ</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>その他の会社が独自に開発し、または他の会社との業務協力契約により会員に提供する一切のサービス</li>
          </ol>
          <p>
            ②会社は、変更されるサービスの内容および提供日をエンタージョブエデュサービスにて告知し、サービスを変更して提供することがあります。
          </p>
          <p>
            <strong>第8条[個人情報の保護および利用]</strong>
          </p>
          <p>①会社は、お客様の個人情報を保護し、尊重します。</p>
          <p>
            ②会社は、利用申請時にお客様が提供する情報、各種イベント参加のためにお客様が提供する情報その他のサービスを利用するに際して、収集される情報等によりお客様に関する情報を収集し、その収集されたお客様の情報は、本利用契約の履行および本利用契約上のサービスを提供するための目的として利用されます。
          </p>
          <p>
            ③会社は、サービスの提供に関して知り得たお客様の身元情報を、本人の承諾なしに第三者に漏らしてはならないものとします。ただし、次の各号に掲げる場合は、この限りではありません。
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>サービス提供による料金精算のために必要な場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              統計作成、学術研究または市場調査のために必要な場合であって、特定の個人を識別できない形態に加工して提供する場合
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>関係法令に基づき、捜査目的で定められた手続きおよび方法により関係機関の要求がある場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>他の法律に特別な規定がある場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>情報通信倫理委員会が関係法令により要請する場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>会員に対して、より専門的で多様なサービスを提供するために必要な場合</li>
          </ol>
          <p>
            ④会社は会員に対して、第3項の専門的で多様なサービスを提供するために、自主的にTMを行い、または外部の専門事業者と連携し、共同でサービス（TM、SMS、メール等広告情報の送信）を提供することがあります。
          </p>
          <p>
            ⑤専門的な知識、経験および相談が必要なサービスの場合、会員の同意を得て外部の専門事業者と共同でサービスを提供します。この場合は、その専門事業者の商号および共有目的、共有情報を明示するものとします。
          </p>
          <p>
            ⑥外部の専門事業者との共同サービスを提供することにあたって、会員の氏名、連絡先等の共同サービスに必要な最小限の情報が共有されることがあります。その共有情報は、次の各号のとおり厳しく保護、管理されます。
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>共有情報は、その専門的なサービスのほか、いかなる用途にも利用されません。</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              サービスの提供にあたって、その専門的なサービスにつき、会員が同意の意思を表明しなかったとき、または事前に拒否の意思を表明したときは、最小限の情報も専門事業者と共有しないものとします。
            </li>
          </ol>
          <p>⑦個人情報の利用に関する会員の同意は、本規約に同意することにより、それに代えることができます。</p>
          <p>
            ⑧会員はいつでも必要な場合、会社に提供した個人情報の収集および利用に関する同意を撤回することができます。その同意の撤回は、解除申請をすることにより行われるものとします。
          </p>
          <p>⑨個人情報保護に関する詳細はプライバシーポリシーを参考にしてください。</p>
          <p>
            <strong>第9条[会員情報の変更]</strong>
          </p>
          <p>
            ①会員は、会社の公式サイトの「情報変更」ページにていつでも自己の個人情報を閲覧し、変更することができます。
          </p>
          <p>②会員が、前項の変更事項を修正しなかったことにより生じた不利益について会社は責任を負いません。</p>
          <p>
            <strong>第10条 [会員の「ID」及び「パスワード」の管理に関する義務]</strong>
          </p>
          <p>
            ①会員には、IDおよびパスワードの管理責任があるとともに、これを他人に開示して第三者が利用するようにしてはいけません。
          </p>
          <p>
            ②会員は、自己のIDおよびパスワードが流出され、第三者により利用されていることを認知した場合、直ちに会社に知らせる必要があります。
          </p>
          <p>
            ③会社は前項の場合、会員の個人情報保護その他のサービスの不正利用行為の防止等のために、会員に対してパスワードの変更等、必要な措置を求めることがあるとともに、会員は、会社の要求があるとき、直ちに会社の要求に誠実に応じる必要があります。
          </p>
          <p>
            ④会社は、会員が本条第2項および第3項による義務を誠実に履行しなかったことにより生じた不利益について責任を負いません。
          </p>
          <p>
            <strong>第11条[会員退会および資格の取り消し]</strong>
          </p>
          <p>①会員は、いつでもサービスの利用を停止し、退会することができます。</p>
          <p>
            ②会社は、会員が本規約または関係法令に違反する場合、サービスの利用を制限し、または会員資格を取り消すことがあります。
          </p>
          <p>
            ③本条項により解除する会員については、利用契約の終了（会員退会）時に、ポイント、割引クーポンが消滅します。
          </p>
          <p>
            ④会社は、会員退会時に会員から提供された情報を関係法令およびプライバシーポリシー等により、削除あるいは破棄、または一定期間保管します。ただし、会員が退会前に作成した投稿は削除されないとともに、会員退会後、会社に対してその投稿の削除を要求することができません。
          </p>
          <p>
            <strong>第3章 サービス利用契約</strong>
          </p>
          <p>
            <strong>第12条[会社の義務]</strong>
          </p>
          <p>
            ①会社は、特別な事情がない限り、会員が希望したサービス利用開始日にサービスを提供し、継続的かつ安定的にサービスを提供するものとします。
          </p>
          <p>
            ②会社は、個人情報保護のためのセキュリティシステムを構築するとともに、プライバシーポリシーを告知し、遵守します。
          </p>
          <p>
            ③会社は、利用者からの意見あるいはクレームが正当であると認める場合、適切な措置を講じるものとします。また、迅速な処理が困難な場合には、利用者に対してその事由および処理日程を通知するものとします。
          </p>
          <p>④会社は、次の各号のいずれかに該当する事由が生じた場合、サービスを停止することがあります。</p>
          <ol type="1" className="numbered-list" start="1">
            <li>システムの定期診断、増設および交換が必要な場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>緊急なシステムの診断、増設および交換が必要な場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>新しいサービスへの変更等、会社が適切であると判断する場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>
              国家非常事態、停電、サービス設備の障害またはサービス利用の急増等によりサービスを正常に提供できない場合
            </li>
          </ol>
          <p>
            ⑤会社は、前項の規定によりサービスの利用を制限し、または停止したときは、その事由および制限期間等を会員に知らせるものとします。
          </p>
          <p>
            ⑥会社は、会社が制御できない事由によりサービスが停止される場合（システム管理者の故意または過失のないディスク障害、システムダウン等）、事前通知が不可能であって、他人（PC通信会社、基幹通信事業者等）の故意、過失によるシステム停止等の場合は、通知の義務を負わないものとします
          </p>
          <p>
            <strong>第13条[会員の義務]</strong>
          </p>
          <p>
            ①会社の明示的な同意がない限り、会員の利用権限は個人に限定され、他人に譲渡、贈与、またはこれを担保として提供することができません。
          </p>
          <p>
            ②会員は、住所、連絡先、電子メールアドレス等、利用契約事項に変更が生じた場合、当該手続きを経て、直ちにこれを会社に知らせる必要があります。
          </p>
          <p>
            ③会員は、本規約および関係法令等、諸規定および会社のお知らせを遵守しなければなりません。また、会社の業務を妨害し、または会社の名誉を傷つける行為をしてはいけません。
          </p>
          <p>
            ④会員は、会社の事前承諾なしにサービスを利用して営業活動を行うことができないとともに、会社は、その営業活動について責任を負いません。また、会員は、上記の営業活動により会社に損害を与えた場合、損害賠償責任を負います。
          </p>
          <p>
            ⑤会員は、会社の明示的な同意がない限り、サービスの利用権限その他の利用契約上の地位を他人に譲渡、贈与、担保として提供することができません。
          </p>
          <p>⑥会員は、会社および第三者の知的財産権を侵害してはいけません。</p>
          <p>
            ⑦会社は、会員が次の各号に掲げる行為をする場合、当該会員のサービス利用制限等、適切な制限措置を取ることがあります。
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              会員が、利用申請または変更時に、虚偽の事実を記載し、または自己あるいは他の会員のIDおよび個人情報を利用および共有する場合
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>会員が、その他の違法な方法により会員登録を行ったことが確認される場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>会員が、会社あるいは第三者の権利または著作権を侵害する場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>会員が、会社が提供するサービスを利用し、商品又は役務を販売する営業活動等の商行為をする場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>会員が、他の利用者のサービス利用を妨害し、または会社の運営者、従業員または関係者を詐称する場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>
              公の秩序および善良な風俗に反する低俗、わいせつな内容の情報、文章、図形、音響、動画を送信、掲示、電子メールその他の方法により他人に流布する場合
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="7">
            <li>
              会社のサービス運用を故意に妨害し、または妨害する目的で多量の情報を送信し、または広告情報を送信する場合
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="8">
            <li>
              侮辱的または身元情報に関する内容であって、他人の名誉やプライバシーを侵害するおそれがある内容を送信、掲示、電子メールその他の方法により他人に流布する場合
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="9">
            <li>会社の承認を得ないで、他の利用者の個人情報を収集または保存する場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="10">
            <li>会社および他人の名誉を毀損し、または侮辱する場合</li>
          </ol>
          <ol type="1" className="numbered-list" start="11">
            <li>関係法令その他の本規約に規定する事項に違反する場合</li>
          </ol>
          <p>
            <strong>第14条[サービス利用料金】</strong>
          </p>
          <p>
            ①有料サービスの利用料金および支払い方法は、当該サービスおよび支払いページに記載されている内容に従うものとします。
          </p>
          <p>
            ②会社は、電子マネー（クーポンおよびポイント）に関するポリシーを会社の運営状況に応じて変更することがあるとともに、これを公式サイトに掲載します。
          </p>
          <p>
            <strong>第15条[返金ポリシー]</strong>
          </p>
          <p>
            ①会員は、電話またはインターネットにより、返金または変更の意思を表明するとともに、会社は、返金要求を受け付けた場合、会員の要求と返金ポリシーを確認の上、可能な限り速やかに返金します。
          </p>
          <p>1.返金</p>
          <p>
            <strong>（1）オンライン講義の受講料に関する返金規約</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              お支払い日から7日以内に講義を受講していない場合は、全額返金します。ただし、お客様の責に帰すべき事由により返金する場合、仮想口座については、500ウォンの振込手数料を差し引いたうえで返金します。
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              利用時間の終了前に返金申請を行った場合、実際のお支払い金額 -
              実際に再生した講義数に相当する金額を返金します。
            </li>
          </ul>
          <p>（実際に再生した講義数に相当する金額：受講した講義のお支払い金額/講義構成数*学習講義数）</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              パッケージ、フリーパス等の特別企画商品の場合、返金ポリシーが異なることがありますので、必ずご確認ください。
            </li>
          </ul>
          <p>
            <strong>（2）オフライン講義の受講料に関する返金規約</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              受講料の全額返金については、開講日から起算して7日前までは全額返金ができます。その後は10％の違約金が発生します。
            </li>
          </ul>
          <p>
            ※
            事前連絡なしで講義に参加せず返金申請を行う場合、またはお客様の責に帰すべき事由により返金する場合、仮想口座については、振込手数料500ウォンを差し引いたうえで返金します。（閉講は除く）不可能です。
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              お客様の責に帰すべき事由により返金する場合、カード決済については、3％の手数料を差し引いたうえで返金します。（閉講は除く）
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              返金の際、実際にお支払いいただいた金額（割引を除く）を基準に返金処理を行います。
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              開講後の返金に関する法令は以下の通りです。（塾の設立、運営および課外教習に関する法律）
            </li>
          </ul>
          <p>（開講後の返金については、実際に受講したかどうかに関わらず、日割り計算または分数計算で返金します）</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              全授業の3分の1が経過するまでは、3分の1を控除し、残りの3分の2を返金
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              全授業の2分の1が経過するまでは、2分の1を控除し、残りの2分の1を返金
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>全授業の2分の1が経過した場合は返金不可</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              無断欠席の場合、返金額の計算は当該授業に参加したものとみなして処理されます。
            </li>
          </ul>
          <p>[受講期間が1カ月を超える場合]</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>返金事由が生じた月の返金額</li>
          </ul>
          <p>（受講料の徴収期間が1カ月以内の場合に算出された受講料）と残りの月の分の受講料を合算した金額</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              1カ月目に8時間登録および授業料24万ウォン、2カ月目に月2時間登録および受講料6万ウォンとみなす
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              1カ月目に授業の3分の1が経過する前に返金する場合：1カ月目の授業料の3分の2 +、2カ月目の授業料全額返金可能
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              1カ月目に授業の2分の1が経過する前に返金する場合：1カ月目の授業料の1分の2 +、2カ月目の授業料全額返金可能
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              1カ月目に授業の2分の1が経過した後に返金する場合：1カ月目の授業料返金不可 +、2カ月目の授業料全額返金可能
            </li>
          </ul>
          <p>
            <strong>（3）オンライン+オフラインで構成された講義に関する返金規約</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              オンライン+オフラインで提供されるオンライン講義を受講した後、オフラインの授業開講前に返金を要請する場合、オンライン受講料を差し引いた額を返金します。
            </li>
          </ul>
          <p>2.変更</p>
          <p>
            返金条件に合致する講座を他の講座に変更する場合、直接変更はできず、返金と同じ条件で返金を受けた後、再申し込みし、またはサポートセンターまでお問い合わせする必要があります。
          </p>
          <p>3.会社都合によるサービス停止</p>
          <p>
            会社が、やむを得ない事由によりサービスを継続することができない場合は、これを会員に告知の上、可能な限り速やかに返金します。
          </p>
          <p>4.利用停止および強制退会</p>
          <p>
            会員が、関係法令および本規約の規定に違反し、会社から利用停止および強制退会された場合には、本条の返金規約が適用されません。
          </p>
          <p>
            ②会社が、会員に対して無料で配布したクーポンおよびチャージ率を超えたクーポンの金額については返金ができません。
          </p>
          <p>③第17条の規定により利用が制限される場合、料金は返金されません。</p>
          <p>
            <strong>第16条[著作権]</strong>
          </p>
          <p>
            ①会員が、サービス内に掲示した投稿の著作権は、その投稿の著作者に帰属します。ただし、会員は、サービスを通じて投稿を提出、掲示することにより、会社に対して投稿の利用、コピー、複製、処理、脚色、改変、開示、送信、掲載、または配布することができるように使用を許諾する著作使用権（二次的著作物の使用権を与える権利を含む）を与えたものとみなします。本著作使用権は、会員がサービスの利用を中止し、または公式サイトを退会した後もなお存続します。ただし、会社は、会員が提供したコンテンツにアクセスし、またはこれを削除する方法を提供することがあります。
          </p>
          <p>
            ②会員は、会社が提供する「サービス」を利用して得た情報を、会社の事前承諾なしに録画、複製、送信、出版、配布、放送その他の方法により営利、非営利の目的で利用し、または第三者に利用させてはいけません。
          </p>
          <p>
            ③会社が提供するコンテンツは、著作権法に基づき保護されており、一部あるいは全体の内容を会社の同意なしに無断で複製、配布、掲示する行為は、著作権法に反します。
          </p>
          <p>④会社の著作物の著作権を侵害した場合は、損害賠償を請求することがあります。</p>
          <p>
            <strong>第17条[不正利用の禁止およびブロック]</strong>
          </p>
          <p>①不正利用の識別方法およびブロック</p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              会社は、会員のサービス利用中に収集・確認されたIP情報、MACアドレス等の資料をもとに、サーバを通じて不正利用であるかどうかを分類・確認するものとします。
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              会社は、会員がサービス利用中に複製プログラムを実行し、または同じIDで同時アクセスを行う場合、サービスへのアクセスを強制的に終了させるものとします。
            </li>
          </ol>
          <p>
            <strong>第4章 損害賠償およびその他の事項</strong>
          </p>
          <p>
            <strong>第18条[損害賠償]</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ①会社が提供するすべてのサービスに関して、会社の責に帰すべき事由により利用者に損害が生じた場合、会社はその損害を賠償するものとします。ただし、会社は、無料サービスの障害等による損害については賠償しません。
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ②会員が、本規約の規定に違反した行為により会社および第三者に損害を与えた場合、または会員の責に帰すべき事由により会社および第三者に損害を与えた場合、会員はその損害を賠償しなければなりません。
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>③その他の損害賠償の方法、手続き等は関係法令に従うものとします。</li>
          </ul>
          <p>
            <strong>第19条[免責条項]</strong>
          </p>
          <p>
            ①会社は、天変地異、戦争その他これに準ずる不可抗力によりサービスを提供することができない場合には、サービス提供に関する責任を負いません。
          </p>
          <p>
            ②会社は、基幹通信事業者が電気通信サービスを停止し、または正常に提供しなかったことにより生じた損害については責任を負いません。
          </p>
          <p>
            ③会社は、サービス用設備のメンテナンス、交換、定期診断、工事等、やむを得ない事由により生じた損害については責任を負いません。
          </p>
          <p>④会社は、会員の責に帰すべき事由によるサービス利用の障害または損害については責任を負いません。</p>
          <p>
            ⑤会社は、利用者のコンピュータエラーにより損害が生じた場合、または会員が身元情報および電子メールアドレスを誤って記載し損害が生じた場合は責任を負いません。
          </p>
          <p>
            ⑥会社は、会員がサービスを利用して期待する利益を得られなかったこと、または損失したことについて責任を負いません。
          </p>
          <p>
            ⑦会社は、会員がサービスを利用して得た資料により生じた損害について責任を負いません。また、会社は、会員がサービスを利用して他の会員から受けた精神的な被害について補償責任を負いません。
          </p>
          <p>⑧会社は、会員がサービスに掲載した各種情報、資料、事実の信頼性、正確性等の内容について責任を負いません。</p>
          <p>
            ⑨会社は、利用者間および利用者と第三者間において、サービスにより生じた紛争について介入する義務を負わないとともに、これによる損害を賠償する責任も負いません。
          </p>
          <p>⑩会社が会員に無料で提供するサービスの利用については、いかなる損害も責任を負いません</p>
          <p>
            ⑪会員が掲載する資料のうち、違法または善良な風俗その他社会秩序に反する内容について、会社は一切の責任を負いません。公式サイトに会員が掲載するいかなる情報およびこれに関する広告その他の情報、または提案の結果として取得することになるいかなる財貨および役務についても、その価値、用途、広告その他の関係法令の遵守等について、一切の保証を行いません。
          </p>
          <p>
            <strong>第20条[紛争解決]</strong>
          </p>
          <p>
            ①会社は、紛争が生じた場合、利用者が提起する正当な意見またはクレームを反映し、適切かつ迅速な措置を講ずるものとします。ただし、迅速な処理が困難な場合、会社は利用者に対して、その事由および処理日程を通知するものとします。
          </p>
          <p>②会社と会員間の紛争は、大韓民国法を準拠法とするものとします。</p>
          <p>
            ③会社と利用者間に生じた電子商取引紛争に関して、利用者の被害救済申請がある場合には、公正取引委員会または電子取引紛争調整委員会、市・道知事が依頼する紛争調整機関の調整に従うことがあります。
          </p>
          <p>
            <strong>第21条[商品クーポンおよびポイントの使用]</strong>
          </p>
          <p>
            ①会社は、財貨等を購入する利用者に対して、指定の商品を購入する場合、一定額または一定割合の割引を受けることができるクーポンを発行することがあります。
          </p>
          <p>
            ②会社が別途に明示した場合を除き、これを他人に譲渡することができないとともに、いかなる場合であっても、他人に実質的に売買することはできません。
          </p>
          <p>
            ③商品クーポンは現金に交換できないとともに、表示された有効期間が満了し、または利用契約が終了すると消滅します。
          </p>
          <p>
            ④商品クーポンは、一部の品目または金額により使用が制限されることがあります。また、会員を退会した場合、商品クーポンは消滅します。
          </p>
          <p>
            <strong>[附則]</strong>
          </p>
          <p>本規約は2021年9月27日から適用されます。既存規約は本規約に代替されます。</p>
          <p>改正日以前に登録した利用者は、改正後の利用規約の適用を受けます。</p>
        </div>
      </article>
    </div>
  );
}
