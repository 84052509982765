import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import { FallingLines } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import template from '../../Assets/Images/certificate-template.jpg';
import Meta from '../../Commons/Components/Meta';
import { GET } from '../../Commons/Utils/fetch';

export default function Certificate() {
  const { code } = useParams();
  const canvasRef = useRef(null);
  const [certificate, setCertificate] = useState(null);
  const [metaInfo, setMetaInfo] = useState(null);

  const [isValid, setIsValid] = useState(true);
  useEffect(async () => {
    if (code) {
      const { success, data } = await GET({
        url: `/auth/certificate/${code}`,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      if (success) {
        setMetaInfo(data);
        setCertificate(data);
      } else {
        await setIsValid(false);
        console.error(data);
      }
    }
  }, [code]);

  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    const canvas = await html2canvas(document.querySelector('#certificate'));
    await setImage(canvas.toDataURL('image/png'));
  }, [certificate]);

  return (
    <div className="certificate-wrapper">
      {metaInfo && <Meta metaInfo={metaInfo} />}
      <div style={{ height: 0, width: 0, overflow: 'hidden' }}>
        {certificate && (
          <div id="certificate">
            <div className="container">
              <div className="lecture-user-info">
                <div className="certificate-lecture-info">
                  <strong>{certificate?.lectureNameWithRound}</strong>
                  <p>
                    ({dayjs(certificate?.lectureStartDate).format('YYYY.MM.DD')}-
                    {dayjs(certificate?.lectureEndDate).format('YYYY.MM.DD')})
                  </p>
                </div>
                <div className="certificate-student-name">
                  <strong>{certificate?.userName}</strong>
                </div>
              </div>
              <div className="certificate-date">
                <strong>{dayjs(certificate?.certificatedDate).format('YYYY.MM.DD')}</strong>
              </div>
              <QRCode
                id="certificate-qrcode"
                value={`${process.env.REACT_APP_HOST}/certificate/${code}`}
                size={60}
                bgColor="transparent"
                fgColor="#000000"
                level="L"
                renderAs="png"
              />
              <strong id="certificate-code">{code}</strong>
            </div>
            <style jsx="true">{`
              #certificate {
                width: 1080px;
                height: 1527px;
                background-image: url(${template});
                background-size: cover;
                background-repeat: no-repeat;
                overflow: hidden;
                position: relative;
              }

              .container {
                height: 100%;
                position: relative;
              }

              .lecture-user-info {
                display: flex;
                justify-content: space-between;
                padding: 510px 130px 0 100px;
                column-gap: 16px;
              }

              .certificate-lecture-info {
                font-size: 1.55rem;
                max-width: 440px;
              }

              .certificate-student-name {
                font-size: 2rem;
              }

              .certificate-date {
                font-size: 1.55rem;
                position: absolute;
                bottom: 280px;
                left: 124px;
              }

              #certificate-code {
                position: absolute;
                bottom: 0;
                right: 40px;
                font-size: 0.8rem;
                margin: 0;
                padding: 0;
              }

              #certificate-qrcode {
                position: absolute;
                bottom: 10px;
                left: 10px;
              }
            `}</style>
          </div>
        )}
      </div>
      {isValid ? (
        <>
          {certificate && (
            <img
              src={image}
              alt={certificate.title}
              className={`certification-image ${!isLoading ? 'active' : ''}`}
              onLoad={() => setIsLoading(false)}
            />
          )}
          <div className={`certificate-loader ${isLoading ? '' : 'hidden'}`}>
            <FallingLines color="#ffffff" width="100" visible={true} ariaLabel="falling-lines-loading" />
          </div>
        </>
      ) : (
        <div className="certificate-invalid">
          <h3>잘못된 접근입니다.</h3>
          <style jsx="true">{`
            .certificate-invalid {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 400px;
              background-color: #fff;
            }

            h3 {
              font-size: 1.5rem;
              margin: 0;
              padding: 0;
            }
          `}</style>
        </div>
      )}
      <style jsx="true">{`
        .certificate-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          min-height: 100vh;
          background-color: #2b2b2b;
          font-size: 0;
        }

        .certification-image {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .certification-image.active {
          opacity: 1;
          width: auto;
          height: auto;
          transition: opacity 0.3s ease-in-out;
        }
      `}</style>
    </div>
  );
}
