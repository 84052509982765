import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import ResetIcon from '../../Assets/Images/button-refresh.png';
import IconAlbum from '../../Assets/Images/icon-album.png';
import CancelIcon from '../../Assets/Images/icon-cancel-white.png';

import DropdownDownIcon from '../../Assets/Images/icon-dropdown-down.png';

import Loading from '../../Commons/Components/Loading';
import Meta from '../../Commons/Components/Meta';
import Pagination from '../../Commons/Components/Pagination';
import RecordsFilterModal from '../../Commons/Components/RecordsFilterModal';
import Main from '../../Commons/Layouts/Main';
import { GET } from '../../Commons/Utils/fetch';
import Record from '../KpopDBDetail/components/record';
import FilterOptionList from './components/filterOptionList';

import SortOptionList from './components/SortOptionList';
import { sortOptionConstant } from './constant';
import './index.scss';

const KpopDB = () => {
  const eje_lang = localStorage.getItem('eje_lang');
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const location = useLocation();
  const history = useHistory();
  let {
    page = 1,
    sort = 'publishedDate',
    order = 'desc',
    agencies = '',
    artists = '',
  } = useMemo(() => queryString.parse(location.search), [location]);
  agencies === 'undefined' &&
    history.replace(`/albumcredit-db?page=${page}&sort=${sort}&order=${order}&agencies=&artists=${artists}`);
  artists === 'undefined' &&
    history.replace(`/albumcredit-db?page=${page}&sort=${sort}&order=${order}&agencies=${agencies}&artists=`);
  const [isLoading, setIsLoading] = useState(true);
  const params = useMemo(
    () => ({
      page: parseInt(page) || 1,
      limit: 20,
      sort,
      order,
      agencies,
      artists,
    }),
    [page, sort, agencies, artists],
  );
  const [agencyList, setAgencyList] = useState([]);
  const [artistList, setArtistList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const agencyList = await GET({
        url: '/auth/agencies',
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      agencyList?.length > 0 && setAgencyList(agencyList);
      // if (response.status === 200) setAgencyList(agencyList.data);
      const artistList = await GET({
        url: '/auth/artists',
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      artistList?.length > 0 && setArtistList(artistList);
      // if (response.status === 200) setAgencyList(artistList.data);
    };
    fetchData();
  }, []);
  const [isShowSortOptions, setIsShowSortOptions] = useState(false);
  const [isShowAgencyFilterOptions, setIsShowAgencyFilterOptions] = useState(false);
  const [isShowArtistFilterOptions, setIsShowArtistFilterOptions] = useState(false);
  const currentSort = useMemo(
    () => sortOptionConstant.find((option) => option.value === `${sort}_${order}`),
    [sort, order],
  );

  const onClickIsShowSortOptions = useCallback(() => {
    setIsShowSortOptions(true);
  }, []);

  const onClickSetSortOption = useCallback(
    (e, option) => {
      e.stopPropagation();
      setIsShowSortOptions(false);
      const [sort, order] = option.value.split('_');
      if (params.sort !== sort || params.order !== order) params.page = 1;
      params.sort = sort;
      params.order = order;
      history.push(
        `${location.pathname}?page=${params.page}&sort=${sort}&order=${order}&agencies=${params.agencies}&artists=${params.artists}`,
      );
    },
    [location, history, page, params],
  );
  const onClickSortOptionOutside = useCallback(
    (e) => {
      if (!isShowSortOptions) return;
      setIsShowSortOptions(false);
    },
    [isShowSortOptions],
  );

  const onClickIsShowAgencyFilterOptions = useCallback(() => {
    setCurrentFilterGroup('agency');
    isMobile ? setIsShowMobileFilterModal(true) : setIsShowAgencyFilterOptions(true);
  }, []);

  const onClickSetAgencyFilterOption = async (e, option) => {
    e.stopPropagation();
    const selectedAgencies = !agencies ? [] : agencies.split(',').map(Number);
    if (selectedAgencies.includes(option.value) || !option.value)
      selectedAgencies.splice(selectedAgencies.indexOf(option.value), 1);
    else selectedAgencies.push(option.value);
    const paramAgencies =
      selectedAgencies.length > 1
        ? selectedAgencies.join(',')
        : selectedAgencies.length === 1
        ? selectedAgencies[0]
        : '';
    await history.push(
      `${location.pathname}?page=${params.page}&sort=${params.sort}&order=${params.order}&agencies=${paramAgencies}&artists=${params.artists}`,
    );
  };
  const onClickAgencyFilterOptionOutside = useCallback(
    (e) => {
      if (!isShowAgencyFilterOptions) return;
      setIsShowAgencyFilterOptions(false);
    },
    [isShowAgencyFilterOptions],
  );

  const onClickIsShowArtistFilterOptions = useCallback(() => {
    setCurrentFilterGroup('artist');
    isMobile ? setIsShowMobileFilterModal(true) : setIsShowArtistFilterOptions(true);
  }, [isMobile]);

  const onClickSetArtistFilterOption = (e, option) => {
    e.stopPropagation();
    const selectedArtists = !artists ? [] : artists.split(',').map(Number);
    if (selectedArtists.includes(option.value) || !option.value)
      selectedArtists.splice(selectedArtists.indexOf(option.value), 1);
    else selectedArtists.push(option.value);
    const paramArtists = selectedArtists.length > 1 ? selectedArtists.join(',') : selectedArtists[0];
    history.push(
      `${location.pathname}?page=${params.page}&sort=${params.sort}&order=${params.order}&agencies=${params.agencies}&artists=${paramArtists}`,
    );
  };
  const onClickArtistFilterOptionOutside = useCallback(
    (e) => {
      if (!isShowArtistFilterOptions) return;
      setIsShowArtistFilterOptions(false);
    },
    [isShowArtistFilterOptions],
  );

  const [kpopList, setKpopList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0);
  const [emptyList, setEmptyList] = useState([]);
  const getDB = useCallback(async () => {
    if (params?.page && params.page < 0) {
      history.replace(`${location.pathname}?page=1&sort=${sort}`);
    } else {
      try {
        setIsLoading(true);
        const { rows, count, total } = await GET({
          url: '/auth/records',
          params,
          header: {
            EJE_API_KEY: 'auth',
          },
        });
        if (rows) {
          if (params?.page && (params.page < 1 || (count > 0 && params.page > Math.ceil(count / params.limit)))) {
            history.replace(`${location.pathname}?page=${Math.ceil(count / params.limit)}&sort=${sort}`);
          }
          setIsLoading(false);
          setKpopList(rows);
          setTotalCount(total);
          setRecordsCount(count);
          const value = isMobile ? 2 : 4;
          if (rows.length % 4 > 0) {
            setEmptyList(Array(value - (rows.length % value)).fill(0));
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [history, location.pathname, params, sort]);

  useEffect(() => getDB(), [currentSort, getDB, params]);

  // Paginate
  const onClickPaginate = useCallback(
    (number) => {
      history.push(
        `${location.pathname}?page=${number}&sort=${sort}&order=${order}&agencies=${params.agencies}&artists=${params.artists}`,
      );
      window.scrollTo(0, 0);
    },
    [location, history, sort],
  );

  const resetFilters = useCallback(() => {
    history.push(`${location.pathname}?page=1&sort=${sort}&order=${order}`);
  }, [location, history, sort, order]);

  const deleteFilterItem = (filterType, filterValue) => {
    const filterList = filterType === 'agency' ? agencies.split(',') : artists.split(',');
    const filterListArrayIndex = filterList.indexOf(filterValue);
    if (filterListArrayIndex > -1) {
      filterList.splice(filterListArrayIndex, 1);
    }
    const filterListString = filterList.length > 1 ? filterList.join(',') : filterList[0];
    if (filterType === 'agency') {
      history.push(
        `${location.pathname}?page=${params.page}&sort=${params.sort}&order=${params.order}&agencies=${filterListString}&artists=${params.artists}`,
      );
    } else {
      history.push(
        `${location.pathname}?page=${params.page}&sort=${params.sort}&order=${params.order}&agencies=${params.agencies}&artists=${filterListString}`,
      );
    }
  };

  const [isShowMobileFilterModal, setIsShowMobileFilterModal] = useState(false);
  const [currentFilterGroup, setCurrentFilterGroup] = useState('artist');
  const onClickCloseMobileFilterModal = useCallback(() => {
    setIsShowMobileFilterModal(false);
  }, []);

  const selectFilter = (currentFilterGroup, targetId) => {
    switch (currentFilterGroup) {
      case 'agency':
        const arrAgencies = params.agencies.length > 0 ? params.agencies.split(',') : [];
        if (arrAgencies.includes(targetId)) {
          arrAgencies.splice(arrAgencies.indexOf(targetId), 1);
          params.agencies = arrAgencies.join(',');
        } else {
          arrAgencies.push(targetId);
          params.agencies = arrAgencies.join(',');
        }
        break;
      case 'artist':
        const arrArtists = params.artists.length > 0 ? params.artists.split(',') : [];
        if (arrArtists.includes(targetId)) {
          arrArtists.splice(arrArtists.indexOf(targetId), 1);
          params.artists = arrArtists.join(',');
        } else {
          arrArtists.push(targetId);
          params.artists = arrArtists.join(',');
        }
        break;
      default:
        break;
    }
  };
  const changeFilterGroup = (currentFilterGroup) => {
    setCurrentFilterGroup(currentFilterGroup);
  };
  const mobileApplyFilter = (params) => {
    setIsShowMobileFilterModal(false);
    history.push(
      `${location.pathname}?page=${params.page}&sort=${params.sort}&order=${params.order}&agencies=${params.agencies}&artists=${params.artists}`,
    );
  };
  const metaInfo = {
    title: 'KPOP 앨범 크레딧 한 번에 알 수 있는 곳 | 엔터잡에듀',
    description:
      '앨범 크레딧 정보 무료로 제공하는 유일한 곳! KPOP 의 세계화와 성장을 선도하는 이들의 정보를 확인해보세요.',
    keywords:
      '앨범크레딧, album credit, KPOP, 아이돌, idol, A&R, producer, 마케팅, 팬마케팅, 콘텐츠마케팅, artist management, 홍보, public relations, media, mv, music video, choreography, stylist, hair, makeup, design, photographer, mixing engineer, recording engineer, SM, JYP, HYBE, 하이브, YG, 카카오, 포트폴리오, 기획안',
  };
  return (
    <Main>
      <Meta metaInfo={metaInfo} />
      <div className="makers_wrap">
        <div className="inner_wrap">
          <div className="header_wrap">
            <h2 className="h-10 mb-1">
              Album Credit DB
              <img
                src={IconAlbum}
                alt="album-credit-header"
                className="aspect-square w-10 ml-4 -mt-1 inline-block align-middle"
              />
            </h2>
            <p>
              <FormattedMessage
                id="국내에서 발매된 {totalCount}개 앨범 크레딧 정보 집합소!{br}KPOP을 세계로 알리고 있는 Staff 정보를 확인해보세요."
                values={{
                  br: <br />,
                  totalCount,
                }}
              />
            </p>
          </div>
          <div className="search_makers_wrap">
            <div className="filter_select_wrap">
              <div className="filter_select_box_wrap">
                <div className={`filter_selected_option`} onClick={onClickIsShowArtistFilterOptions}>
                  <span>
                    <FormattedMessage id="아티스트" />
                    <img src={DropdownDownIcon} alt="Open select option modal" style={{ marginLeft: '8px' }} />
                  </span>
                  {isShowArtistFilterOptions && (
                    <FilterOptionList
                      filterType="artist"
                      filterOptionConstant={artistList}
                      onClickFilterOptionOutside={onClickArtistFilterOptionOutside}
                      onClickSetFilterOption={onClickSetArtistFilterOption}
                      params={params}
                    />
                  )}
                </div>
              </div>
              <div className="filter_select_box_wrap">
                <div className={`filter_selected_option`} onClick={onClickIsShowAgencyFilterOptions}>
                  <span>
                    <FormattedMessage id="기획사" />
                    <img src={DropdownDownIcon} alt="Open select option modal" style={{ marginLeft: '8px' }} />
                  </span>
                  {isShowAgencyFilterOptions && (
                    <FilterOptionList
                      filterType="agency"
                      filterOptionConstant={agencyList}
                      onClickFilterOptionOutside={onClickAgencyFilterOptionOutside}
                      onClickSetFilterOption={onClickSetAgencyFilterOption}
                      params={params}
                    />
                  )}
                </div>
              </div>
            </div>
            {((!!artists && artists !== '') || (!!agencies && agencies !== '')) && (
              <div className="selected_options">
                <ul>
                  {!!artists &&
                    artists.split(',').map((artist, index) => (
                      <li key={`selected_viewer_${artist}_${index}`}>
                        <span title={artistList.find((item) => item.id === +artist)?.text}>
                          {artistList.find((item) => item.id === +artist)?.text}
                        </span>
                        <span className="delete_option_btn" onClick={() => deleteFilterItem('artist', artist)}>
                          <img src={CancelIcon} alt="delete_selected_option" />
                        </span>
                      </li>
                    ))}
                  {!!agencies &&
                    agencies.split(',').map((agency, index) => (
                      <li
                        key={`selected_viewer_${agency}_${index}`}
                        title={agencyList.find((item) => item.id === +agency)?.text}
                        onClick={() => deleteFilterItem('agency', agency)}
                      >
                        <span>{agencyList.find((item) => item.id === +agency)?.text}</span>
                        <span className="delete_option_btn">
                          <img src={CancelIcon} alt="delete_selected_option" />
                        </span>
                      </li>
                    ))}
                </ul>

                <span className="reset_btn" onClick={resetFilters}>
                  <FormattedMessage id="RESET" />
                  <img src={ResetIcon} alt="reset_selected_options" />
                </span>
              </div>
            )}
          </div>
          <div className="makers_content">
            <div className="sort_select_wrap">
              <div className="sort_select_box_wrap">
                <div className="total_count">
                  <FormattedMessage id="전체 {totalCount}개" values={{ totalCount: recordsCount }} />
                </div>
                <div
                  className={`sort_selected_option${eje_lang !== 'ko' && eje_lang !== 'ja' ? '' : ''}`}
                  onClick={sortOptionConstant.length > 1 ? onClickIsShowSortOptions : null}
                >
                  <span>
                    <FormattedMessage id={currentSort.key} />
                    <img src={DropdownDownIcon} alt="Open select option modal" style={{ marginLeft: '8px' }} />
                  </span>
                  {isShowSortOptions && (
                    <SortOptionList
                      sortOptionConstant={sortOptionConstant}
                      onClickSortOptionOutside={onClickSortOptionOutside}
                      onClickSetSortOption={onClickSetSortOption}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="makers_list_wrap">
              {isLoading ? (
                <Loading />
              ) : (
                <div className="makers_list">
                  {kpopList?.map((record, index) => {
                    record.artistName = record.Record_Artists.map(
                      (artist, index) =>
                        artist.FK_Artist.name + (record.Record_Artists.length === index + 1 ? '' : ', '),
                    );
                    return <Record data={record} key={`record-${index}`} />;
                  })}
                  {emptyList.map((_, index) => (
                    <Record isEmpty={true} key={`empty-${index}`} />
                  ))}
                </div>
              )}
            </div>
            {recordsCount > 20 && (
              <Pagination
                total={recordsCount}
                limit={params.limit}
                currentPage={parseInt(page)}
                paginate={onClickPaginate}
              />
            )}
          </div>
        </div>
      </div>
      {isMobile && isShowMobileFilterModal && (
        <>
          <div className="modal_outside" onClick={() => setIsShowMobileFilterModal(false)} />
          <RecordsFilterModal
            params={params}
            pathname={location.pathname}
            artistList={artistList}
            agencyList={agencyList}
            selectFilter={selectFilter}
            currentFilterGroup={currentFilterGroup}
            changeFilterGroup={changeFilterGroup}
            applyFilters={mobileApplyFilter}
            onClickClose={() => setIsShowMobileFilterModal(false)}
            total={recordsCount}
          />
        </>
      )}
    </Main>
  );
};

export default KpopDB;
