import dropdownUp from '../../Assets/Images/icon-dropdown-up.png';
import dropdownDown from '../../Assets/Images/icon-dropdown-down.png';
import dropdownLeft from '../../Assets/Images/icon-dropdown-left.png';
import dropdownRight from '../../Assets/Images/icon-dropdown-right.png';
import download from '../../Assets/Images/icon_download.png';

const Images = {
  dropdownUp,
  dropdownDown,
  dropdownLeft,
  dropdownRight,
  download,
};

export default Images;
