import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
// import { useMediaQuery } from 'react-responsive';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import Diagonal1 from '../../Assets/Images/bg-diagonal-1.png';
import Diagonal2 from '../../Assets/Images/bg-diagonal-2.png';
import Diagonal3 from '../../Assets/Images/bg-diagonal-3.png';
import DropdownLeftIcon from '../../Assets/Images/icon-dropdown-left.png';
import Footer from '../../Commons/Components/Footer';
import Header from '../../Commons/Components/Header';
import Loading from '../../Commons/Components/Loading';
import { LECTURE, TRANSACTION } from '../../Commons/Utils/constant';

import { checkIsMobile } from '../../Commons/Utils/deviceHelper';
import { GET } from '../../Commons/Utils/fetch';
import ReactPixel from '../../Commons/Utils/pixel';
import './index.scss';

const Complete = () => {
  const history = useHistory();
  const [transaction, setTransaction] = useState(undefined);
  const [purchaseData, setPurchaseData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(undefined);
  const [vbankInfo, setVbankInfo] = useState(undefined);
  const utm = localStorage.getItem('utm');
  useEffect(() => {
    if (utm && !window.location.search.includes('utm')) {
      history.replace(`/payment/complete${JSON.parse(utm).string}&${window.location.search.substring(1)}`);
    }
  }, [utm]);

  const queryInfo = queryString.parse(window.location.search);
  let { merchant_uid, error_msg, host } = queryInfo;
  if (!merchant_uid) {
    history.goBack();
  }
  const isMobile = checkIsMobile();
  const { READY, PAID, FAILED, PENDING } = TRANSACTION.TYPE.STATUS;

  const callGtag = (e = 'event', actionType = 'purchase') => {
    gtag(e, actionType, {
      transaction_id: merchant_uid,
      affiliation: 'EJE',
      value: purchaseData.value,
      currency: purchaseData.currency,
      items: [
        {
          id: transaction.class_id,
          name: transaction.class_title,
          price: purchaseData.value,
          brand: 'EJE',
          category: 'Class',
          variant: transaction.class_listen_type,
          list_name: transaction.class_listen_type,
          price_currency: purchaseData.currency,
        },
      ],
    });
  };

  useEffect(() => {
    // iamport mobile redirect이 동기화 되지 않는 문제를 최소하기 위해 1초 지연시킵니다.
    setTimeout(() => {
      getTransaction();
    }, 1000);

    async function getTransaction() {
      try {
        const token = localStorage.getItem('eje_token');

        const { transaction } = await GET({
          url: '/payment/transaction',
          params: {
            merchant_uid,
          },
          header: {
            EJE_API_KEY: token,
          },
        });

        const isPaypal = transaction.pg === 'paypal';
        const _status = isPaypal ? transaction.status : isMobile ? transaction.status : queryInfo.status;

        setStatus(_status);

        const _vankInfo = isMobile ? transaction.vbankInfo : queryInfo.vbankInfo;
        if (_vankInfo) setVbankInfo(JSON.parse(_vankInfo));

        setTransaction({
          ...transaction,
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  useEffect(() => {
    if (transaction) {
      setPurchaseData({
        currency: transaction.pg === 'paypal' ? 'USD' : 'KRW',
        value: transaction.paid_amount,
        contents: [
          {
            id: transaction.class_id,
            content_name: transaction.class_title,
            quantity: 1,
          },
        ],
        content_type: 'product',
      });
    }
  }, [transaction]);

  const formatCurrency = (value = 0) => {
    const isForeigner = transaction && transaction.class_listen_type === 'foreigner';
    return (
      <>
        {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        {isForeigner ? '$' : <FormattedMessage id="CURRENCY_KOR" />}
      </>
    );
  };

  const onClickHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const diagonalImageElements = (
    <>
      <img className="complete_wrap-diagonal1" src={Diagonal1} alt="diagonal" />
      <img className="complete_wrap-diagonal2" src={Diagonal2} alt="diagonal" />
      <img className="complete_wrap-diagonal3" src={Diagonal3} alt="diagonal" />
    </>
  );

  let element = <></>;
  if (!isLoading && status) {
    switch (status) {
      case PAID:
        ReactPixel.track('Purchase', purchaseData);
        callGtag('event', 'purchase');

        element = (
          <div className="payment_complete_wrap">
            {diagonalImageElements}
            <div className="inner_wrap">
              <h3 className="status_title">
                {host !== 'gov' ? <FormattedMessage id="PAYMENT_COMPLETED" /> : '신청 완료'}
              </h3>
              {host !== 'gov' && (
                <p className="order_number">
                  <strong>
                    <FormattedMessage id="ORDER_NUMBER" />
                  </strong>
                  <span>{merchant_uid}</span>
                </p>
              )}

              <div className="payment_message message">
                <p>
                  {host !== 'gov' ? (
                    <FormattedMessage id="PAYMENT_SUCCESSFULLY_COMPLETED" />
                  ) : (
                    '수강신청이 성공적으로 완료되었습니다.'
                  )}
                </p>
                <p>
                  {transaction.survey_pre ? (
                    '사전 설문조사를 진행하고 내 강의실에서 강의정보를 확인해보세요.'
                  ) : host !== 'gov' ? (
                    <FormattedMessage id="CHECK_MY_LECTURE_ROOM" />
                  ) : (
                    '내 강의실에서 강의정보를 확인해보세요!'
                  )}
                </p>
              </div>

              {transaction.survey_pre && (
                <span className="link_to_lecture link_to" style={{ marginBottom: '1rem' }}>
                  <a href={transaction.survey_pre} target="_blank" onClick={() => history.push('/my/lectures')}>
                    사전 설문조사 진행하기
                  </a>
                </span>
              )}

              <span className="link_to_lecture link_to">
                <Link
                  to={{
                    pathname: '/my/lectures',
                    state: {
                      currentTab: Object.values(LECTURE.TYPE.ONLINE).includes(transaction.class_listen_type)
                        ? 'online'
                        : 'offline',
                    },
                  }}
                >
                  <FormattedMessage id="COMMON_LINK_TO_MY_LECTURES" />
                </Link>
              </span>
            </div>
          </div>
        );
        break;
      case READY:
        if (!vbankInfo) break;

        callGtag('event', 'begin_checkout');
        ReactPixel.track('InitiateCheckout', purchaseData);

        element = (
          <div className="order_complete_wrap ">
            {diagonalImageElements}

            <div className="inner_wrap">
              {/* 주문 완료 */}
              <h3 className="status_title">
                <FormattedMessage id="ORDER_COMPLETED" />
              </h3>
              <p className="order_number">
                <strong>
                  <FormattedMessage id="ORDER_NUMBER" />
                </strong>
                <span>{merchant_uid}</span>
              </p>
              <div className="deposit_info_wrap">
                <h4>
                  <FormattedMessage id="DEPOSIT_INFORMATION" />
                </h4>
                <div className="transaction_info">
                  <p style={{ marginBottom: '5px' }}>
                    <strong className="label">
                      <FormattedMessage id="DEPOSIT_ACCOUNT" />
                    </strong>
                    <span>{`${vbankInfo.vbank_num} ${vbankInfo.vbank_name}`}</span>
                  </p>
                  <p>
                    <strong className="label">
                      <FormattedMessage id="AMOUNT" />
                    </strong>
                    <span>{formatCurrency(transaction.paid_amount)}</span>
                  </p>
                </div>
              </div>

              {/* 결제 완료 */}
              <div className="order_message message">
                <p>
                  <FormattedMessage
                    id="ORDER_COMPLETED_MESSAGE"
                    values={{
                      deadline: (
                        <strong>
                          <FormattedMessage id="ORDER_COMPLETED_MESSAGE_DEADLINE" />
                        </strong>
                      ),
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage id="ORDER_WILL_BE_CANCELED" />
                </p>
              </div>

              <span className="link_to_main link_to">
                <Link to="/">
                  <FormattedMessage id="COMMON_LINK_TO_MAIN_2" />
                </Link>
              </span>
            </div>
          </div>
        );
        break;
      case FAILED:
      case PENDING:
        element = (
          <div className="payment_complete_wrap">
            {diagonalImageElements}
            <div className="inner_wrap">
              <h3 className="status_title">
                <FormattedMessage id="PAYMENT_FAIL" />
              </h3>
              <p className="order_number">
                <strong>
                  <FormattedMessage id="ORDER_NUMBER" />
                </strong>
                <span>{merchant_uid}</span>
              </p>
              {error_msg && (
                <div className="payment_message message">
                  <p>{error_msg}</p>
                </div>
              )}

              <span className="link_to_lecture link_to">
                <Link to="/">
                  <FormattedMessage id="COMMON_LINK_TO_MAIN_2" />
                </Link>
              </span>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
  }

  return (
    <>
      {!isMobile ? (
        <Header />
      ) : (
        <div className="mobile_header">
          <img src={DropdownLeftIcon} alt="go_back" onClick={onClickHistoryBack} />
          <FormattedMessage id={'PAYMENT'} />
        </div>
      )}
      {isLoading ? (
        <div style={{ height: '300px' }}>
          <Loading />
        </div>
      ) : (
        <div className="complete_wrap">{element}</div>
      )}

      <Footer />
    </>
  );
};

export default Complete;
