import '../index.scss';

function Information() {
  return (
    <div className="payment_term">
      <h1 className="page-title">個人情報の第三者提供に関する同意</h1>
      <div className="page-body">
        <p>
          会社は、利用者の個人情報を本プライバシーポリシーで明示した範囲内で利用し、利用者の事前の同意なしで同範囲を超えて利用し、または利用者の個人情報を第三者に提供しません。
        </p>
        <p>
          ただし、関係法令に基づき、または捜査目的で法令で定められた手続きおよび方法により捜査機関等に対して、個人情報を提供しなければならない場合は例外とします。会社のサービス履行のために、個人情報を第三者に提供する場合は以下の通りです。
        </p>
        <p>
          <a href="https://www.notion.so/13b9a699b6ad4f3caf9b0ec37b3f0842">タイトルなし</a>
        </p>
      </div>
    </div>
  );
}

export default Information;
