import queryString from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '../../../Assets/Images/ico-search-black.png';
import DropdownDownIcon from '../../../Assets/Images/icon-dropdown-down.png';
import DropdownUpIcon from '../../../Assets/Images/icon-dropdown-up.png';
import UserIcon from '../../../Assets/Images/icon-user.png';
import Logo from '../../../Assets/Images/logo-word-black.png';
import ProfileDefaultImage from '../../../Assets/Images/profile-custom.png';
import { GET } from '../../Utils/fetch';
import LocaleSelect from '../LocaleSelect';
import LoggedInMenu from './components/LoggedInMenu';
import './index.scss';
import GnbDropdownMenu from './components/GnbDropdownMenu';
import { setGnb } from '../../Store/Actions/gnb';

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { pathname } = useLocation();
  const history = useHistory();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userInfo = useSelector((state) => state.user.userInfo);
  const gnbList = useSelector((state) => state.gnb.gnbList);
  const [isShowBottomNav, setIsShowBottomNav] = useState(true);
  const [isShowLoggedInUserMenu, setIsShowLoggedInUserMenu] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    if (
      isMobile &&
      !(
        pathname === '/' ||
        pathname === '/online' ||
        pathname === '/offline' ||
        pathname === '/foreigner' ||
        pathname === '/albumcredit-db' ||
        pathname === '/gov-class' ||
        pathname.includes('/event') ||
        pathname.includes('/notice')
      )
    ) {
      setIsShowBottomNav(false);
    }
    if (isMobile && isShowSearch) {
      setIsShowSearch(false);
    }
  }, [isMobile, pathname]);

  const scrollTo = useCallback((element, target) => {
    const targetId = target.replace('/', '');
    $(document.querySelector(element)).scrollLeft(
      targetId === 'foreigner' ? 180 : targetId === 'albumcredit-db' ? 300 : 0,
    );
  }, []);

  useEffect(() => {
    if (
      isMobile &&
      (pathname === '/' ||
        pathname === '/online' ||
        pathname === '/offline' ||
        pathname === '/foreigner' ||
        pathname === '/albumcredit-db' ||
        pathname.includes('/event') ||
        pathname.includes('/notice'))
    ) {
      setIsFixed(true);
      scrollTo('.header_bottom_nav', pathname);
    }
  }, [isMobile, pathname]);

  const getGnbList = async () => {
    try {
      const { success, data } = await GET({
        url: '/auth/system/gnbs',
      });

      if (success) {
        return data.rows;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGnbList().then((list) => {
      const prevGnbList = JSON.stringify(gnbList);
      const newGnbList = JSON.stringify(list);

      if (prevGnbList !== newGnbList) {
        dispatch(setGnb(list));
      }
    });
  }, [pathname, dispatch]);

  const onClickMobileNav = useCallback(() => {
    if (!isLoggedIn) {
      history.push('/login');
    } else {
      history.push('/my');
    }
  }, [isLoggedIn, history]);

  /** Helper Methods */
  const onClickLoggedInUserMenuOutside = useCallback(
    (e) => {
      if (!isShowLoggedInUserMenu) return;
      setIsShowLoggedInUserMenu(false);
    },
    [isShowLoggedInUserMenu],
  );

  const onClickToggleLoggedInUserMenu = useCallback(() => {
    setIsShowLoggedInUserMenu((prev) => !prev);
  }, []);

  const handleImageError = useCallback((e) => {
    e.target.src = ProfileDefaultImage;
  }, []);

  const [recentSearch, setRecentSearch] = useState([]);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [recommendKeywords, setRecommendKeywords] = useState([]);
  const { q = '', category = 'lectures' } = queryString.parse(useLocation().search);

  useEffect(async () => {
    if (isShowSearch) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    const EJE_API_KEY = localStorage.getItem('eje_token') || 'auth';
    const { classRecommends, recordRecommends } = await GET({
      url: '/auth/search/recommend',
      params: {
        cate: category === 'lectures' ? 'class' : 'record',
      },
      header: {
        EJE_API_KEY,
      },
    });

    if (category === 'lectures') {
      setRecommendKeywords(classRecommends?.classRecommendWord || []);
    } else {
      setRecommendKeywords(recordRecommends?.recordRecommendWord || []);
    }
  }, [isShowSearch]);

  useEffect(() => {
    if (q) {
      setSearchText(q.replace(/%3F/g, '?').replace(/%26/g, '&').replace(/%23/g, '#'));
    }
  }, [q]);

  useEffect(() => {
    const recentSearch = localStorage.getItem('recentSearch');
    if (recentSearch) {
      setRecentSearch(JSON.parse(recentSearch));
    }
  }, []);

  const updateRecentSearch = useCallback((text) => {
    if (text !== '') {
      setRecentSearch((prev) => {
        if (prev.includes(text)) {
          return prev;
        }
        localStorage.setItem('recentSearch', JSON.stringify([text, ...prev]));
        return [text, ...prev];
      });
    }
  }, []);
  const resetRecentSearch = useCallback(() => {
    localStorage.removeItem('recentSearch');
    setRecentSearch([]);
  }, []);

  const deleteRecentSearch = useCallback((text) => {
    setRecentSearch((prev) => {
      const newRecentSearch = prev.filter((item) => item !== text);
      localStorage.setItem('recentSearch', JSON.stringify(newRecentSearch));
      return newRecentSearch;
    });
  }, []);

  function getQueryText(searchText) {
    return searchText.replace('&', '%26').replace('#', '%23').replace('?', '%3F').replace(',', '%2C').trim();
  }

  return (
    <>
      <div className={`dimmed ${isShowSearch ? '' : 'hidden'}`} onClick={() => setIsShowSearch(false)} />
      <div className={`header_wrap ${isFixed ? 'isFixed' : ''}`}>
        <div className="inner_wrap">
          <div className="header_top_nav nav_wrap">
            <h1 className="logo">
              <Link to="/">
                <img src={Logo?.toCloudFrontURL()} alt="케이랩컴퍼니" />
              </Link>
            </h1>
            <div className={`search_wrap ${isShowSearch && 'active'}`}>
              <div className="search_input_wrap">
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'SEARCH_INPUT_PLACEHOLDER',
                  })}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onFocus={() => {
                    setIsShowSearch(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      updateRecentSearch(searchText);
                      sessionStorage.setItem('searchActionType', 'search');
                      const q = getQueryText(searchText);
                      history.push(`/search?q=${q}`);
                      setIsShowSearch(false);
                    }
                  }}
                />
              </div>
              <button className="search_cancel_btn" onClick={() => setIsShowSearch(false)}>
                <FormattedMessage id="COMMON_CANCEL" />
              </button>
            </div>
            <LocaleSelect />
            {isMobile && (
              <Link
                className="mobile_search_btn"
                to={`/search`}
                style={{
                  marginRight: '8px',
                  marginTop: '4px',
                }}
              >
                <img
                  className="mobile_search_icon"
                  src={SearchIcon}
                  onError={handleImageError}
                  alt="user_profile_img"
                />
              </Link>
            )}
            {isLoggedIn ? (
              <div className="user_nav logged_in_nav" onClick={onClickToggleLoggedInUserMenu}>
                <img
                  className="user_profile"
                  src={(userInfo.image_profile || ProfileDefaultImage)?.toCloudFrontURL()}
                  onError={handleImageError}
                  alt="user_profile_img"
                />
                <p className="user_message">
                  <span>
                    <FormattedMessage id={'HEADER_LOGGED_IN_USER_MESSAGE'} values={{ user: userInfo.nickname }} />
                  </span>
                  <img
                    src={isShowLoggedInUserMenu ? DropdownUpIcon : DropdownDownIcon}
                    alt="user_nav_drop_down_menu_icon"
                  />
                </p>

                {isShowLoggedInUserMenu && (
                  <LoggedInMenu onClickLoggedInUserMenuOutside={onClickLoggedInUserMenuOutside} />
                )}
              </div>
            ) : (
              <ul className="user_nav">
                <li>
                  <Link to="/login">
                    <FormattedMessage id="HEADER_USER_NAV_LOGIN" />
                  </Link>
                </li>
                <li>
                  <Link to="/signup">
                    <FormattedMessage id="HEADER_USER_NAV_SIGNUP" />
                  </Link>
                </li>
                <li>
                  <a href="https://enterjobedu.channel.io/" target="_blank" rel="noreferrer">
                    <FormattedMessage id="HEADER_USER_NAV_INQUIRY" />
                  </a>
                </li>
                <li>
                  <Link to="/notices">
                    <FormattedMessage id="HEADER_USER_NAV_NOTICE" />
                  </Link>
                </li>
                <li>
                  <Link to="/events">
                    <FormattedMessage id="HEADER_USER_NAV_EVENT" />
                  </Link>
                </li>
              </ul>
            )}
            <span className="user_mobile_nav_btn" onClick={onClickMobileNav} style={{ marginTop: '5px' }}>
              <img src={UserIcon} alt="user_nav" />
            </span>
          </div>
          <div className={`search_control_wrap ${isShowSearch ? '' : 'hidden'}`}>
            <div className="search_control_inner_wrap">
              {recentSearch.length > 0 && (
                <>
                  <div className="search_control_recent_words">
                    <div className="search_control_recent_words_title_wrap">
                      <h3>
                        <FormattedMessage id="SEARCH_RECENT_KEYWORDS_TITLE" />
                      </h3>
                      <button className="search_control_recent_words_clear_btn" onClick={resetRecentSearch}>
                        <FormattedMessage id="COMMON_DELETE_ALL" />
                      </button>
                    </div>
                    <ul className="search_control_recent_words_list">
                      {recentSearch.map((text) => (
                        <li key={text}>
                          <Link to={`/search?q=${getQueryText(text)}`} onClick={() => setIsShowSearch(false)}>
                            {text}
                          </Link>
                          <button
                            onClick={() => {
                              deleteRecentSearch(text);
                            }}
                          >
                            delete
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="divider" />
                </>
              )}
              <div className="search_control_keyword_wrap">
                <h3>
                  <FormattedMessage id="SEARCH_RECOMMEND_KEYWORDS_TITLE" />
                </h3>
                <ul className="search_control_keyword_list">
                  {recommendKeywords?.map(({ word, searchCategory: category }) => (
                    <li key={word}>
                      <Link
                        to={`/search?q=${getQueryText(word)}`}
                        onClick={() => {
                          sessionStorage.setItem('searchActionType', 'search');
                          setIsShowSearch(false);
                        }}
                      >
                        <button>{word}</button>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div className="search_wrap_close_wrap">
            <button
              className="search_wrap_close"
              onClick={() => setIsShowSearch(false)}
            >
              닫기
            </button>
          </div> */}
          </div>
          {isShowBottomNav && (
            <div className="header_bottom_nav nav_wrap">
              <ul className="global_nav_wrap">
                <li id="ours">
                  <ul className="global_nav ours">
                    {gnbList &&
                      gnbList
                        .filter((item) => item !== null && !!item.menu_name)
                        .map((item, index) => {
                          const isEventMenu = item.editable === false && item.to.includes('/event');
                          if (isEventMenu && item.exposePosts?.length <= 0) {
                            return null;
                          } else if (isEventMenu && item.exposePosts?.length > 1) {
                            // 드롭다운 메뉴 생성
                            return <GnbDropdownMenu key={item.id} item={item} isMobile={isMobile} />;
                          } else {
                            return (
                              <li key={item.id} id={item.id} className={pathname.includes(item.to) ? 'isActive' : ''}>
                                <Link to={item.to}>{item.menu_name}</Link>
                              </li>
                            );
                          }
                        })}
                    {/*<li id="online" className={pathname.includes('/online') ? 'isActive' : ''}>*/}
                    {/*  <Link to="/online">*/}
                    {/*    <FormattedMessage id="HEADER_GLOBAL_NAV_ONLINE" />*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li id="offline" className={pathname.includes('/offline') ? 'isActive' : ''}>*/}
                    {/*  <Link to="/offline">*/}
                    {/*    <FormattedMessage id="HEADER_GLOBAL_NAV_OFFLINE" />*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {/*<li id="foreigner" className={pathname.includes('/foreigner') ? 'isActive' : ''}>*/}
                    {/*  <Link to="/foreigner">*/}
                    {/*    <FormattedMessage id="HEADER_GLOBAL_NAV_FOREIGNER" />*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    {isMobile && (
                      <li
                        id="gov-class"
                        className={pathname.includes('/gov-class') ? 'isGov isActive' : 'isGov'}
                        style={{ color: '#f24462' }}
                      >
                        <Link to="/gov-class">
                          <FormattedMessage id="HEADER_GLOBAL_NAV_GOV" />
                        </Link>
                      </li>
                    )}
                    {/* <li
                      id="albumcredit-db"
                      className={
                        pathname.includes('/albumcredit-db') ? 'isActive' : ''
                      }
                    >
                      <Link to="/albumcredit-db">
                        <FormattedMessage id="Album Credit DB" />
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li id="gov">
                  <ul className="global_nav gov">
                    <li
                      id="gov-class"
                      className={pathname.includes('/gov-class') ? 'isActive isGov' : 'isGov'}
                      style={{ color: '#f24462' }}
                    >
                      <Link to="/gov-class">
                        <FormattedMessage id="HEADER_GLOBAL_NAV_GOV" />
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>

              <span className="instructor_link">
                <a
                  href="https://klabcompany.notion.site/About-the-Instructors-2b70ea1a519044798ce2a6cb7e422952"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage id="HEADER_INSTRUCTOR_INTRODUCTION" />
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
