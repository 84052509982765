import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CouponDetailModal from '../../../Commons/Components/CouponDetailModal';

const CouponItem = ({ coupon }) => {
  const history = useHistory();
  const [isShowCouponModal, setIsShowCouponModal] = useState(false);

  const onClickOpenCouponModal = useCallback(() => {
    if (coupon.FK_coupon.Coupon_Classes.length > 1) {
      setIsShowCouponModal(true);
    } else {
      // 적용 강의가 하나일 경우
      const id = coupon.FK_coupon.Coupon_Classes[0].FK_class.id;
      history.push(`/lecture/${id}`);
    }
  }, [coupon.FK_coupon.Coupon_Classes, history]);

  const onClickCloseCouponModal = useCallback(() => {
    if (!isShowCouponModal) return;
    setIsShowCouponModal(false);
  }, [isShowCouponModal]);

  const formatCurrency = (value) => (
    <span>
      {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      <FormattedMessage id="CURRENCY_KOR" />
    </span>
  );

  const isAvailable = coupon.status === 'available';

  return (
    <div className="coupon_item">
      <strong className={`coupon_price ${!isAvailable ? 'expired' : ''}`}>
        {coupon.FK_coupon.type === 'price' && <span>{formatCurrency(coupon.FK_coupon.amount)}</span>}
        {coupon.FK_coupon.type === 'rate' && <span>{coupon.FK_coupon.amount}%</span>}
      </strong>
      <p className="coupon_title">{coupon.FK_coupon.name}</p>
      <div className="coupon_info">
        <p className="due">
          {coupon.status === 'expired' ? (
            <FormattedMessage id="EXPIRED" />
          ) : coupon.status === 'used' ? (
            <>
              <span>{dayjs(coupon.usedAt).format('YYYY . M . D')}</span>
              <FormattedMessage id="USED" />
            </>
          ) : (
            <>
              <span>{dayjs(coupon.FK_coupon.end_date).format('YYYY . M . D')}</span>
              <FormattedMessage id="DUE" />
            </>
          )}
        </p>

        {coupon.FK_coupon.Coupon_Classes.length > 0 && coupon.status === 'available' && (
          <span className="check_btn" onClick={onClickOpenCouponModal}>
            <FormattedMessage id="COMMON_COUPON_CHECK_HISTORY" />
          </span>
        )}
      </div>

      {isShowCouponModal && (
        <div className="modal_outside" onClick={onClickCloseCouponModal}>
          <CouponDetailModal
            classes={coupon.FK_coupon.Coupon_Classes}
            onClickCloseCouponModal={onClickCloseCouponModal}
          />
        </div>
      )}
    </div>
  );
};

export default CouponItem;
