import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const Main = ({ children }) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [hasPadding, setHasPadding] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (
      isMobile &&
      (pathname === '/' ||
        pathname === '/online' ||
        pathname === '/offline' ||
        pathname === '/foreigner' ||
        pathname === '/albumcredit-db')
    ) {
      setHasPadding(true);
    }
  }, [isMobile, pathname]);

  return (
    <>
      <Header />
      <div
        className={`content_wrap ${hasPadding ? 'hasPadding' : hasPadding}`}
        style={{
          position: 'relative',
        }}
      >
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Main;
