import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';

import IconAlbum from '../../../Assets/Images/icon-album.png';

const AlbumDetail = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return data ? (
    <div id="album-credit" className="pb-7">
      <div id="album-credit-header" className="mb-5 flex items-center justify-items-center">
        <img src={IconAlbum} alt="album-credit-header" className="w-12 aspect-square mr-5" />
        <div className="relative">
          <span className="font-semibold text-base md:text-xl after:h-3 after:w-full after:inline-block after:bg-[#F24462] after:absolute after:bottom-1 after:left-0 after:z-[-1] after:opacity-20">
            <FormattedMessage
              id='앨범 크레딧 "이분들의 땀과 노력이 담겼어요!"'
              values={{
                br: isMobile ? <br /> : '',
              }}
            />
          </span>
        </div>
      </div>
      <div id="album-credit-info" className="break-all pt-5" dangerouslySetInnerHTML={{ __html: data }} />
    </div>
  ) : (
    ''
  );
};
export default AlbumDetail;
