import React from 'react';
import './index.scss';

function YouTubeEmbed(props) {
  return (
    <div className="youtube_embed">
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={props.src}
        {...props}
      />
    </div>
  );
}

export default YouTubeEmbed;
