import dayjs from 'dayjs';
import { useMemo } from 'react';

const LectureSummary = ({ lecture }) => {
  const remainDays = useMemo(() => {
    const now = dayjs();
    const endDatetime = dayjs(lecture.endDatetime);
    const diff = endDatetime.diff(now, 'hour');
    if (diff < 0) {
      return 0;
    }
    return Math.ceil(diff / 24);
  }, [lecture.endDatetime]);
  return (
    <section className="lecture-summary-wrap">
      <div className="lecture-summary-header">
        <h5>강의 요약</h5>
      </div>
      <div className="lecture-summary-body">
        <section className="lecture-summary-schedules">
          <h6>강의 일정</h6>
          <div className="lecture-summary-schedules-body">
            <div className="lecture-summary-schedules-start">
              <span>시작일: </span>
              {lecture.startDatetime}
            </div>
            <div className="lecture-summary-schedules-end">
              <span>종료일: </span>
              {lecture.endDatetime}
            </div>
          </div>
          <h6>남은 수강 기간</h6>
          <div className="lecture-summary-schedules-body">
            <div className="lecture-summary-schedules-start">{remainDays}일</div>
          </div>
        </section>
        <section className="lecture-summary-status">
          <h6>수강생 현황</h6>
          <div className="lecture-summary-status-body">
            <div className="lecture-summary-status-ongoing">
              <span>수강중: </span>
              {lecture.student?.count?.ongoing}
            </div>
            <div className="lecture-summary-status-fail">
              <span>실패: </span>
              {lecture.student?.count?.fail}
            </div>
            <div className="lecture-summary-status-done">
              <span>이수: </span>
              {lecture.student?.count?.done}
            </div>
          </div>
        </section>
        <section>
          <h6>수료 기준</h6>
          <div className="lecture-summary-schedules-body">
            <div className="lecture-summary-schedules-start">
              <span>진도율: </span>
              {lecture.completionRate}%
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default LectureSummary;
