import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as localeActions from '../../Store/Actions/locale';
import * as MUI from '@mui/material';
import './index.scss';

export default function LocaleSelect(props) {
  const dispatch = useDispatch();

  const locales = useMemo(
    () => [
      { value: 'ko', label: '한국어' },
      { value: 'en', label: 'English' },
      { value: 'ja', label: '日本語' },
      { value: 'vi', label: 'Tiếng Việt' },
    ],
    [],
  );

  const locale = useSelector((state) => state.locale);

  const onChangeLocale = useCallback((locale) => dispatch(localeActions.setLocale(locale)), [dispatch]);

  return (
    <MUI.Select
      className="locale-select"
      value={locale}
      onChange={(e) => onChangeLocale(e.target.value)}
      sx={{
        fontSize: '11px',
        '& .MuiSelect-select': { padding: '9px 16px' },
        '& .MuiSvgIcon-root': { color: '#ececec' },
      }}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            boxShadow: 'none',
            marginTop: '3px',
            border: '1px solid #ececec',
          },
        },
      }}
    >
      {locales.map((locale) => (
        <MUI.MenuItem key={locale.value} value={locale.value} sx={{ fontSize: '11px', textAlign: 'center' }}>
          {locale.label}
        </MUI.MenuItem>
      ))}
    </MUI.Select>
  );
}
