import '../index.scss';

function Refund() {
  return (
    <div className="payment_term">
      <h1 className="page-title">返金ポリシー</h1>
      <div className="page-body">
        <p>
          ①
          会員は、電話またはインターネットにより、返金または変更の意思を表明するとともに、会社は、返金要求を受け付けた場合、会員の要求と返金ポリシーを確認の上、可能な限り速やかに返金します。
        </p>
        <p>1.返金</p>
        <p>
          <strong>（1）オンライン講義の受講料に関する返金規約</strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            お支払い日から7日以内に講義を受講していない場合は、全額返金します。ただし、お客様の責に帰すべき事由により返金する場合、仮想口座については、500ウォンの振込手数料を差し引いたうえで返金します。
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            利用時間の終了前に返金申請を行った場合、実際のお支払い金額 -
            実際に再生した講義数に相当する金額を返金します。
          </li>
        </ul>
        <p>（実際に再生した講義数に相当する金額：受講した講義のお支払い金額/講義構成数*学習講義数）</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            パッケージ、フリーパス等の特別企画商品の場合、返金ポリシーが異なることがありますので、必ずご確認ください。
          </li>
        </ul>
        <p>
          <strong>（2）オフライン講義の受講料に関する返金規約</strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            受講料の全額返金については、開講日から起算して7日前までは全額返金ができます。その後は10％の違約金が発生します。
          </li>
        </ul>
        <p>※事前連絡なしに講義に参加せず返金申請を行う場合、返金ができません。</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            お客様の責に帰すべき事由により返金する場合、仮想口座については、500ウォンの振込手数料を差し引いたうえで返金します。（閉講は除く）
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            返金の際、実際にお支払いいただいた金額（割引を除く）を基準に返金処理を行います。
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            開講後の返金に関する法令は以下の通りです。（塾の設立、運営および課外教習に関する法律）
          </li>
        </ul>
        <p>（開講後の返金については、実際に受講したかどうかに関わらず、日割り計算または分数計算で返金します）</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>全授業の3分の1が経過するまでは、3分の1を控除し、残りの3分の2を返金</li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>全授業の2分の1が経過するまでは、2分の1を控除し、残りの2分の1を返金</li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>全授業の2分の1が経過した場合は返金不可</li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            無断欠席の場合、返金額の計算は当該授業に参加したものとみなして処理されます。
          </li>
        </ul>
        <p>[受講期間が1カ月を超える場合]</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>返金事由が生じた月の返金額</li>
        </ul>
        <p>（受講料の徴収期間が1カ月以内の場合に算出された受講料）と残りの月の分の受講料を合算した金額</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            1カ月目に8時間登録および授業料24万ウォン、2カ月目に月2時間登録および受講料6万ウォンとみなす
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            1カ月目に授業の3分の1が経過する前に返金する場合：1カ月目の授業料の3分の2 +、2カ月目の授業料全額返金可能
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            1カ月目に授業の2分の1が経過する前に返金する場合：1カ月目の授業料の1分の2 +、2カ月目の授業料全額返金可能
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            1カ月目に授業の2分の1が経過した後に返金する場合：1カ月目の授業料返金不可 +、2カ月目の授業料全額返金可能
          </li>
        </ul>
        <p>
          <strong>（3）オンライン+オフラインで構成された講義に関する返金規約</strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            オンライン+オフラインで提供されるオンライン講義を受講した後、オフラインの授業開講前に返金を要請する場合、オンライン受講料を差し引いた額を返金します。
          </li>
        </ul>
        <p>2.変更</p>
        <p>
          返金条件に合致する講座を他の講座に変更する場合、直接変更はできず、返金と同じ条件で返金を受けた後、再申し込みし、またはサポートセンターまでお問い合わせする必要があります。
        </p>
        <p>3.会社都合によるサービス停止</p>
        <p>
          会社が、やむを得ない事由によりサービスを継続することができない場合は、これを会員に告知の上、可能な限り速やかに返金します。
        </p>
        <p>4.利用停止および強制退会</p>
        <p>
          会員が、関係法令および本規約の規定に違反し、会社から利用停止および強制退会された場合には、本条の返金規約が適用されません。
        </p>
        <p>
          ② 会社が、会員に対して無料で配布したクーポンおよびチャージ率を超えたクーポンの金額については返金ができません。
        </p>
        <p>③ 第17条の規定により利用が制限される場合、料金は返金されません。</p>
      </div>
    </div>
  );
}

export default Refund;
