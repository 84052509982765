import { Link } from 'react-router-dom';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import FooterLogo from '../../../Assets/Images/logo-only-image-black.png';

const Footer = () => {
  return (
    <div className="footer_wrap">
      <div className="inner_wrap">
        <div className="footer_info">
          <div className="footer_nav">
            <h4 className="footer_logo">
              <img src={FooterLogo} alt="footer_logo" />
            </h4>
            <ul>
              <li>
                <a
                  href="https://klabcompany.notion.site/Conexus-Lab-b0ad8dbea992405ca9886b7833bba7fc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage id="FOOTER_NAV_ABOUT_US" />
                </a>
              </li>
              <li>
                <a
                  href="https://klabcompany.notion.site/FAQ-7526cc15ceab40c7b39ad41458bb55f2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage id="FOOTER_NAV_FAQ" />
                </a>
              </li>
              <li>
                <Link to="/privacy">
                  <FormattedMessage id="FOOTER_NAV_PRIVACY_STATEMENT" />
                </Link>
              </li>
              <li>
                <Link to="/terms">
                  <FormattedMessage id="FOOTER_NAV_TERMS" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer_company_information">
            <p>
              <FormattedMessage id="FOOTER_COMPANY" /> |
              <FormattedMessage id="FOOTER_DELEGATE" /> |
              <FormattedMessage id="FOOTER_INFORMATION_MANAGER" /> |
              <FormattedMessage id="FOOTER_ADDRESS" />
            </p>
            <p>
              <FormattedMessage id="FOOTER_COMPANY_REGISTRATION_NUMBER" /> |
              <FormattedMessage id="FOOTER_BUSINESS_REPORT_NUMBER" />
            </p>
            <p>
              <FormattedMessage id="FOOTER_LIFELONG_EDUCATION_FACILITY" />
            </p>
            <p>
              <FormattedMessage id="FOOTER_EMAIL" />
            </p>
            <p className="copyright">
              <FormattedMessage id="FOOTER_COPYRIGHT" />
            </p>
          </div>
        </div>

        <div className="footer_channels">
          <h4>
            <FormattedMessage id="FOOTER_CHANNEL" />
          </h4>
          <ul>
            <li>
              <a href="https://cafe.naver.com/snswjdghktkdl" target="_blank" rel="noreferrer">
                <FormattedMessage id="FOOTER_CHANNEL_LIST_COMMUNITY" />
              </a>
            </li>
            <li>
              <a href="https://blog.naver.com/enterjobedu" target="_blank" rel="noreferrer">
                <FormattedMessage id="FOOTER_CHANNEL_LIST_BLOG" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCL12m9yG__hYNtY-eueF85w" target="_blank" rel="noreferrer">
                <FormattedMessage id="FOOTER_CHANNEL_LIST_YOUTUBE" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/enterjobedu" target="_blank" rel="noreferrer">
                <FormattedMessage id="FOOTER_CHANNEL_LIST_FACEBOOK" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/enterjobedu_official/" target="_blank" rel="noreferrer">
                <FormattedMessage id="FOOTER_CHANNEL_LIST_INSTAGRAM" />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer_service_center">
          <h4>
            <FormattedMessage id="FOOTER_SERVICE_CENTER" />
          </h4>
          <span className="inquiry_btn">
            <a href="https://enterjobedu.channel.io/" target="_blank" rel="noreferrer">
              <FormattedMessage id="FOOTER_INQUIRY" />
            </a>
          </span>
          <p>
            <FormattedMessage id="FOOTER_INQUIRY_TIME" />
          </p>
        </div>
        <div className="footer_mobile_company_information">
          <p>
            <FormattedMessage id="FOOTER_COMPANY" /> | <FormattedMessage id="FOOTER_DELEGATE" /> |{' '}
            <FormattedMessage id="FOOTER_INFORMATION_MANAGER" />
          </p>
          <p>
            <FormattedMessage id="FOOTER_ADDRESS" />
          </p>
          <p>
            <FormattedMessage id="FOOTER_LIFELONG_EDUCATION_FACILITY" />
          </p>
          <p>
            <FormattedMessage id="FOOTER_EMAIL" />
          </p>
          <p className="copyright">
            <FormattedMessage id="FOOTER_COPYRIGHT" />
          </p>
        </div>
        <div className="footer_mobile_service_center">
          <h4>
            <FormattedMessage id="FOOTER_SERVICE_CENTER" />
          </h4>
          <span className="inquiry_btn">
            <a href="https://enterjobedu.channel.io/" target="_blank" rel="noreferrer">
              <FormattedMessage id="FOOTER_INQUIRY" />
            </a>
          </span>
          <p>
            <FormattedMessage id="FOOTER_INQUIRY_TIME" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
