import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from 'react';
import { GET } from '../../../Commons/Utils/fetch';
import Record from './record';

const RelatedRecords = ({ recordId: id }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [related, setRelated] = useState(null);
  const [emptyList, setEmptyList] = useState([]);
  const [hasntRelatedAlbums, setHasntRelatedAlbums] = useState(false);
  useEffect(() => {
    const relatedFetch = async () =>
      await GET({
        url: `/auth/records/${id}/related`,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
    relatedFetch().then(({ relatedAlbums, hasntRelatedAlbums }) => {
      setRelated(relatedAlbums);
      setHasntRelatedAlbums(hasntRelatedAlbums);
      const value = isMobile ? 2 : 4;
      if (relatedAlbums.length < value) {
        setEmptyList(Array(value - relatedAlbums.length).fill(0));
      }
    });
  }, [id]);
  return (
    <>
      {related && related.length > 0 && (
        <div id="related-albums" className="pt-7 border-t-eje-gray border-t-[1px]">
          <h3 id="related-albums-header" className="font-bold mb-5 text-base md:text-xl">
            {hasntRelatedAlbums ? (
              <FormattedMessage id="다른 앨범 정보도 궁금하지 않으세요?" />
            ) : (
              <FormattedMessage id="아티스트와 관련된 다른 앨범 정보도 궁금하지 않으세요?" />
            )}
          </h3>
          <div id="related-albums-list" className="flex justify-between flex-wrap flex-row">
            {related.map((record) => (
              <Record data={record} key={record.id} />
            ))}
            {emptyList.map((record, index) => (
              <Record isEmpty={true} key={`empty-${{ index }}`} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default RelatedRecords;
