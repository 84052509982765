import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MyPage from '../../../../Commons/Layouts/MyPage';
import { DELETE, GET, POST } from '../../../../Commons/Utils/fetch';
import View from './View';

const Group = (props) => {
  const { orgId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState({ ...props, orgId });
  const fetchOrgLectures = useCallback(async () => {
    const url = `/classes/organizations/${orgId}`;
    let lectures = [];
    try {
      const response = await GET({ url });
      const list = response?.list || [];
      lectures = {
        ...response,
        list: list.map((lecture) => {
          const { student } = lecture || {};
          const { count } = student || {};
          const { total = 0, ready = 0, ongoing = 0, warn = 0, fail = 0, done = 0 } = count || {};
          return {
            ...lecture,
            student: {
              ...student,
              count: {
                total,
                ongoing: ready + ongoing + warn,
                fail,
                done,
              },
            },
          };
        }),
      };
    } catch (error) {
      console.error(error);
    } finally {
      setProperties((prev) => ({
        ...prev,
        lectures,
        orgId,
      }));
    }
  }, [orgId]);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const fetchOrgLectureDetail = useCallback(async ({ orgId, lectureId, mapping_id, student }) => {
    if (orgId && lectureId) {
      const url = `/class/organizations/${orgId}/${lectureId}`;
      try {
        const lecture = await GET({ url, params: { mapping_id, student } });
        if (student) {
          const { student: studentObj } = lecture || {};
          const { count } = studentObj || {};
          const { total = 0, ready = 0, ongoing = 0, warn = 0, fail = 0, done = 0 } = count || {};
          return {
            ...lecture,
            student: {
              ...studentObj,
              count: {
                total,
                ongoing: ready + ongoing + warn,
                fail,
                done,
              },
            },
          };
        } else {
          return lecture;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const postOrgLectureStudent = useCallback(
    async (properties) => {
      const { org_id, class_id, mapping_id, user_ids } = properties;
      if (org_id && class_id) {
        const url = `/payment/organization/assignments`;
        try {
          const response = await POST({
            url,
            body: {
              mapping_id,
              org_id,
              class_id,
              user_ids,
            },
          });
          await fetchOrgLectures();
          return response;
        } catch (error) {
          console.error(error);
        }
      }
    },
    [fetchOrgLectures],
  );

  const requestSendSMS = useCallback(async ({ students, lecture }) => {
    const requestList = [];
    const today = dayjs();
    students
      .filter((student) => student.phone)
      .map((student) => {
        const { phone, name } = student;
        const endDatetime = dayjs(lecture.endDatetime);
        const diff = endDatetime.diff(today, 'day', true);
        const text = `[엔터잡에듀]\n\n안녕하세요 ${name}님! 엔터잡에듀입니다.\n\n현재 수강 중인 [${
          lecture.title
        }]의 수강 종료일은 ${lecture.endDatetime}이며, 수강 종료까지 ${Math.ceil(
          diff,
        )}일 남았습니다.\n\n학습 현황을 꼭 확인해주시고, 기간 내 꼭 수료해주시길 바랍니다.\n\n감사합니다.`;
        requestList.push(
          POST({
            url: '/alimtalk',
            body: {
              templateCode: 'EJE0007',
              text,
              phoneNumbers: [phone.formatPhoneNumber().split('-').join('')],
              buttons: [
                {
                  type: 'WL',
                  name: '내 강의실 바로가기',
                  linkMobile: 'https://www.enterjobedu.co.kr/my/lectures',
                  linkPc: 'https://www.enterjobedu.co.kr/my/lectures',
                },
              ],
            },
          }),
        );
      });
    return await Promise.all(requestList);
  }, []);

  const deleteOrgLectureStudent = useCallback(
    async ({ org_id, class_id, mapping_id, user_ids }) => {
      if (org_id && class_id) {
        const url = `/payment/organization/assignments`;
        try {
          const response = await DELETE({
            url,
            body: {
              mapping_id,
              org_id,
              class_id,
              user_ids,
            },
          });
          await fetchOrgLectures();
          return response;
        } catch (error) {
          console.error(error);
        }
      }
    },
    [fetchOrgLectures],
  );
  useEffect(() => {
    fetchOrgLectures().then((_) => setIsLoading(false));
  }, [fetchOrgLectureDetail, fetchOrgLectures, orgId, postOrgLectureStudent]);

  return (
    <MyPage>
      <View
        isLoading={isLoading}
        {...properties}
        deleteOrgLectureStudent={deleteOrgLectureStudent}
        fetchOrgLectureDetail={fetchOrgLectureDetail}
        postOrgLectureStudent={postOrgLectureStudent}
        requestSendSMS={requestSendSMS}
      />
    </MyPage>
  );
};
export default Group;
