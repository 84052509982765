import { FormattedMessage } from 'react-intl';
import './index.scss';

const View = ({ organization, orgId }) => {
  return (
    <div className="coupon_content">
      <h3 className="title">
        <FormattedMessage id="MY_PAGE_ORG_MGMT" /> - {organization.name || orgId}
      </h3>
    </div>
  );
};

export default View;
