import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';

import Loading from '../../../Commons/Components/Loading';
import PackageLectureDetail from '../../MyPage/Lectures/components/PackageLectureDetail';
import LectureCard from './components/LectureCard';
import LectureRow from './components/LectureRow';
import './index.scss';

const View = ({
  currentTab,
  currentSubTab,
  isLoading,
  lectures,
  onClickTab,
  onClickSubTab,
  originLectures,
  hasCompulsory,
}) => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { state: { shouldShowPackageDetail, packageId } = {} } = useLocation();
  const [permissionReview, setPermissionReview] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setPermissionReview(
      lectures.filter(
        ({ FK_class_offline_schedule, completion_rate, class_status, class_review, FK_class: { host } }) =>
          (host !== 'gov' && !FK_class_offline_schedule && completion_rate >= 30 && class_review.length === 0) ||
          (!!FK_class_offline_schedule && class_status === 'end' && class_review.length === 0),
      ).length > 0,
    );
  }, [lectures]);

  useEffect(() => {
    const blocker = localStorage.getItem('blockPermissionReviewAlert');
    const isBlock = blocker && dayjs(blocker).isAfter(dayjs());
    if (permissionReview && !isBlock) {
      swal(formatMessage({ id: 'MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_BANNER' }), {
        icon: 'info',
        buttons: {
          oneday: {
            text: formatMessage({ id: 'COMMON_1DAY_CLOSE' }),
            color: 'red',
          },
          close: formatMessage({ id: 'COMMON_CLOSE' }),
        },
      }).then((value) => {
        setPermissionReview(false);
        if (value === 'oneday') {
          // 쿠키 작성
          let expires = new Date();
          expires.setDate(expires.getDate() + 1);
          expires.setHours(0, 0, 0, 0);
          localStorage.setItem('blockPermissionReviewAlert', expires);
        }
      });
    }
  }, [permissionReview]);

  const LectureList = () => {
    let result = '';

    if (lectures.length < 1) {
      result = <LecturesPlaceholder linkTo={`/${currentTab}`} />;
    } else if (isMobile) {
      if (currentTab === 'online') {
        result = shouldShowPackageDetail ? (
          <PackageLectureDetail packageLecture={lectures.find((lecture) => lecture.id === packageId)} />
        ) : (
          <>
            <ul className="lectures_subtabs">
              {['single', 'package'].map((subtab) => (
                <li
                  key={subtab}
                  className={subtab === currentSubTab ? 'isActive' : ''}
                  onClick={() => onClickSubTab(subtab)}
                >
                  <FormattedMessage id={'MY_PAGE_LECTURES_' + subtab.toUpperCase()} />
                </li>
              ))}
            </ul>
            <LectureCards
              currentTab={currentTab}
              lectures={lectures.filter((lecture) => lecture.FK_class.class_type === currentSubTab)}
              originLectures={originLectures}
            />
          </>
        );
      } else {
        result = <LectureCards currentTab={currentTab} lectures={lectures} originLectures={originLectures} />;
      }
    } else {
      if (currentTab === 'online') {
        result = (
          <>
            <section>
              <h4>
                <FormattedMessage id="MY_PAGE_LECTURES_SINGLE" />
              </h4>
              <LecturesTable
                type={currentTab}
                lectures={lectures.filter(
                  (lecture) =>
                    lecture.FK_class.class_type === 'single' && lecture.FK_class.listen_type !== 'compulsory',
                )}
                originLectures={originLectures}
                className="my-lectures-table"
              />
            </section>
            <section>
              <h4>
                <FormattedMessage id="MY_PAGE_LECTURES_PACKAGE" />
              </h4>
              <LecturesTable
                type={currentTab}
                lectures={lectures.filter((lecture) => lecture.FK_class.class_type === 'package')}
                originLectures={originLectures}
                classType="package"
                className="my-lectures-table"
              />
            </section>
          </>
        );
      } else if (currentTab === 'gov') {
        result = (
          <LecturesTable
            type="online"
            lectures={lectures.filter(
              (lecture) => lecture.FK_class.class_type === 'single' && lecture.FK_class.listen_type !== 'compulsory',
            )}
            originLectures={originLectures}
            className="my-lectures-table"
          />
        );
      } else if (currentTab === 'compulsory') {
        result = (
          <LecturesTable
            type="compulsory"
            lectures={lectures.filter((lecture) => lecture.FK_class.listen_type === 'compulsory')}
            originLectures={originLectures}
            className="my-lectures-table"
          />
        );
      } else {
        result = (
          <LecturesTable
            type={currentTab}
            lectures={lectures.filter((lecture) => lecture.FK_class.listen_type !== 'compulsory')}
            originLectures={originLectures}
            className="my-lectures-table"
          />
        );
      }
    }
    return result;
  };

  return (
    <div className="lectures_content">
      <h3 className="title">
        <FormattedMessage id="MY_PAGE_LECTURES" />
      </h3>
      {shouldShowPackageDetail || (
        <ul className="lectures_tabs">
          {['online', 'offline'].map((tab, index) => (
            <li key={tab} className={tab === currentTab ? 'isActive' : ''} onClick={() => onClickTab(tab)}>
              <FormattedMessage id={'HEADER_GLOBAL_NAV_' + tab.toUpperCase()} />
            </li>
          ))}
          {hasCompulsory && (
            <li className={currentTab === 'compulsory' ? 'isActive' : ''} onClick={() => onClickTab('compulsory')}>
              법정의무교육
            </li>
          )}
        </ul>
      )}
      {isLoading ? <Loading /> : <LectureList />}
    </div>
  );
};

function LectureCards({ lectures, originLectures }) {
  const userInfo = useSelector((state) => state.user.userInfo);
  return (
    <div className="lecture_list">
      {lectures.map((lecture) => (
        <LectureCard userInfo={userInfo} key={lecture.id} lecture={lecture} originLectures={originLectures} />
      ))}
    </div>
  );
}

function LecturesTable({ type, lectures, originLectures, classType }) {
  const commonPreFields = [
    { fieldName: 'status', style: { width: '85px' } },
    {
      fieldName: 'title',
      style: { width: '190px' },
    },
  ];
  classType !== 'package' &&
    commonPreFields.push({
      fieldName: 'option',
      style: { width: '90px' },
    });
  const commonPostFields = [
    { fieldName: 'attached_file', style: { width: '75px' } },
    {
      fieldName: 'certificate',
      style: { width: '80px' },
    },
    { fieldName: 'review', style: { width: '90px' } },
  ];
  const columns = useMemo(
    () =>
      type === 'online' || type === 'compulsory'
        ? [
            ...commonPreFields,
            {
              fieldName: 'period',
              style: { width: '110px' },
            },
            { fieldName: 'remain_days', style: { width: '80px' } },
            {
              fieldName: 'attendance_rate',
              style: { width: '60px' },
            },
            {
              fieldName: 'lecture_room',
              style: { width: '102px' },
            },
            ...commonPostFields,
          ]
        : [
            ...commonPreFields,
            {
              fieldName: 'generation',
              style: { width: '60px' },
            },
            { fieldName: 'period', style: { width: '110px' } },
            {
              fieldName: 'date',
              style: { width: '100px' },
            },
            { fieldName: 'place', style: { width: '100px' } },
            { fieldName: 'survey', style: { width: '100px' } },
            ...commonPostFields,
          ],
    [commonPostFields, commonPreFields, type],
  );
  return (
    <table className="my-lectures-table">
      <thead>
        <tr>
          {columns.map(({ fieldName, style }) => (
            <th key={fieldName} style={style}>
              <FormattedMessage id={'MY_PAGE_LECTURES_COLUMN_' + fieldName.toUpperCase()} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {lectures.map((lecture) => (
          <LectureRow
            key={lecture.id}
            userClassId={lecture.id}
            lecture={lecture}
            originLectures={originLectures}
            columns={columns}
          />
        ))}
      </tbody>
    </table>
  );
}

function LecturesPlaceholder({ linkTo }) {
  return (
    <div className="none_list">
      <Link to={linkTo}>
        <FormattedMessage id="MY_PAGE_NONE_LECTURE" />
      </Link>
    </div>
  );
}

export default View;
