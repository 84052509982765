import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import DropdownLeftIcon from '../../Assets/Images/icon-dropdown-left.png';
import Footer from '../../Commons/Components/Footer';
import Header from '../../Commons/Components/Header';
import Container from './Container';

const Payment = (props) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onClickHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      {!isMobile ? (
        <Header />
      ) : (
        <div className="mobile_header">
          <img src={DropdownLeftIcon} alt="go_back" onClick={onClickHistoryBack} />
          교육신청
        </div>
      )}
      <Container {...props} />
      <Footer />
    </>
  );
};

export default Payment;
