import '../index.scss';

function Information() {
  return (
    <div className="payment_term">
      <h1 id="0f985c99-d9dc-4d35-945e-594c1125bb26" className="page-title">
        개인정보 제3자 제공동의
      </h1>
      <div id="ba29df36-52f2-43c2-bbf9-242c7faa9895" className="page-body">
        <p id="bab916e4-3dba-42ea-baaa-cdf80b3d5ba0" className="">
          <strong>①</strong> 개인정보를 제공받는 자 : KG이니시스
        </p>
        <p id="8b070b5c-a8f3-42c9-b28c-2aac745c0b2c" className="">
          <strong>②</strong> 개인정보를 제공받는 자의 개인정보 이용 목적 : 결제
        </p>
        <p id="d0eca8b5-02c8-4b15-9646-e922a232601e" className="">
          <strong>③</strong> 제공하는 개인정보의 항목 : 거래정보
        </p>
        <p id="f56bbd55-c416-46c3-b85e-ddf6569ab5cf" className="">
          <strong>④</strong> 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간 : 예시)제공 후 1년
        </p>
        <p id="ef12c832-f1fe-4fec-b7db-3d996e0c5e3f" className=""></p>
      </div>
    </div>
  );
}

export default Information;
