// User Action Types
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_LECTURE_LIKE_LIST = 'SET_LECTURE_LIKE_LIST';

// User Action Creator
export const login = (payload) => {
  return {
    type: LOGIN,
    payload,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const setLectureLikeList = (list) => {
  return {
    type: SET_LECTURE_LIKE_LIST,
    lectureLikeList: list,
  };
};
