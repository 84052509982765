import '../index.scss';

function Refund() {
  return (
    <div className="payment_term">
      <h1 className="page-title">환불 정책</h1>
      <div className="page-body">
        ① 회원은 환불 또는 변경을 유ㆍ무선의 방법을 통하여 회사에 그 의사를 표시하여야 하며, 회사는 환불요청을 접수하고
        회원의 요청과 환불규정 확인 후, 최대한 신속하게 환불하여 드립니다.
        <div className="indented">
          <div className="">
            1.환불
            <div className="indented">
              <div className="">
                (1) <strong>온라인 강의 수강료 환불규정</strong>
                <div className="indented">
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      결제일로부터 7일 이내 미수강시 100% 환불이 됩니다. 단, 고객님의 귀책사유로 환불 시, 가상계좌의
                      경우 금융수수료 500원을 제하고 환불해드립니다.
                    </li>
                  </ul>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      이용시간 종료 전에 환불 신청을 한 경우, 실제 결제 금액 - 실제 재생한 강의 수에 해당하는 금액으로
                      환불됩니다.
                      <p className="">
                        (실제 재생한 강의 수에 해당하는 금액 : 수강한 강의의 결제 금액 / 강의 구성 수 * 학습 강의 수)
                      </p>
                      <p className="">
                        단, 강의수강 신청 후 전체 수강기간 중 1/2이 지나면, 관련 법령에 의거하여 환불이 불가합니다. (총
                        기간이 30일인 경우, 15일이 되는 시점부터 환불 불가)
                      </p>
                      <p className="">
                        ※ 강의 수강 후 환불은 관련 법령에 의거 아래와 같습니다. (학원 설립, 운영 및 과외교습에 관련
                        법률) : 강의 가격에서 실제 강의를 수강한 부분 + 강의자료를 다운받으신 경우 강의자료비(구매금액의
                        50%)를 제하고 환불됩니다.
                      </p>
                      <p className="">
                        ex. 5만원에 해당하는 강의(총강좌수 10강)를 3강 수강 및 강의자료 다운로드 후 환불할 경우, 50,000
                        - 15,000(=50,000 / 10강 * 3강) - 25,000(5만원 강의에 대한 강의자료비) = 10,000원
                      </p>
                    </li>
                  </ul>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      패키지, 프리패스 등 특별 기획 상품의 경우 환불정책이 다를 수 있으니 꼭 확인부탁드립니다.
                    </li>
                  </ul>
                </div>
              </div>
              <p className=""></p>
              <div className="">
                (2) <strong>오프라인 강의 수강료 환불규정</strong>
                <div className="indented">
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      수강료 전액 반환은 개강일 수업시작일 기준으로 7일 전까지는 100% 환불 가능하며, 그 이후부터는
                      위약금 10%가 발생합니다.
                      <p className="">※ 사전 연락 없이 강의 불참 후 환불 요청시 환불 불가능합니다.</p>
                    </li>
                  </ul>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      고객님의 귀책사유로 환불 시, 가상계좌의 경우 금융수수료 500원을 제하고 환불해드립니다. (폐강은
                      제외)
                    </li>
                  </ul>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      환불시, 실제로 결제한 금액(할인 제외)을 기준으로 환불 처리됩니다.
                    </li>
                  </ul>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      개강 후 반환은 관련 법령에 의거 아래와 같습니다. (학원 설립, 운영 및 과외교습에 관련 법률)
                      <p className="">(개강 후 반환은 실제 수강여부와 관계없이 일할공제 또는 분수공제 합니다.)</p>
                      <p className=""></p>
                      <p className="">[수강 개월이 1개월인 경우]</p>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          총 수업의 1/3 경과 전까지는 1/3 공제하고 나머지 2/3 반환
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          총 수업의 1/2 경과 전까지는 1/2 공제하고 나머지 1/2 반환
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          <strong>총 수업의 1/2 이상 수강한 경우 반환 불가</strong>
                          <p className="">
                            <strong>ex. 총 4회 수업 중 2회까지 수업을 수강한 경우, 반환 불가</strong>
                          </p>
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          수업에 무단불참 시, 환불액 계산은 해당 수업을 참가한 것으로 간주하여 처리됩니다.
                        </li>
                      </ul>
                      <p className=""></p>
                      <p className="">[수강 개월이 1개월 초과하는 경우]</p>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          반환 사유가 발생한 월의 환불 액
                          <p className="">
                            (수강료 징수기간이 1개월 이내인 경우에 따라 산출된 수강료)와 나머지 월의 수강료 전액을
                            합산한 금액
                          </p>
                          <p className="">환불 예: 총 5주, 매주 월요일 2시간 총 10시간, 수업료 30만원인 경우,</p>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'square' }}>
                              첫째달 8시간 등록 및 수업료 24만원, 둘째달 월 2시간 등록 및 수강료 6만원으로 간주
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'square' }}>
                              첫째달 1/3 수업 경과 전 환불할 경우: 첫째달 수업료의 2/3 + 둘째달 수업료 전액 환불가능
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'square' }}>
                              첫째달 1/2 수업 경과 전 환불할 경우: 첫째달 수업료의 1/2 + 둘째달 수업료 전액 환불가능
                            </li>
                          </ul>
                          <ul className="bulleted-list">
                            <li style={{ listStyleType: 'square' }}>
                              <strong>
                                첫째달 1/2 이상 수강 후 환불할 경우: 첫째달 수업료 환불불가 + 둘째달 수업료 전액
                                환불가능
                              </strong>
                            </li>
                          </ul>
                          <p className="">*처음 프로그램이 시작되는 주를 1주라고 기준으로 정하여 위와 같이 계산</p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <p className=""></p>
              <div>
                (3) <strong>온라인 + 오프라인 강의 구성된 학원강의에 대한 환불규정</strong>
                <div className="indented">
                  <p>[강의 수강 전]</p>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      수강료 전액 반환은 오프라인 강의 개강일 기준으로 7일 전까지는 100% 환불 가능하며, 그 이후부터는
                      위약금 10%가 발생합니다. ※ 사전 연락 없이 강의 불참 후 환불 요청시 환불 불가능합니다.
                    </li>
                  </ul>
                  <p>[강의 수강 후]</p>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      온라인 강의 + 오프라인 강의로 제공되는 온라인 강의를 수강 후, 오프라인 수업 개강 전 환불을
                      요청하는 경우 온라인 강의 가격을 제한 나머지 금액만 환불됩니다.
                    </li>
                    <li style={{ listStyleType: 'disc' }}>
                      온라인 강의 + 오프라인 강의로 제공되는 오프라인 강의 수강 후, 온라인 강의 미수강 상태에서 환불을
                      요청하는 경우 오프라인 환불 규정에 따른 가격을 제한 나머지 금액만 환불됩니다.
                    </li>
                  </ul>
                  <p>[환급반]</p>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      환급반 수강 기간 도중 환불 신청을 한 경우 실제 결제 금액 - (오프라인) 강의 금액 - (온라인) 강의
                      금액으로 환불이 진행됩니다. <br />각 강의의 금액 산정은 아래와 같습니다.
                    </li>
                  </ul>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      (오프라인) <strong>첫기수</strong> 한달반 수업을 기준으로 환불 진행
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          총 수업의 1/3 경과 전까지는 1/3 공제하고 나머지 2/3 반환
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          총 수업의 1/2 경과 전까지는 1/2 공제하고 나머지 1/2 반환
                        </li>
                      </ul>
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          <strong>총 수업의 1/2 이상 수강한 경우 반환 불가</strong>
                          <p>
                            <strong>ex. 총 4회 수업 중 2회까지 수업을 수강한 경우, 반환 불가</strong>
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      (온라인) 실제 결제 금액 - 실제 재생한 강의 수에 해당하는 금액으로 환불 진행
                      <ul className="bulleted-list">
                        <li style={{ listStyleType: 'circle' }}>
                          (실제 재생한 강의 수에 해당하는 금액 : (수강한 강의의 결제 금액 – 오프라인 강의+컨설팅 금액) /
                          강의 구성 수 * 학습 강의 수) 단, 강의수강 신청 후 전체 수강기간 중 1/2이 지나면, 관련 법령에
                          의거하여 환불이 불가합니다.
                          <br />
                          (총 기간이 30일인 경우, 15일이 되는 시점부터 환불 불가)
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>※ 강의 수강 후 환불은 관련 법령에 의거 아래와 같습니다.</p>
                  <p>
                    (학원 설립, 운영 및 과외교습에 관련 법률) : 강의 가격에서 실제 강의를 수강한 부분 + 강의자료를
                    다운로드하신 경우 강의자료비(구매금액의 50%)를 제하고 환불됩니다.
                  </p>
                  <p className="indented">
                    <strong>
                      ex. 5만원에 해당하는 강의(총강좌수 10강)를 3강 수강 및 강의자료 다운로드 후 환불할 경우, 50,000 -
                      15,000(=50,000 / 10강 * 3강) - 25,000(5만원 강의에 대한 강의자료비) = 10,000원
                    </strong>
                  </p>
                  <ul className="bulleted-list">
                    <li style={{ listStyleType: 'disc' }}>
                      <strong>환급반에서 사용한 컨설팅 비용은 반환되지 않습니다.</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            2.변경
            <div className="indented">
              <p className="">
                환불조건에 부합하는 강좌를 타 강좌로 변경하는 경우, 직접변경은 불가능하며 환불과 동일한 조건으로
                환불처리 후 재신청 하거나 고객센터로 문의하여야 합니다.
              </p>
            </div>
          </div>
          <div className="">
            3.회사 사정에 의한 서비스의 중단
            <div className="indented">
              <p className="">
                회사가 기타 부득이한 사유로 서비스를 지속할 수 없을 경우에는 이를 회원에게 공지한 후 최대한 신속하게
                환불하여 드립니다.
              </p>
            </div>
          </div>
          <div className="">
            4.이용중지 및 강제탈퇴
            <div className="indented">
              <p className="">
                회원이 관계법령 및 본 약관의 규정을 위반하여 회사로부터 이용중지 및 강제탈퇴 처리가 되는 경우에는 본
                조의 환불규정이 적용되지 않습니다.
              </p>
            </div>
          </div>
        </div>
        <p className="">
          ② 회사가 회원에게 무료로 지급한 쿠폰 및 충전비율 이상으로 주어진 쿠폰 금액에 대해서는 환불이 되지 않습니다.
        </p>
        <p className="">③ 제17조에 따라 이용제한이 될 경우 요금은 반환하지 않습니다.</p>
        <p className=""></p>
      </div>
    </div>
  );
}

export default Refund;
