import { Checkbox } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const ListWrap = (properties) => {
  const { header, list, useCheckbox, style = {}, selectStudents = () => {} } = properties;
  const [checkedList, setCheckedList] = useState([]);
  const handleAllCheck = useCallback(() => {
    setCheckedList((prev) => {
      if (prev.length === list.length) {
        return [];
      }
      return list.map((item) => item.id);
    });
  }, [list]);
  const handleCheck = useCallback(
    (id) => {
      if (checkedList.includes(id)) {
        setCheckedList((prev) => prev.filter((item) => item !== id));
      } else {
        setCheckedList((prev) => [...prev, id]);
      }
    },
    [checkedList],
  );
  useEffect(() => selectStudents(checkedList), [checkedList, selectStudents]);

  useEffect(() => {
    const filteredList = checkedList.filter((item) => {
      return list.some((listItem) => listItem.id === item);
    });
    if (filteredList.length !== checkedList.length) {
      setCheckedList([]);
    }
  }, [list, checkedList]);
  return (
    list?.length > 0 &&
    header?.length > 0 && (
      <ul className="list-wrap" style={style}>
        <li className="list-header">
          {useCheckbox && (
            <div className="list-header-checkbox checkbox-wrap">
              <Checkbox
                color="primary"
                size="small"
                checked={checkedList.length > 0 && checkedList.length === list.length}
                indeterminate={checkedList.length > 0 && checkedList.length < list.length}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onClick={handleAllCheck}
              />
            </div>
          )}
          {header.map((item) => (
            <div key={item.key} style={item.style}>
              {item.label}
            </div>
          ))}
        </li>
        <li className="list-body">
          <ul>
            {list.map((item) => (
              <li key={`list-item-${item.id}`}>
                {useCheckbox && (
                  <div className="list-body-checkbox checkbox-wrap">
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={checkedList.includes(item.id)}
                      onChange={() => handleCheck(item.id)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                )}
                {header.map((headerItem) => (
                  <div key={headerItem.key} style={headerItem.style}>
                    {item[headerItem.key]}
                  </div>
                ))}
              </li>
            ))}
          </ul>
        </li>
      </ul>
    )
  );
};

export default ListWrap;
