import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledRadioContainer = styled.div.attrs({ className: 'radio-group' })`
  display: flex;
  flex-direction: ${(props) => (props.layout === 'row' ? 'row' : 'column')};
  gap: ${(props) => (props.layout === 'row' ? '2.5rem' : '1rem')};
  margin-top: ${(props) => (props.layout === 'row' ? '0.75rem' : '0')};
  padding: 0.75rem 0 0.75rem 2.5rem;
  flex-wrap: ${(props) => (props.layout === 'row' ? 'wrap' : 'nowrap')};

  @media (max-width: 480px) {
    padding-left: 0;
    flex-direction: column;
    gap: 1rem;
  }
`;

const StyledRadioLabel = styled.label`
  // 여기에 필요한 스타일을 적용하세요.
  display: flex;
  flex-wrap: ${(props) => (props.layout === 'row' ? 'normal' : 'nowrap')};
  gap: ${(props) => (props.layout === 'row' ? '0.25rem' : '0.625rem')};
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 1.25rem;
  flex-direction: ${(props) => (props.layout === 'row' ? 'column-reverse' : 'row')};

  flex-direction: column;
  justify-content: center;

  span {
    margin-bottom: -0.125rem;
  }
`;

const StyledRadioInput = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  outline: none;
  cursor: pointer;

  width: 1.125rem;
  height: 1.125rem;
  border: 0.0625rem solid #ced4da;
  border-radius: 50%;
  aspect-ratio: 1/1;

  &:not(:checked) {
    background-color: #f8f9fa;
  }

  &:checked {
    background-color: #ffffff;
    border-color: #f24462;

    &:before {
      content: '';
      display: block;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #f24462;
    }
  }
`;

const StyledTextInput = styled.input.attrs({ type: 'text' })`
  background-color: #ffffff;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: auto;
  max-width: 20rem;
  padding: 0.6875rem 1.25rem 0.5625rem;
`;

const StyledRadioInputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: ${(props) => (props.layout === 'row' ? 'column-reverse' : 'row')};
  align-items: ${(props) => (props.layout === 'row' ? 'center' : 'start')};

  @media (max-width: 480px) {
    flex-direction: row;
  }
`;

const RadioOption = ({
  id: value,
  content: label,
  is_etc: isEtc,
  register,
  name,
  layout,
  isRequired,
  watchGroup,
  setValue,
}) => {
  const inputName = isEtc ? `${name}Text` : name;
  const isEtcChecked = watchGroup && +watchGroup === +value;
  useEffect(() => {
    if (register) {
      register(inputName, {
        required: isEtcChecked,
      });
    }
  }, [register, inputName, isEtcChecked]);

  return (
    <StyledRadioLabel layout={layout}>
      <StyledRadioInputContainer layout={layout}>
        <StyledRadioInput
          value={value}
          {...register(name, {
            required: isRequired,
          })}
        />
        <span>{label}</span>
      </StyledRadioInputContainer>
      {isEtc && (
        <StyledTextInput
          name={inputName}
          placeholder="기타 내용을 입력하세요"
          onChange={(e) => {
            setValue(inputName, e.target.value);
          }}
          style={{ marginLeft: '1.5rem' }}
        />
      )}
    </StyledRadioLabel>
  );
};

const RadioGroup = ({ options, register, name, layout = 'column', isRequired, watch, setValue }) => {
  const watchGroup = watch(name);
  const selectedText = `${name}SelectedText`;
  useEffect(() => {
    // watchGroup에 값에 매칭되는 텍스트를 selectedText에 저장합니다.
    const selectedOption = options.find((option) => +option.id === +watchGroup);
    setValue(selectedText, selectedOption?.content);
  }, [watchGroup]);
  return (
    <StyledRadioContainer layout={layout}>
      {options.map((option) => (
        <RadioOption
          key={`${name}-option-${option.id}`}
          {...option}
          register={register}
          name={name}
          layout={layout}
          isRequired={isRequired}
          watchGroup={watchGroup}
          setValue={setValue}
        />
      ))}
      <input {...register(selectedText)} type="hidden" />
    </StyledRadioContainer>
  );
};

export default RadioGroup;
