import onClickOutside from 'react-onclickoutside';
import './filterOptionList.scss';
import { useState } from 'react';

import CheckIcon from '../../../Assets/Images/icon-checkbox-checked.png';
import UncheckIcon from '../../../Assets/Images/icon-checkbox-unchecked.png';

function FilterOptionList({
  filterType,
  filterOptionConstant,
  onClickFilterOptionOutside,
  onClickSetFilterOption,
  params,
}) {
  const { agencies, artists } = params;
  const checkIconFlag = filterType === 'artist' ? artists : agencies;
  FilterOptionList.handleClickOutside = (e) => {
    if (!onClickFilterOptionOutside) return;
    onClickFilterOptionOutside(e);
  };
  const placeholder = filterType === 'artist' ? '아티스트명을 입력해보세요.' : '기획사명을 입력해보세요.';

  const [foundItems, setFoundItems] = useState(filterOptionConstant || []);
  const findItems = (e) => {
    const { value } = e.target;
    if (!value) setFoundItems(filterOptionConstant);
    else {
      const foundItems = filterOptionConstant.filter((item) => {
        return item.text.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
      setFoundItems(foundItems);
    }
  };

  return (
    <div className="filter_select_box_options">
      <div className="filter_select_box_options_search">
        <img src="https://cdn1.iconfinder.com/data/icons/hawcons/32/698627-icon-111-search-256.png" alt="search" />
        <input type="text" placeholder={placeholder} onChange={findItems} />
      </div>
      <div className="filter_select_box_options_list_wrap">
        <ul id={`${filterType}_filter_list`} className="filter_select_box_options_list">
          {foundItems.map((option, index) => (
            <li
              key={option.id}
              id={`${filterType}_filter_list_${index}`}
              onClick={(e) => onClickSetFilterOption(e, option)}
            >
              <img
                src={checkIconFlag.split(',').find((item) => +item === +option.id) ? CheckIcon : UncheckIcon}
                alt="check"
              />
              <div>{option.text}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default onClickOutside(FilterOptionList, {
  handleClickOutside: () => FilterOptionList.handleClickOutside,
});
