import { PAYMENT_IMPLEMENTS } from './constant.js';
import { TRANSACTION } from '../../../Commons/Utils/constant';
import './index.scss';
import { FormattedMessage, useIntl } from 'react-intl';

import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PaymentMessageModal from '../../../Commons/Components/PaymentMessageModal';

const { PAID, FAILED, READY, CANCELLED, PARTIAL_CANCELLED } = TRANSACTION.TYPE.STATUS;

/**
 * 결제 시스템
 * - 가상 계좌 주문 => ready(가상 계좌 주문) => paid(이체 확인 시 = 결제 성공)
 * - 가상 계좌 주문 외 => paid(결제 성공) - cancel(환불 완료) => 관리자 권한
 *                 => false(결제 실패)
 */

const lang = localStorage.getItem('eje_lang');

const PaymentItem = ({ item, onClickOpenMessageModal }) => {
  const { formatMessage } = useIntl();

  const statusMessage = (status) => {
    switch (status) {
      case PAID:
        return <FormattedMessage id="PAYMENT_COMPLETED" />;
      case READY:
        return <FormattedMessage id="PAYMENT_WAITING" />;
      case FAILED:
        return <FormattedMessage id="PAYMENT_FAIL" />;
      case CANCELLED:
      case PARTIAL_CANCELLED:
        return <FormattedMessage id="REFUND_COMPLETED" />;
      default:
        return;
    }
  };

  const currentImplement = PAYMENT_IMPLEMENTS.filter((implement) => {
    return implement.pay_method === item.pay_method ? implement : null;
  })[0];

  const formatCurrency = (value = 0) => (
    <>
      {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      {item.class_listen_type === 'foreigner' ? '$' : <FormattedMessage id="CURRENCY_KOR" />}
    </>
  );

  return (
    <div className="payment_item">
      <div className="payment_item_info">
        <div className="title_wrap">
          <strong className={`payment_item_status ${item.status === 'paid' ? 'isPaid' : ''}`}>
            {statusMessage(item.status)}
          </strong>
          <h4 className="payment_item_title">{item.class_title || ''}</h4>
        </div>
        <div className="info_list_wrap">
          <p className="info_list">
            <span className="label">
              <FormattedMessage id="ORIGINAL_PRICE" />
            </span>
            <strong>
              {/* 원가 */}
              {formatCurrency(item.class_price_original)}
            </strong>
          </p>
          <p className="info_list">
            <span className="label">
              <FormattedMessage id="DISCOUNT_AMOUNT" />
            </span>
            <strong>
              <span style={{ marginRight: '3px' }}>(-)</span>
              {formatCurrency(item.class_discount_amount + item.coupon_discount_amount)}
            </strong>
          </p>
          <p className="info_list">
            <span className="label">
              <FormattedMessage id="AMOUNT_OF_PAYMENT" />
            </span>
            <strong>
              {/* 정가(할인률 적용된) - 쿠폰 */}
              {formatCurrency(item.paid_amount)}
            </strong>
          </p>
          <p className="info_list">
            <span className="label">
              <FormattedMessage id="PAYMENT_METHOD" />
            </span>

            <strong>
              <FormattedMessage id={currentImplement.key} />
            </strong>
          </p>
        </div>
      </div>

      <div className={`date_info ${lang === 'en' || lang === 'vi' ? 'isColumn' : ''}`}>
        <span className="order_number">
          <div className="label">{`${formatMessage({ id: 'ORDER_NUMBER' })} ${item.merchant_uid}`}</div>
        </span>

        {/* 결제 완료 */}
        {item.status === PAID && (
          <span className="date">
            <div className="label">{`${formatMessage({ id: 'PAYMENT_DATE' })} ${item.date_paid_at}`}</div>
          </span>
        )}

        {/* 결제 대기 */}
        {item.status === READY && (
          <span className="date">
            <div className="label">
              {`${formatMessage({ id: 'ORDER_DATE' })} ${dayjs(item.createdAt).add(9, 'h').format('YYYY.MM.DD')}`}
            </div>
          </span>
        )}

        {/* 환불 완료 */}
        {item.status === CANCELLED && (
          <span className="date">{`${formatMessage({ id: 'REFUND_DATE' })} ${item.date_canceled_at}`}</span>
        )}
      </div>

      <div className="manage_list_wrap">
        {item.status === PAID && (
          <>
            {/* 환불 신청 */}
            <span onClick={() => onClickOpenMessageModal('refund')}>
              <FormattedMessage id="APPLY_FOR_A_REFUND" />
            </span>
            {/* 결게 확인서 발급 */}
            <span onClick={() => onClickOpenMessageModal('confirm')}>
              <FormattedMessage id="ISSUANCE_OF_PAYMENT_CONFIRMATION" />
            </span>
          </>
        )}

        {/* 결제 정보 확인 => 가상 계좌 */}
        {item.status === READY && (
          <span onClick={() => onClickOpenMessageModal('info', item)} className="waiting">
            <FormattedMessage id="CONFIRM_PAYMENT_INFORMATION" />
          </span>
        )}
      </div>
    </div>
  );
};

const View = ({
  paymentList,
  startDate,
  endDate,
  onClickStartDate,
  onClickEndDate,
  onClickInquire,
  isShowMessageModal,
  messageType,
  onClickOpenMessageModal,
  onClickCloseMessageModal,
  currentModalItem,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="payment_content">
      <h3 className="title">
        <FormattedMessage id="MY_PAGE_PAYMENT" />
      </h3>

      <p className="message">
        {isMobile ? (
          <FormattedMessage
            id="MY_PAGE_PAYMENT_MESSAGE_MOBILE"
            values={{
              lastMonth: (
                <strong>
                  <FormattedMessage id="MY_PAGE_PAYMENT_MESSAGE_LAST_MONTH" />
                </strong>
              ),
            }}
          />
        ) : (
          <FormattedMessage
            id="MY_PAGE_PAYMENT_MESSAGE"
            values={{
              lastMonth: (
                <strong>
                  <FormattedMessage id="MY_PAGE_PAYMENT_MESSAGE_LAST_MONTH" />
                </strong>
              ),
            }}
          />
        )}
      </p>

      <div className="date_picker_wrap">
        <DatePicker
          selected={dayjs(startDate).toDate()}
          onChange={(date) => onClickStartDate(date)}
          maxDate={dayjs(endDate).toDate()}
          dateFormat="yyyy-MM-dd"
          showDisabledMonthNavigation
        />
        <span className="tilde">~</span>
        <DatePicker
          selected={dayjs(endDate).toDate()}
          onChange={(date) => onClickEndDate(date)}
          minDate={dayjs(startDate).toDate()}
          showDisabledMonthNavigation
          dateFormat="yyyy-MM-dd"
        />
        <span className="lookup_btn" onClick={onClickInquire}>
          <FormattedMessage id="COMMON_REFERENCE" />
        </span>
      </div>

      <div className="payment_list_wrap">
        {paymentList.length > 0 ? (
          paymentList.map((item) => (
            <PaymentItem key={item.id} item={item} onClickOpenMessageModal={onClickOpenMessageModal} />
          ))
        ) : (
          <div className="none_list">
            <FormattedMessage id="MY_PAGE_NONE_PAYMENT" />
          </div>
        )}
      </div>

      {isShowMessageModal && (
        <div className="modal_outside" onClick={onClickCloseMessageModal}>
          <PaymentMessageModal
            type={messageType}
            onClickCloseMessageModal={onClickCloseMessageModal}
            currentModalItem={currentModalItem}
          />
        </div>
      )}
    </div>
  );
};

export default View;
