export const DEVICE_TYPE = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return DEVICE_TYPE.TABLET;
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return DEVICE_TYPE.MOBILE;
  }
  return DEVICE_TYPE.DESKTOP;
};

export const checkIsMobile = () => {
  return getDeviceType() === DEVICE_TYPE.MOBILE;
};
