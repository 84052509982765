import React, { PureComponent } from 'react';
import { GET } from '../../Commons/Utils/fetch';
import View from './View';
import queryString from 'query-string';
import TermsOfUse from '../../Commons/Components/Terms/TermsOfUse';
import PrivacyPolicy from '../../Commons/Components/Terms/PrivacyPolicy';

export default class Container extends PureComponent {
  state = {
    event: [],
    offline: [],
    online: [],
    top: [],
    video: [],
    bottomBanner: [],
    isFixed: false,
    isMobile: false,
    currentTopImageSrc: '',
    currentBottomImageSrc: '',
    currentBottom: null,
  };

  setCurrentTopImageSrc = (index) => {
    this.setState({
      currentTopImageSrc: this.state.top[index].image,
    });
  };

  setCurrentBottomImageSrc = (index) => {
    const { isMobile, bottomBanner } = this.state;
    if (bottomBanner.length <= 0) return;
    const src = isMobile ? bottomBanner[index].image_mobile : bottomBanner[index].image_pc;

    this.setState({
      currentBottomImageSrc: src,
    });
  };

  setCurrentBottom = (index) => {
    const { bottomBanner } = this.state;
    if (bottomBanner.length <= 0) return;

    this.setState({
      currentBottom: bottomBanner[index],
    });
  };

  getBanner = async () => {
    const data = await GET({
      url: '/auth/banners',
      header: {
        EJE_API_KEY: 'auth',
      },
    });

    const { event, offline, online, top, video } = data.banners;

    this.setState({
      event,
      offline,
      online,
      top,
      video,
      currentTopImageSrc: top ? top[0]?.image : '',
    });
  };

  getBottomBanner = async () => {
    const { bottomBanner } = await GET({
      url: '/auth/banner/bottom',
      header: {
        EJE_API_KEY: 'auth',
      },
    });

    let currentBottomImageSrc = '';
    if (bottomBanner.length > 0) {
      currentBottomImageSrc = this.state.isMobile ? bottomBanner[0].image_mobile : bottomBanner[0].image_pc;
    }

    this.setState({
      bottomBanner,
      currentBottomImageSrc,
      currentBottom: bottomBanner[0],
    });
  };

  handleScroll = () => {
    const isScrollPositionDown =
      parseInt(window.scrollY + document.documentElement.clientHeight) >
      parseInt(document.documentElement.scrollHeight) - 240;

    this.setState({
      isFixed: !isScrollPositionDown,
    });
  };

  setupIsMobile = () => {
    const MOBILE_SIZE = 767;

    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  };

  componentDidMount() {
    this.getBanner();
    this.getBottomBanner();

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.setupIsMobile);
    window.addEventListener('load', this.setupIsMobile);
  }

  componentDidUpdate() {
    this.setupIsMobile();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.setupIsMobile);
    window.removeEventListener('load', this.setupIsMobile);
  }

  render() {
    const {
      event,
      offline,
      online,
      top,
      video,
      bottomBanner,
      isFixed,
      isMobile,
      currentTopImageSrc,
      currentBottomImageSrc,
      currentBottom,
    } = this.state;

    const { setCurrentTopImageSrc, setCurrentBottomImageSrc, setCurrentBottom } = this;

    const query = queryString.parse(window.location.search);
    const { mode } = query;
    if (mode === 'policy') return <TermsOfUse />;
    if (mode === 'privacy') return <PrivacyPolicy />;
    return (
      <View
        event={event}
        offline={offline}
        online={online}
        top={top}
        video={video}
        bottomBanner={bottomBanner}
        isFixed={isFixed}
        isMobile={isMobile}
        currentTopImageSrc={currentTopImageSrc}
        currentBottomImageSrc={currentBottomImageSrc}
        currentBottom={currentBottom}
        setCurrentTopImageSrc={setCurrentTopImageSrc}
        setCurrentBottomImageSrc={setCurrentBottomImageSrc}
        setCurrentBottom={setCurrentBottom}
      />
    );
  }
}
