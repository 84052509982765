import { Button } from '@mui/material';
import ListWrap from '../ListWrap';

const AddStudents = (props) => {
  const { students, selectStudents, setShowAddPopup, requestAddStudents, addTargetStudents } = props;
  if (!students || students.length === 0) {
    return null;
  }
  const header = [
    {
      key: 'id',
      label: 'ID',
      style: {
        width: '32px',
      },
    },
    {
      key: 'name',
      label: '이름',
      style: {
        width: '120px',
      },
    },
    {
      key: 'phone',
      label: '전화번호',
      style: {
        width: '120px',
      },
    },
    {
      key: 'email',
      label: '이메일',
      style: {
        width: '200px',
        textAlign: 'left',
      },
    },
  ];

  const list =
    students?.map((item) => ({
      ...item,
      status: item.isDone
        ? '이수'
        : item.isWarn
        ? '수강중'
        : item.isFail
        ? '미이수'
        : item.completionRate === 0
        ? '수강중'
        : '수강중',
      completionRate: `${item.completionRate}%`,
    })) || [];

  const properties = {
    header,
    list,
    useCheckbox: true,
    selectStudents,
    style: {
      minWidth: '400px',
      backgroundColor: '#fff',
    },
  };
  return (
    <section className="add-student-popup">
      <h5>수강 대상 선택</h5>
      <ListWrap {...properties} />
      <div className="add-student-footer">
        <Button
          className="btn-cancel"
          variant="contained"
          size="small"
          disableRipple
          disableElevation
          onClick={() => {
            selectStudents([]);
            setShowAddPopup(false);
          }}
        >
          취소
        </Button>
        <Button
          className="btn-add"
          variant="contained"
          size="small"
          disableRipple
          disableElevation
          onClick={requestAddStudents}
          disabled={!addTargetStudents.length}
        >
          추가
        </Button>
      </div>
    </section>
  );
};

export default AddStudents;
