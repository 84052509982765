import { SET_LOCALE } from '../Actions/locale';
import { getNavigatorLanguage } from '../../Utils/browser';

const initialStates = getNavigatorLanguage();

const reducers = (state = initialStates, action) => {
  const { type } = action;
  switch (type) {
    case SET_LOCALE:
      localStorage.setItem('eje_lang', action.locale);
      return action.locale;
    default:
      return state;
  }
};

export default reducers;
