import ko from './ko';
import en from './en';
import ja from './ja';
import vi from './vi';

const locale = {
  ko,
  en,
  ja,
  vi,
};

export default locale;
