import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import FireIcon from '../../../Assets/Images/icon-fire.png';
import ItemDefaultImage from '../../../Assets/Images/item-default-image.png';
import './index.scss';
import StarIcon from '@mui/icons-material/Star';
import HeartIcon from '../HeartIcon';
import { PUT } from '../../Utils/fetch';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { setLectureLikeList } from '../../Store/Actions/user';

const LectureItem = ({
  item,
  onClickAddFilterOption,
  onClickLikeInMyPage,
  isSearched = false,
  className = '',
  history,
  isLoggedIn,
  isGov = false,
}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const itemRef = useRef(null);
  const hasDiscount = item.isDiscountAvailable;
  const hasDeadline = item.hasDeadline;
  const isLikeList = !!onClickLikeInMyPage;
  const likeList = useSelector((state) => state.user.lectureLikeList);

  const totalCategories = useMemo(() => {
    const categories = item
      ? [
          ...(item?.Class_CategoryCompanies || []),
          ...(item?.Class_CategoryFields || []),
          ...(item?.Class_CategoryProcesses || []),
        ]
      : [];

    return categories.length > 2 ? categories.slice(0, 2) : categories;
  }, [item]);

  const totalTutors = useMemo(() => {
    const tutors = item?.Class_Tutors || [];
    return tutors.length > 2 ? tutors.slice(0, 2) : tutors;
  }, [item]);

  const [currentHeight, setCurrentHeight] = useState(0);
  const [isLiked, setIsLiked] = useState(likeList.includes(item.id));
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {}, [item]);

  const onClickLike = useCallback(async () => {
    if (!isLoggedIn) {
      Swal.fire({
        title: '로그인이 필요합니다.',
        text: '로그인 페이지로 이동하시겠습니까?',
        confirmButtonText: '확인',
        confirmButtonColor: '#F24462',
        showCancelButton: true,
        cancelButtonText: '취소',
        cancelButtonColor: '#A2A2A2',
        preConfirm: () => {
          sessionStorage.setItem('login_redirect', window.location.pathname);
          history.push('/login');
        },
      });
      return;
    }

    if (isLiked && isLikeList) {
      const result = await Swal.fire({
        title: '취소하시겠습니까?',
        text: '즉시 찜한 목록에서 제거됩니다.',
        confirmButtonText: '확인',
        confirmButtonColor: '#F24462',
        showCancelButton: true,
        cancelButtonText: '취소',
        cancelButtonColor: '#A2A2A2',
      });
      if (result.isConfirmed) {
        onClickLikeInMyPage();
      } else if (!result.isConfirmed) {
        return;
      }
    }

    try {
      const { success } = await PUT({
        url: `/class/${item.id}/like`,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
        body: {
          is_like: !isLiked,
        },
      });

      if (success) {
        setIsLiked(!isLiked);
        dispatch(setLectureLikeList(isLiked ? likeList.filter((id) => id !== item.id) : [...likeList, item.id]));
        setIsHovered(false);
      }
    } catch (error) {
      Swal.fire({
        title: '찜하기 실패',
        text: error.detail || '일시적인 오류로 인해 찜하기에 실패했습니다. 잠시 후 다시 시도해주세요.',
        confirmButtonText: '확인',
        confirmButtonColor: '#F24462',
      });
    }
  }, [item, isLiked, isLoggedIn, history]);

  const handleResize = useCallback(() => {
    const currentWidth = itemRef.current.clientWidth;
    setCurrentHeight(itemRef.current.clientWidth);
    document.documentElement.style.setProperty('--lectureMobileLikeWh', `${currentWidth / 10 + 10}px`);
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  // 반응형 넓이에 따른 높이값 대응
  useEffect(() => {
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      // cleanup
      window.addEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [itemRef, handleResize, currentHeight]);

  return (
    <div className={`lecture_item ${className}`} ref={itemRef}>
      <div
        className="lecture_like"
        onClick={onClickLike}
        onMouseEnter={() => {
          !isMobile && setIsHovered(true);
        }}
        onMouseLeave={() => {
          !isMobile && setIsHovered(false);
        }}
      >
        <HeartIcon
          theme={isHovered ? 'filled' : isLiked ? 'filled' : 'outlined'}
          color={isHovered ? '#EE7B8D' : isLiked ? '#F24462' : '#FFFFFF'}
          width={isMobile ? currentHeight / 10 + 5 : '1.5rem'}
          height={isMobile ? currentHeight / 10 + 5 : '1.5rem'}
        />
      </div>
      <Link to={`/lecture/${item.id}`} className="lecture_item_link_wrap">
        <div className="thumbnail_wrap">
          <img
            className="thumbnail"
            src={(item.image_thumb || ItemDefaultImage)?.toCloudFrontURL()}
            alt={item.title}
            // style={{
            //   height: currentHeight ? currentHeight + 'px' : 'auto'
            // }}
          />

          {item.leftDays >= 0 && (
            <span className="appointment">
              {item.leftDays > 0 ? (
                item.leftDays === 1 ? (
                  <FormattedMessage id="ADVANCE_RESERVATION_COUNTING_ONE" /> // 영어 단수형 번역 텍스트
                ) : (
                  <FormattedMessage id="ADVANCE_RESERVATION_COUNTING" values={{ n: item.leftDays }} />
                )
              ) : item.leftDays === 0 ? (
                <FormattedMessage id="ADVANCE_RESERVATION_TODAY" />
              ) : null}
            </span>
          )}
        </div>
        {/* TODO: 모바일에서 뱃지 2개 초과하면 레이아웃 틀어지는 부분 개선 필요 */}
        {isSearched || isLikeList ? (
          <div className="badge-wrap">
            <span className="listen_type">{item.listen_type === 'roundOnline' ? 'Online' : item.listen_type}</span>
            {item.host === 'gov' ? <span className="listen_type">정부지원교육</span> : ''}
          </div>
        ) : (
          <div className="badge-wrap">
            {hasDiscount && (
              <span className="discount">
                {Math.round(item.discount_rate)}% <FormattedMessage id="DISCOUNT" />
              </span>
            )}
            {hasDeadline && (
              <span className="deadline">
                <img src={FireIcon} alt="close to deadline" />
                <FormattedMessage id="마감임박" />
              </span>
            )}
            {isGov && <span className="discount">무료교육</span>}
          </div>
        )}
        <h4 className="lecture_item_title ellipsis">{item.title}</h4>
      </Link>
      {isLikeList ||
        ((item.reviewCount > 0 || item.count_selling > 0 || item.additive > 0) && (
          <div className="lecture_item_rate_wrap">
            <div className="lecture_item_rate">
              {item.reviewCount > 0 && (
                <>
                  {/*<Rating*/}
                  {/*  defaultValue={5}*/}
                  {/*  readOnly*/}
                  {/*  size="small"*/}
                  {/*  value={item.reviews.reduce((total, current) => current.rate + total, 0) / item.reviewCount}*/}
                  {/*/>*/}
                  <StarIcon htmlColor="#faaf00" fontSize="small" />
                  <span>{Number(item.reviewRate).toFixed(1)}</span>
                </>
              )}
            </div>
            <div className="lecture_selling_count">
              {item.count_selling + item.reviewCount + item.additive > 50 && (
                <FormattedMessage
                  id="LECTURE_SELLING_COUNT"
                  values={{
                    count: (() => {
                      const sum = item.count_selling + item.reviewCount + item.additive;
                      let result = '';
                      if (sum < 1000) {
                        result = `${Math.floor(sum / 10) * 10}+`; // 1000이하 일때 100단위로 내림
                      } else if (sum < 10000) {
                        result = `${Math.floor(sum / 100) * 100}+`; // 1000이상 10000이하 일때 1000단위로 내림
                      } else if (sum < 100000) {
                        result = `${Math.floor(sum / 1000) * 1000}+`; // 10000이상 일때 10000단위로 내림
                      } else if (sum < 1000000) {
                        result = `${Math.floor(sum / 10000) * 10000}+`; // 100000이상 일때 100000단위로 내림
                      } else {
                        result = `${Math.floor(sum / 100000) * 100000}+`; // 1000000이상 일때 1000000단위로 내림
                      }
                      return result;
                    })(),
                  }}
                />
              )}
            </div>
          </div>
        ))}
      <div className={`lecture_item_description ${isMobile ? 'isRow' : ''}`}>
        <span className="lecture_item_lecture_item_tutors">
          {totalTutors.map((tutor) => tutor.FK_tutor.name).join(', ')}
        </span>
        {isSearched || (
          <ul className="lecture_item_category">
            {totalCategories?.map((category, index) => (
              <li key={`${category.id}_${index}`} onClick={() => onClickAddFilterOption(category.FK_category)}>
                <FormattedMessage
                  id={`CATEGORY_${category.FK_category.type.toUpperCase()}_${category.FK_category.id}`}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default memo(LectureItem);
