import '../index.scss';

export default function PrivacyPolicy(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">Privacy Policy</h2>
      <article className="page sans">
        <div className="page-body">
          <p>
            Klab Company Co., Ltd (hereinafter referred to as “Klab Company” or “Enterjob Edu Service”) complies with
            the personal information protection provisions specified in the relevant laws and regulations that
            information and communications service providers must comply with, such as the Act on Promotion of
            Information and Communications Network Utilization and Information Protection (hereinafter referred to as
            the “Information and Communications Network Act”). Klab Company is doing its utmost efforts to protect the
            rights and interests of users by establishing the Privacy Policy.
          </p>
          <p>Chapter 1: Items of collected personal information and the method of collection</p>
          <p>Chapter 2: Purpose of collection and use of personal information</p>
          <p>Chapter 3: Consent to collection of personal information</p>
          <p>Chapter 4: Period of retention and use of personal information</p>
          <p>Chapter 5: Procedure and method of destroying personal information</p>
          <p>Chapter 6: Rights of users and their legal representatives and the method of exercising the rights</p>
          <p>
            Chapter 7: Matters concerning the installation, operation and rejection of the automatic personal
            information collection device
          </p>
          <p>Chapter 8: Technical and administrative measures for protection of personal information</p>
          <p>Chapter 9. Personal information of children</p>
          <p>Chapter 10. Personal information manager</p>
          <p>Chapter 11. Guide to Customer Center</p>
          <p>
            <strong>Chapter 1: Items of collected personal information and the method of collection</strong>
          </p>
          <p>
            Klab Company is collecting personal information for membership registration, service provision, and other
            consultations, and may also request personal information for collective statistical analysis during a survey
            or event or for the purpose of sending promotional gifts to its customers. However, Klab Company does not
            collect sensitive personal information that may infringe on users&#x27; basic human rights (race and
            ethnicity, ideology and creed, place of birth and domicile, political orientation and criminal record,
            etc.). If the collection of personal information is inevitable, Klab Company will obtain the prior consent
            from its users. Even at this time, the information entered will not be used for any other purpose than the
            provision of the service or the purpose previously disclosed to members.
          </p>
          <p>① Items of collected personal information</p>
          <p>(1) Membership registration</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Mandatory items: login ID, login password, e-mail address, mobile phone number
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Optional items: gender, address, phone number, learning goal, occupation, field of interest, field of exam
              application, exam information, exam result
            </li>
          </ul>
          <p>
            (2) The following information may be generated and collected in the course of service use or business
            processing.
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Service use record, access log, cookie, access IP information, computer device information, payment
              record, faulty use record
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              (When using mobile devices) Terminal ID number, terminal OS information, mobile communications provider,
              PUSH reception
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Real name authentication information</li>
          </ul>
          <p>(3) Items that are collected when using paid service</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Information necessary for payment, such as credit card information, bank account information, and mobile
              phone information, may be additionally collected, and this information may be provided to third parties
              (partners, PG (Payment Gateway)).
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Payment by credit card: Payment-related records such as credit card company code and approval number
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Account transfer: Payment-related records such as name of the bank, account number, name of the
                  trader, bank code, etc.
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Payment by mobile phone: Payment-related records such as mobile phone number, approval number, mobile
                  carrier code
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Others: Postal code, address, contact number (when purchasing contents that include delivery products)
                </li>
              </ul>
            </li>
          </ul>
          <p>(Specifying that information not provided during membership registration may be additionally collected)</p>
          <p>(4) In the case of additional services and events</p>
          <p>
            Klab Company may additionally collect personal information necessary for the service after obtaining
            separate consent from the service users.
          </p>
          <p>② Items of collected personal information</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Collected through Klab Company’s homepage or e-mail</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Collected offline using phone, fax, or Klab Company’s application form
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Automatically collected through generated information collection tool
            </li>
          </ul>
          <p>
            <strong>Chapter 2: Purpose of collection and use of personal information</strong>
          </p>
          <p>Klab Company collects personal information for the following purposes:</p>
          <p>① Implementation of service contract and settlement of fees arising from service provision</p>
          <p>
            ② Content provision, delivery of goods, billing, user authentication, purchase and payment, financial
            services
          </p>
          <p>③ Membership management</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              User authentication for use of membership service, user identification, prevention of illegal and
              unauthorized use by bad members, confirmation of the intention to obtain the membership, age confirmation,
              confirmation of consent from legal representative when collecting personal information of children under
              the age of 14, processing of complaints, delivery of notices, etc.
            </li>
          </ul>
          <p>④ Marketing and advertisements</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              New service development and specialization, provision of services according to demographic
              characteristics, posting advertisements, access frequency check, statistics on use of services by members,
              and delivery of advertising information such as events
            </li>
          </ul>
          <p>
            <strong>Chapter 3: Consent to collection of personal information</strong>
          </p>
          <p>
            Klab Company obtains consent from its members for the collection of personal information, and has
            established a procedure for obtaining members’ consent to collection of personal information according to
            Terms and Conditions of Use and Privacy Policy during the membership registration process. The members, who
            obtained the membership through member registration page of Enterjob Edu Service, are deemed to have
            consented to the collection of personal information.
          </p>
          <p>
            <strong>Chapter 4: Period of retention and use of personal information</strong>
          </p>
          <p>
            ① In principle, all the personal information of the members will be destroyed immediately after the purpose
            of collecting and using their personal information is achieved. However, the following information will be
            retained for the following reasons and for the period specified below.
          </p>
          <p>(1) Personal information to be retained after the membership withdrawal</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Information items to be retained: Name, ID, e-mail address, address, and phone number provided by the
              member
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Reason for retention: Prevention of re-registration of bad users, cooperation in the dispute and
              investigation of right infringement such as defamation
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Retention period: for one year after the membership withdrawal</li>
          </ul>
          <p>(2) Commercial transaction related personal information to be retained</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Information items to be retained: Commercial transaction records</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Reason for retention: Commercial law, Act on the consumer protection in electronic commerce
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Retention period: 5 years for records on contract or membership withdrawal / 3 years for records on
              payment and supply of goods / 3 years for records on processing of consumer complaints or disputes
            </li>
          </ul>
          <p>
            ② Personal information of service users will be destroyed in a way that cannot be reproduced when the
            purpose of the collection and use (including temporary purposes such as surveys and events) is achieved or
            when the user has directly deleted the information or withdrawn from membership.
          </p>
          <p>
            <strong>Chapter 5: Procedure and method of destroying personal information</strong>
          </p>
          <p>
            If a member wants to withdraw from membership, he or she can withdraw through 1:1 inquiry. However, the
            member should carefully consider the membership withdrawal because all his or her registered information
            will be destroyed. In addition, there may be restrictions on re-registration of the membership after the
            withdrawal. Klab Company&#x27;s procedures and methods of destroying personal information are as follows.
          </p>
          <p>① Procedure of destroying personal information</p>
          <p>
            (1) The information entered by the user for membership registration, etc. will be transferred to a separate
            DB (or separate filing cabinet for information written on paper) after the purpose of the information is
            achieved (separate filing cabinet in the case of paper), and will be destroyed after being stored for a
            certain period of time according to Klab Company’s internal policies or other regulations for information
            protection specified in relevant laws (refer to the period of retention and use).
          </p>
          <p>
            (2) Unless required by law, the personal information above will not be used for any other purpose than
            retention.
          </p>
          <p>② Method of destroying personal information</p>
          <p>
            (1) Personal information printed on paper will be destroyed using a paper shredder or will be incinerated.
          </p>
          <p>
            (2) Personal information stored in the form of electronic files will be destroyed using a technical method
            that cannot reproduce the record.
          </p>
          <p>
            <strong>
              Chapter 6: Rights of users and their legal representatives and the method of exercising the rights
            </strong>
          </p>
          <p>
            ① Users and their legal representatives can inquire or modify their registered personal information or the
            personal information of their own children under the age of 14 at any time, and may request the cancellation
            of their membership.
          </p>
          <p>
            ② For inquiry of personal information of users or their children under the age of 14, or for ‘correction of
            member information’ (or ‘change of member information’), the member may log in and go through the
            authentication process to directly view and correct their personal information or withdraw from the
            membership.
          </p>
          <p>
            The users may also contact Klab Company’s personal information manager in writing, by phone or e-mail, who
            will take necessary measures without delay.
          </p>
          <p>
            ③ If a user has requested the correction of errors in his or her personal information, the personal
            information will not be used or provided until the correction is completed. In addition, if incorrect
            personal information has already been provided to a third party, Klab Company will notify the third party of
            the result of the correction without delay.
          </p>
          <p>
            ④ Klab Company handles personal information that has been canceled or deleted at the request of users or
            their legal representatives as specified in the “Period of retention and use of personal information” and
            prohibits others from viewing or using their personal information for any other purpose.
          </p>
          <p>
            <strong>
              Chapter 7: Matters concerning the installation, operation and rejection of the automatic personal
              information collection device
            </strong>
          </p>
          <p>
            ① Cookie is a small data file sent from an HTTP server to a user&#x27;s browser and it is stored on the
            member’s computer. Klab Company uses this cookie to obtain the information on the member&#x27;s browser type
            or service use pattern. Klab Company uses this cookie information to provide the members with more useful
            and convenient customized services.
          </p>
          <p>
            ② The members have options for cookie. By selecting the option in web browser, the members can accept all
            cookies, check every time time a cookie is saved, or refuse to save all cookies. However, when refusing to
            store cookies, the members will not be able to use all the services of Klab Company that require log-in.
          </p>
          <p>
            <strong>Chapter 8: Technical and administrative measures for protection of personal information</strong>
          </p>
          <p>
            ① The personal information of the members is protected by a password. Only the members know the password of
            their account, and only the members who know the password can check and change their personal information.
            Therefore, the members should not disclose their password to anyone. In addition, it is desirable to log out
            and close the web browser after completing the work. In particular, when the members share a computer with
            others or use it in a public place, this procedure is more necessary to prevent their personal information
            from being disclosed to others. Klab Company will not be held responsible for any leakage of personal
            information due to users’ negligence or Internet problems.
          </p>
          <p>
            ② Klab Company emphasizes the compliance with the Privacy Policy by minimizing the number of employees
            handling personal information and providing the employees with frequent training.
          </p>
          <p>
            ③ Klab Company is implementing technical and administrative measures for the protection of personal
            information as follows.
          </p>
          <p>(1) Klab Company is paying special attention to firewalls and security systems to prevent hacking.</p>
          <p>
            (2) Klab Company is protecting personal information of the members by installing an SSL security server when
            sending and receiving personal information.
          </p>
          <p>
            (3) Klab Company restricts the access to personal information to those who are in charge personal
            information protection and to those who inevitably have to handle personal information when performing their
            tasks, and prevents other employees from accessing the personal information.
          </p>
          <p>
            <strong>Chapter 9: Personal information of children</strong>
          </p>
          <p>
            In principle, Klab Company does not collect personal information of children under the age of 14. In special
            cases, however, children&#x27;s personal information may be collected after obtaining the consent from their
            legal representatives.
          </p>
          <p>
            <strong>Chapter 10: Personal information manager</strong>
          </p>
          <p>
            If the service users have any queries regarding the Klab Company’s Privacy Policy, they may send an e-mail
            to Klab Company, which will take action immediately and notify them of the results of the processing. The
            personal information manager and the staff in charge of personal information are as follows.
          </p>
          <p>
            <strong>Personal information manager</strong>
          </p>
          <p>Name: Sang Hwan Lee</p>
          <p>Company: Klab Company Co., Ltd</p>
          <p>Position: Co-CEO</p>
          <p>Phone number: 010-9866-8640</p>
          <p>
            E-mail:
            <a href="mailto:enterjobedu@k-lab.me">enterjobedu@k-lab.me</a>
          </p>
          <p>
            <strong>Chapter 11: Guide to Customer Center</strong>
          </p>
          <p>Klab Company is operating the Customer Center to process civil complaints as follows.</p>
          <p>Working hours: Weekday KST 10:00~18:00 (closed on Saturday, Sunday, and public holidays)</p>
          <p>
            Kakao Talk:
            <a href="http://pf.kakao.com/_jyPFT/chat">http://pf.kakao.com/_jyPFT/chat</a>
          </p>
          <p>
            Address: 9F, 129, Jayang-ro, Gwangjin-gu, Seoul / Attention: Staff in charge of personal information
            processing at Klab Company
          </p>
          <p>Other queries: Please check Customer Satisfaction Center page</p>
          <p>[Supplementary clause]</p>
          <p>
            Any revision or change to the current Privacy Policy will be notified to members through a notice on the
            website at least 7 days in advance.
          </p>
          <p>Effective date of this Privacy Policy: September 27, 2021</p>
        </div>
      </article>
    </div>
  );
}
