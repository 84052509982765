import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import CloseIcon from '../../../Assets/Images/icon-cancel-black.png';

const CouponDetailModal = ({ classes, onClickCloseCouponModal }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const onClickCloseStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div className="coupon_detail_modal_wrap" onClick={onClickCloseStopPropagation}>
      <h3 className="title">
        <FormattedMessage id="MY_PAGE_COUPON_MODAL_TITLE" />
      </h3>

      {isMobile && (
        <h3 className="mobile_title">
          <FormattedMessage id="MY_PAGE_COUPON_MODAL_MOBILE_TITLE" />
        </h3>
      )}

      <span className="close_btn" onClick={onClickCloseCouponModal}>
        <img src={CloseIcon} alt="close_modal" />
      </span>

      <div className="link_list_wrap">
        {classes.length > 0 &&
          classes.map((classItem, index) => (
            <Link key={index} to={`/lecture/${classItem.FK_class?.id}`}>
              {classItem.FK_class?.title || ''}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default CouponDetailModal;
