import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import DropdownDownIcon from '../../../Assets/Images/icon-dropdown-down.png';
import DropdownUpIcon from '../../../Assets/Images/icon-dropdown-up.png';
import EmailIcon from '../../../Assets/Images/icon-logo-email.png';
import FacebookIcon from '../../../Assets/Images/icon-logo-facebook.png';
import GoogleIcon from '../../../Assets/Images/icon-logo-google.png';
import KakaoIcon from '../../../Assets/Images/icon-logo-kakaotalk.png';
import NaverIcon from '../../../Assets/Images/icon-logo-naver.png';
import { COUNTRY } from '../../../Commons/Utils/constant';
import { GET, POST } from '../../../Commons/Utils/fetch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FindIdPassword({ handleClickOpen, handleClose, open }) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const globalFormatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return undefined;
    const cleanPhoneNumber = phoneNumber.replaceAll(/-/g, '');
    return cleanPhoneNumber.replace(/^0/, '82');
  };

  const findId = async ({ findIdPhone: phone }) => {
    if (!phone) {
      swal({
        text: '전화번호를 정확히 입력해주세요.',
        icon: 'error',
        timer: 2000,
        button: false,
      });
      return;
    }
    const response = await POST({
      url: '/auth/user/find-id',
      body: {
        phone: globalFormatPhoneNumber(phone),
      },
      header: {
        EJE_API_KEY: 'auth',
      },
    });
    swal({
      text: response.message,
      icon: response.success ? 'success' : 'error',
      timer: 2000,
      button: false,
    }).then(() => {
      response.success && handleClose();
      reset();
    });
  };

  const findPW = async ({ findPWPhone: phone, findPWEmail: email, pw, pwCheck }) => {
    const pwPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,20}$/;
    if (!phone && !email) {
      swal({
        text: '전화번호 또는 이메일을 정확히 입력해주세요.',
        icon: 'error',
        timer: 2000,
        button: false,
      });
      return;
    }
    if (!pw || pwPattern.test(pw) === false) {
      swal({
        text: '비밀번호를 정확히 입력해주세요.',
        icon: 'error',
        timer: 2000,
        button: false,
      });
      setError('pw', {
        type: 'manual',
        message: '비밀번호는 8자리 이상 20자리 이하의 영문, 숫자로 입력해주세요.',
      });
      return;
    } else {
      clearErrors('pw');
    }
    if (pw !== pwCheck) {
      setError('pwCheck', {
        type: 'manual',
        message: '비밀번호가 일치하지 않습니다.',
      });
      return;
    } else {
      clearErrors('pwCheck');
    }

    const response = await POST({
      url: '/auth/user/find-pw',
      body: {
        phone: globalFormatPhoneNumber(phone),
        email,
        pw: pwCheck,
      },
      header: {
        EJE_API_KEY: 'auth',
      },
    });

    if (response.success) {
      swal({
        text: response.message,
        icon: response.success ? 'success' : 'error',
        timer: 2000,
        button: false,
      }).then(() => {
        response.success && handleClose();
        reset();
      });
    } else {
      swal({
        text: response.message,
        icon: response.success ? 'success' : 'error',
        timer: 2000,
        button: false,
      }).then(() => {
        const { close } = response;
        if (close) {
          setReadonly(false);
          setInputPassword(false);
          reset();
          handleClose();
        }
      });
    }
  };

  const formatPhoneNumber = (input) => {
    if (!input) return undefined;
    const cleanInput = input.replaceAll(/\D/g, '');
    let result = cleanInput.replace(/^82/, '0');
    let phoneNumber = '';
    if (result.length <= 3) phoneNumber = result;
    else if (result.length <= 7) phoneNumber = `${result.slice(0, 3)}-${result.slice(3)}`;
    else phoneNumber = `${result.slice(0, 3)}-${result.slice(3, 7)}` + `-${result.slice(7, 11)}`;
    result = phoneNumber;
    return result;
  };
  const [readonly, setReadonly] = React.useState(false);
  const [inputPassword, setInputPassword] = React.useState(false);
  const requestSecureCode = async () => {
    try {
      const { findPWPhone: phone, findPWEmail: email } = getValues();
      const { success, message } = await GET({
        url: `/auth/user/find-pw/phone-check`,
        params: {
          phone: globalFormatPhoneNumber(phone),
          email,
        },
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      if (success) {
        setReadonly(true);
        await swal({
          icon: 'success',
          text: intl.formatMessage({ id: 'SIGNUP_FORM_ALERT_SUCCESS_SEND' }),
          timer: 2000,
          button: false,
        });
      } else {
        if (message) {
          await swal({
            icon: 'error',
            text: intl.formatMessage({ id: message }),
          });
        }
      }
    } catch (e) {
      console.error(e);
      swal({
        icon: 'error',
        text: intl.formatMessage({ id: 'ERROR_NETWORK' }),
      });
    }
  };
  const verifyCode = async ({ verificationCode: code }) => {
    if (!code.trim()) return;
    if (code.length !== 6) {
      swal({
        icon: 'warning',
        text: intl.formatMessage({
          id: 'SIGNUP_FORM_AUTHENTICATE_ERROR',
        }),
      });
      return;
    }

    try {
      const params = {
        code,
      };

      const checkResponse = await GET({
        url: '/auth/check/phone',
        params,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      const { success } = checkResponse;
      if (!success) {
        swal({
          icon: 'error',
          text: intl.formatMessage({
            id: 'SIGNUP_FORM_ALERT_FAIL_CHECK_CERTIFICATION',
          }),
        });
      } else {
        setInputPassword(true);
      }
    } catch (error) {
      console.error(error);
      swal({
        icon: 'error',
        text: intl.formatMessage({
          id: 'ERROR_NETWORK',
        }),
      });
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={(_, reason) => {
        if (reason) return;
        setTabIndex(0);
        setInputPassword(false);
        setReadonly(false);
        reset();
        handleClose();
      }}
      onSubmit={() => reset()}
    >
      <DialogTitle>
        <FormattedMessage id="FIND_ID_PASSWORD" />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={handleChange}>
              <Tab label="아이디 찾기" />
              <Tab label="비밀번호 찾기" />
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={0}>
            <form className="find-account-form" onSubmit={handleSubmit(findId)}>
              <div className="field-wrap">
                <label htmlFor="findIdPhone">전화번호</label>
                <FormattedMessage id="SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER">
                  {(phonePlaceholder) => (
                    <input
                      id="findIdPhone"
                      type="tel"
                      placeholder={phonePlaceholder}
                      {...register('findIdPhone', {
                        type: 'tel',
                      })}
                      onBlur={(e) => {
                        setValue('findIdPhone', formatPhoneNumber(e.target.value));
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              <button type="submit">
                <FormattedMessage id="COMMON_CONFIRM" />
              </button>
            </form>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <form className="find-account-form" onSubmit={handleSubmit(findPW)}>
              <div className="field-wrap">
                <label htmlFor="email">이메일(아이디)</label>
                <FormattedMessage id="SIGNUP_FORM_EMAIL_PLACEHOLDER">
                  {(phonePlaceholder) => (
                    <input
                      type="text"
                      placeholder={phonePlaceholder}
                      readOnly={readonly}
                      {...register('findPWEmail')}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="field-wrap">
                <label htmlFor="email">전화번호</label>
                <FormattedMessage id="SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER">
                  {(phonePlaceholder) => (
                    <input
                      type="tel"
                      placeholder={phonePlaceholder}
                      readOnly={readonly}
                      {...register('findPWPhone')}
                      onBlur={(e) => {
                        setValue('findPWPhone', formatPhoneNumber(e.target.value));
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              {readonly && inputPassword && (
                <>
                  <div className="field-wrap">
                    <label htmlFor="pw">비밀번호</label>
                    <FormattedMessage id="PLACEHOLDER_LOGIN_PASSWORD">
                      {(phonePlaceholder) => (
                        <input
                          type="password"
                          placeholder="8~20글자, 영어와 숫자 포함"
                          className={errors.pw ? 'error' : ''}
                          required
                          {...register('pw')}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <span className={`error-message ${errors.pw ? 'active' : ''}`}>비밀번호를 확인해주세요.</span>
                  <div className="field-wrap">
                    <label htmlFor="pwCheck">비밀번호 확인</label>
                    <FormattedMessage id="PLACEHOLDER_LOGIN_PASSWORD">
                      {(phonePlaceholder) => (
                        <input
                          type="password"
                          placeholder="비밀번호 확인"
                          className={errors.pwCheck ? 'error' : ''}
                          required
                          {...register('pwCheck')}
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <span className={`error-message ${errors.pwCheck ? 'active' : ''}`}>비밀번호를 확인해주세요.</span>
                </>
              )}

              {inputPassword && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    findPW(getValues());
                  }}
                >
                  <FormattedMessage id="COMMON_CONFIRM" />
                </button>
              )}
              {!readonly && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    requestSecureCode();
                  }}
                >
                  <FormattedMessage id="SIGNUP_FORM_SEND_VERIFICATION_CODE" />
                </button>
              )}
            </form>
            {readonly && !inputPassword && (
              <form className="find-account-form">
                <div className="field-wrap">
                  <label htmlFor="verificationCode">인증번호</label>
                  <FormattedMessage id="SIGNUP_FORM_SEND_VERIFICATION_CODE">
                    {(phonePlaceholder) => (
                      <input
                        type="text"
                        placeholder={phonePlaceholder}
                        {...register('verificationCode', {
                          type: 'text',
                          required: true,
                        })}
                      />
                    )}
                  </FormattedMessage>
                </div>
                <button type="submit" onClick={handleSubmit(verifyCode)}>
                  <FormattedMessage id="SIGNUP_FORM_AUTHENTICATE" />
                </button>
              </form>
            )}
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setTabIndex(0);
            setInputPassword(false);
            setReadonly(false);
            reset();
            handleClose();
          }}
        >
          <FormattedMessage id="COMMON_CLOSE" />
        </Button>
      </DialogActions>
      <style jsx="true">{`
        .find-account-form {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 1rem;
          row-gap: 1rem;
        }

        .find-account-form .field-wrap {
          display: flex;
          align-items: center;
          column-gap: 1rem;
        }

        .find-account-form .field-wrap label {
          display: flex;
          align-items: center;
          width: 120px;
          font-size: 14px;
          font-weight: bold;
        }

        .find-account-form .field-wrap input {
          min-width: 200px;
          width: 100%;
          height: 40px;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          padding: 0 10px;
          font-size: 14px;
          color: #8f8f8f;
        }

        .find-account-form .field-wrap input.error {
          border: 1px solid #ff0000;
        }

        .find-account-form span.error-message {
          height: 0;
          overflow: hidden;
          font-size: 12px;
          color: #ff0000;
          margin-top: -16px;
          transition: height 0.3s ease;
        }

        .find-account-form span.error-message.active {
          height: 16px;
          transition: height 0.3s ease;
        }

        .find-account-form button {
          margin: 0 auto;
          padding: 0.5rem 1rem;
          height: 40px;
          border-radius: 5px;
          background-color: #f24462;
          color: #ffffff;
          font-size: 14px;
        }
      `}</style>
    </Dialog>
  );
}

const View = ({
  handleSMSLogin,
  onChangeEmail,
  onChangePassword,
  onClickToggleLoginForm,
  onSubmit,
  toggleLoginFrom,
  email,
  password,
  onResponseSMS,
  selectedCountry,
  handleCountry,
  isLoginPage,
}) => {
  const { KOREAN, FOREIGNER } = COUNTRY.TYPE;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason) return;
    setOpen(false);
  };
  return (
    <div className="login">
      {isLoginPage ? (
        <h2>
          <FormattedMessage id="LOGIN_TITLE" />
        </h2>
      ) : (
        <>
          <h2>
            <FormattedMessage id="SIGNUP_TITLE" />
          </h2>
          <p className="message">
            <FormattedMessage id="SIGNUP_MESSAGE" />
          </p>
        </>
      )}

      <div className="login_country_wrap">
        {Object.keys(COUNTRY.TYPE).map((countryKey, index) => {
          const countryValue = COUNTRY.TYPE[countryKey];
          return (
            <div
              key={`login_country_wrap-btn-${index}`}
              className={`login_country_wrap-btn ${
                selectedCountry === countryValue && 'login_country_wrap-btn-active'
              }`}
              onClick={() => {
                handleCountry(countryValue);
              }}
            >
              {countryValue === KOREAN ? '한국인' : FOREIGNER}
            </div>
          );
        })}
      </div>

      <div className="login_btn_wrap">
        {selectedCountry === KOREAN && (
          <>
            <div
              className="social_login_btn kakao"
              onClick={() => {
                handleSMSLogin('kakao');
              }}
            >
              <FormattedMessage id="LOGIN_KAKAO">
                {(login_kakao) => <img className="login_logo" src={KakaoIcon} alt={login_kakao} />}
              </FormattedMessage>
              <span>
                <FormattedMessage id="LOGIN_KAKAO" />
              </span>
            </div>

            <div style={{ display: 'none' }} id="naverIdLogin" />
            <div
              className="social_login_btn naver"
              onClick={() => {
                handleSMSLogin('naver');
              }}
            >
              <FormattedMessage id="LOGIN_NAVER">
                {(login_naver) => <img className="login_logo" src={NaverIcon} alt={login_naver} />}
              </FormattedMessage>
              <span>
                <FormattedMessage id="LOGIN_NAVER" />
              </span>
            </div>
          </>
        )}
        <GoogleLogin
          clientId="914816291724-gkq1mq4jnb0tvmmk43n07mgo05ojhd9g.apps.googleusercontent.com"
          render={(renderProps) => (
            <div className="social_login_btn google" onClick={renderProps.onClick}>
              <FormattedMessage id="LOGIN_GOOGLE">
                {(login_google) => <img className="login_logo" src={GoogleIcon} alt={login_google} />}
              </FormattedMessage>
              <span>
                <FormattedMessage id="LOGIN_GOOGLE" />
              </span>
            </div>
          )}
          buttonText="Login"
          onSuccess={(res) => {
            onResponseSMS('google', res);
          }}
          onFailure={(res) => {
            onResponseSMS('google', res);
          }}
          cookiePolicy={'single_host_origin'}
        />

        <div
          className="social_login_btn facebook"
          onClick={() => {
            handleSMSLogin('facebook');
          }}
        >
          <FormattedMessage id="LOGIN_FACEBOOK">
            {(login_facebook) => <img className="login_logo" src={FacebookIcon} alt={login_facebook} />}
          </FormattedMessage>
          <span>
            <FormattedMessage id="LOGIN_FACEBOOK" />
          </span>
        </div>

        {isLoginPage && selectedCountry === KOREAN && (
          <>
            <div className="login_btn" onClick={onClickToggleLoginForm}>
              <img className="login_logo" src={EmailIcon} alt={'icon'} />
              <div>
                <FormattedMessage id="LOGIN_EMAIL" />
              </div>
              <img className="login_btn-arrow" src={toggleLoginFrom ? DropdownUpIcon : DropdownDownIcon} alt="arrow" />
            </div>

            {toggleLoginFrom && (
              <>
                <form id="login_form" onSubmit={onSubmit}>
                  <FormattedMessage id="PLACEHOLDER_LOGIN_EMAIL">
                    {(emailPlaceholder) => (
                      <input type="text" placeholder={emailPlaceholder} value={email} onChange={onChangeEmail} />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="PLACEHOLDER_LOGIN_PASSWORD">
                    {(passwordPlaceholder) => (
                      <input
                        type="password"
                        placeholder={passwordPlaceholder}
                        value={password}
                        onChange={onChangePassword}
                      />
                    )}
                  </FormattedMessage>

                  <button type="submit" className="login_btn">
                    <FormattedMessage id="LOGIN" />
                  </button>
                  <p className="forget-password">
                    <FormattedMessage id="FORGOT_MEMBER_INFORMATION" />
                    <button onClick={handleClickOpen}>
                      <FormattedMessage id="FIND_ID_PASSWORD" />
                    </button>
                  </p>
                </form>
                <FindIdPassword handleClickOpen={handleClickOpen} open={open} handleClose={handleClose} />
              </>
            )}
          </>
        )}
      </div>

      {isLoginPage ? (
        <>
          <p>
            <FormattedMessage id="NOT_A_MEMBER_YET" />
            <span>
              <Link to="/signup">
                <FormattedMessage id="COMMON_LINK_TO_SIGNUP" />
              </Link>
            </span>
          </p>
        </>
      ) : (
        <p className="signup_terms">
          <FormattedMessage
            id="SIGNUP_TERMS"
            values={{
              tos: (
                <Link to="/terms">
                  <FormattedMessage id="FOOTER_NAV_TERMS" />
                </Link>
              ),
              privacy: (
                <Link to="/privacy">
                  <FormattedMessage id="FOOTER_NAV_PRIVACY_STATEMENT" />
                </Link>
              ),
            }}
          />
        </p>
      )}
    </div>
  );
};
export default View;
