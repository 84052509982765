import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MyPage from '../../../../Commons/Layouts/MyPage';
import { GET } from '../../../../Commons/Utils/fetch';
import View from './View';

const Group = (props) => {
  const { orgId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState({ ...props, orgId });
  const fetchOrgMembers = async (orgId) => {
    const url = `/users/organizations/${orgId}`;
    try {
      const response = await GET({
        url,
        params: {
          page: 1,
          limit: 20,
        },
      });
      const { manager, member, supervisor } = response || {};
      const touchCount = (obj) => {
        const { total = 0, done = 0, fail = 0, warn = 0, ready = 0, ongoing = 0 } = obj || {};
        return {
          total,
          ongoing: ready + ongoing + warn,
          fail,
          done,
        };
      };

      return {
        ...response,
        manager: {
          ...manager,
          list: [
            ...manager.list.map((item) => {
              return {
                ...item,
                lecture: {
                  ...item.lecture,
                  count: touchCount(item.lecture.count),
                },
              };
            }),
          ],
        },
        member: {
          ...member,
          list: [
            ...member.list.map((item) => {
              return {
                ...item,
                lecture: {
                  ...item.lecture,
                  count: touchCount(item.lecture.count),
                },
              };
            }),
          ],
        },
        supervisor: {
          ...supervisor,
          lecture: {
            ...supervisor.lecture,
            count: touchCount(supervisor.lecture.count),
          },
        },
      };
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setIsLoading(true);
  }, []);
  useEffect(
    () =>
      fetchOrgMembers(orgId).then((members) => {
        setProperties((prev) => {
          return {
            ...prev,
            members,
          };
        });
      }),
    [orgId],
  );
  useEffect(() => {
    if (properties.hasOwnProperty('members')) {
      setIsLoading(false);
    }
  }, [properties]);
  return (
    <MyPage>
      <View isLoading={isLoading} {...properties} />
    </MyPage>
  );
};

export default Group;
