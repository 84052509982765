if (!Number.prototype.toFormattedPrice) {
  // eslint-disable-next-line
  Number.prototype.toFormattedPrice = function () {
    return String(this).replace(/\d(?=(\d{3})+$)/g, '$&,');
  };
}

if (!Number.prototype.toFormattedPhoneNumber) {
  Number.prototype.toFormattedPhoneNumber = function () {
    const input = String(this);
    const cleanInput = input.replaceAll(/\D/g, '');
    let result = '';
    const length = cleanInput.length;
    result = cleanInput.replace(/^82/, '0');
    let phoneNumber = '';
    if (result.length <= 3) {
      phoneNumber = result;
    } else if (result.length <= 7) {
      phoneNumber = `${result.slice(0, 3)}-${result.slice(3)}`;
    } else {
      phoneNumber = `${result.slice(0, 3)}-${result.slice(3, 7)}` + `-${result.slice(7, 11)}`;
    }
    result = phoneNumber;
    return result;
  };
}

if (!Number.prototype.formatVideoTime) {
  Number.prototype.formatVideoTime = function () {
    const input = Number(this);
    const hours = Math.floor(input / 3600);
    const minutes = Math.floor((input - hours * 3600) / 60);
    const seconds = input - hours * 3600 - minutes * 60;
    const result = `${hours > 0 ? `${hours}:` : ''}${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
    return result;
  };
}
