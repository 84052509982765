import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import './index.scss';

function PackageLectureDetail(props) {
  const { packageLecture } = props;
  return (
    <main className={`package-lecture package-lecture--${packageLecture.class_status}`}>
      <h1>[패키지] {packageLecture.FK_class.title}</h1>
      {packageLecture.FK_class.ClassSingle_ClassPackages.map(
        (ClassSingle_ClassPackage) => ClassSingle_ClassPackage.FK_class_single,
      ).map((FK_class_single, index) => (
        <ChildLectureCard
          key={FK_class_single.id}
          number={index + 1}
          lecture={FK_class_single}
          packageLecture={packageLecture}
        />
      ))}
    </main>
  );
}

function ChildLectureCard(props) {
  const { number, lecture, packageLecture } = props;
  return (
    <article className="package-lecture__child-lecture-card">
      <h2>
        {number}. {lecture.title}
      </h2>
      <hr />
      <button
        style={{
          width: '100%',
        }}
        onClick={() => window.open(`/classroom/${packageLecture.id}/${lecture.id}`)}
      >
        <span>강의실 바로가기</span>
      </button>
      {/*<Link*/}
      {/*  to={{*/}
      {/*    pathname: `/classroom/${packageLecture.id}`,*/}
      {/*    state: { targetClassId: lecture.id }*/}
      {/*  }}*/}
      {/*>*/}
      {/*  강의실 바로가기*/}
      {/*</Link>*/}
      {lecture.url_attached_file && (
        <Link
          to={{
            pathname: lecture.url_attached_file,
          }}
          target="_blank"
          className="file_download"
        >
          <FormattedMessage id="MY_PAGE_LECTURES_COLUMN_ATTACHED_FILE" />{' '}
          <FormattedMessage id="MY_PAGE_LECTURES_DOWNLOAD_FILE" />
        </Link>
      )}
    </article>
  );
}

export default PackageLectureDetail;
