export const categoryConstant = [
  {
    key: 'CATEGORIES_TITLE_COMPANY',
    type: 'company',
    options: [
      {
        id: 1,
        key: 'CATEGORIES_COMPANY_SM',
        type: 'company',
      },
      {
        id: 2,
        key: 'CATEGORIES_COMPANY_JYP',
        type: 'company',
      },
      {
        id: 3,
        key: 'CATEGORIES_COMPANY_YG',
        type: 'company',
      },
      {
        id: 4,
        key: 'CATEGORIES_COMPANY_CJ',
        type: 'company',
      },
      {
        id: 5,
        key: 'CATEGORIES_COMPANY_HYBE',
        type: 'company',
      },
      {
        id: 6,
        key: 'CATEGORIES_COMPANY_KAKAO',
        type: 'company',
      },
      {
        id: 7,
        key: 'CATEGORIES_ETC',
        type: 'company',
      },
    ],
  },
  {
    key: 'CATEGORIES_TITLE_FIELD',
    type: 'field',
    options: [
      {
        id: 1,
        key: 'CATEGORIES_FIELD_MUSIC',
        type: 'field',
      },
      {
        id: 2,
        key: 'CATEGORIES_FIELD_MOVIE',
        type: 'field',
      },
      {
        id: 3,
        key: 'CATEGORIES_FIELD_BROADCAST',
        type: 'field',
      },
      {
        id: 4,
        key: 'CATEGORIES_FIELD_PERFORMANCE',
        type: 'field',
      },
      {
        id: 5,
        key: 'CATEGORIES_ETC',
        type: 'field',
      },
    ],
  },
  {
    key: 'CATEGORIES_TITLE_PROCESS',
    type: 'process',
    options: [
      {
        id: 1,
        key: 'CATEGORIES_PROCESS_INTRODUCTORY',
        type: 'process',
      },
      {
        id: 2,
        key: 'CATEGORIES_PROCESS_TASK',
        type: 'process',
      },
      {
        id: 3,
        key: 'CATEGORIES_PROCESS_DOCUMENT',
        type: 'process',
      },
      {
        id: 4,
        key: 'CATEGORIES_PROCESS_PERSONALITY',
        type: 'process',
      },
      {
        id: 5,
        key: 'CATEGORIES_PROCESS_INTERVIEW',
        type: 'process',
      },
      {
        id: 6,
        key: 'CATEGORIES_ETC',
        type: 'process',
      },
    ],
  },
];

export const sortOptionConstant = [
  {
    key: 'NEW',
    value: 'new',
  },
  {
    key: 'VIEW',
    value: 'view',
  },
  {
    key: 'SELLING',
    value: 'selling',
  },
];
