import '../index.scss';

function Otherpay() {
  return (
    <div className="payment_term">
      <h1 className="page-title">決済代行サービスの利用規約</h1>
      <div className="page-body">
        <ol type="1" className="numbered-list" start="1">
          <li>電子金融取引の利用規約</li>
        </ol>
        <p>
          <a href="https://www.inicis.com/terms">https://www.inicis.com/terms</a>
          の規約内容を一括追加
        </p>
        <ol type="1" className="numbered-list" start="1">
          <li>個人情報の収集および利用に関する同意</li>
        </ol>
        <p>[個人情報の収集および利用目的]</p>
        <p>次の目的のために個人情報を取り扱います。取り扱った個人情報は、次の目的以外の用途には利用しません。</p>
        <p>
          <strong>1）電子金融取引サービスの提供に関する決済情報等個人情報の収集</strong>
        </p>
        <p>
          ·利用者が購入した財貨または役務の代金決済および現金領収書の発行・決済時の本人識別、認証、実名確認および利用者が決済した取引履歴を要求する場合の応対および確認・利用者が決済した取引の取り消しまたは払い戻し、商品配送等の電子商取引に関するサービスの提供・利用者が決済した代金の請求および受領・電子金融取引および通信課金サービスの利用ができない利用者（未成年者等）および不正利用者、違法利用者の不正利用を防止・サービスの提供および関連業務の処理に必要な同意または撤回等の意思確認·会社が提供する決済サービスの通知メッセージおよびメール送信・顧客のクレームおよび苦情対応・利用者のテーブルオーダー決済
        </p>
        <p>
          <strong>2）電子決済サービス契約の締結</strong>
          ·サービス提供契約の締結に関する相談・サービス提供契約の条件を確認するための本人識別および実名確認
        </p>
        <p>
          <strong>3）オフライン決済契約の締結</strong>
          ·サービス利用および契約の案内、その他の問い合わせ等顧客からの相談対応・その他の業務提携等様々な意見の聴取
        </p>
        <p>
          <strong>4）業務提携</strong>
          ·新規加盟契約、事業提携の提案、広告の提携、投資に関する問い合わせ等の様々な分野の意見聴取およびビジネスへの参入
        </p>
        <p>
          <strong>5）顧客相談及び苦情対応</strong>
          ·顧客と加盟店からの問い合わせ、サービスに関する問い合わせ（追加および変更）、紛争解決、提案、不正行為の通報等苦情対応
        </p>
        <p>
          <strong>6）レンタルサービスの支払いおよび契約者の本人確認</strong>
          ·レンタルサービス契約、ハッピーコール実施および契約者の本人確認、レンタル料金の請求および支払い・利用者の信用度を判断する情報
        </p>
        <p>
          <strong>7）採用管理</strong>
          ·エントリー、入社に関する問い合わせへの応答、就業保護対象者を確認し採用に優遇条件を付与・サポートスタッフの確保および人材登録
        </p>
        <p>
          <strong>8）新規サービスの開発および人口統計学的な特性に応じた統計データの活用</strong>
          ·利用頻度の把握および統計学的な特性に応じたサービスの提供・新規サービスの開発および統計学的な特性の分析・サービスの有効性確認、アクセス頻度の把握、会員のサービス利用に関する統計データの活用
        </p>
        <p>
          利用者の個人情報は、原則として個人情報の収集および利用目的が達成され次第、遅滞なく破棄します。ただし、以下の電子金融取引法、電子商取引等における消費者保護に関する法律、信用情報の利用および保護に関する法律、信用情報の利用および保護に関する法律の関係法令により保管する必要がある場合、関係法令で定められた一定の期間個人情報を保管します。この場合、会社は保管する情報をその保管目的にのみ利用し、保管期間は以下の通りです。
        </p>
        <p>·件当たり1万ウォンを超える電子金融取引に関する記録の保管理由：電子金融取引法による保管期間：5年</p>
        <p>·件当たり1万ウォン以下の電子金融取引に関する記録の保管理由：電子金融取引法による保管期間：1年</p>
        <p>
          ·契約または申出の撤回等に関する記録の保管理由：電子商取引等における消費者保護に関する法律による保管期間：5年
        </p>
        <p>
          ·代金決済および財貨等の供給に関する記録の保管理由：電子商取引等における消費者保護に関する法律による保管期間：5年
        </p>
        <p>
          ·消費者のクレームまたは紛争処理に関する記録の保管理由：電子商取引等における消費者保護に関する法律による保管期間：3年
        </p>
        <p>
          ·電子決済申し込みによるサービス提供契約の締結に関する情報の保管理由：相談履歴の確認および紛争発生に関する疎明資料活用の保管期間：相談完了後6ヶ月
        </p>
        <p>
          ·メッセージ（SMS / LMS）およびKakaoTalkのBiz
          Messageに関する送信記録の保管理由：通信事実確認資料活用の保管期間：メッセージ送信後1年
        </p>
        <p>
          ·オフライン決済契約の締結および問い合わせに関する収集情報の保管理由：提案、投資履歴の確認および検討結果返信の保管期間：問い合わせ受付後1年
        </p>
        <p>
          ·業務提携に関する問い合わせによる提携および投資提案者情報の保管理由：問い合わせ履歴の確認および応対情報の保管期間：問い合わせ受付後1年
        </p>
        <p>·エントリーによる応募者情報の保管理由：サポートスタッフの確保および人材登録情報の保管期間：1年</p>
        <p>
          ·レンタルサービスの支払いおよび契約者の本人確認情報の保管理由：レンタルサービス契約、ハッピーコール実施および契約者の本人確認、苦情対応情報の保管期間：契約終了時まで
        </p>
        <p>
          ·信用度判断情報照会の保管理由：信用情報の利用および保護に関する法律による保管期間：商取引関係が終了した日から5年まで
        </p>
        <p>ただし、法令に別途規定がある場合、その終了時まで</p>
      </div>
    </div>
  );
}

export default Otherpay;
