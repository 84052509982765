import { combineReducers } from 'redux';

import user from './user';
import locale from './locale';
import gnb from './gnb';

const rootReducer = combineReducers({
  user,
  locale,
  gnb,
});

export default rootReducer;
