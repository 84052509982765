import React from 'react';
import Container from './Container';
import Main from '../../../Commons/Layouts/Main';

const Login = (props) => {
  return (
    <Main>
      <Container {...props} />
    </Main>
  );
};

export default Login;
