import '../index.scss';

function Buying() {
  return (
    <div className="payment_term">
      <h1 className="page-title">Đồng ý mua</h1>
      <div className="page-body">
        <p>Tôi đã xác nhận tên bài giảng, giá tiền, thông tin vận chuyển,… của sản phẩm đặt hàng và đồng ý.</p>
      </div>
    </div>
  );
}

export default Buying;
