import { useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Commons/Store/Actions/user';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import Main from '../../Commons/Layouts/Main';
import { GET, PUT } from '../../Commons/Utils/fetch';
import ProfileBasicImage from '../../Assets/Images/profile-basic.png';
import CheckIcon from '../../Assets/Images/icon-checkbox-checked.png';
import UncheckIcon from '../../Assets/Images/icon-checkbox-unchecked.png';
import CameraIcon from '../../Assets/Images/icon-camera.png';
import ClearProfileImageIcon from '../../Assets/Images/icon-cancel-black.png';
import BgSignup from '../../Assets/Images/bg-signup.png';
import LogoWhite from '../../Assets/Images/logo-only-image-white.png';
import Rectangle1 from '../../Assets/Images/rectangle-1.png';
import Rectangle2 from '../../Assets/Images/rectangle-2.png';
import Rectangle3 from '../../Assets/Images/rectangle-3.png';
import swal from 'sweetalert';

const rectangleImageElements = (
  <>
    <img className="complete_wrap-rectangle1" src={Rectangle1} alt="rectangle" />
    <img className="complete_wrap-rectangle2" src={Rectangle2} alt="rectangle" />
    <img className="complete_wrap-rectangle3" src={Rectangle3} alt="rectangle" />
  </>
);

export const getKoreanPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  if (phoneNumber.length <= 0) return '';
  let _phoneNumber = phoneNumber;
  if (phoneNumber[0] === '0') _phoneNumber = phoneNumber.slice(1);
  return '82' + _phoneNumber;
};

const PrevUserSignupForm = () => {
  let file = null;

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const intl = useIntl();

  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);

  const imageInput = useRef(null);

  const [imageFile, setImageFile] = useState(null);
  const [userName, setUsername] = useState('');
  const [nickname, setNickname] = useState(query.nickname);
  const [checkNickname, setCheckNickname] = useState(null);
  const [nicknameSuccessMessage, setNicknameSuccessMessage] = useState(null);
  const [nicknameErrorMessage, setNicknameErrorMessage] = useState(null);
  const [email, setEmail] = useState(query.email);
  const [isEmailValidation, setIsEmailValidation] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  // 인증하기 => 인증번호 발송되었을시 보이도록
  const [hasCertification, setHasCertification] = useState(false);
  const [certificationNumber, setCertificationNumber] = useState('');
  const [checkCertificationNumber, setCheckCertificationNumber] = useState(false);
  const [isMarketing, setIsMarketing] = useState(true);
  const [isValidation, setIsValidation] = useState(true);

  const [isShowConFirmModal, setIsShowConFirmModal] = useState(false);

  useEffect(() => {
    const eje_token = localStorage.getItem('eje_token');
    if (isLoggedIn || eje_token) {
      history.push('/');
    }

    const { email } = query;
    if (email) {
      setEmail(email);
    }

    alert('2021년 10월 4일자로 사이트 정책이 변경되었습니다. 이에 대한 동의 및 필요 정보를 입력 받고 있어요!');
  }, []);

  useEffect(() => {
    const validation = [
      userName,
      nickname,
      checkNickname,
      email,
      phoneNumber,
      hasCertification,
      certificationNumber,
      checkCertificationNumber,
    ]
      .map((value) => {
        if (typeof value === 'string') return value.trim();
        else return value;
      })
      .every((value) => value);

    // username, nickname, email, phoneNumber, certificationNumber => trim() 처리 후 하나라도 빈 값이라면 validation 통과하지 못함
    if (validation) {
      setIsValidation(true);
    } else {
      setIsValidation(false);
    }
  }, [
    userName,
    nickname,
    checkNickname,
    email,
    phoneNumber,
    hasCertification,
    certificationNumber,
    checkCertificationNumber,
  ]);

  const onChangeUserName = useCallback((e) => {
    const value = e.target.value;
    setUsername(value);
  }, []);

  const onChangeNickname = (e) => {
    const value = e.target.value;
    setNickname(value);

    // eslint-disable-next-line
    var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]$/;

    if (reg.test(value)) {
      setNicknameErrorMessage(
        intl.formatMessage({
          id: 'SIGNUP_FORM_NICKNAME_VALIDATION_ERROR_MESSAGE',
        }),
      );

      setCheckNickname(false);
    } else {
      setNicknameErrorMessage(null);
    }
  };

  const onChangeEmail = useCallback(
    (e) => {
      const value = e.target.value;
      setEmail(value);

      var reg_email =
        // eslint-disable-next-line
        /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

      if (reg_email.test(email)) {
        setIsEmailValidation(true);
      } else {
        setIsEmailValidation(false);
      }
    },
    [email],
  );

  const onChangePhoneNumber = useCallback((e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(value);
  }, []);

  const onChangeCertificationNumber = useCallback((e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    setCertificationNumber(value);
  }, []);

  const onChangeImage = (e) => {
    e.preventDefault();

    if (e.target?.files) {
      const _file = e.target.files[0];

      if (!_file) return;

      if (_file.size > 1024 * 1024 * 10) {
        // 용량 초과시 경고후 해당 파일의 용량도 보여줌
        window.alert(
          intl.formatMessage({
            id: 'SIGNUP_FROM_ALERT_FILE_UPLOAD_ERROR_MESSAGE',
          }),
        );
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(_file);

        file = _file;

        reader.addEventListener(
          'load',
          function () {
            // convert image file to base64 string
            setImageFile(reader.result);
          },
          false,
        );
      }
    }
  };

  const onClickCheckNicknameOverlap = async () => {
    if (!nickname.trim() || nicknameErrorMessage) return;

    const params = {
      nickname,
    };

    const overlap = await GET({
      url: '/auth/check/nickname',
      params,
      header: {
        EJE_API_KEY: 'auth',
      },
    });

    if (overlap.success) {
      setNicknameSuccessMessage(
        intl.formatMessage({
          id: 'SIGNUP_FORM_SUCCESS_NICKNAME',
        }),
      );
      setNicknameErrorMessage(null);
    } else {
      setNicknameErrorMessage(
        intl.formatMessage({
          id: 'SIGNUP_FORM_FAIL_NICKNAME',
        }),
      );
      setNicknameSuccessMessage(null);
    }

    setCheckNickname(overlap.success);
  };

  const onClickImageUpload = useCallback(() => {
    imageInput.current.click();
  }, []);

  const onClickClearProfileImage = (e) => {
    e.stopPropagation();

    file = null;
    setImageFile(null);
  };

  // 휴대폰 인증 번호 발송
  const onClickSendVerificationCode = useCallback(async () => {
    if (!phoneNumber.trim()) return;

    try {
      const params = {
        phone: getKoreanPhoneNumber(phoneNumber),
      };

      // 인증번호 발송
      const checkRes = await GET({
        url: `/auth/check/phone/sms`,
        params,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      const { success, message } = checkRes;
      if (success) {
        await swal({
          icon: 'success',
          text: intl.formatMessage({
            id: 'SIGNUP_FORM_ALERT_SUCCESS_SEND',
          }),
        });
        setHasCertification(true);
      } else {
        if (message)
          swal({
            icon: 'error',
            text: intl.formatMessage({
              id: message,
            }),
          });
      }
    } catch (error) {
      console.error(error);
      swal({
        icon: 'error',
        text: intl.formatMessage({
          id: 'ERROR_NETWORK',
        }),
      });
    }
  }, [phoneNumber, intl]);

  // 휴대폰 본인 인증
  const onClickCheckCertificationNumber = useCallback(async () => {
    if (!certificationNumber.trim()) return;
    if (certificationNumber.length !== 6) {
      swal({
        icon: 'warning',
        text: intl.formatMessage({
          id: 'SIGNUP_FORM_AUTHENTICATE_ERROR',
        }),
      });
      return;
    }

    try {
      const params = {
        code: certificationNumber,
      };

      const checkPhoneResponse = await GET({
        url: '/auth/check/phone',
        params,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      const { success } = checkPhoneResponse;
      if (success) {
        await swal({
          icon: 'success',
          text: intl.formatMessage({
            id: 'SIGNUP_FORM_ALERT_SUCCESS_CHECK_CERTIFICATION',
          }),
        });
        setCheckCertificationNumber(success);
      } else {
        swal({
          icon: 'error',
          text: intl.formatMessage({
            id: 'SIGNUP_FORM_ALERT_FAIL_CHECK_CERTIFICATION',
          }),
        });
      }
    } catch (error) {
      console.error(error);
      swal({
        icon: 'error',
        text: intl.formatMessage({
          id: 'ERROR_NETWORK',
        }),
      });
    }
  }, [certificationNumber, intl]);

  const onClickIsMarketing = useCallback((flag) => {
    setIsMarketing(flag);
  }, []);

  const onClickLinkToMain = useCallback(() => {
    history.push('/');
  }, [history]);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      // TODO: FormData 구성 Params
      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      } else {
        formData.append('image_profile', imageFile);
      }

      formData.append('name', userName);
      formData.append('nickname', nickname);
      formData.append('email', email);
      // 국가 코드 번호 + 핸드폰 번호
      formData.append('phone', getKoreanPhoneNumber(phoneNumber));
      formData.append('is_marketing_on', isMarketing);
      formData.append('authorization', query.token);

      const header = {
        EJE_API_KEY: query.token,
      };

      await PUT({
        url: `/user/prev`,
        body: formData,
        header,
      }).then((res) => {
        if (res.token) {
          localStorage.setItem('eje_token', res.token);
          dispatch(login(res.user));
          setIsShowConFirmModal(true);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Main>
      <div className="firstuser_signup_form_wrap">
        <div className="inner_wrap">
          <div className="signup_image" style={{ backgroundImage: `url(${BgSignup})` }}>
            <div className="signup_intro">
              <p>
                <img src={LogoWhite} alt="signup_intro" />
              </p>
              <p>
                <FormattedMessage id="SIGNUP_FROM_INTRO" />
              </p>
            </div>
          </div>
          <div className="signup_form_filed">
            <div className="title">
              <h2>
                <FormattedMessage id="SIGNUP_FORM_TITLE" />
              </h2>
              <p className="message">
                <FormattedMessage id="SIGNUP_FORM_MESSAGE" />

                <span>
                  <FormattedMessage id="SIGNUP_FORM_SUB_MESSAGE" />
                </span>
              </p>
            </div>

            <form id="signup_form" onSubmit={onSubmit}>
              <div className="image_upload_filed">
                <p className="label">
                  <FormattedMessage id="SIGNUP_FORM_PROFILE_IMAGE" />
                </p>
                <input ref={imageInput} accept=".jpg, .png" type="file" name="file" hidden onChange={onChangeImage} />
                <div className="profile_image" onClick={onClickImageUpload}>
                  {imageFile ? (
                    <>
                      <FormattedMessage id="PROFILE_IMAGE_ALT">
                        {(profile_image_alt) => (
                          <div className="profile_overlap_wrap">
                            <img src={imageFile} alt={profile_image_alt} />
                          </div>
                        )}
                      </FormattedMessage>

                      <span className="profile_upload_icon">
                        <img src={CameraIcon} alt="profile_image_upload_icon" />
                      </span>
                    </>
                  ) : (
                    <img src={ProfileBasicImage} alt="profile_default_image" />
                  )}
                  {imageFile && (
                    <span className="clear_profile_image" onClick={onClickClearProfileImage}>
                      <img src={ClearProfileImageIcon} alt="clear_profile_image" />
                    </span>
                  )}
                </div>
              </div>

              <div className="text_filed_wrap">
                <p className="label">
                  <FormattedMessage id="SIGNUP_FORM_USERNAME" />
                </p>
                <div className="text_filed user_name">
                  <FormattedMessage id="SIGNUP_FORM_USERNAME_PLACEHOLDER">
                    {(username_placeholder) => (
                      <input
                        type="text"
                        value={userName}
                        placeholder={username_placeholder}
                        minLength="2"
                        maxLength="20"
                        onChange={onChangeUserName}
                      />
                    )}
                  </FormattedMessage>
                </div>
              </div>

              <div className="text_filed_wrap">
                <p className="label">
                  <FormattedMessage id="SIGNUP_FORM_NICKNAME" />
                </p>
                <div className="text_filed nickname">
                  <FormattedMessage id="SIGNUP_FORM_NICKNAME_PLACEHOLDER">
                    {(nickname_placeholder) => (
                      <input
                        type="text"
                        placeholder={nickname_placeholder}
                        value={nickname}
                        maxLength="10"
                        onChange={onChangeNickname}
                      />
                    )}
                  </FormattedMessage>

                  <span className="btn" onClick={onClickCheckNicknameOverlap}>
                    <FormattedMessage id="SIGNUP_FORM_CHECK_OVERLAP" />
                  </span>
                </div>
                {nicknameSuccessMessage && <p className="success_message">{nicknameSuccessMessage}</p>}
                {nicknameErrorMessage && <p className="error_message">{nicknameErrorMessage}</p>}
              </div>

              <div className="text_filed_wrap email_filed_wrap">
                <p className="label">
                  <FormattedMessage id="SIGNUP_FORM_EMAIL" />
                </p>
                <div className="text_filed email">
                  <FormattedMessage id="SIGNUP_FORM_EMAIL_PLACEHOLDER">
                    {(email_placeholder) => (
                      <input type="email" placeholder={email_placeholder} value={email} onChange={onChangeEmail} />
                    )}
                  </FormattedMessage>
                </div>
                {isEmailValidation !== null && !isEmailValidation && (
                  <p className="error_message">
                    <FormattedMessage id="SIGNUP_FORM_FAIL_EMAIL" />
                  </p>
                )}
              </div>

              <div className="text_filed_wrap">
                <p className="label">
                  <FormattedMessage id="SIGNUP_FORM_PHONE_NUMBER" />
                </p>
                <div className="text_filed phone_number_filed">
                  <div className="phone_number_inner_filed">
                    <FormattedMessage id="SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER">
                      {(phone_number_placeholder) => (
                        <input
                          type="text"
                          placeholder={phone_number_placeholder}
                          value={phoneNumber}
                          onChange={onChangePhoneNumber}
                        />
                      )}
                    </FormattedMessage>

                    <span className="btn" onClick={onClickSendVerificationCode}>
                      <FormattedMessage id="SIGNUP_FORM_SEND_VERIFICATION_CODE" />
                    </span>
                  </div>
                </div>
                {hasCertification && (
                  <div className="text_filed certification_filed">
                    <FormattedMessage id="SIGNUP_FORM_AUTHENTICATE_PLACEHOLDER">
                      {(authenticate_placeholder) => (
                        <input
                          type="text"
                          placeholder={authenticate_placeholder}
                          value={certificationNumber}
                          onChange={onChangeCertificationNumber}
                        />
                      )}
                    </FormattedMessage>

                    <span className="btn" onClick={onClickCheckCertificationNumber}>
                      <FormattedMessage id="SIGNUP_FORM_AUTHENTICATE" />
                    </span>
                  </div>
                )}
              </div>

              <div className="text_filed_wrap benefit_filed">
                <p className="label">
                  <FormattedMessage id="SIGNUP_FORM_TERMS" />
                </p>
                <div className="benefit_consent_receive">
                  <p>
                    <FormattedMessage id="SIGNUP_FORM_TERMS_MESSAGE" />
                  </p>
                  <div className="checkbox_filed">
                    <div onClick={() => onClickIsMarketing(true)}>
                      <span className="checkbox">
                        <img src={isMarketing ? CheckIcon : UncheckIcon} alt="agreement_marketing" />
                      </span>
                      <FormattedMessage id="SIGNUP_FORM_TERMS_AGREEMENT" />
                    </div>
                    <div onClick={() => onClickIsMarketing(false)}>
                      <span className="checkbox">
                        <img src={!isMarketing ? CheckIcon : UncheckIcon} alt="disagreement_marketing" />
                      </span>
                      <FormattedMessage id="SIGNUP_FORM_TERMS_DISAGREE" />
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" disabled={isValidation ? false : true} id="signup_complete_click">
                <FormattedMessage id="SIGNUP_FORM_COMPLETED" />
              </button>
            </form>
          </div>
        </div>
      </div>

      {isShowConFirmModal && (
        <div className="modal_outside">
          <div className="signup_confirm_modal_wrap">
            {rectangleImageElements}
            <h3 className="title">
              <FormattedMessage id="SIGNUP_FORM_CONFIRM_MODAL_TITLE" />
            </h3>
            <div className="message">
              <FormattedMessage id="SIGNUP_FORM_CONFIRM_MODAL_MESSAGE" />
            </div>
            <span className="go_to_main" onClick={onClickLinkToMain}>
              <FormattedMessage id="SIGNUP_FORM_CONFIRM_MODAL_LINK_TO_MAIN" />
            </span>
          </div>
        </div>
      )}
    </Main>
  );
};

export default PrevUserSignupForm;
