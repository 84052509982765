import Model from './Model';

export default class Lecture extends Model {
  get price_discounted() {
    if (this.isDiscountAvailable) {
      if (this.usesUSD) {
        return Math.round(this.price_original * (1 - this.discount_rate / 100));
      } else {
        return Math.round((this.price_original * (1 - this.discount_rate / 100)) / 100) * 100;
      }
    } else {
      return this.price_original;
    }
  }

  get usesUSD() {
    return this.listen_type === 'foreigner';
  }
}
