import '../index.scss';

function Otherpay() {
  return (
    <div className="payment_term">
      <h1 className="page-title">Điều khoản sử dụng dịch vụ thanh toán hộ</h1>
      <div className="page-body">
        <ol type="1" className="numbered-list" start="1">
          <li>Điều khoản sử dụng giao dịch tài chính điện tử</li>
        </ol>
        <p>
          Áp dụng toàn bộ nội dung điều khoản trong
          <a href="https://www.inicis.com/terms">https://www.inicis.com/terms</a>
        </p>
        <ol type="1" className="numbered-list" start="1">
          <li>Đồng ý thu thập và sử dụng thông tin cá nhân</li>
        </ol>
        <p>[Mục đích thu thập và sử dụng thông tin cá nhân]</p>
        <p>
          Thông tin cá nhân được xử lý cho mục đích sau đây. Thông tin cá nhân được xử lý sẽ không được sử dụng cho bất
          kỳ mục đích nào khác ngoài những mục đích sau.
        </p>
        <p>
          <strong>
            1) Thu thập thông tin cá nhân như thông tin thanh toán để cung cấp dịch vụ giao dịch tài chính điện tử
          </strong>
        </p>
        <p>
          · Thanh toán hàng hóa hoặc dịch vụ mà người dùng mua và phát hành biên lai, trả lời và xác nhận các yêu cầu
          nhận dạng, xác thực, xác minh tên thật và chi tiết giao dịch mà người dùng thanh toán trong quá trình thanh
          toán, cung cấp các dịch vụ liên quan đến giao dịch thương mại điện tử như hủy giao dịch hoặc hoàn tiền cho các
          giao dịch do người dùng thanh toán, giao sản phẩm, đề nghị thanh toán và nhận số tiền người dùng thanh toán,
          ngăn chặn việc sử dụng bất hợp pháp từ những đối tượng người dùng không thể thực hiện các giao dịch tài chính
          điện tử và các dịch vụ thanh toán cước viễn thông (như trẻ vị thành niên) và những người dùng lý lịch xấu, bất
          hợp pháp, xác nhận ý kiến càn cho việc cung cấp dịch vụ và xử lý các nghiệp vụ liên quan như đồng ý hoặc rút
          lại· gửi tin nhắn và thư thông báo dịch vụ thanh toán do công ty cung cấp, xử lý khiếu nại của khách hàng,
          thanh toán đơn đặt hàng của người dùng
        </p>
        <p>
          <strong>2) Ký hợp đồng dịch vụ thanh toán điện tử</strong>· Tư vấn liên quan tới ký hợp đồng cung cấp dịch vụ,
          xác định danh tính và tên thật để xác nhận những điều kiện hợp đồng cung cấp dịch vụ
        </p>
        <p>
          <strong>3) Ký hợp đồng thanh toán trực tiếp</strong>· Lắng nghe các ý kiến ​​khác nhau về các nghiệp vụ như tư
          vấn khách hàng, gồm hướng dẫn sử dụng dịch vụ và hợp đồng, xử lý các thắc mắc khác và các nghiệp vụ quan hệ
          đối tác khác
        </p>
        <p>
          <strong>4) Quan hệ đối tác</strong>· Lắng nghe các ý kiến của nhiều lĩnh vực và tham gia kinh doanh như ký hợp
          đồng nhượng quyền mới, đề xuất đối tác kinh doanh, đối tác quảng cáo, đề nghị đầu tư,...
        </p>
        <p>
          <strong>5) Tư vấn khách hàng và xử lý khiếu nại</strong>· Hỏi khách hàng và đơn vị nhượng quyền, hỏi về dịch
          vụ (thêm hoặc thay đổi), giải quyết tranh chấp, đề xuất, yêu cầu về dịch vụ (bổ sung và thay đổi), giải quyết
          tranh chấp, nghiệp vụ xử lý khiếu nại như đề xuất, báo cáo điểm không hợp lý,...
        </p>
        <p>
          <strong>6) Xác nhận danh tính người thanh toán và ký hợp đồng dịch vụ thuê</strong>· Hợp đồng dịch vụ thuê,
          thực hiện cuộc gọi đánh giá mức độ hài lòng (happy call), và xác nhận danh tính người ký hợp đồng, yêu cầu và
          thanh toán phí thuê, thông tin đánh giá mức độ tín dụng của người dùng
        </p>
        <p>
          <strong>7) Quản lý tuyển dụng</strong>· Đơn xin việc, các câu trả lời cho câu hỏi liên quan đến việc vào công
          ty, xác nhận đôi tượng được ưu tiên tìm việc, cho điều kiện ưu đãi ở giai đoạn tuyển dụng, lưu nhân lực ứng
          tuyển và đăng ký đội ngũ nhân tài
        </p>
        <p>
          <strong>8) Phát triển các dịch vụ mới và ứng dụng vào tài liệu thống kê theo đặc điểm nhân khẩu học</strong>·
          Kiểm tra tần suất sử dụng và cung cấp dịch vụ theo đặc điểm thống kê, phát triển dịch vụ mới và phân tích đặc
          điểm thống kê, kiểm tra tính hợp lệ của dịch vụ, kiểm tra tần suất truy cập, ứng dụng tài liệu thống kê về sử
          dụng dịch vụ của hội viên
        </p>
        <p>
          Trên nguyên tắc, thông tin cá nhân của người dùng sẽ được hủy ngay lập tức sau khi đã hoàn thành mục đích thu
          thập và sử dụng thông tin cá nhân. Tuy nhiên, trường hợp cần bảo lưu thông tin cá nhân theo luật giao dịch tài
          chính điện tử, luật về bảo vệ người tiêu dùng trong giao dịch điện tử, luật về sử dụng và bảo vệ thông tin tín
          dụng như sau thì sẽ thực hiện bảo lưu thông tin cá nhân trong một khoảng thời gian nhất định mà các bộ luật
          quy định. Trong trường hợp này, công ty chỉ sử dụng thông tin được lưu trữ cho mục đích lưu trữ và thời hạn
          lưu trữ sẽ như sau.
        </p>
        <p>
          · Lý do bảo lưu lịch sử với giao dịch tài chính điện tử trên 10,000 won một lần : Thời hạn bảo lưu theo luật
          giao dịch tài chính điện tử : 5 năm
        </p>
        <p>
          · Lý do bảo lưu lịch sử với giao dịch tài chính điện tử dưới 10,000 won một lần : Thời hạn bảo lưu theo luật
          giao dịch tài chính điện tử : 1 năm
        </p>
        <p>
          · Lý do bảo lưu lịch sử về việc rút hợp đồng hoặc ký hợp đồng : Thời hạn bảo lưu theo luật về bảo vệ người
          tiêu dùng trong giao dịch điện tử : 5 năm
        </p>
        <p>
          · Lý do bảo lưu lịch sử về thanh toán và cung cấp hàng hóa : Thời hạn bảo lưu theo luật về bảo vệ người tiêu
          dùng trong giao dịch điện tử : 5 năm
        </p>
        <p>
          · Lý do bảo lưu lịch sử về xử lý khiếu nại hoặc tranh chấp của người tiêu dùng: Thời hạn bảo lưu theo luật về
          bảo vệ người tiêu dùng trong giao dịch điện tử : 3 năm
        </p>
        <p>
          · Lý do bảo lưu lịch sử vể ký hợp đồng cung cấp dịch vụ thông qua đăng ký thanh toán điện tử : Thời hạn bảo
          lưu ứng dụng tài liệu giải thích về xác nhận lịch sử tư vấn và phát sinh tranh chấp : 6 tháng sau khi hoàn
          thành tư vấn
        </p>
        <p>
          · Lý do bảo lưu lịch sử gửi tin nhắn (SMS/LMS) và tin nhắn công việc Kakaotalk : Thời hạn bảo lưu ứng dụng tài
          liệu xác nhận đã liên hệ : 1 năm sau khi gửi tin nhắn
        </p>
        <p>
          · Lý do bảo lưu thông tin thu thập được về ký hợp đồng thanh toán trực tiếp và các thắc mắc : Thời hạn bảo lưu
          lịch sử xác nhận đề nghị, đầu tư và trả lời kết quả kiểm tra : 1 năm sau khi tiếp nhận thắc mắc
        </p>
        <p>
          · Lý do bảo lưu thông tin của người đề nghị đối tác và đầu tư thông qua hỏi về đối tác kinh doanh : Thời hạn
          bảo lưu lịch sử xác nhận câu hỏi và trả lời : 1 năm sau khi tiếp nhận thắc mắc
        </p>
        <p>
          · Lý do bảo lưu thông tin người ứng tuyển thông qua xin việc công ty : Thời hạn bảo lưu nhân lực ứng tuyển và
          đăng ký đội ngũ nhân tài : 1 năm
        </p>
        <p>
          · Lý do bảo lưu thông tin thanh toán dịch vụ thuê và xác nhận danh tính người thuê : Thời hạn bảo lưu thông
          tin hợp đồng thuê, cuộc gọi đánh giá mức độ hài lòng và xác nhận danh tính người ký hợp đồng, xử lý khiếu nại
          : Cho tới khi kết thúc hợp đồng
        </p>
        <p>
          · Lý do bảo lưu thông tin tra cứu về thông tin đánh giá mức độc tín dụng : Thời hạn bảo lưu theo pháp luật về
          sử dụng và bảo vệ thông tin tín dụng : 5 năm kể từ ngày kết thúc quan hệ giao dịch thương mại.
        </p>
        <p>Tuy nhiên, trong trường hợp điều luật có quy định riêng thì bảo lưu tới thời điểm được quy định đó</p>
      </div>
    </div>
  );
}

export default Otherpay;
