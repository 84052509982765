import Meta from '../../../Commons/Components/Meta';
import Main from '../../../Commons/Layouts/Main';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { GET } from '../../../Commons/Utils/fetch';
import dayjs from 'dayjs';
import Pagination from '../../../Commons/Components/Pagination';
import queryString from 'query-string';
import { AttachFileOutlined } from '@mui/icons-material';
import { useMediaQuery } from 'react-responsive';

const metaInfo = {
  title: '이벤트 | 엔터테인먼트 미디어 취업 엔터잡에듀',
  description: '엔터테인먼트 미디어 취업 정보를 제공하는 엔터잡에듀의 이벤트 페이지입니다.',
};

const Event = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const history = useHistory();
  const { target } = useParams();
  const { page = 1 } = queryString.parse(location.search);

  const [isLoaded, setIsLoaded] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [list, setList] = useState([]);
  const [topListCount, setTopListCount] = useState(0);
  const [detail, setDetail] = useState({});
  const [currentPage, setCurrentPage] = useState(+page);
  const readEventIds = JSON.parse(localStorage.getItem('readEventHistories')) || [];

  const fetchList = async () => {
    const { page = 1 } = queryString.parse(location.search);
    return await GET({
      url: '/auth/posts',
      params: {
        boardCode: 'event',
        page,
      },
    });
  };
  const fetchDetail = async (id) => {};

  const fetchData = useCallback(async () => {
    // 목록 획득
    const { success, data } = await fetchList();
    if (success) {
      const { count, topPosts, posts } = data;
      setListCount(count);
      setList([...topPosts, ...posts]);
      setTopListCount(topPosts.length);
      setIsLoaded(true);
    }
    if (+target > 0) {
      // 상세 획득
      const noticeDetail = await fetchDetail(target);
      setDetail(noticeDetail);
    }
  }, [target, page]);

  useEffect(() => {
    fetchData();
  }, [target, page]);

  return (
    <Main>
      <Meta metaInfo={metaInfo} />
      <style jsx="true">{`
        #event-list-container {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          box-sizing: border-box;
          padding: 2.625rem 0 8rem;
          display: flex;
          flex-direction: column;
          row-gap: 2.5rem;
        }

        #event-list-container h2 {
          font-size: 1.5rem;
          letter-spacing: -0.4px;
          color: #000000;
          font-weight: bold;
        }

        #event-list-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 1.25rem;
        }

        #event-list {
          width: 100%;
          min-height: 910px;
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 0;
          margin: 0;
          user-select: none;
        }

        #event-list li {
          width: 100%;
          height: 100%;
          border-bottom: 1px solid #e5e5e5;
          overflow: hidden;
        }

        #event-list li a,
        #event-list li.event-list-header {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 1.25rem 0.5rem 1rem;
          transition: background-color 0.2s ease-in-out;
          text-align: center;
          height: 4rem;
          vertical-align: middle;
        }

        #event-list li a span,
        #event-list li.event-list-header span {
          max-height: 3rem;
          line-height: 1.5rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        #event-list li.event-list-header {
          font-weight: bold;
          background-color: #f5f5f5;
          border-radius: 0.5rem 0.5rem 0 0;
        }

        .title-wrap {
          width: 100%;
          max-width: 60%;
          display: flex;
        }

        span.new-event {
          display: inline !important;
          margin-left: 0.5rem;
          font-size: 0.75rem;
          font-weight: bold;
          animation: colorChange 1.7s ease-in-out infinite alternate;
        }

        @keyframes colorChange {
          0% {
            color: #f24462;
          }
          50% {
            color: rgba(255, 59, 59, 0.2);
          }
          100% {
            color: #f24462;
          }
        }

        span.read-event {
          color: rgba(162, 162, 162, 0.6);
          animation: none;
        }

        .date-wrap {
          width: 40%;
        }

        .file-wrap {
          width: 6rem;
        }

        .event-title {
          text-align: left;
          padding: 0 1.25rem;
        }

        .top-event-title {
          font-weight: bold;
        }

        #event-list li a:hover {
          background-color: #f5f5f5;
        }

        #event-list li:last-child {
          border-bottom: none;
        }

        #event-pagination {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 0.5rem;
        }

        #event-pagination li {
          min-width: 2rem;
          height: 2rem;
          border-radius: 1rem;
          font-size: 0;
          overflow: hidden;
          cursor: pointer;
          padding: 0.5rem;
        }

        #event-pagination li:hover {
          background-color: #e5e5e5;
        }

        #event-pagination li a {
          width: 100%;
          height: 100%;
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.125rem;
        }

        .top-event-pin-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
        }

        @media screen and (max-width: 768px) {
          #event-list-container {
            padding: 1.5rem 1rem 8rem;
            row-gap: 1rem;
          }

          #event-list-container h2 {
            font-size: 1.25rem;
          }

          #event-list-wrap #event-list li.event-list-header {
            display: none;
          }

          #event-list-wrap #event-list li a {
            display: flex;
            flex-wrap: nowrap;
            column-gap: 0.25rem;
            padding: 1rem 0.25rem 0.75rem;
            justify-content: space-between;
          }

          #event-list-wrap #event-list li a span {
            width: auto;
            min-width: 20%;
            text-align: left;
            white-space: nowrap;
            font-size: 0.875rem;
            padding: 0;
            height: 100%;
          }

          #event-list-wrap #event-list li a span.event-title {
            text-align: left;
            width: 60%;

            max-height: 2.5rem;
            line-height: 1.25rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            word-break: break-all;
            white-space: normal;
            overflow: hidden;
          }

          #event-list-wrap #event-list li a span.event-date {
            color: #999999;
            font-size: 0.75rem;
          }

          #event-list-wrap #event-list li a span.event-file {
            color: #999999;
            font-size: 0.75rem;
            min-width: 20px;
          }

          #event-list {
            min-height: 0;
          }
        }
      `}</style>
      <div id="event-list-container">
        <div id="event-title-wrap">
          <h2>
            <FormattedMessage id="HEADER_USER_NAV_EVENT" />
          </h2>
        </div>
        <div id="event-list-wrap">
          <ol id="event-list">
            <li className="event-list-header">
              <span className="title-wrap">제목</span>
              <span className="date-wrap">작성일</span>
              <span className="file-wrap">첨부파일</span>
            </li>
            {list.map((item, index) => (
              <li id={`event-${item.id}`} key={index}>
                <Link to={`/event/${item.id}?page=${currentPage}`}>
                  <div className="top-event-pin-wrap">{index < topListCount && <span>📌</span>}</div>
                  <span className={`title-wrap event-title ${index < topListCount ? 'top-event-title' : ''}`}>
                    {item.title}
                    {item.isRecent && (
                      <span
                        className={`new-event ${
                          item.isRead || readEventIds.includes(item.id.toString()) ? 'read-event' : ''
                        }`}
                      >
                        New
                      </span>
                    )}
                  </span>
                  {isMobile && (
                    <span className="file-wrap event-file">
                      {item.fileCount && item.fileCount > 0 ? (
                        <AttachFileOutlined
                          fontSize="small"
                          sx={{
                            color: '#999999',
                            marginLeft: '0.25rem',
                            marginBottom: '-0.125rem',
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </span>
                  )}
                  <span className="date-wrap event-date">{dayjs(item.createdAt).format('YYYY-MM-DD')}</span>
                  {isMobile || (
                    <span className="file-wrap event-file">
                      {item.fileCount && item.fileCount > 0 ? (
                        <AttachFileOutlined
                          fontSize="small"
                          sx={{
                            color: '#999999',
                            marginLeft: '0.25rem',
                            marginBottom: '-0.125rem',
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </span>
                  )}
                </Link>
              </li>
            ))}
          </ol>
          {listCount > 10 && (
            <Pagination
              total={listCount}
              limit={10}
              currentPage={parseInt(page)}
              paginate={async (page) => {
                await setCurrentPage(page);
                if (page === 1) history.push('/events');
                else history.push(`/events?page=${page}`);
              }}
            />
          )}
        </div>
      </div>
    </Main>
  );
};

export default Event;
