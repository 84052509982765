export default function TermsOfUse(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">Điều khoản sử dụng</h2>
      <article className="page sans">
        <div className="page-body">
          <p>
            <strong>Chương 1 Điều khoản chung</strong>
          </p>
          <p>
            <strong>Điều 1 [Mục đích]</strong>
          </p>
          <p>
            Điều khoản này nhằm để công ty CONNEXUSLAB (sau đây gọi là “công ty”) quy định các nội dung như quyền lợi,
            nghĩa vụ và trách nhiệm giữa công ty và hội viên liên qua tới điều kiện gia nhập và sử dụng dịch vụ thông
            tin giáo dục (sau đây gọi là “dịch vụ”) mà công ty cung cấp cho khách hàng sử dụng (sau đây gọi là “hội
            viên”).
          </p>
          <p>
            <strong>Điều 2 [Định nghĩa]</strong>
          </p>
          <p>Định nghĩa của những thuật ngữ sử dụng ở điều khoản này như sau.</p>
          <p>
            1.“Người dùng” là chỉ những người “hội viên” và “không phải hội viên” truy cập vào website của “công ty”, sử
            dụng những “nội dung” và các dịch vụ mà “công ty” cung cấp theo điều khoản này.
          </p>
          <p>
            2.“Hội viên” là chỉ những người truy cập vào website công ty và đồng ý với điều khoản này, với tư cách là
            nugời ký hợp đồng sử dụng với công ty và được cấp ID, có thể sử dụng liên tục thông tin và dịch vụ mà công
            ty cung cấp.
          </p>
          <p>
            3.“Nội dung” là chỉ những tài liệu hoặc thông tin, với ý nghĩa là những bài giảng trực tuyến và những thông
            tin liên quan khác mà công ty cung cấp, được thể hiện bằng ký hiệu, chữ, giọng nói, âm thanh, hình ảnh, hoặc
            video,… trong mạng viễn thông, theo quy định tại mục 1 khoản 1 điều 2 của Luật về khuyến khích sử dụng mạng
            viễn thông và bảo vệ thông tin của Hàn Quốc.
          </p>
          <p>
            4.“ID” là chỉ tổ hợp của chữ hoặc số do hội viên chỉ định và công ty duyệt, nhằm phân biệt hội viên và sử
            dụng dịch vụ.
          </p>
          <p>
            5.“Mật khẩu (PASSWORD)” là chỉ tổ hợp của chữ hoặc số do chính hội viên chỉ định, nhằm xác nhận đây là hội
            viên dùng đúng ID và nhằm bảo vệ thông tin cá nhân của hội viên khi sử dụng dịch vụ.
          </p>
          <p>6.“Hòm thư điện tử” là chỉ hòm thư qua mạng internet hoặc hòm thư sử dụng phương tiện điện tử.</p>
          <p>
            7.“Người vận hành (người quản lý)” là chỉ người hoặc công ty mà công ty chọn ra nhằm quản lý toàn bộ dịch vụ
            và vận hành thông suốt.
          </p>
          <p>
            8.“Bài đăng của hội viên” là chỉ những thông tin như bài viết, hình ảnh, các tập tin và đường dẫn, các nhận
            xét,… mà người dùng đăng lên website mà công ty cung cấp dịch vụ.
          </p>
          <p>
            ② Định nghĩa về những thuật ngữ ngoài những thuật ngữ nằm ở mục, khoản trên, những thuật ngữ khác sẽ theo
            thông lệ giao dịch và các luật liên quan.
          </p>
          <p>
            <strong>Điều 3 [Cung cấp thông tin của công ty]</strong>
          </p>
          <p>
            Công ty sẽ đăng lên màn hình chính của website những thông tin như tên công ty, tên người đại diện, địa chỉ,
            số điện thoại đại diện, số fax, địa chỉ hòm thư điện tử, số đăng ký kinh doanh, số khai báo doanh nghiệp
            kinh doanh viễn thông, người phụ trách quản lý thông tin các nhân,… để người dùng có thể dễ dàng tìm hiểu.
          </p>
          <p>
            <strong>Điều 4 [Hiệu lực và thay đổi của điều khoản]</strong>
          </p>
          <p>① Điều khoản này có hiệu lực với toàn bộ hội viên muốn sử dụng dịch vụ.</p>
          <p>
            ② Điều khoản này có hiệu lực thông qua việc được thông báo trực tuyến trên website của công ty (
            <a href="http://www.webheasd.co.kr/">enterjobedu.co.kr</a> , sau đây gọi là “dịch vụ Enter Job Edu&#x27;),
            trong trường hợp có lý do chính đáng, công ty có thể thay đổi điều khoản này trong phạm vi không vi phạm
            những bộ luật liên quan.
          </p>
          <p>
            ③ Điều khoản sửa đổi cũng có hiệu lực thông qua việc được thông báo trực tuyến ở dịch vụ Enter Job Edu.
            Trường hợp công ty muốn thay đổi điều khoản thì phải ngay lập tức thông báo, trong trường hợp muốn thay đổi
            những nội dung quan trọng về quyền lợi hoặc nghĩa vụ của hội viên thì phải thông báo trước.
          </p>
          <p>
            ④ Việc đồng ý với điều khoản này ngĩa là đồng ý với việc định kỳ ghé thăm dịch vụ Enter Job Edu và xác nhận
            nội dung thay đổi của điều khoản. Công ty không chịu trách nhiệm cho những thiệt hại của người dùng phát
            sinh do không biết điều khoản đã thay đổi.
          </p>
          <p>
            ⑤ Hội viên không đồng ý với điều khoản thì có thể yêu cầu xóa (hủy), trường hợp trong vòng 7 ngày kể từ ngày
            điều khoản có hiệu lực mà người dùng không bày tỏ ý kiến từ chối và tiếp tục sử dụng dịch vụ thì được coi
            như đã đồng ý với điều khoản.
          </p>
          <p>
            <strong>Điều 5 [Điều khoản và các quy định khác]</strong>
          </p>
          <p>
            ① Điều khoản này được áp dụng cùng với hướng dẫn sử dụng của từng dịch vụ công ty cung cấp (sau đây gọi là
            “hướng dẫn của từng dịch vụ”).
          </p>
          <p>
            ② Những nội dung chưa được làm rõ trong điều khoản này sẽ theo “Luật về quy định của điều khoản”, “Luật về
            khuyến khích sử dụng mạng viễn thông và bảo vệ thông tin”, “Luật về bảo vệ người tiêu dùng trong giao dịch
            điện tử” và những bộ luật liên quan khác.
          </p>
          <p>
            <strong>Chương 2 Đăng ký hội viên</strong>
          </p>
          <p>
            <strong>Điều 6 [Đăng ký hội viên]</strong>
          </p>
          <p>
            ① Người muốn đăng ký làm hội viên để sử dụng các dịch vụ của công ty thì phải đồng ý sau khi đã hiểu rõ nội
            dung của điều khoản, điền vào những nội dung liên quan trong mẫu đăng ký hội viên có sẵn mà công ty đưa ra
            rồi đăng ký hội viên.
          </p>
          <p>
            ② Theo khoản trên, công ty sẽ coi mọi thông tin hội viên được ghi ở mẫu đăng ký hội viên trực tuyến là thông
            tin thực.
          </p>
          <p>
            ③ Hội viên không nhập tên thật hoặc thông tin thật không thể được pháp luật bảo vệ, và theo nội dung liên
            quan trong điều khoản này, hội viên đó có thể bị hạn chế sử dụng dịch vụ.
          </p>
          <p>
            ④ Theo nguyên tắc, công ty sẽ chấp thuận đăng ký hội viên của người dùng tại khoản 1 điều này. Tuy nhiên,
            công ty có thể không chấp thuận các đăng ký trong những trường hợp ở các mục sau, đồng thời có thể hủy sau
            khi đã chấp thuận.
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>Trường hợp không thể duyệt do nguyên nhân phía người dùng</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>Trường hợp lấy cắp tên hoặc thông tin cá nhân của người khác</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>Trường hợp cung cấp thông tin giả</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>Trường hợp sử dụng trùng ID</li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>Trường hợp không ghi những nội dung liên quan ở mẫu đăng ký hội viên mà công ty cung cấp</li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>
              Trường hợp đã bị mất tư cách hội viên do vi phạm điều khoản sử dụng của công ty hoặc những bộ luật liên
              quan trước đây
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="7">
            <li>Trường hợp vi phạm điều khoản này và những bộ luật liên quan</li>
          </ol>
          <ol type="1" className="numbered-list" start="8">
            <li>
              Trường hợp thông tin cá nhân được ghi trong mẫu đăng ký hội viên và cung cấp cho công ty (ID, mật khẩu,
              địa chỉ,…) vi phạm thuần phong mỹ tục, trật tự xã hội hoặc xúc phạm người khác
            </li>
          </ol>
          <p>
            <strong>Điều 7 [Cung cấp và thay đổi dịch vụ]</strong>
          </p>
          <p>① Công ty cung cấp cho hội viên những dịch vụ sau.</p>
          <ol type="1" className="numbered-list" start="1">
            <li>Những nội dung giáo dục trực tuyến, dịch vụ theo yêu cầu, nội dung giáo dục trực tiếp cho hội viên</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>Các dịch vụ khác do công ty tự phát triển hoặc hợp tác với công ty khác để cung cấp cho hội viên</li>
          </ol>
          <p>
            ② Công ty có thể thông báo về nội dung và ngày cung cấp dịch vụ sẽ thay đổi qua dịch vụ Enter Job Edu để
            cung cấp dịch vụ cho khách hàng.
          </p>
          <p>
            <strong>Điều 8 [Bảo mật và sử dụng thông tin cá nhân]</strong>
          </p>
          <p>① Công ty tôn trọng và bảo vệ thông tin cá nhân của khách hàng.</p>
          <p>
            ② Công ty thu thập thông tin về khách hàng thông qua thông tin khách hàng cung cấp khi đăng ký sử dụng,
            thông tin khách hàng cung cấp để tham gia các sự kiện, thông tin thu thập được trong quá trình sử dụng các
            dịch vụ khác,…, các thông tin thu thập được của khách hàng được sử dụng cho mục đích thực hiện điều khoản sử
            dụng này và cung cấp dịch vụ trên hợp đồng sử dụng.
          </p>
          <p>
            ③ Công ty không được tiết lộ cho bên thứ 3 thông tin cá nhân của khách hàng mà công ty có được liên quan tới
            việc cung cấp dịch vụ mà không có sự đồng ý của cá nhân khách hàng. Tuy nhiên, nội dung này không áp dụng
            trong những trường hợp ở các mục sau.
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>Trường hợp cần thông tin để thanh toán phí dịch vụ</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              Trường hợp cung cấp thông tin dưới hình thức đã được xử lý để không biết được từng cá nhân, cho những tình
              huống cần thông tin nhằm phục vụ làm thống kê, nghiên cứu học thuật hoặc khảo sát thị trường
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>
              Trường hợp có yêu cầu từ các cơ quan liên quan, cung cấp thông tin theo thủ tục và phương pháp được chỉ
              định nhằm mục đích phục vụ công tác điều tra theo quy định của các bộ luật liên quan
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>Trường hợp có quy định đặc biệt trong những điều luật khác</li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>Trường hợp Ủy ban đạo đức viễn thông yêu cầu dựa theo những bộ luật liên quan</li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>Trường hợp nhằm cung cấp thêm các dịch vụ chuyên môn và đa dạng hơn cho hội viên</li>
          </ol>
          <p>
            ④ Nhằm cung cấo thêm các dịch vụ chuyên môn và đa dạng hơn cho khách hàng như nội dung ở khoản 3, công ty có
            thể tự tiếp thị qua điện thoại (TM) hoặc kết hợp với doanh nghiệp chuyên môn bên ngoài để cùng cung cấp dịch
            vụ (gửi thông tin mang tính quảng cáo như TM, SMS, mail,…).
          </p>
          <p>
            ⑤ Trường hợp các dịch vụ yêu cầu có kiến thức chuyên môn, kinh nghiệm và tư vấn, công ty sẽ yêu cầu sự đồng
            ý của khách hàng để cùng doanh nghiệp chuyên môn bên ngoài cung cấp dịch vụ, lúc này, tên của doanh nghiệp
            chuyên môn bên ngoài, nội dung chia sẻ, thông tin chia sẻ sẽ được ghi rõ.
          </p>
          <p>
            ⑥ Khi cung cấp dịch vụ cùng doanh nghiệp chuyên môn bên ngoài, những thông tin tối thiểu cần cho dịch vụ
            chung như tên của hội viên, thông tin liên lạc,… sẽ được chia sẻ, những thông tin được chia sẻ sẽ được quản
            lý bảo vệ nghiêm ngặt theo những mục sau.
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              Những thông tin được chia sẻ sẽ không được sử dụng cho bất kỳ mục đích nào khác ngoài những dịch vụ chuyên
              môn tương ứng.
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              Trong quá trình cung cấp dịch vụ, nếu hội viên không đồng ý hoặc trước đó đã từ chối về dịch vụ chuyên môn
              tương ứng thì các thông tin bao gồm cả các thông tin tối thiểu của hội viên cũng sẽ không được chia sẻ với
              doanh nghiệp chuyên môn bên ngoài.
            </li>
          </ol>
          <p>
            ⑦ Hội viên đồng ý với việc sử dụng thông tin cá nhân có thể sẽ đồng nghĩa với việc đồng ý với điều khoản
            này.
          </p>
          <p>
            ⑧ Hội viên có thể thu hồi lại việc đồng ý cho công ty thu thập và sử dụng thông tin cá nhân đã cung cấp cho
            công ty bất cứ lúc nào, việc thu hồi đồng ý sẽ được thực hiện thông qua việc đăng ký hủy tư cách hội viên.
          </p>
          <p>
            ⑨ Để biết thêm chi tiết về bảo vệ thông tin cá nhân, vui lòng tham khảo chính sách sử dụng thông tin cá
            nhân.
          </p>
          <p>
            <strong>Điều 9 [Thay đổi thông tin hội viên]</strong>
          </p>
          <p>
            ① Hội viên có thể xem và sửa thông tin cá nhân của mình bất cứ lúc nào ở trang “Sửa thông tin” trên website
            công ty.
          </p>
          <p>
            ② Công ty không chịu trách nhiệm cho những thiệt hại phát sinh do hội viên không sửa những nội dung thay đổi
            như ở khoản trên.
          </p>
          <p>
            <strong>Điều 10 [Nghĩa vụ quản lý ID và mật khẩu của hội viên]</strong>
          </p>
          <p>
            ① Hội viên có trách nhiệm quản lý ID và mật khẩu, không được cung cấp cho người khác khiến bên thứ 3 có thể
            sử dụng được.
          </p>
          <p>
            ② Trường hợp hội viên biết được ID và mật khẩu của mình bị lộ và đang bị bên thứ 3 sử dụng thì phải ngay lập
            tức báo cho công ty.
          </p>
          <p>
            ③ Trong trường hợp ở khoản trên, công ty có thể yêu cầu hội viên thực hiện những hành động xử lý cần thiết
            như thay đổi mật khẩu, khi công ty có yêu cầu, hội viên phải ngay lập tức thực hiện đầy đủ theo yêu cầu.
          </p>
          <p>
            ④ Công ty không chịu trách nhiệm với những thiệt hại phát sinh do hội viên không thực hiện đầy đủ những
            nghĩa vụ theo khoản 2 và khoản 3 điều này.
          </p>
          <p>
            <strong>Điều 11 [Xóa tài khoản hội viên và mất tư cách hội viên]</strong>
          </p>
          <p>① Hội viên có thể dừng sử dụng dịch vụ và xóa tài khoản bất cứ lúc nào.</p>
          <p>
            ② Trường hợp hội viên vi phạm điều khoản hoặc các bộ luật liên quan, công ty có thể xử lý hạn chế sử dụng
            dịch vụ hoặc tước tư cách hội viên.
          </p>
          <p>
            ③ Khi bị hủy bỏ theo quy định của điều, khoản này và hội viên chấm dứt dịch vụ sử dụng (xóa tài khoản hội
            viên), điểm tích lũy và phiếu giảm giá sẽ bị hủy bỏ.
          </p>
          <p>
            ④ Khi xóa tài khoản hội viên, công ty sẽ xóa, hủy hoặc bảo lưu trong một thời gian nhất định những thông tin
            nhận được từ hội viên theo những bộ luật liên quan và chính sách xử lý thông tin cá nhân. Tuy nhiên, những
            bài đăng hội viên đã viết cho tới trước khi xóa tài khoản hội viên sẽ không bị xóa bỏ, sau khi xóa tài khoản
            hội viên thì hội viên cũng không thể yêu cầu công ty xóa bài đăng đó.
          </p>
          <p>
            <strong>Chương 3 Hợp đồng sử dụng dịch vụ</strong>
          </p>
          <p>
            <strong>Điều 12 [Nghĩa vụ của công ty]</strong>
          </p>
          <p>
            ① Trừ trường hợp có lý do đặc biệt, công ty phải cung cấp dịch vụ vào ngày bắt đầu sử dụng dịch vụ mà hội
            viên mong muốn, và phải cung cấp dịch vụ liên tục, ổn định.
          </p>
          <p>
            ② Công ty xây dựng hệ thống bảo vệ thông tin cá nhân và thông báo công khai, tuân thủ chính sách sử dụng
            thông tin cá nhân.
          </p>
          <p>
            ③ Trong trường hợp ý kiến hoặc khiếu nại của người dùng được công nhận là chính đáng, công ty phải thực hiện
            những biện pháp xử lý thích hợp, nếu khó giải quyết ngay lập tức, công ty phải thông báo cho người dùng lý
            do và lịch trình xử lý.
          </p>
          <p>④ Trong trường hợp phát sinh một trong những lý do sau, công ty có thể bị gián đoạn dịch vụ.</p>
          <ol type="1" className="numbered-list" start="1">
            <li>Trường hợp cần kiểm tra định kỳ hệ thống, cài đặt thêm hoặc đổi hệ thống</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>Trường hợp cần kiểm tra hệ, cài đặt thêm hoặc đổi hệ thống khẩn cấp</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>Trường hợp công ty phán đoán phù hợp để đổi sang dịch vụ mới</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>
              Trường hợp không thể cung cấp dịch vụ bình thường do tình trạng khẩn cấp quốc gia, mất điện, hỏng hóc
              thiết bị của dịch vụ hoặc tắc nghẽn sử dụng dịch vụ,…
            </li>
          </ol>
          <p>
            ⑤ Khi giới hạn hoặc tạm dừng dịch vụ do lý do của khoản trên, công ty phải cho khách hàng biết lý do và thời
            hạn hạn chế sử dụng.
          </p>
          <p>
            ⑥ Trong trường hợp dịch vụ bị gián đoạn do những lý do ngoài tầm kiểm soát của công ty (lỗi ổ đĩa, hệ thống
            ngừng hoạt động mà không do người quản lý hệ thống cố ý hay sơ suất,…), công ty sẽ không thể thông báo
            trước, và trong trường hợp hệ thống bị gián đoạn do bên thứ 3 cố ý hoặc sơ suất (công ty viễn thông máy
            tính, doanh nghiệp viễn thông,…), công ty không có nghĩa vụ thông báo.
          </p>
          <p>
            <strong>Điều 13 [Nghĩa vụ của hội viên]</strong>
          </p>
          <p>
            ① Khi chưa có sự đồng ý chính thức từ công ty, quyền sử dụng của hội viên chỉ giới hạn ở cá nhân hội viên,
            không được chuyển nhượng, tặng hoặc thế chấp cho người khác.
          </p>
          <p>
            ② Trường hợp có thay đổi về nội dung hợp đồng sử dụng như địa chỉ, thông tin liên hệ, địa chỉ thư điện tử,…,
            hội viên phải thực hiện những thủ tục liên quan và thông báo ngay cho công ty.
          </p>
          <p>
            ③ Hội viên phải tuân thủ các quy định như điều khoản này, những bộ luật liên quan, nội dung thông báo của
            công ty; không được thực hiện những hành vi can thiệp vào nghiệp vụ của công ty hoặc gây tổn hại tới danh dự
            của công ty.
          </p>
          <p>
            ④ Hội viên không được sử dụng dịch vụ để hoạt động kinh doanh mà không có sự chấp thuận trước của công ty,
            và công ty sẽ không chịu trách nhiệm cho hoạt động kinh doanh đó. Ngoài ra, nếu các hoạt động kinh doanh
            trên gây thiệt hại cho công ty, hội viên có trách nhiệm bồi thường thiệt hại.
          </p>
          <p>
            ⑤ Khi chưa có sự đồng ý chính thức của công ty, hội viên không được nhượng, tặng, thế chấp quyền sử dụng
            dịch vụ, các tư cách khác trong hợp đồng sử dụng cho người khác.
          </p>
          <p>⑥ Hội viên không được xâm phạm tới quyền sở hữu trí tuệ của công ty và bên thứ 3.</p>
          <p>
            ⑦ Trường hợp hội viên thực hiện một trong các hành vi sau, công ty có thể thực hiện những biện pháp xử lý
            thích hợp như hạn chế sử dụng dịch với hội viên đó.
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              Trường hợp hội viên ghi thông tin giả, sử dụng – chia sẻ ID và thông tin cá nhân của bản thân hoặc hội
              viên khác khi đăng ký sử dụng hoặc thay đổi thông tin
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>Trường hợp xác nhận được hội viên dùng cách bất hợp pháp để đăng ký hội viên</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>Trường hợp hội viên xâm phạm tới quyền lợi hoặc bản quyền của công ty hoặc bên thứ 3</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>
              Trường hợp hội viên sử dụng dịch vụ công ty cung cấp để thực hiện những hành vi thương mại như kinh doanh,
              buôn bán sản phẩm, dịch vụ
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>
              Trường hợp hội viên can thiệp việc sử dụng dịch vụ của người dùng khác hoặc mạo danh người quản lý của
              công ty, nhân viên hoặc các bên liên quan
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>
              Hành vi gửi, đăng, dùng thư điện tử hoặc các cách khác để tuyên truyền thông tin, câu văn, hình ảnh, âm
              thanh hoặc video có nội dung thô tục hoặc khiêu dâm, vi phạm trật tự công cộng và thuần phong mỹ tục
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="7">
            <li>
              Trường hợp cố ý can thiệp vào việc vận hành dịch vụ của công ty hoặc gửi số lượng lớn thông tin, gửi thông
              tin mang tính quảng cáo với mục đích can thiệp vào việc vận hành dịch vụ của công ty
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="8">
            <li>
              Hành vi gửi, đăng, dùng thư điện tử hoặc các cách khác để tuyên truyền những nội dung có tính xúc pháp
              hoặc có thể xâm phạm danh dự, quyền riêng tư của người khác do là những thông tin cá nhân
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="9">
            <li>
              Hành vi thu thập hoặc lưu thông tin cá nhân của người dùng khác mà không được sự chấp thuận của công ty
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="10">
            <li>Trường hợp gây tổn hại hoặc xúc phạm danh dự của công ty và người khác</li>
          </ol>
          <ol type="1" className="numbered-list" start="11">
            <li>Trường hợp vi phạm nội dung quy định của điều khoản này và các bộ luật khác</li>
          </ol>
          <p>
            <strong>Điều 14 [Phí sử dụng dịch vụ]</strong>
          </p>
          <p>
            ① Phí sử dụng và phương thức thanh toán cho dịch vụ trả phí sẽ theo nội dung được ghi ở dịch vụ đó và ở
            trang thanh toán.
          </p>
          <p>
            ② Công ty có thể thay đổi chính sách về tiền điện tử (phiếu và điểm tích lũy) theo tình hình vận hành công
            ty và nội dung này sẽ đăng ở website tổng hợp.
          </p>
          <p>
            <strong>Điều 15 [Chính sách hoàn tiền]</strong>
          </p>
          <p>
            ① Hội viên phải thể hiện ý định muốn được hoàn tiền hoặc thay đổi khác tới công ty bằng phương pháp trực
            tiếp hoặc trực tuyến, sau khi tiếp nhận yêu cầu hoàn tiền và xác nhận yêu cầu hoàn tiền của hội viên cũng
            như quy định hoàn tiền, công ty sẽ thực hiện hoàn tiền nhanh chóng trong thời gian ngắn nhất.
          </p>
          <p>1.Hoàn tiền</p>
          <p>
            (1) <strong>Quy định hoàn tiền học phí bài giảng trực tuyến</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Nếu chưa nghe giảng trong vòng 7 ngày kể từ ngày thanh toán thì công ty sẽ hoàn tiền 100%. Tuy nhiên, nếu
              hoàn tiền vì lý do từ phía khách hàng, trường hợp dùng tài khoản chuyên dụng (virtual account), công ty sẽ
              khấu trừ 500 won rồi hoàn tiền cho khách hàng.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Nếu đăng ký hoàn tiền trước khi kết thúc thời hạn sử dụng, công ty sẽ hoàn tiền số tiền thực tế thanh toán
              – số tiền tương ứng với số bài giảng thực tế đã nghe.
            </li>
          </ul>
          <p>
            (Số tiền tương ứng với số bài giảng thực tế đã nghe : Số tiền thanh toán cho bài giảng đã nghe / Số bài
            giảng kế hoạch * Số bài giảng học)
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Trường hợp những sản phẩm đặc biệt như Package, Freepass có thể chính sách hoàn tiền sẽ khác nên hội viên
              cần phải xác nhận.
            </li>
          </ul>
          <p>
            (2) <strong>Quy định hoàn tiền học phí bài giảng trực tiếp</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Việc hoàn trả toàn bộ học phí sẽ được thực hiện hoàn 100% trong thời gian trước 7 ngày tính từ ngày khai
              giảng, ngày bắt đầu khóa học, còn sau thời gian đó sẽ có phí phạt 10%.
            </li>
          </ul>
          <p>
            ※ Trường hợp yêu cầu hoàn tiền sau khi nghỉ học mà không báo trước, trường hợp hoàn tiền vì lý do từ phía
            khách hàng, nếu dùng tài khoản chuyên dụng, công ty sẽ khấu trừ 500 won rồi hoàn tiền cho khách hàng. (Trừ
            trường hợp hủy lớp) không thể.
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Trường hợp hoàn tiền vì lý do từ phía khách hàng, nếu thanh toán thẻ thì công ty sẽ khấu trừ 3% phí dịch
              vụ rồi hoàn tiền cho khách hàng.(Trừ trường hợp hủy lớp)
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Khi hoàn tiền, công ty sẽ xử lý hoàn tiền dựa theo số tiền thực tế đã thanh toán (trừ phần đã giảm giá)
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Căn cứ theo những bộ luật liên quan của việc hoàn tiền sau khi đã khai giảng như sau. (Các điều luật liên
              quan tới thành lập, vận hành trung tâm và dạy học thêm)
            </li>
          </ul>
          <p>
            (Việc hoàn tiền sau khai giảng sẽ được khấu trừ theo số ngày hoặc khấu trừ theo phần, bất kể có nghe giảng
            trong thực tế hay không.)
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Khấu trừ 1/3 và hoàn lại 2/3 còn lại, nếu chưa quá 1/3 tổng số bài giảng
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Khấu trừ 1/2 và hoàn lại 1/2 còn lại, nếu chưa quá 1/2 tổng số bài giảng
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Không hoàn lại nếu đã quá 1/2 tổng số bài giảng</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Nếu nghỉ học mà không xin phép, công ty sẽ coi như người học đã tham gia buổi học đó để xử lý tính tiền
              hoàn lại.
            </li>
          </ul>
          <p>[Trường hợp nghe giảng hơn 1 tháng]</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Số tiền hoàn sẽ gộp lại từ số tiền cần hoàn của tháng phát sinh</li>
          </ul>
          <p>
            (học phí tính theo trường hợp thời gian thu học phí là trong vòng 1 tháng) và toàn bộ học phí của những
            tháng còn lại
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Coi như tháng thứ nhất đăng ký 8 tiếng và học phí 240,000 won, tháng thứ 2 đăng ký 2 tiếng và học phí
              60,000 won
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Trường hợp hoàn tiền trước khi quá 1/3 khóa học ở tháng thứ nhất: Có thể hoàn tiền 2/3 học phí tháng thứ
              nhất + toàn bộ học phí tháng thứ hai
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Trường hợp hoàn tiền trước khi quá 1/2 khóa học ở tháng thứ nhất: Có thể hoàn tiền 1/2 học phí tháng thứ
              nhất + toàn bộ học phí tháng thứ hai
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Trường hợp hoàn tiền sau khi quá 1/2 khóa học ở tháng thứ nhất: Không thể hoàn tiền học phí tháng thứ nhất
              + có thể hoàn tiền toàn bộ học phí tháng thứ hai
            </li>
          </ul>
          <p>
            (3)
            <strong>Quy định hoàn tiền cho bài giảng của trung tâm bao gồm bài giảng trực tuyến + trực tiếp</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Trường hợp yêu cầu hoàn tiền sau khi học bài giảng trực tuyến và trước khi khóa học trực tiếp khai giảng,
              công ty sẽ khấu trừ giá tiền bài giảng trực tuyến và chỉ hoàn số tiền còn lại.
            </li>
          </ul>
          <p>2.Thay đổi</p>
          <p>
            Trường hợp thay đổi từ bài giảng phù hợp với điều kiện hoàn tiền sang bài giảng khác, hội viên không thể
            trực tiếp thực hiện thay đổi mà phải thực hiện thay đổi sau khi đã xử lý hoàn tiền theo quy định xong, hoặc
            liên hệ với trung tâm chăm sóc khách hàng.
          </p>
          <p>3.Gián đoạn dịch vụ do tình hình công ty</p>
          <p>
            Trường hợp công ty không thể tiếp tục dịch vụ vì những lý do bất khả kháng, công ty sẽ thông báo cho hội
            viên và thực hiện hoàn tiền nhanh chóng trong thời gian sớm nhất.
          </p>
          <p>4.Ngừng sử dụng và cưỡng chế xóa tài khoản</p>
          <p>
            Trường hợp hội viên vi phạm quy định bộ luật liên quan hoặc điều khoản này và bị công ty xử lý ngừng sử dụng
            dịch vụ hoặc cưỡng chế xóa tài khoản thì sẽ không áp dụng quy định hoàn tiền của điều này.
          </p>
          <p>
            ② Số tiền của phiếu mà công ty cung cấp miễn phí cho hội viên, phiếu được cấp khi hội viên vượt tỉ lệ mức
            tiền nạp sẽ không được hoàn lại.
          </p>
          <p>③ Trường hợp bị hạn chế sử dụng theo điều 17 sẽ không được hoàn lại tiền phí.</p>
          <p>
            <strong>Điều 16 [Bản quyền]</strong>
          </p>
          <p>
            ① Bản quyền của bài đăng do hội viên đăng ở trên dịch vụ sẽ thuộc về tác giả bài đăng đó. Tuy nhiên, với
            việc gửi, đăng bài đăng bằng dịch vụ của công ty, coi như hội viên đã cấp cho công ty quyền sử dụng tác phẩm
            (bao gồm quyền có thể gián tiếp sử dụng tác phẩm), cho phép sử dụng, sao chép, phục chế, xử lý, điều chỉnh,
            thay đổi, công khai, gửi, đăng hoặc phát tán bài đăng đó. Quyền sử dụng tác phẩm này vẫn tiếp tục cả sau khi
            hội viên ngừng sử dụng dịch vụ hoặc xóa tài khoản khỏi trang web tổng hợp. Tuy nhiên, công ty có thể cung
            cấp cách truy cập hoặc xóa nội dung do thành viên cung cấp.
          </p>
          <p>
            ② Hội viên không được lợi dụng thông tin có được từ việc sử dụng “dịch vụ” mà công ty cung cấp vào mục đích
            lợi nhuận hoặc phi lợ nhuận, hoặc cho bên thứ 3 sử dụng, bằng cách ghi âm, sao chép, gửi, xuất bản, phát
            tán, phát sóng hoặc các cách khác mà không được sự chấp thuận trước của công ty.
          </p>
          <p>
            ③ Nội dung do công ty cung cấp được bảo vệ theo luật bản quyền, những hành vi sao chép, phát tán, đăng một
            phần hoặc toàn bộ nội dung mà không được sự đồng ý của công ty là vi phạm luật bản quyền.
          </p>
          <p>④ Trường hợp xâm phạm bản quyền tác phẩm của công ty, công ty có thể yêu cầu bồi thường thiệt hại.</p>
          <p>
            <strong>Điều 17 [Nghiêm cấm và ngăn chặn sử dụng trái phép]</strong>
          </p>
          <p>① Cách nhận biết sử dụng trái phép và ngăn chặn</p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              Công ty sẽ phân loại, xác nhận xem hội viên có sử dụng trái phép hay không thông qua server, dựa trên
              những tài liệu thu thập, kiểm tra được khi hội viên sử dụng dịch vụ như thông tin IP, địa chỉ MAC,.. .
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              Trường hợp trong khi người dùng sử dụng dịch vụ mà cho chạy chương trình sao chép hoặc cùng một ID mà truy
              cập nhiều thiết bị cùng lúc, công ty sẽ cưỡng chế đóng truy cập sử dịch vụ.
            </li>
          </ol>
          <p>
            <strong>Chương 4 Bồi thường thiệt hại và các nội dung khác</strong>
          </p>
          <p>
            <strong>Điều 18 [Bồi thường thiệt hại]</strong>
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ① Trường hợp người sử dụng bị phát sinh thiệt hại do lý do của công ty, liên quan tới tất cả các dịch vụ
              mà công ty cung cấp thì công ty phải bồi thường cho thiệt hại đó. Tuy nhiên, công ty không bồi thường cho
              những thiệt hại như do lỗi của dịch vụ miễn phí.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ② Trường hợp hội viên vi phạm quy định của điều khoản này, dẫn tới thiệt hại cho công ty và bên thứ 3,
              hoặc công ty và bên thứ 3 bị thiệt hại bởi lý do từ phía hội viên, hội viên phải bồi thường cho thiệt hại
              đó.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              ③ Cách và thủ tục bồi thường thiệt hại khác sẽ theo những bộ luật liên quan.
            </li>
          </ul>
          <p>
            <strong>Điều 19 [Điều khoản miễn trừ trách nhiệm]</strong>
          </p>
          <p>
            ① Trường hợp công ty không thể cung cấp dịch vụ do thiên tai, chiến tranh hoặc các trường hợp bất khả kháng
            khác, công ty sẽ được miễn trách nhiệm cung cấp dịch vụ.
          </p>
          <p>
            ② Trường hợp phát sinh thiệt hại do doanh nghiệp viễn thông bị ngưng dịch vụ viễn thông hoặc không thể cung
            cấp bình thường, công ty sẽ được miễn trách nhiệm.
          </p>
          <p>
            ③ Công ty được miễn trách nhiệm với những thiệt hại phát sinh do những lý do không tránh khỏi như bảo trì,
            thay thế, kiểm tra định kỳ, thi công,… các công trình, thiết bị dùng cho dịch vụ.
          </p>
          <p>
            ④ Công ty không chịu trách nhiệm với những khó khăn khi sử dụng dịch vụ hoặc thiệt hại phát sinh do lý do từ
            phía hội viên.
          </p>
          <p>
            ⑤ Trường hợp phát sinh thiệt hại do lỗi máy tính của người dùng, hoặc trường hợp phát sinh thiệt hại do hội
            viên nhập sai thông tin cá nhân và địa chỉ thư điện tử, công ty sẽ không chịu trách nhiệm.
          </p>
          <p>
            ⑥ Công ty không chịu trách nhiệm về việc hội viên sử dụng dịch vụ mà không thu được lợi nhuận như kỳ vọng
            hoặc bị mất mát.
          </p>
          <p>
            ⑦ Công ty không chịu trách nhiệm với những thiệt hại do tài liệu mà hội viên có được khi sử dụng dịch vụ.
            Ngoài ra, công ty không chịu trách nhiệm bồi thường những thiệt hại về mặt tinh thần mà hội viên phải chịu
            từ hội viên khác trong quá trình sử dụng dịch vụ.
          </p>
          <p>
            ⑧ Công ty không chịu trách nhiệm về những thông tin, tài liệu, độ tin cậy, tính chính xác,… của nội dung mà
            thành viên đăng ở dịch vụ.
          </p>
          <p>
            ⑨ Công ty không có nghĩa vụ can thiệp vào những tranh chấp giữa người dùng với nhau, giữa người dùng với bên
            thứ 3 thông qua dịch vụ, và cũng không có trách nhiệm bồi thường thiệt hại phát sinh từ tranh chấp này.
          </p>
          <p>
            ⑩ Công ty không chịu trách nhiệm về bất kỳ thiệt hại nào liên quan tới việc sử dụng dịch vụ mà công ty cung
            cấp miễn phí cho hội viên.
          </p>
          <p>
            ⑪ Công ty không chịu bất kỳ trách nhiệm nào với những nội dung trái phép hay nội dung vi phạm thuần phong mỹ
            tục, trật tự xã hội trong số những tài liệu mà hội viên đăng, và công ty không đảm bảo bất kỳ thông tin nào
            do thành viên đăng lên trang web, không đảm bảo giá trị, mục đích sử dụng, quảng cáo và tính tuân thủ các bộ
            luật liên quan của hàng hóa, dịch vụ là kết quả của quảng cáo, thông tin khác và đề nghị liên quan tới những
            thông tin đó.
          </p>
          <p>
            <strong>Điều 20 [Giải quyết tranh chấp]</strong>
          </p>
          <p>
            ① Trường hợp phát sinh tranh chấp, công ty sẽ nhanh chóng tìm hành động xử lý phù hợp có phản ánh những ý
            kiến hoặc khiếu nại chính đáng của người dùng. Tuy nhiên, trong trường hợp khó xử lý nhanh chóng, công ty sẽ
            thông báo cho người dùng lý do và lịch trình xử lý.
          </p>
          <p>② Việc tranh chấp giữa công ty với hội viên sẽ dựa theo luật pháp của Hàn Quốc.</p>
          <p>
            ③ Trong trường hợp người dùng đăng ký trợ giúp thiệt hại liên quan tới tranh chấp giao dịch thương mại điện
            tử phát sinh giữa công ty và người dùng, thì việc xử lý sẽ theo sự điều chỉnh của Ủy ban giao dịch công bằng
            hoặc Ủy ban xử lý tranh chấp giao dịch thương mại điện tử, hoặc cơ quan xử lý tranh chấp cho do chính quyền
            thành phố ủy quyền.
          </p>
          <p>
            <strong>Điều 21 [Sử dụng phiếu sản phẩm và điểm tích lũy]</strong>
          </p>
          <p>
            ① Công ty có thể phát hành phiếu để giảm một số tiền nhất định hoặc một tỉ lệ nhất định cho người dùng mua
            hàng khi mua những sản phẩm được chỉ định.
          </p>
          <p>
            ② Ngoại trừ trường hợp công ty ghi rõ riêng ra, còn lại người dùng không thể nhượng cho người khác, trong
            bất cứ trường hợp nào cũng không thể thực hiện mua, bán với người khác
          </p>
          <p>
            ③ Phiếu sản phẩm không thể quy đổi thành tiền mặt và sẽ bị xóa khi hết thời hạn ghi trên phiếu hoặc khi kết
            thúc hợp đồng sử dụng.
          </p>
          <p>
            ④ Phiếu sản phẩm có thể bị hạn chế sử dụng theo một số mặt hàng hoặc theo số tiền, nếu xóa tài khoản hội
            viên thì phiếu sản phẩm sẽ bị xóa.
          </p>
          <p>
            <strong>[Điều khoản bổ sung]</strong>
          </p>
          <p>
            Điều khoản này được áp dụng từ ngày 27 tháng 9 năm 2021. Những điều khoản trước sẽ được thay thế bằng điều
            khoản này.
          </p>
          <p>
            Người đăng ký hội viên trước ngày sửa đổi điều khoản cũng áp dụng theo nội dung điều khoản sử dụng đã được
            sửa đổi.
          </p>
        </div>
      </article>
    </div>
  );
}
