export default function TermsOfUse(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">Terms of Use</h2>
      <article className="page sans">
        <div className="page-body">
          <p>
            <strong>CHAPTER I. GENERAL PROVISIONS</strong>
          </p>
          <p>
            <strong>Article 1 [Purpose]</strong>
          </p>
          <p>
            These terms of use (hereinafter referred to as “Terms of Use”) aim to provide the rights, obligations,
            responsibilities, etc. of Klab Company (hereinafter referred to as “Company”) and its customers (hereinafter
            referred to as “Members”) using the educational information services (hereinafter referred to as “Services”)
            provided by the Company, in relation to the terms and conditions of applying for membership and use of the
            Services.
          </p>
          <p>
            <strong>Article 2 [Definitions]</strong>
          </p>
          <p>① As used herein, the following terms shall have the meanings hereby assigned to them:</p>
          <p>
            1.&quot;User&quot; means a Member (as defined below) and a non-member who accesses the Company website and
            uses the Contents (as defined below) and other services provided by the Company in accordance with these
            Terms of Use.
          </p>
          <p>
            2.&quot;Member&quot; means a person who has signed a user agreement with the Company by accessing the
            Company website and agreeing to these Terms of Use and is assigned an ID by the Company and who is allowed
            to use the information and the services provided by the Company on a continuing basis.
          </p>
          <p>
            3.&quot;Content&quot; means the lectures provided online on the Company website and other information
            related thereto, including the data or information in the form of codes, characters, voice, sound, image or
            video used in the information and communications network under Article 2.1.1 of the Act on Promotion of
            Information and Communications Network Utilization and Information Protection, Etc. .
          </p>
          <p>
            4.&quot;ID&quot; means a combination of characters and/or numbers created by a Member and approved by the
            Company for the purpose of his/her identification and use of the Services.
          </p>
          <p>
            5.&quot; Password&quot; means a combination of characters and/or numbers set by a Member him/herself to
            confirm that that Member is identified as matching with his/her ID and protect his/her personal information.
          </p>
          <p>6.&quot;Email&quot; means a mail exchanged through the Internet or by using any electrical media.</p>
          <p>
            7.&quot;Operatr (or Manager)&quot; means a person or company appointed by the Company to ensure overall
            management and proper operation of the Services.
          </p>
          <p>
            8.&quot;Member Postings&quot; mean the information posted by Members on the Company website where the
            Services are provided by the Company, including but not limited to messages, images, files, links and
            comments.
          </p>
          <p>
            ② Any terms other than those defined in subsection 1 above shall have the meanings assigned to them in
            applicable laws or in accordance with business practices.
          </p>
          <p>
            <strong>Article 3 [Provision of the Information concerning the Company Profile, etc.]</strong>
          </p>
          <p>
            The Company displays on the main page of its website its trade name, representative’s name, address,
            representative phone number, facsimile number, email address, business registration number, mail-order
            business registration number, data protection officer (DPO), etc. in such a manner that users can readily
            find such information.
          </p>
          <p>
            <strong>Article 4 [Validity and Amendment of Terms of Use]</strong>
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>These Terms of Use shall have a binding effect on all Members who intend to use the Services.</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              These Terms of Use shall come into effect upon public notice thereof posted online on the Company website
              (<a href="http://www.webheasd.co.kr/">enterjobedu.co.kr</a>, hereinafter referred to as “Enterjobedu
              Service”). The Company may revise these Terms of Use, to the extent that it does not violate applicable
              laws.
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>
              The revised Terms of Use, if any, shall also come into effect upon public notice thereof posted online on
              the Enterjobedu Service. Upon the revision of the Terms of Use, the Company shall promptly give public
              notice thereof without delay. In case of a revision of any significant matters related to Members’ rights,
              obligations, etc., prior public notice shall be required.
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>
              Giving consent to these Terms of Use means that the Member in question regularly visits the Enterjobedu
              Service and agrees to verify changes made to the Terms of Use. The Company shall not be liable to users
              for the damage suffered due to their unawareness of such changes to the Terms of Use.
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>
              A Member who does not agree to the Terms of Use may make a request for withdrawal from (or termination of)
              membership. If a Member continues using the Services without expressing his/her intent to refuse to agree
              to the Terms of Use even seven days after the effective date thereof, the Member in question shall be
              deemed to have agreed to the Terms of Use.
            </li>
          </ol>
          <p>
            <strong>Article 5 [Terms of Use and Other Working Rules]</strong>
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              These Terms of Use shall apply, together with a user guide on the individual services provided by the
              Company (hereinafter referred to as “Individual service guide”).
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              Any matters not specifically provided herein shall be subject to the relevant provisions of the &quot;Act
              on the Regulation of Terms and Conditions&quot;, “Act on Promotion of Information and Communications
              Network Utilization and Information Protection, Etc. &quot;, &quot;Act on the Consumer Protection in
              Electronic Commerce, Etc.&quot; and other applicable laws.
            </li>
          </ol>
          <p>
            <strong>CHAPTER II. MEMBERSHIP APPLICATION</strong>
          </p>
          <p>
            <strong>Article 6 [Membership Application]</strong>
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              An applicant who wishes to become a Member and use the Services provided by the Company shall express
              his/her consent to the Terms of Use after having acquainted him/herself herewith and then shall apply for
              membership by entering the required information in the membership application form provided by the
              Company.
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              All of the member information provided in the online membership application form by the applicant pursuant
              to subsection 1 above shall be regarded by the Company as the actual and accurate data of the applicant in
              question.
            </li>
          </ol>
          <p>
            ③ If a Member fails to enter his/her real name or actual data, the Member shall not be protected and may be
            restricted from using the Services pursuant to the relevant provisions of these Terms of Use.
          </p>
          <p>
            ④ The Company shall, in principle, accept the membership application submitted by the applicant in
            accordance with subsection 1 above. Notwithstanding the foregoing, the Company may refuse to accept the
            membership applications falling within any of the following categories and may revoke its acceptance thereof
            even after such applications have been already accepted:
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>The application cannot be accepted by any means due to a cause attributable to the applicant;</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              The application is made in the name of another person or by stealing the personal information of another
              person;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>False information is provided;</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>A duplicated ID is used;</li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>
              The application is made by entering the required information in a form other than the membership
              application form provided by the Company;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>
              The applicant has previously violated the terms of use of the Company or applicable laws and, as a result
              of it, has been disqualified from membership;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="7">
            <li>The application is otherwise in violation of these Terms of Use and/or applicable laws; or</li>
          </ol>
          <ol type="1" className="numbered-list" start="8">
            <li>
              The personal information (such as ID, password, address, etc.) provided to the Company by the applicant,
              as contained in the membership application form, is against good public morals or insulting to others.
            </li>
          </ol>
          <p>
            <strong>Article 7 [Provision and Change of Services]</strong>
          </p>
          <p>① The Company shall provide Members with the Services described below:</p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              Online educational content services, customized services and offline educational content for Members; and
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              Any other services to be provided for Members by the Company through its own development or collaboration
              agreements with other companies.
            </li>
          </ol>
          <p>
            ② The Company may change the Services by giving public notice of the proposed changes thereto and the
            effective date of such changes through the Enterjobedu Service and then provide the Services so changed.
          </p>
          <p>
            <strong>Article 8 [Protection and Use of Personal Information]</strong>
          </p>
          <p>① The Company protects and respects the personal information of its customers.</p>
          <p>
            ② The Company collects customer information out of the information provided by customers upon subscribing
            for the Services or in order to participate in events or otherwise collected in the course of using the
            Services by customers and so forth. The customer information so collected shall be used by the Company for
            the purpose of performing the user agreements with customers and providing the Services thereunder.
          </p>
          <p>
            ③ The Company shall not divulge to a third party the profile information of customers that it has acquired
            in relation to the provision of the Services, without the consent of customers. The foregoing provision
            shall not apply if:
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>It is necessary in order to settle the fees for the Services provided;</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              The profile information is processed in a form that cannot identify the person in question and provided to
              a third party, as required for the purpose of statistics, academic research or market survey;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>
              It is requested by the agencies concerned for investigation purposes in accordance with the procedure and
              method prescribed by applicable laws;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>It is otherwise specifically provided by other law(s);</li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>It is required by the Korea Internet Safety Commission pursuant to applicable laws; or</li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>It is necessary in order to provide Members with more specialized and diverse services.</li>
          </ol>
          <p>
            ④ In order to provide Members with such specialized and diverse services under subsection 3 above, the
            Company may deliver text messages (TM) on its own or may provide joint services (i.e. sending advertising
            information such as TM, SMS, mails, etc.) in alliance with an external specialized service provider(s).
          </p>
          <p>
            ⑤ In case of any services requiring certain expertise, experience and consulting service, the Company shall
            provide such services jointly with an external specialized service provider(s) by obtaining consent from
            Members. In such a case, the Company shall clearly indicate the trade name of the specialized service
            provider(s), the content of the information to be shared and the purpose of sharing such information.
          </p>
          <p>
            ⑥ In providing joint services with the aforementioned external specialized service provider(s), the minimum
            amount of information necessary for such services (such as Members’ names, contact information, etc.) may be
            shared and the information to be shared (“Shared information”) shall be strictly protected and managed in
            the manner described below:
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              The shared information shall not be used for any purposes whatsoever other than the purpose of procuring
              the said specialized services.
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              If a Member does not express his/her intent to consent to such specialized services or has expressed
              his/her intent to refuse to consent thereto in advance, then the Company shall not share even the
              aforesaid minimum amount of the Member’s information with such specialized service provider.
            </li>
          </ol>
          <p>
            ⑦ When a Member has given his/her consent to the Company’s using his/her personal information, the Member in
            question shall be deemed to have consented to these Terms of Use.
          </p>
          <p>
            ⑧ A Member may withdraw his/her consent to the collection and use of his/her personal information he/she
            have provided to the Company at any time and such consent shall result in a request for termination of the
            Services.
          </p>
          <p>
            ⑨ For more details regarding the protection of personal information, please refer to the privacy policy of
            the Company.
          </p>
          <p>
            <strong>Article 9 [Change of Membership Information]</strong>
          </p>
          <p>
            ① A Member may inspect and change his/her personal information on the “data change” page of the Company
            website at any time.
          </p>
          <p>
            ② The Company shall not be liable to the Member for the disadvantages suffered due to a failure to change
            his/her personal information pursuant to subsection 1 above.
          </p>
          <p>
            <strong>Article 10 [Member’s Obligation to Manage his/her ‘ID’ and ‘Password’]</strong>
          </p>
          <p>
            ① A Member shall be responsible for managing his/her ID and password and shall in no event disclose such ID
            and password to a third party, thereby allowing the latter to use the same.
          </p>
          <p>
            ② A Member shall immediately inform the Company when he/she becomes aware that his/her ID and/or password
            has been leaked and used by a third party.
          </p>
          <p>
            ③ Upon the occurrence of such incident under subsection 2 above, the Company shall be entitled to request
            the Member concerned to take the necessary measures to protect the Member’s personal information and prevent
            any illegal use of the Services and so forth, including but not limited to changing his/her password and,
            upon request of the Company, the Member shall promptly respond to such request in good faith.
          </p>
          <p>
            ④ The Company shall not be liable to the Member for the disadvantages suffered due to a failure to
            faithfully perform his/her obligations under subsections 2 and 3 above.
          </p>
          <p>
            <strong>Article 11 [Withdrawal from Membership and Disqualification, etc.]</strong>
          </p>
          <p>① A Member may stop using the Services and withdraw from membership at any time.</p>
          <p>
            ② Should a Member violate these Terms of Use or any applicable laws, the Company may restrict the Member in
            question from using the Services or disqualify the latter from membership.
          </p>
          <p>
            ③ Upon the termination of membership pursuant to this Article 11, the points and discount coupons held by
            the Member in question shall expire at the time when the user agreement is terminated (i.e. the Member
            withdraws from membership.
          </p>
          <p>
            ④ When a Member withdraws from membership, the information previously provided by the Member concerned shall
            be deleted or destroyed or otherwise stored by the Company for a prescribed period of time in accordance
            with applicable laws, the privacy policy of the Company, etc. For the avoidance of doubt, the postings made
            by the Member in question up to the time of his/her withdrawal from membership shall not be deleted and the
            Member concerned may not request the Company to delete such postings after he/she has withdrawn from
            membership.
          </p>
          <p>
            <strong>CHAPTER III. SERVICE USER AGREEMENT</strong>
          </p>
          <p>
            <strong>Article 12 [Obligations of the Company]</strong>
          </p>
          <p>
            ① The Company shall provide Members with the Services, starting on the desired commencement date for using
            the Services, and shall keep providing the Services on a continuing and stable basis unless there are
            special circumstances.
          </p>
          <p>
            ② The Company shall establish a security system to protect personal information and shall give public notice
            of and comply with its privacy policy.
          </p>
          <p>
            ③ When any of the opinions or complaints raised by customers using the Services are deemed justified, the
            Company shall take appropriate action. If the Company is not able to promptly process such opinions and
            complaints, the Company shall give notice to the user in question of the reason therefor and the anticipated
            processing schedule.
          </p>
          <p>④ The Company may suspend the Services, upon the occurrence of any of the following events:</p>
          <ol type="1" className="numbered-list" start="1">
            <li>A regular checkup, expansion or replacement of the system is required;</li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>An urgent checkup, expansion or replacement of the system is required;</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>Replacement with new services, etc. as the Company deems appropriate; or</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>
              It is not possible to provide the Services in a normal manner due to a national emergency, power failure,
              malfunctioning of the equipment used to provide the Services or an explosion of subscriptions for the
              Services, etc.
            </li>
          </ol>
          <p>
            ⑤ When the Company has placed restrictions on use of the Services or suspended the Services pursuant to
            subsection 4 above, the Company shall advise Members of the reason therefor, the duration of such
            restrictions or suspension, etc.
          </p>
          <p>
            ⑥ In case of suspension of the Services due to a cause beyond its control (such as (a hard disk failure,
            system breakdown, etc. through no deliberate action or fault of the system manager), the Company is unable
            to give prior notice of such suspension. If the Services are suspended due to a deliberate action or fault
            of a third party (such as PC communications service providers, facilities-based telecommunications service
            providers, etc.), the Company shall have no obligation to give notice thereof at all.
          </p>
          <p>
            <strong>Article 13 [Member’s Obligations]</strong>
          </p>
          <p>
            ① A Member’s right to use the Services shall be personal to the Member concerned and shall not be assigned,
            donated or hypothecated to another person unless expressly consented thereto by the Company.
          </p>
          <p>
            ② In case of any changes to his/her user agreement (such as address, contact information, email address,
            etc.), the Member concerned shall immediately advise the Company thereof in accordance with applicable
            procedure.
          </p>
          <p>
            ③ A Member shall comply with these Terms of Use, applicable laws and regulations, and the requirements
            publicly notified by the Company from time to time and shall not hinder the Company’s business activities
            nor shall engage in any activity that may damage the Company’s reputation.
          </p>
          <p>
            ④ A Member shall not engage in any business activities by using the Services, without the prior consent of
            the Company, and the Company shall bear no liability for such business activities. In addition, a Member
            shall be liable to the Company for the damage suffered as a result of the aforesaid business activities, if
            any.
          </p>
          <p>
            ⑤ A Member shall not assign, donate or hypothecate his/her right to use the Services and his/her status
            under the user agreement to another person, unless expressly consented thereto by the Company.
          </p>
          <p>⑥ A Member shall in no event infringe on any intellectual property of a third party’s or the Company’s.</p>
          <p>
            ⑦ If a Member has committed any of the following acts, the Company may take appropriate action including but
            not limited to restricting the Member in question from using the Services:
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              Provides any false information, uses another Member’s ID and/or personal information or shares the ID
              and/or personal information of his/her own or another Member’s in subscribing for the Services or changing
              his/her subscription therefor;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>Is found to has become a Member in any other unlawful way;</li>
          </ol>
          <ol type="1" className="numbered-list" start="3">
            <li>Infringes on any of the rights or copyrights of the Company’s or a third party’s;</li>
          </ol>
          <ol type="1" className="numbered-list" start="4">
            <li>
              Engages in any commercial transactions, including but not limited to business activities involving sale of
              goods or services by using the Services provided by the Company;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="5">
            <li>
              Interferes with other users’ use of the Services or pretends that he/she is an executive, employee or
              official of the Company;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="6">
            <li>
              Transmits, posts or spreads to another person via email or using other methods any obscene or indecent
              information, texts, figures, sound or videos that are against public order and good public morals;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="7">
            <li>
              Intentionally hinders the Company from providing the Services or transmits a great deal of information or
              any advertising information for that purpose;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="8">
            <li>
              Transmits, posts or spreads to another person via email or using other methods any information of an
              insulting nature or related to another person’s personal profile that may damage or violate the latter’s
              reputation or privacy;
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="9">
            <li>Collects or stores the personal information of another user, without the approval of the Company;</li>
          </ol>
          <ol type="1" className="numbered-list" start="10">
            <li>
              Damages the reputation of the Company or another person or insults the Company or another person; or
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="11">
            <li>Has otherwise violated the provision(s) of applicable laws and/or these Terms of Use.</li>
          </ol>
          <p>
            <strong>Article 14 [Service Fee]</strong>
          </p>
          <p>
            ① The fee for paid services and payment terms shall be subject to the provisions set out in the relevant
            service page and the payment page.
          </p>
          <p>
            ② The Company shall change its policy concerning cyber money (such as coupons and points), depending on the
            status of the Company’s operations, and, in case of any changes made thereto, such changes shall be posted
            on its integrated website.
          </p>
          <p>
            <strong>Article 15 [Refund Policy]</strong>
          </p>
          <p>
            ① A Member who intends to get a refund of tuitions already paid or change the course already registered
            shall express to the Company his/her intent to do so using wired or wireless methods. Upon request of a
            Member, the Company shall check the Member’s request and the relevant provisions of Refund Regulations and
            shall promptly refund the tuitions as soon as possible.
          </p>
          <p>1. Refund</p>
          <p>(1) Refund regulations concerning tuitions for online courses</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              100% to be refunded, if the Member concerned has not taken any lectures within seven days from the date of
              payment thereof. In case of a refund made due to a cause attributable to the customer concerned, the
              tuitions already paid shall be refunded after 500 Korean Won is deducted therefrom as a finance charge.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              If a refund request is made prior to the expiration of the prescribed period for use (이용시간), a refund
              shall be made in the amount actually paid <em>less</em> the amount equal to the number of lectures
              actually reproduced.
            </li>
          </ul>
          <p>
            (The amount equal to the number of lectures actually reproduced: The amount paid for the lectures taken/the
            number of lecture units * the number of lectures to be delivered)
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Please do not fail to confirm the refund policy applicable to special offer products (such as packages,
              free passes, etc.) which policy may be different from the aforementioned one.
            </li>
          </ul>
          <p>(2) Refund regulations concerning tuitions for offline courses</p>
          <p>
            Tuitions shall be 100% refunded up to seven days prior to the opening date of the course in question (i.e.
            the commencement date of lessons) and, thereafter, ten percent (10%) of the tuitions paid shall be deducted
            as penalties.
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Upon request for a refund due to a cause attributable to the customer, the tuitions shall be refunded
              after the deduction of a fee equal to three percent (3%) in case of the payment made in credit card
              (except as the course is cancelled).
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              A refund of tuitions hereunder shall be made, based on the amount thereof actually paid (excluding the
              discount given, if any).
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              According to applicable laws, it is provided that tuitions may be refunded, as follows, after the opening
              of the course (The Act on the Establishment and Operation of Private Teaching Institutes and
              Extracurricular Lessons):
            </li>
          </ul>
          <p>
            (Following the opening of the course, a refund of tuitions shall be made subject to deductions on a daily or
            pro rata basis regardless of whether the customer has actually taken such lectures or not.)
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Prior to the lapse of 1/3 of the total number of lessons: 2/3 of the tuitions paid shall be refunded after
              the deduction of 1/3 thereof)
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Prior to the lapse of 1/2 of the total number of lessons: 1/2 of the tuitions paid shall be refunded after
              the deduction of 1/2 thereof)
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              After the lapse of 1/2 of the total number of lessons: no refund available
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              If the customer concerned is absent from any lessons without notice, the customer shall be deemed to have
              attend such lessons and the amount of refund shall be calculated accordingly.
            </li>
          </ul>
          <p>[If the planned duration of the course in question exceeds one month]</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              The amount of refund for the month in which the reason for a refund request has occurred:
            </li>
          </ul>
          <p>
            The total amount of (the tuitions calculated for the collection period not exceeding one month, if any) and
            the tuitions for the remaining months
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              It is deemed that the registration fee (eight hours/month) and tuitions for the first month amount to
              240,000 Korean Won and the registration fee (two hours/month) and tuitions for the second month amount to
              60,000 Korean Won.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              In case of a refund request prior to the lapse of 1/3 of the lessons for the first month: 2/3 of the
              tuitions for the first month + 100% of the tuitions for the second month are refundable.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              In case of a refund request prior to the lapse of 1/2 of the lessons for the first month: 1/2 of the
              tuitions for the first month + 100% of the tuitions for the second month are refundable.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              In case of a refund request after the lapse of 1/2 of the lessons for the first month: the tuitions for
              the first months are not refundable, while 100% of the tuitions for the second month are refundable.
            </li>
          </ul>
          <p>
            (3) Refund Regulations applicable to lectures (a combination of online and offline lectures) delivered at
            private teaching institutions
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              If, in case of a combination of online and offline lectures, the customer concerned requests a refund of
              tuitions after having taken online lectures but before the opening of offline lectures, part of the
              tuitions corresponding to the online lectures shall be deducted and only the remaining amount thereof
              shall be refunded.
            </li>
          </ul>
          <ol type="1" className="numbered-list" start="1">
            <li>Changes</li>
          </ol>
          <p>
            If any course meeting the requirements for refund is changed to another course, it is not possible to change
            such course directly. Alternatively, the customer concerned may request a refund of the tuitions already
            paid in a normal manner and then register for such another course, or otherwise shall make an inquiry to the
            customer center.
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>Suspension of Services due to the Company’s circumstances</li>
          </ol>
          <p>
            If the Company is unable to continue providing the Services due to unavoidable circumstances, the Company
            shall give public notice thereof to Members and promptly make a refund to the best of its ability.
          </p>
          <ol type="1" className="numbered-list" start="1">
            <li>Suspension of Use and Forced Withdrawal from Membership</li>
          </ol>
          <p>
            If a Member has violated applicable laws and/or these Terms of Use and, as a result of it, is subject to a
            suspension of use of the Services provided by the Company and is forced to withdraw from membership, the
            refund regulations under this Article 15 shall not apply.
          </p>
          <p>
            ② The discount coupons issued to Members by the Company, free of charge, and the amount of such coupons
            given by the Company beyond the prescribed charging ratio (충전비율) shall not be refunded.
          </p>
          <p>
            ③ If a Member is subject to the restrictions placed on the use of the Services pursuant to Article 17
            hereof, the fees already paid shall not be refunded in any way.
          </p>
          <p>
            <strong>Article 16 [Copyright]</strong>
          </p>
          <p>
            ① All copyrights in the postings made by Members on the Company website shall be vested in authors of such
            postings. By submitting or making such postings through the Services, the Member in question shall be deemed
            to have granted the Company a license (including the right to sublicense) to use, copy, reproduce, process,
            adapt, modify, disclose, transmit, post or distribute the aforesaid postings. The license so granted above
            shall remain in full force and effect even after the Member in question has ceased to use the Services or
            withdraw from membership to the integrated website; provided, however, that the Company may inform that
            Member how to access or delete the content posted by him/her.
          </p>
          <p>
            ② A Member shall not make use of or have a third party make use of the information that he/she has acquired
            by using the Services provided by the Company by means of recording, reproduction, transmission,
            publication, distribution, broadcast or using any other means, whether on a commercial or non-commercial
            basis, without the prior consent of the Company.
          </p>
          <p>
            ③ The contents provided by the Company are protected by the Copyright Act and the act of reproducing,
            distributing or posting part or all of such contents without the consent of the Company shall constitute
            violation of the Copyright Act.
          </p>
          <p>
            ④ If a Member has infringed on the Company’s copyright in any of the works owned by the Company, the Company
            shall be entitled to make a claim against the Member in question for damages.
          </p>
          <p>
            <strong>Article 17 [Prevention and Tackling of Illegal Use]</strong>
          </p>
          <p>① How to Identify and Tackle Illegal Use</p>
          <ol type="1" className="numbered-list" start="1">
            <li>
              The Company screens and verifies any illegal use of the Services via the server, based on the IP
              information, MAC addresses, etc. that have been collected and confirmed while the Services are being used
              by Members.
            </li>
          </ol>
          <ol type="1" className="numbered-list" start="2">
            <li>
              If a Member executes a reproduction program while using the Services or two or more Members access the
              Services simultaneously using the same user ID, then the Company forcibly terminates such access to the
              Services at its sole discretion.
            </li>
          </ol>
          <p>
            <strong>CHAPTER IV. DAMAGES AND MISCELLANEOUS PROVISIONS</strong>
          </p>
          <p>
            <strong>Article 18 [Damages]</strong>
          </p>
          <p>
            ① The Company shall be liable to users for the damage suffered due to a cause attributable to the Company in
            relation to all of the Services provided by the Company. For the avoidance of doubt, the Company shall in no
            event compensate users for the damage arising due to a failure, etc. of the services provided free of
            charge.
          </p>
          <p>
            ② A Member shall be liable to the Company and a third party for the damage suffered as a result of the
            Member’s activity that violates these Terms of Use or due to a cause otherwise attributable to him/her, if
            any.
          </p>
          <p>
            ③ Compensation methods, procedures, etc. shall be subject to the relevant provisions of applicable laws.
          </p>
          <p>
            <strong>Article 19 [Disclaimer]</strong>
          </p>
          <p>
            ① If the Company is prevented from providing the Services by acts of god, wars or similar causes beyond its
            control, then the Company shall be relieved of its responsibility for non-performance of such obligations.
          </p>
          <p>
            ② The Company shall be relieved of liability for the damage caused by the suspension of telecommunications
            services or a failure to provide such services in a normal manner by a facilities-based telecommunications
            service provider(s).
          </p>
          <p>
            ③ The Company shall be relieved of liability for the damage caused due to unavoidable circumstances,
            including but not limited to repair, replacement, regular checkup and installation of the equipment used to
            provide the Services.
          </p>
          <p>
            ④ The Company shall not be responsible or liable for the hindrance to the use of the Services arising due to
            a cause attributable to a Member or for the resultant damage.
          </p>
          <p>
            ⑤ The Company shall not be responsible or liable for the damage caused by an error(s) on a user’s computer
            or the incorrect profile information and/or email address provided by a Member.
          </p>
          <p>
            ⑥ The Company shall not be responsible or liable for a failure to realize the profit that a Member has
            expected to earn out of his/her use of the Services or a loss of such profit.
          </p>
          <p>
            ⑦ The Company shall not be responsible or liable for the damage caused by the data or materials that a
            Member has acquired in the course of using the Services. In addition, the Company shall not be liable to
            compensate Members for the mental damages suffered due to other Members while using the Services.
          </p>
          <p>
            ⑧ The Company shall not be responsible for the content of the information, data, materials and facts posted
            by Members, including the reliability and accuracy thereof.
          </p>
          <p>
            ⑨ The Company shall have no obligation to get involved in the disputes among users or between a user and a
            third party arising out of or in connection with the Services nor shall be liable to compensate for the
            damage caused thereby, either.
          </p>
          <p>
            ⑩ The Company shall in no event be liable to Members for any damage arising in relation to their use of the
            services provided free of charge.
          </p>
          <p>
            ⑪ The Company shall not bear any liability whatsoever for the content of the postings made by Members that
            is illegal or against good public morals or social order. Furthermore, the Company does not make any
            warranties, express or implied, with respect to the value, use, compliance with applicable laws (including
            the advertising law), etc. of any and all information posted by Members on the Company website and of any
            goods and services whatsoever acquired by Members as a result of advertisements, other information or
            proposals related to the aforesaid information
          </p>
          <p>
            <strong>Article 20 [Settlement of Disputes]</strong>
          </p>
          <p>
            ① Upon the occurrence of a dispute, the Company shall take appropriate and prompt actions by taking into
            account the reasonable opinions or complaints raised by the user in question. Notwithstanding the foregoing,
            if the Company is not able to promptly process such opinions and complaints, the Company shall give notice
            to the user in question of the reason therefor and the anticipated processing schedule.
          </p>
          <p>② All disputes between the Company and users shall be governed by the laws of the Republic of Korea.</p>
          <p>
            ③ If a request for remedies is made by a user for the damage allegedly suffered in relation to an e-commerce
            dispute between the Company and the user, the dispute in question may be settled by mediation conducted by
            the Fair Trade Commission, E-commerce Mediation Committee or other dispute mediation institution at the
            request of the Mayor or
            <em>Do</em> Governor.
          </p>
          <p>
            <strong>Article 21 [Use of Discount Coupons and Points]</strong>
          </p>
          <p>
            ① The Company may issue to users purchasing its goods, etc. some discount coupons which entitle such users
            to get a discount in the prescribed amount or at the prescribed rate when purchasing the commodities
            designated by the Company.
          </p>
          <p>
            ② Such coupons shall not be assigned or otherwise transferred to another person nor shall in any event be
            substantially purchased from or sold to another person, unless otherwise expressly provide by the Company.
          </p>
          <p>
            ③ The aforesaid discount coupons may not be negotiated or converted into cash and shall expire upon the
            expiration of its validity indicated thereon or upon the expiration or termination of the user agreement
            concerned.
          </p>
          <p>
            ④ Restrictions may be placed on the use of discount coupons, with respect to some items of commodities or
            depending on the total purchase amount. If a Member has withdrawn from membership, the discount coupons held
            by the Member in question shall automatically expire.
          </p>
          <p>
            <strong>[Addenda]</strong>
          </p>
          <p>
            These Terms of Use come into effect on September 27, 2021 and supersedes the previous terms of use of the
            Company.
          </p>
          <p>
            Members who have become a Member prior to the effective date hereof shall also be subject to these Terms of
            Use.
          </p>
        </div>
      </article>
    </div>
  );
}
