import '../index.scss';

function Refund() {
  return (
    <div className="payment_term">
      <h1 className="page-title">Refund Policy</h1>
      <div className="page-body">
        <p>
          ① A Member who intends to get a refund of tuitions already paid or change the course already registered shall
          express to the Company his/her intent to do so using wired or wireless methods. Upon request of a Member, the
          Company shall check the Member’s request and the relevant provisions of Refund Regulations and shall promptly
          refund the tuitions as soon as possible.
        </p>
        <p>1. Refund</p>
        <p>
          (1)
          <strong>Refund regulations concerning tuitions for online courses</strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            100% to be refunded, if the Member concerned has not taken any lectures within seven days from the date of
            payment thereof. In case of a refund made due to a cause attributable to the customer concerned, the
            tuitions already paid shall be refunded after 500 Korean Won is deducted therefrom as a finance charge.
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            If a refund request is made prior to the expiration of the prescribed period for use (이용시간), a refund
            shall be made in the amount actually paid <em>less</em> the amount equal to the number of lectures actually
            reproduced.
          </li>
        </ul>
        <p>
          ((The amount equal to the number of lectures actually reproduced: The amount paid for the lectures taken/the
          number of lecture units * the number of lectures to be delivered)
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Please do not fail to confirm the refund policy applicable to special offer products (such as packages, free
            passes, etc.) which policy may be different from the aforementioned one.
          </li>
        </ul>
        <p>
          (2)
          <strong>Refund regulations concerning tuitions for offline courses</strong>
        </p>
        <p>
          Tuitions shall be 100% refunded up to seven days prior to the opening date of the course in question (i.e. the
          commencement date of lessons) and, thereafter, ten percent (10%) of the tuitions paid shall be deducted as
          penalties.
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Upon request for a refund due to a cause attributable to the customer concerned, the tuitions already paid
            shall be refunded after 500 Korean Won is deducted as a finance charge in case of a virtual account (except
            as the course is cancelled).
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            A refund of tuitions hereunder shall be made, based on the amount thereof actually paid (excluding the
            discount given, if any).
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            According to applicable laws, it is provided that tuitions may be refunded, as follows, after the opening of
            the course (The Act on the Establishment and Operation of Private Teaching Institutes and Extracurricular
            Lessons):
          </li>
        </ul>
        <p>
          (Following the opening of the course, a refund of tuitions shall be made subject to deductions on a daily or
          pro rata basis regardless of whether the customer has actually taken such lectures or not.)
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Prior to the lapse of 1/3 of the total number of lessons: 2/3 of the tuitions paid shall be refunded after
            the deduction of 1/3 thereof)
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Prior to the lapse of 1/2 of the total number of lessons: 1/2 of the tuitions paid shall be refunded after
            the deduction of 1/2 thereof)
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            After the lapse of 1/2 of the total number of lessons: no refund available
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            If the customer concerned is absent from any lessons without notice, the customer shall be deemed to have
            attend such lessons and the amount of refund shall be calculated accordingly.
          </li>
        </ul>
        <p>[If the planned duration of the course in question exceeds one month]</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            The amount of refund for the month in which the reason for a refund request has occurred:
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}></li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            The total amount of (the tuitions calculated for the collection period not exceeding one month, if any) and
            the tuitions for the remaining months
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Example of refund: The duration of the course is 5 week in total; lessons shall be given for 2 hours every
            Monday (a total of 10 hours); and the amount of tuitions is 300,000 Korean Won.
            <ul className="bulleted-list">
              <li style={{ listStyleType: 'circle' }}>
                It is deemed that the registration fee (eight hours/month) and tuitions for the first month amount to
                240,000 Korean Won and the registration fee (two hours/month) and tuitions for the second month amount
                to 60,000 Korean Won.
              </li>
            </ul>
            <ul className="bulleted-list">
              <li style={{ listStyleType: 'circle' }}>
                In case of a refund request prior to the lapse of 1/3 of the lessons for the first month: 2/3 of the
                tuitions for the first month + 100% of the tuitions for the second month are refundable.
              </li>
            </ul>
            <ul className="bulleted-list">
              <li style={{ listStyleType: 'circle' }}>
                In case of a refund request prior to the lapse of 1/2 of the lessons for the first month: 1/2 of the
                tuitions for the first month + 100% of the tuitions for the second month are refundable.
              </li>
            </ul>
            <ul className="bulleted-list">
              <li style={{ listStyleType: 'circle' }}>
                In case of a refund request after the lapse of 1/2 of the lessons for the first month: the tuitions for
                the first months are not refundable, while 100% of the tuitions for the second month are refundable.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          (3)
          <strong>
            Refund Regulations applicable to lectures (a combination of online and offline lectures) delivered at
            private teaching institutions
          </strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            If, in case of a combination of online and offline lectures, the customer concerned requests a refund of
            tuitions after having taken online lectures but before the opening of offline lectures, part of the tuitions
            corresponding to the online lectures shall be deducted and only the remaining amount thereof shall be
            refunded.
            <ol type="1" className="numbered-list" start="1">
              <li>Changes</li>
            </ol>
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            If any course meeting the requirements for refund is changed to another course, it is not possible to change
            such course directly. Alternatively, the customer concerned may request a refund of the tuitions already
            paid in a normal manner and then register for such another course, or otherwise shall make an inquiry to the
            customer center.
          </li>
        </ul>
        <ol type="1" className="numbered-list" start="1">
          <li>Suspension of Services due to the Company’s Circumstances</li>
        </ol>
        <p>
          If the Company is unable to continue providing the Services due to unavoidable circumstances, the Company
          shall give public notice thereof to Members and promptly make a refund to the best of its ability.
        </p>
        <ol type="1" className="numbered-list" start="1">
          <li>Suspension of Use and Forced Withdrawal from Membership</li>
        </ol>
        <p>
          If a Member has violated applicable laws and/or these Terms of Use and, as a result of it, is subject to a
          suspension of use of the Services provided by the Company and is forced to withdraw from membership, the
          refund regulations under this Article 15 shall not apply.
        </p>
        <p>
          ② The discount coupons issued to Members by the Company, free of charge, and the amount of such coupons given
          by the Company beyond the prescribed charging ratio (충전비율) shall not be refunded.
        </p>
        <p>
          ③ If a Member is subject to the restrictions placed on the use of the Services pursuant to Article 17 hereof,
          the fees already paid shall not be refunded in any way.
        </p>
      </div>
    </div>
  );
}

export default Refund;
