import { applyMiddleware, createStore } from 'redux';
import Reducers from './Reducers';
import { createLogger } from 'redux-logger';

/** Store */
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const enhancer = isDev ? applyMiddleware(createLogger()) : undefined;

const store = createStore(Reducers, undefined, enhancer);

export default store;
