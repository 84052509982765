import { useCallback, useEffect, useState } from 'react';
import './index.scss';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '../../../Assets/Images/icon-cancel-black.png';
import Rectangle1 from '../../../Assets/Images/rectangle-1.png';
import Rectangle2 from '../../../Assets/Images/rectangle-2.png';
import Rectangle3 from '../../../Assets/Images/rectangle-3.png';

const rectangleImageElements = (
  <>
    <img className="complete_wrap-rectangle1" src={Rectangle1} alt="rectangle" />
    <img className="complete_wrap-rectangle2" src={Rectangle2} alt="rectangle" />
    <img className="complete_wrap-rectangle3" src={Rectangle3} alt="rectangle" />
  </>
);

const PaymentMessageModal = ({ type, onClickCloseMessageModal, currentModalItem }) => {
  const [vbankInfo, setVbankInfo] = useState(null);

  const onClickCloseStopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    if (currentModalItem?.vbankInfo) {
      const vbankInfo = JSON.parse(currentModalItem.vbankInfo);
      setVbankInfo(vbankInfo);
    }
  }, [currentModalItem]);

  // 결제 확인서 발급
  if (type === 'confirm') {
    return (
      <div className="payment_message_modal_wrap" onClick={onClickCloseStopPropagation}>
        {rectangleImageElements}
        <h3 className="modal_title">
          <FormattedMessage id="MY_PAGE_PAYMENT_MODAL_CONFIRM_TITLE" />
        </h3>
        <span className="close_btn" onClick={onClickCloseMessageModal}>
          <img src={CloseIcon} alt="close_modal" />
        </span>
        <div className="confirm_message message">
          <FormattedMessage
            id="MY_PAGE_PAYMENT_MODAL_CONFIRM_MESSAGE"
            values={{
              spam: (
                <strong>
                  <FormattedMessage id="MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD1" />
                </strong>
              ),
              inquiry: (
                <strong>
                  <FormattedMessage id="MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD2" />
                </strong>
              ),
            }}
          />
        </div>
        <a className="link_to_inquiry link_to_btn" href="https://enterjobedu.channel.io/">
          <FormattedMessage id="GO_TO_SERVICE_CENTER" />
        </a>
      </div>
    );
  }

  // 환불 신청
  if (type === 'refund') {
    return (
      <div className="payment_message_modal_wrap" onClick={onClickCloseStopPropagation}>
        {rectangleImageElements}
        <h3 className="modal_title">
          <FormattedMessage id="MY_PAGE_PAYMENT_MODAL_REFUND_TITLE" />
        </h3>
        <span className="close_btn" onClick={onClickCloseMessageModal}>
          <img src={CloseIcon} alt="close_modal" />
        </span>
        <div className="refund_message message">
          <FormattedMessage
            id="MY_PAGE_PAYMENT_MODAL_REFUND_MESSAGE"
            values={{
              inquiry: (
                <strong>
                  <FormattedMessage id="MY_PAGE_PAYMENT_MODAL_REFUND_BOLD" />
                </strong>
              ),
            }}
          />
        </div>
        <a className="link_to_inquiry link_to_btn" href="https://enterjobedu.channel.io/">
          <FormattedMessage id="GO_TO_SERVICE_CENTER" />
        </a>
      </div>
    );
  }

  // 결제 정보 확인
  if (type === 'info') {
    return (
      <div className="payment_message_modal_wrap" onClick={onClickCloseStopPropagation}>
        {rectangleImageElements}
        <h3 className="modal_title">
          <FormattedMessage id="MY_PAGE_PAYMENT_MODAL_INFO_TITLE" />
        </h3>
        <span className="close_btn" onClick={onClickCloseMessageModal}>
          <img src={CloseIcon} alt="close_modal" />
        </span>
        {vbankInfo && (
          <div className="refund_info_list">
            <p>
              <strong className="label">
                <FormattedMessage id="NAME_OF_BANK" />
              </strong>
              <span>{vbankInfo.vbank_name}</span>
            </p>
            <p>
              <strong className="label">
                <FormattedMessage id="ACCOUNT_HOLDER" />
              </strong>
              <span>{vbankInfo.vbank_holder}</span>
            </p>
            <p>
              <strong className="label">
                <FormattedMessage id="ACCOUNT_NUMBER" />
              </strong>
              <span>{vbankInfo.vbank_num}</span>
            </p>
            <p>
              <strong className="label">
                <FormattedMessage id="DEPOSIT_DEADLINE" />
              </strong>
              <span>
                {dayjs.unix(vbankInfo.vbank_date).format('YYYY년 MM월 DD일')}
                까지
              </span>
            </p>
          </div>
        )}
        <span className="confirm_btn" onClick={onClickCloseMessageModal}>
          <FormattedMessage id="COMMON_CONFIRM" />
        </span>
      </div>
    );
  }
};

export default PaymentMessageModal;
