import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MyPage from '../../../Commons/Layouts/MyPage';
import { GET } from '../../../Commons/Utils/fetch';
import View from './View';

const Group = (props) => {
  const { orgId } = useParams();
  const fetchOrganization = async (orgId) => {
    const response = await GET({
      url: `/organization/${orgId}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });
    return response;
  };

  const organization = useMemo(() => {
    return fetchOrganization(orgId);
  }, [orgId]);
  return (
    <MyPage>
      <View {...props} organization={organization} orgId={orgId} />
    </MyPage>
  );
};

export default Group;
