import React, { useEffect, useState } from 'react';
import Meta from '../../Commons/Components/Meta';
import Main from '../../Commons/Layouts/Main';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GET } from '../../Commons/Utils/fetch';

import AlbumInfo from './components/albumInfo';
import AlbumDetail from './components/albumDetail';
import RelatedRecords from './components/relatedRecords';

const KpopDBDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [record, setRecord] = useState(null);
  const [metaInfo, setMetaInfo] = useState(null);
  const concatArray = (array, key) =>
    array?.map((item, index) => (index ? `,${item[key].name}` : item[key].name)).join('');

  const concatStrings = (array, connector) => array?.join(connector);

  useEffect(() => {
    const fetch = async () =>
      await GET({
        url: `/auth/records/${id}`,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
    fetch().then((data) => {
      setRecord(data);
      if (Boolean(data)) {
        const artists = concatArray(data.Record_Artists, 'FK_Artist');
        const agencies = concatArray(data.Record_Agencies, 'FK_Agency');
        setMetaInfo({
          title: data.title.concat(' | 엔터잡에듀'),
          description: `${artists}의 ${data.title} 앨범 크레딧 정보를 확인해보세요.`,
          image: data.thumbnail || null,
          keywords:
            '앨범크레딧, album credit, KPOP, 아이돌, idol, A&R, producer, 마케팅, 팬마케팅, 콘텐츠마케팅, artist management, 홍보, public relations, media, mv, music video, choreography, stylist, hair, makeup, design, photographer, mixing engineer, recording engineer, SM, JYP, HYBE, 하이브, YG, 카카오, 포트폴리오, 기획안',
          canonical: location.pathname,
          type: 'article',
        });
      } else {
        history.replace('/albumcredit-db');
      }
    });
  }, [id]);

  return (
    record &&
    metaInfo && (
      <Main>
        <Meta metaInfo={metaInfo} />
        <div
          id="maker-db-detail-container"
          className="w-full md:max-w-[1200px] min-w-[320px] pb-12 px-6 md:px-0 m-auto box-border md:pt-[90px]"
        >
          <AlbumInfo
            data={record}
            artists={concatArray(record.Record_Artists, 'FK_Artist')}
            agencies={concatArray(record.Record_Agencies, 'FK_Agency')}
          />
          <AlbumDetail data={record.details} />
          <RelatedRecords recordId={id} />
        </div>
      </Main>
    )
  );
};

export default KpopDBDetail;
