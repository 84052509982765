export const myPageList = [
  {
    to: '/my/lectures',
    key: 'MY_PAGE_LECTURES',
  },
  // {
  //   to: '/',
  //   key: 'MY_PAGE_WISH_LIST'
  // },
  {
    to: '/my/payment',
    key: 'MY_PAGE_PAYMENT',
  },
  {
    to: '/my/coupon',
    key: 'MY_PAGE_COUPON',
  },
  {
    to: '/my/like-lectures',
    key: 'MY_PAGE_LIKE_LECTURES',
  },
  // {
  //   to: '/',
  //   key: 'MY_PAGE_POINT'
  // },
  {
    to: '/my/profile',
    key: 'MY_PAGE_PROFILE',
  },
  {
    href: 'https://enterjobedu.channel.io/',
    key: 'MY_PAGE_INQUIRY',
  },
];

export const boardList = [
  {
    to: '/notice',
    key: 'HEADER_USER_NAV_NOTICE',
  },
  {
    to: '/event',
    key: 'HEADER_USER_NAV_EVENT',
  },
  {
    to: '/blog',
    key: 'HEADER_USER_NAV_BLOG',
  },
];
