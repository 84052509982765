import Main from '../../Commons/Layouts/Main';
import PrivacyPolicy from '../../Commons/Components/Terms/PrivacyPolicy';

const PrivacyPolicyPage = (props) => {
  return (
    <Main>
      <PrivacyPolicy />
    </Main>
  );
};

export default PrivacyPolicyPage;
