import ReactPopup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './index.scss';

import closeIcon from '../../../Assets/Images/icon-cancel-black.png';
import diagonalLeft from '../../../Assets/Images/popup-diagonal-left.svg';
import diagonalRightTop from '../../../Assets/Images/popup-diagonal-right-top.svg';
import diagonalRightBottom from '../../../Assets/Images/popup-diagonal-right-bottom.svg';

function Popup(props) {
  return (
    <ReactPopup {...props}>
      {(close) => (
        <>
          {props.children}
          <img className="popup-close" onClick={close} src={closeIcon} alt="close" />
          <div className="popup-diagonals">
            <img className="popup-diagonal popup-diagonal-left" src={diagonalLeft} alt="diagonal-left" />
            <img className="popup-diagonal popup-diagonal-right-top" src={diagonalRightTop} alt="diagonal-right-top" />
            <img
              className="popup-diagonal popup-diagonal-right-bottom"
              src={diagonalRightBottom}
              alt="diagonal-right-bottom"
            />
          </div>
        </>
      )}
    </ReactPopup>
  );
}

export default Popup;
