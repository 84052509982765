import '../index.scss';

export default function PrivacyPolicy(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">Chính sách xử lý thông tin cá nhân</h2>
      <article className="page sans">
        <div className="page-body">
          <p>
            Công ty cổ phần Klab Company (sau đây gọi là “Công ty” hoặc “Dịch vụ Enterjobedu”) tuân thủ quy định bảo vệ
            thông tin cá nhân theo các luật liên quan mà bên cung cấp dịch vụ truyền thông và thông tin phải tuân theo
            như Luật về Bảo vệ thông tin và thúc đẩy sử dụng mạng thông tin và truyền thông (sau đây gọi là “Luật Mạng
            thông tin và truyền thông”). Chúng tôi cũng lập ra chính sách xử lý thông tin cá nhân dựa trên các luật liên
            quan và cố gắng hết mình để bảo vệ quyền lợi của người sử dụng.
          </p>
          <p>Chương 1. Các hạng mục thông tin cá nhân được thu thập và phương pháp thu thập</p>
          <p>Chương 2. Thu thập thông tin cá nhân và mục đích sử dụng</p>
          <p>Chương 3. Đồng ý về việc thu thập thông tin cá nhân</p>
          <p>Chương 4. Sở hữu thông tin cá nhân và thời hạn sử dụng</p>
          <p>Chương 5. Thủ tục và cách thức hủy bỏ thông tin cá nhân</p>
          <p>Chương 6. Quyền lợi của người sử dụng, người đại diện theo pháp luật, và phương thức thực hiện</p>
          <p>
            Chương 7. Cài đặt, vận hành thiết bị tự động thu thập thông tin cá nhân và các nội dung liên quan đến vấn đề
            từ chối
          </p>
          <p>Chương 8. Đối sách kỹ thuật, quản lý để bảo vệ thông tin cá nhân</p>
          <p>Chương 9. Thông tin cá nhân của trẻ em</p>
          <p>Chương 10. Người chịu trách nhiệm quản lý thông tin cá nhân</p>
          <p>Chương 11. Hướng dẫn về Trung tâm chăm sóc khách hàng</p>
          <p>
            <strong>Chương 1. Các hạng mục thông tin cá nhân được thu thập và phương pháp thu thập</strong>
          </p>
          <p>
            Công ty đang thu thập thông tin cá nhân để đăng ký hội viên, cung cấp dịch vụ, tư vấn, v.v., ngoài ra cũng
            có thể yêu cầu điền thông tin cá nhân nhằm mục đích gửi tặng phẩm hay phân tích thống kê tập thể khi có sự
            kiện hoặc cuộc điều tra khảo sát. Tuy nhiên, nếu có thể, công ty sẽ không thu thập các thông tin cá nhân
            nhạy cảm, có nguy cơ xâm hại đến nhân quyền cơ bản của người dùng (chủng tộc và dân tộc, tư tưởng và tín
            điều, nơi sinh và nguyên quán, khuynh hướng chính trị và lý lịch tư pháp, v.v.). Trong trường hợp bất đắc dĩ
            phải thu thập các thông tin này, chúng tôi nhất định sẽ hỏi xin ý kiến đồng ý trước của người dùng. Ngay cả
            lúc này, thông tin mà Quý vị đã điền vào cũng sẽ không được sử dụng cho bất kỳ mục đích nào khác ngoài mục
            đích mà chúng tôi đã thông báo trước đó cho Quý hội viên hoặc chỉ để cung cấp dịch vụ liên quan.
          </p>
          <p>① Các hạng mục thông tin cá nhân được thu thập</p>
          <p>(1) Đăng ký thành viên</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Các mục cần thiết: ID đăng nhập, Mật khẩu đăng nhập, Địa chỉ e-mail, số điện thoại di động
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Các mục tùy chọn: Giới tính, địa chỉ, số điện thoại, mục tiêu học tập, nghề nghiệp, lĩnh vực quan tâm,
              lĩnh vực ứng tuyển, thông tin về kỳ thi ứng tuyển, kết quả ứng tuyển
            </li>
          </ul>
          <p>
            (2) Trong quá trình sử dụng dịch vụ hoặc xử lý chương trình dự án, các thông tin dưới đây có thể được tạo ra
            và thu thập.
          </p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Lịch sử dụng dịch vụ, nhật ký truy cập, Cookie, thông tin IP truy cập, thông tin về thiết bị máy tính,
              lịch sử thanh toán, lịch sử sử dụng bất chính
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              (Khi sử dụng thiết bị di động) Mã số nhận diện của thiết bị đầu cuối, thông tin OS của thiết bị đầu cuối,
              công ty truyền thông di động, có/không nhận tin PUSH
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Thông tin xác thực tên thật</li>
          </ul>
          <p>(3) Hạng mục được thu thập khi sử dụng thanh toán có phí</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Các thông tin cần thiết khi thanh toán tiền hàng như thông tin thẻ tín dụng, thông tin tài khoản ngân
              hàng, thông tin điện thoại di động, v.v., và các thông tin này có thể được cung cấp cho bên thứ 3 (doanh
              nghiệp liên kết, PG (Payment Gateway: Cổng thanh toán)).
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Thanh toán bằng thẻ tín dụng: Lịch sử thanh toán như mã số của công ty thẻ, mã số duyệt, v.v.
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Chuyển khoản: Lịch sử thanh toán như tên ngân hàng giao dịch, số tài khoản, họ tên của người giao
                  dịch, mã số ngân hàng, v.v.
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Thanh toán bằng điện thoại di động: Lịch sử thanh toán như số điện thoại di động, mã số duyệt, mã số
                  của nhà mạng, v.v.
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  Khác: Mã bưu chính, địa chỉ, số liên lạc (khi mua nội dung có bao gồm sản phẩm cần vận chuyển)
                </li>
              </ul>
            </li>
          </ul>
          <p>(Các thông tin không được cung cấp khi đăng ký thành viên có thể được thu thập thêm)</p>
          <p>(4) Trường hợp đăng ký tham gia sự kiện và dịch vụ kèm thêm</p>
          <p>
            Có thể thu thập thêm thông tin cá nhân cần thiết cho dịch vụ liên quan nếu nhận được sự đồng ý riêng của
            người dùng những dịch vụ này.
          </p>
          <p>② Các hạng mục thông tin cá nhân được thu thập</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Thu thập thông qua trang chủ hoặc email</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Thu thập ngoại tuyến thông qua điện thoại, fax, đơn đăng ký trong công ty, v.v.
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Tự động thu thập thông qua các công cụ thu thập những thông tin được tạo ra
            </li>
          </ul>
          <p>
            <strong>Chương 2. Thu thập thông tin cá nhân và mục đích sử dụng</strong>
          </p>
          <p>Công ty thu thập thông tin cá nhân vì những mục đích sau:</p>
          <p>① Thanh toán chi phí cho việc cung cấp dịch vụ và thực hiện hợp đồng liên quan đến cung cấp dịch vụ</p>
          <p>
            ② Cung cấp nội dung, vận chuyển vật phẩm, gửi giấy yêu cầu, xác thực bản thân, mua hàng và thanh toán chi
            phí, dịch vụ tài chính
          </p>
          <p>③ Quản lý hội viên</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Truyền tải nội dung thông báo, xử lý các vấn đề theo yêu cầu của người dùng như giải tỏa bất mãn, xác nhận
              có/không có sự đồng ý của người đại diện theo pháp luật khi thu thập thông tin cá nhân của trẻ em dưới 14
              tuổi, xác nhận độ tuổi, xác nhận ý định đăng ký tham gia, ngăn chặn việc sử dụng mà không được phê duyệt,
              ngăn chặn việc sử dụng bất chính của những hội viên phá rối, nhận diện cá nhân, xác thực bản thân do sử
              dụng dịch vụ theo chế độ hội viên
            </li>
          </ul>
          <p>④ Marketing và quảng cáo để hướng dẫn về dịch vụ</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Truyền tải thông tin mang tính quảng cáo như sự kiện, thống kê về việc sử dụng dịch vụ của hội viên, nắm
              bắt tần suất truy cập, đăng tải quảng cáo và cung cấp dịch vụ theo đặc điểm thống kê dân số học, phát
              triển dịch vụ mới và chuyên hóa
            </li>
          </ul>
          <p>
            <strong>Chương 3. Đồng ý về việc thu thập thông tin cá nhân</strong>
          </p>
          <p>
            Công ty nhận được sự đồng ý về việc thu thập thông tin cá nhân của Quý hội viên, và đang chuẩn bị các thủ
            tục đồng ý thu thập thông tin cá nhân theo Chính sách xử lý thông tin cá nhân và Điều khoản sử dụng trong số
            các thủ tục đăng ký thành viên của công ty. Nếu Quý hội viên đã đăng ký tham gia thông qua trang đăng ký
            thành viên của Enterjobedu thì coi như là đã đồng ý với việc thu thập thông tin cá nhân.
          </p>
          <p>
            <strong>Chương 4. Sở hữu thông tin cá nhân và thời hạn sử dụng</strong>
          </p>
          <p>
            ① Về mặt nguyên tắc, sau khi thu thập thông tin cá nhân và đạt được mục đích sử dụng, các thông tin liên
            quan sẽ được hủy bỏ ngay lập tức. Tuy nhiên, các thông tin sau sẽ được lưu trữ trong khoảng thời gian đã
            được nêu rõ vì những lý do dưới đây.
          </p>
          <p>(1) Thông tin cá nhân được lưu trữ khi hủy đăng ký thành viên</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Các hạng mục được lưu trữ Họ tên, ID, địa chỉ email, địa chỉ, số điện thoại mà Quý hội viên đã cung cấp
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Căn cứ lưu trữ Hợp tác điều tra và tranh chấp về xâm hại quyền lợi như hủy hoại danh dự, ngăn chặn người
              dùng phá rối tái đăng ký tham gia
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Thời hạn lưu trữ: 1 năm sau khi hủy đăng ký thành viên</li>
          </ul>
          <p>(2) Thông tin cá nhân được lưu trữ liên quan đến giao dịch thương mại</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>Hạng mục lưu trữ Lich sử giao dịch thương mại</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Căn cứ lưu trữ: Luật về Bảo vệ người tiêu dùng trong giao dịch thương mại điện tử, Luật Thương mại
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              Thời hạn lưu trữ: 5 năm đối với hồ sơ liên quan đến việc hủy bỏ đăng ký hội viên hoặc hợp đồng / 5 năm đối
              với hồ sơ liên quan đến việc cung cấp hàng hóa, v.v. và thanh toán tiền hàng / 3 năm đối với hồ sơ liên
              quan đến xử lý tranh chấp hoặc bất mãn của người tiêu dùng
            </li>
          </ul>
          <p>
            ② Thông tin cá nhân của người sử dụng dịch vụ sẽ được hủy bỏ bằng phương pháp mà không thể phục hồi lại được
            trong trường hợp đã đạt được mục đích thu thập và sử dụng (bao gồm cả các mục đích nhất thời như điều tra
            khảo sát, sự kiện, v.v.) hoặc khi người dùng trực tiếp xóa bỏ hoặc hủy đăng ký thành viên.
          </p>
          <p>
            <strong>Chương 5. Thủ tục và cách thức hủy bỏ thông tin cá nhân</strong>
          </p>
          <p>
            Trường hợp muốn hủy đăng ký thành viên Có thể hủy đăng ký thành viên thông qua mục Hỏi đáp 1:1, và tất cả
            các thông tin đã đăng ký sẽ bị hủy bỏ nên Quý vị phải cân nhắc cẩn thận việc có hủy đăng ký thành viên hay
            không. Ngoài ra, sau khi hủy đăng ký thành viên thì có thể bị hạn chế trong việc tái đăng ký tham gia. Thủ
            tục và cách thức hủy bỏ thông tin cá nhân của công ty như sau:
          </p>
          <p>① Thủ tục hủy bỏ</p>
          <p>
            (1) Thông tin mà người dùng nhập vào để đăng ký thành viên sẽ được chuyển đến DB (cơ sở dữ liệu) riêng sau
            khi đã đạt được mục đích ban đầu (nếu bằng giấy thì sẽ được lưu giữ ở tủ hồ sơ riêng) rồi lưu trữ trong một
            khoảng thời gian nhất định tùy theo lý do bảo vệ thông tin dựa trên pháp luật liên quan và chính sách nội bộ
            (tham khảo khoảng thời gian lưu giữ và sử dụng) và sau đó sẽ bị hủy bỏ.
          </p>
          <p>
            (2) Các thông tin cá nhân này không được sử dụng cho mục đích khác ngoài việc bảo lưu nếu không phải là
            trường hợp dựa theo pháp luật.
          </p>
          <p>② Cách thức hủy bỏ</p>
          <p>
            (1) Thông tin cá nhân đã được in ra giấy sẽ được hủy bỏ bằng cách đưa vào máy hủy tài liệu để cắt vụn hoặc
            thiêu hủy.
          </p>
          <p>
            (2) Thông tin cá nhân đã được lưu theo dạng thức tập tin điện tử sẽ được xóa bỏ bằng cách sử dụng phương
            pháp kỹ thuật để không thể phục hồi được bản ghi.
          </p>
          <p>
            <strong>
              Chương 6. Quyền lợi của người sử dụng, người đại diện theo pháp luật, và phương thức thực hiện
            </strong>
          </p>
          <p>
            ① Người dùng và người đại diện heo pháp luật có thể truy vấn hoặc chỉnh sửa thông tin cá nhân của bản thân
            hoặc trẻ em dưới 14 tuổi liên quan đã được đăng ký bất cứ lúc nào, và có thể yêu cầu hủy đăng ký thành viên.
          </p>
          <p>
            ② Để truy vấn, chỉnh sửa thông tin cá nhân của trẻ dưới 14 tuổi hoặc người dùng, để ‘chỉnh sửa thông tin
            thành viên’ (hoặc ‘thay đổi thông tin thành viên’) thì cần phải đăng nhập vào và thực hiện các bước xác thực
            bản thân, sau đó có thể trực tiếp mở xem, chỉnh sửa thông tin hoặc hủy đăng ký thành viên.
          </p>
          <p>
            Hoặc nếu Quý vị liên hệ với người chịu trách nhiệm quản lý thông tin cá nhân bằng văn bản, điện thoại hay
            email thì chúng tôi sẽ xử lý ngay lập tức.
          </p>
          <p>
            ③ Trong trường hợp người dùng yêu cầu chỉnh sửa lỗi thông tin cá nhân thì thông tin cá nhân liên quan sẽ
            không được sử dụng hay cung cấp cho đến khi hoàn tất việc chỉnh sửa. Ngoài ra, trong trường hợp thông tin cá
            nhân sai lệch đã được cung cấp cho bên thứ 3 thì chúng tôi sẽ thông báo ngay lập tức cho bên thứ 3 về kết
            quả xử lý chỉnh sửa cũng như thực hiện việc đính chính.
          </p>
          <p>
            ④ Công ty xử lý thông tin cá nhân đã bị hủy bỏ hoặc xóa bỏ theo yêu cầu của người dùng hoặc người đại diện
            theo pháp luật như nội dung đã được nêu rõ trong mục “Sở hữu thông tin cá nhân và thời hạn sử dụng”, và xử
            lý để không thể mở xem hoặc sử dụng cho mục đích khác.
          </p>
          <p>
            <strong>
              Chương 7. Cài đặt, vận hành thiết bị tự động thu thập thông tin cá nhân và các nội dung liên quan đến vấn
              đề từ chối
            </strong>
          </p>
          <p>
            ① Cookie là tệp dữ liệu nhỏ được gửi từ máy chủ HTTP đến Browser (trình duyệt web) của người dùng và được
            lưu trên máy tính của Quý hội viên. Công ty sử dụng Cookie này để nhận được dạng thức sử dụng dịch vụ hoặc
            hình thức Browser (trình duyệt web) của Quý hội viên, và các thông tin Cookie này sẽ được sử dụng để cung
            cấp dịch vụ phù hợp, tiện lợi hơn, và hữu dụng cho Quý hội viên.
          </p>
          <p>
            ② Quý hội viên có quyền lựa chọn đối với Cookie. Bằng việc lựa chọn tùy chọn ở Web Browser (trình duyệt web)
            của mình, Quý hội viên có thể cho phép lưu tất cả các Cookie, hoặc yêu cầu phải được xác nhận mỗi khi lưu
            Cookie, hay từ chối lưu mọi Cookie. Tuy nhiên, trong trường hợp từ chối lưu Cookie, Quý vị sẽ không thể sử
            dụng tất cả các dịch vụ cần phải đăng nhập (Log-in) của Công ty.
          </p>
          <p>
            <strong>Chương 8. Đối sách kỹ thuật, quản lý để bảo vệ thông tin cá nhân</strong>
          </p>
          <p>
            ① Thông tin cá nhân của Quý hội viên đang được bảo vệ dựa vào mật khẩu. Mật khẩu tài khoản của Quý hội viên
            chỉ có bản thân Quý vị mới biết, việc xác nhận và thay đổi thông tin cá nhân chỉ có thể dựa vào bản thân
            người biết mật khẩu là Quý vị. Do vậy, Quý hội viên không được cho bất kỳ ai biết mật khẩu. Ngoài ra, sau
            khi kết thúc các thao tác, Quý vị nên đăng xuất (log-out) và tắt Web Browser (trình duyệt Web) đi. Đặc biệt,
            trong trường hợp sử dụng chung máy tính với người khác hoặc sử dụng ở nơi công cộng thì các bước làm này
            càng trở nên cần thiết hơn để ngăn chặn việc lộ thông tin cá nhân cho người khác biết. Công ty không chịu
            trách nhiệm về việc rò rỉ thông tin cá nhân do các vấn đề trên Internet hay do sự bất cẩn của người dùng.
          </p>
          <p>
            ② Công ty tối thiểu hóa số nhân viên tham gia xử lý thông tin cá nhân và nhấn mạnh việc tuân thủ chính sách
            bảo vệ thông tin cá nhân thông qua chương trình giáo dục thường xuyên dành cho các nhân viên phụ trách.
          </p>
          <p>③ Công ty đang thực thi các đối sách về mặt kỹ thuật và quản lý dưới đây để bảo vệ thông tin cá nhân.</p>
          <p>(1) Chúng tôi đang nỗ lực bảo đảm hoạt động của hệ thống bảo an và tường lửa để ngăn chặn hacking.</p>
          <p>
            (2) Khi truyền nhận thông tin cá nhân, chúng tôi cài đặt chứng thư điện tử cho máy chủ bảo an SSL để bảo vệ
            thông tin.
          </p>
          <p>
            (3) Chỉ người thực thi công việc liên quan mới được tiếp cận với thông tin cá nhân, khi thực hiện công việc
            thì chỉ giới hạn những người thực sự cần thiết mới được xử lý thông tin cá nhân, còn các nhân viên khác sẽ
            không được phép tiếp cận.
          </p>
          <p>
            <strong>Chương 9. Thông tin cá nhân của trẻ em</strong>
          </p>
          <p>
            Về mặt nguyên tắc, Công ty không thu thập thông tin cá nhân của trẻ em dưới 14 tuổi. Tuy nhiên, trong trường
            hợp đặc biệt, chúng tôi cũng có thể thu thập thông tin cá nhân của trẻ em nếu được sự đồng ý của người đại
            diện theo pháp luật.
          </p>
          <p>
            <strong>Chương 10. Người chịu trách nhiệm quản lý thông tin cá nhân</strong>
          </p>
          <p>
            Nếu có ý kiến về Chính sách xử lý thông tin cá nhân của công ty thì hãy gửi email cho chúng tôi, chúng tôi
            sẽ có biện pháp xử lý ngay khi tiếp nhận và thông báo kết quả xử lý cho Quý vị. Người chịu trách nhiệm và
            người phụ trách quản lý thông tin cá nhân như thông tin dưới đây.
          </p>
          <p>Người chịu trách nhiệm quản lý thông tin cá nhân</p>
          <p>Họ tên: Lee Sang-hwan</p>
          <p>Trực thuộc: Công ty cổ phần Klab Company</p>
          <p>Chức vụ: Giám đốc đại diện:</p>
          <p>Số điện thoại: 010-9866-8640</p>
          <p>
            E-mail :<a href="mailto:enterjobedu@k-lab.me">enterjobedu@k-lab.me</a>
          </p>
          <p>
            <strong>Chương 11. Hướng dẫn về Trung tâm chăm sóc khách hàng</strong>
          </p>
          <p>
            Công ty vận hành Trung tâm chăm sóc khách hàng để xử lý các vấn đề theo yêu cầu của người dùng như dưới đây.
          </p>
          <p>Thời gian làm việc: Ngày thường KST 10:00~18:00 (Nghỉ thứ bảy, chủ nhật, ngày nghỉ lễ)</p>
          <p>
            Kakao Talk:
            <a href="http://pf.kakao.com/_jyPFT/chat">http://pf.kakao.com/_jyPFT/chat</a>
          </p>
          <p>
            Địa chỉ: Tầng 9, số 129, đường Jayang, quận Gwangjin, thành phố Seoul / Công ty cổ phần Klab Company, Người
            phụ trách xử lý thông tin cá nhân
          </p>
          <p>Câu hỏi khác: Xác nhận tại trang Trung tâm chăm sóc khách hàng</p>
          <p>[Quy tắc bổ sung]</p>
          <p>
            Nếu chỉnh sửa, thay đổi Chính sách xử lý thông tin cá nhân hiện tại thì phải thông báo trên mục Thông báo
            trong trang web trước đó ít nhất 7 ngày.
          </p>
          <p>Ngày tháng thực hiện Chính sách xử lý thông tin cá nhân này: Ngày 27 tháng 09 năm 2021</p>
        </div>
      </article>
    </div>
  );
}
