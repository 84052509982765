export const sortOptionConstant = [
  {
    key: 'RELEASE_DATE_DESC',
    value: 'publishedDate_desc',
  },
  {
    key: 'RELEASE_DATE_ASC',
    value: 'publishedDate_asc',
  },
];
