import '../index.scss';

export default function PrivacyPolicy(props) {
  return (
    <div className="terms inner_wrap">
      <h2 className="page-title">プライバシーポリシー</h2>
      <article className="page sans">
        <div className="page-body">
          <p>
            （株）コネクサスラボ（以下「会社」または「エンタージョブエデュサービス」という）は、情報通信網の利用促進および情報保護等に関する法律（以下「情報通信網法
            」という）等、情報通信サービス提供者が遵守すべき関係法令上の個人情報保護規定を遵守するとともに、関係法令に基づくプライバシーポリシーを定め、利用者の権益保護に最善を尽くしています。
          </p>
          <p>第1章収集する個人情報の項目および収集方法</p>
          <p>第2章個人情報の収集および利用目的</p>
          <p>第3章個人情報の収集への同意</p>
          <p>第4章個人情報の保有および利用期間</p>
          <p>第5章個人情報の破棄手続きおよびその方法</p>
          <p>第6章利用者および法定代理人の権利およびその行使方法</p>
          <p>第7章個人情報の自動収集ツールの設置、運用およびその拒否に関する事項</p>
          <p>第8章個人情報保護のための技術的・管理的対策</p>
          <p>第9章児童の個人情報</p>
          <p>第10章個人情報の管理責任者</p>
          <p>第11章サポートセンターのご案内</p>
          <p>
            <strong>第1章収集する個人情報の項目および収集方法</strong>
          </p>
          <p>
            会社は、会員登録、サービスの提供その他の相談等のために、個人情報を収集しており、アンケート調査やイベント時に集団的な統計分析または景品発送を目的としても個人情報の提供を要請することがあります。しかし会社は、利用者の基本的な人権侵害のおそれがある要配慮個人情報（人種および民族、思想および信条、出身地および本籍地、政治的見解および犯罪記録等）は、なるべく収集しないとともに、やむを得ず収集する必要がある場合は、あらかじめ利用者の同意を得るものとします。この場合においても、提供された情報は当該サービスの提供、または会員にあらかじめ告知した目的以外には一切利用しないものとします。
          </p>
          <p>① 収集する個人情報の項目</p>
          <p>（1）会員登録</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              必須項目：ログインID、ログインパスワード、電子メールアドレス、携帯電話番号
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              選択項目：性別、住所、電話番号、学習目標、職業、興味分野、受験分野、試験情報、受験結果
            </li>
          </ul>
          <p>（2）サービス利用または事業の処理において、以下の情報が作成され収集されることがあります。</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              サービスの利用記録、アクセスログ、クッキー、アクセスIP情報、コンピュータ情報、決済記録、不正利用記録
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              （モバイル端末の利用時）端末識別番号、端末OS情報、通信キャリア、プッシュ通知の受信有無
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>実名認証情報</li>
          </ul>
          <p>（3）有料サービス利用時に収集する項目</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              クレジットカード情報、銀行口座情報、携帯電話情報等の代金決済に必要な情報等が追加で収集されることがあるとともに、当該情報は、第三者（提携会社、PG（Payment
              Gateway：決済代行会社））に提供されることがあります。
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  クレジットカード決済：カード会社コード、承認番号等の決済関連情報
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  口座振込：取引銀行名、口座番号、取引者の氏名、銀行コード等の決済関連情報
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  携帯電話決済：携帯電話番号、承認番号、通信キャリアコード等の決済関連情報
                </li>
              </ul>
              <ul className="bulleted-list">
                <li style={{ listStyleType: 'circle' }}>
                  その他：郵便番号、住所、連絡先（商品配送が含まれているコンテンツの購入時）
                </li>
              </ul>
            </li>
          </ul>
          <p>（会員登録の際に提供されていない情報が追加で収集されることがあることを明示）</p>
          <p>（4）付加サービスおよびイベント応募の場合</p>
          <p>
            当該サービスの利用者に限って、別途の同意を得て当該サービスに必要な個人情報を追加で収集することがあります。
          </p>
          <p>② 収集する個人情報の項目</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>公式サイトまたは電子メールによる収集</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>電話、ファックス、会社の申請書等によるオフラインでの収集</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>作成情報収集ツールによる自動収集</li>
          </ul>
          <p>
            <strong>第2章個人情報の収集および利用目的</strong>
          </p>
          <p>会社は、以下の目的で個人情報を収集します。</p>
          <p>① サービスの提供に関する契約の履行およびサービスの提供による料金の精算</p>
          <p>② コンテンツの提供、物品配送、請求書の発送、本人認証、購入および料金の支払い、金融サービス</p>
          <p>③ 会員管理</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              会員制サービスの利用における本人確認、個人識別、会員の不正利用および無断使用の防止、会員登録意思の確認、年齢の確認、14歳未満の児童の個人情報を収集するにあたっての法定代理人の同意有無の確認、クレーム等の苦情対応、告示事項の提示
            </li>
          </ul>
          <p>④ サービス案内等マーケティングおよび広告</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              新規サービスの開発および特化、人口統計学的な特性に応じたサービスの提供および広告の掲載、アクセス頻度の把握、会員のサービス利用に関する統計、イベント等広告情報の提示
            </li>
          </ul>
          <p>
            <strong>第3章個人情報の収集への同意</strong>
          </p>
          <p>
            会社は、個人情報の収集について会員の同意を得ています。会員登録の際に利用規約およびプライバシーポリシーにて個人情報収集の同意手続きを取っています。会員が、エンタージョブエデュの会員登録ページから登録した場合、個人情報の収集に同意したものとみなされます。
          </p>
          <p>
            <strong>第4章個人情報の保有および利用期間</strong>
          </p>
          <p>
            ①
            原則として個人情報の収集および利用目的が達成された後は、その情報を遅滞なく破棄します。ただし、次の情報については以下の理由により、明示した期間において保管します。
          </p>
          <p>(1)会員退会時に保管する個人情報</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              保管項目：会員が提供した氏名、ID、電子メールアドレス、住所、電話番号
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              保管理由：不正利用者の再登録防止、名誉毀損等の権利侵害に関する紛争および捜査協力
            </li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>保管期間：会員退会後1年間</li>
          </ul>
          <p>(2)商取引に関する保有個人情報</p>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>保管項目：商取引履歴</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>保管理由：商法、電子商取引等における消費者保護に関する法律</li>
          </ul>
          <ul className="bulleted-list">
            <li style={{ listStyleType: 'disc' }}>
              保管期間：契約または申出の撤回等に関する記録5年/代金決済および財貨等の供給に関する記録5年/消費者のクレームまたは紛争処理に関する記録3年
            </li>
          </ul>
          <p>
            ②
            サービス利用者の個人情報は、その収集および利用目的（アンケート調査、イベント等の一時的な目的を含む）が達成され、または利用者が自ら削除または退会した場合、再生できない方法を用いて破棄されます。
          </p>
          <p>
            <strong>第5章個人情報の破棄手続きおよびその方法</strong>
          </p>
          <p>
            会員退会を希望する場合、1：1問い合わせより退会することができます。登録されているすべての情報を破棄しますので、退会の際は慎重に検討する必要があります。また、退会後の再登録を制限することがあります。会社の個人情報の破棄手続きおよびその方法は以下の通りです。
          </p>
          <p>① 破棄手続き</p>
          <p>
            （1）利用者が会員登録等のために入力した情報は、目的が達成された後、別途のデータベースに移され（紙の場合、別途の書類箱）、内部方針その他の関係法令による情報保護の事由に基づき（保有および利用期間参照）一定期間保存された後に破棄されます。
          </p>
          <p>（2）同個人情報は、法律による場合を除き、保有目的以外には利用されません。</p>
          <p>② 破棄方法</p>
          <p>（1）紙で出力された個人情報は、シュレッダーで粉砕または焼却して破棄します。</p>
          <p>（2）電子ファイルの形態で保存されている個人情報は、記録を再生できない技術的方法を用いて削除します。</p>
          <p>
            <strong>第6章利用者および法定代理人の権利およびその行使方法</strong>
          </p>
          <p>
            ①
            利用者および法定代理人は、いつでも登録されている自己あるいは当該14歳未満の児童の個人情報を照会し、または変更することができるとともに、登録解除を要請することができます。
          </p>
          <p>
            ②
            利用者あるいは14歳未満の児童の個人情報を照会、変更するには「会員情報修正」（または「会員情報変更」等）にて、ログインし本人確認の手続きを経たうえで閲覧、訂正または退会することができます。
          </p>
          <p>
            あるいは個人情報の管理責任者まで書面、電話または電子メールにより連絡をいただければ、直ちに措置を取らせていただきます。
          </p>
          <p>
            ③
            利用者が個人情報のエラーについて訂正を要請した場合には、訂正が完了するまで当該個人情報の利用または提供を行いません。また、誤った個人情報を第三者に対して既に提供した場合には、訂正処理の結果を第三者に遅滞なく通​​知して訂正が行われるようにします。
          </p>
          <p>
            ④ 会社は、利用者または法定代理人の要請により解除または削除された個人情報は、「個人情報の保有および利用期間
            」に明示されているところにより取り扱い、それ以外の用途で閲覧または利用できないようにしています。
          </p>
          <p>
            <strong>第7章個人情報の自動収集ツールの設置、運用およびその拒否に関する事項</strong>
          </p>
          <p>
            ①
            クッキー（cookie）は、HTTPサーバーから利用者のブラウザに送信する非常に小さなデータファイルで、会員のコンピュータに保存されます。会社は、これらのクッキー（cookie）を利用し、会員のブラウザ形式またはサービスの利用形態に関する情報を得るとともに、これらのクッキーの情報に基づき、会員に対して有効で便利なカスタマイズサービスを提供します。
          </p>
          <p>
            ②
            会員は、クッキーに対する選択権を有しています。会員は、ブラウザにおいてオプションを設定することにより、すべてのクッキーを許可し、またはクッキーが保存されるたびに確認を行い、もしくはすべてのクッキーの保存を拒否することができます。ただし、クッキーの保存を拒否する場合、ログイン（Log-in）が必要な会社のすべてのサービスは利用できなくなります。
          </p>
          <p>
            <strong>第8章個人情報保護のための技術的・管理的対策</strong>
          </p>
          <p>
            ①
            会員の個人情報は、パスワードにより保護されています。会員アカウントのパスワードは、本人しか知り得ません。また、個人情報の確認および変更もパスワードを知っている本人だけが行うことができます。したがって、会員のパスワードは誰にも教えてはいけません。また、作業を完了した後は、ログアウト（log-out）をし、ブラウザを終了することが望ましいです。特に他の人とコンピュータを共有して使用し、または公共施設で使用する場合、個人情報が他の人に知られることを防ぐために、このような手続きをさらに行う必要があります。利用者の不注意またはインターネット上のトラブルによる個人情報流出について会社は責任を負いません。
          </p>
          <p>
            ②
            会社は、個人情報を取り扱う担当者を最小限に抑えるとともに、その担当者への随時の教育を通じてプライバシーポリシーの遵守を強調しています。
          </p>
          <p>③ 会社は、個人情報保護のための技術的・管理的対策を次のように実施しています。</p>
          <p>（1）ハッキング防止のためのファイアウォールとセキュリティシステムに万全を期しています。</p>
          <p>（2）個人情報の送受信時にSSLセキュリティサーバー証明書をインストールし、情報を保護します。</p>
          <p>
            （3）個人情報へのアクセスは、その業務を行う者、業務を行う際にやむを得ず個人情報を取り扱う者に制限し、それ以外の従業員はアクセスできないようしています。
          </p>
          <p>
            <strong>第9章児童の個人情報</strong>
          </p>
          <p>
            会社は、原則として14歳未満の児童の個人情報を収集していません。ただし、特別な場合は、法定代理人の同意を得たうえで児童の個人情報を収集することがあります。
          </p>
          <p>
            <strong>第10章個人情報の管理責任者</strong>
          </p>
          <p>
            会社のプライバシーポリシーに関してご意見がありましたら、メールにてご連絡いただければ、受付後直ちに措置を取り、その処理結果をお知らせいたします個人情報の管理責任者および担当者は以下の通りです。
          </p>
          <p>個人情報の管理責任者</p>
          <p>氏名：イ・サンファン</p>
          <p>所属：(株)コネクサスラボ</p>
          <p>役職：代表</p>
          <p>電話：+82-10-9866-8640</p>
          <p>
            E-mail：
            <a href="mailto：enterjobedu@k-lab.me">enterjobedu@k-lab.me</a>
          </p>
          <p>
            <strong>第11章サポートセンターのご案内</strong>
          </p>
          <p>会社は苦情に対応するために、サポートセンターを以下のように運営しています。</p>
          <p>運営時間：平日KST 10：00〜18：00（土、日曜日、祝日は休み）</p>
          <p>
            KakaoTalk：
            <a href="http://pf.kakao.com/_jyPFT/chat">http://pf.kakao.com/_jyPFT/chat</a>
          </p>
          <p>住所：ソウル市広津区チャヤンロ129、9階/（株）コネクサスラボ プライバシーポリシー担当者</p>
          <p>その他のお問い合わせ：顧客満足センターのページで確認</p>
          <p>[附則]</p>
          <p>
            本プライバシーポリシーに修正または変更がある場合、少なくとも7日前から公式サイトのお知らせにて告知します。
          </p>
          <p>本プライバシーポリシーの施行日：2021年09月27日</p>
        </div>
      </article>
    </div>
  );
}
