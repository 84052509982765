import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ResetIcon from '../../Assets/Images/button-refresh.png';
import ClassTitleIcon from '../../Assets/Images/class-icon.png';

import CancelIcon from '../../Assets/Images/icon-cancel-white.png';
import DropdownDownIcon from '../../Assets/Images/icon-dropdown-down.png';
import LectureItem from '../../Commons/Components/LectureItem';
import LecturesFilterModal from '../../Commons/Components/LecturesFilterModal';
import Loading from '../../Commons/Components/Loading';
import Meta from '../../Commons/Components/Meta';
import Pagination from '../../Commons/Components/Pagination';
import Main from '../../Commons/Layouts/Main';
import { GET } from '../../Commons/Utils/fetch';
import SortOptionList from './components/SortOptionList';
import { categoryConstant, sortOptionConstant } from './constant';
import './index.scss';
import { useSelector } from 'react-redux';

const Lectures = () => {
  const eje_lang = localStorage.getItem('eje_lang');
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isOurs, setIsOurs] = useState(false);
  const [isGov, setIsGov] = useState(false);
  const [listenType, setListenType] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  useEffect(() => {
    setIsWrapLoading(false);
  }, [listenType]);

  const [isWrapLoading, setIsWrapLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('new');
  const params = useMemo(
    () => ({
      page: parseInt(page) || 1,
      limit: 20,
      sort,
    }),
    [page, sort],
  );
  const [categoryInfo, setCategoryInfo] = useState({
    company: [],
    field: [],
    process: [],
  });
  // 선택된 카테고리
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);

  useEffect(() => {
    const locationSearch = queryString.parse(location.search);

    const company = locationSearch.company ? locationSearch.company.split(',').map((filter) => Number(filter)) : [];
    const field = locationSearch.field ? locationSearch.field.split(',').map((filter) => Number(filter)) : [];
    const process = locationSearch.process ? locationSearch.process.split(',').map((filter) => Number(filter)) : [];

    setPage(parseInt(locationSearch.page) || 1);
    setSort(locationSearch.sort || 'new');
    setCategoryInfo({
      company,
      field,
      process,
    });
    setSelectedCategoryOptions([
      ...categoryConstant[0].options.filter((option) => company.includes(option.id)),
      ...categoryConstant[1].options.filter((option) => field.includes(option.id)),
      ...categoryConstant[2].options.filter((option) => process.includes(option.id)),
    ]);
  }, [location]);

  const currentSort = useMemo(() => sortOptionConstant.find((option) => option.value === sort), [sort]);
  const [lectureList, setLectureList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [bottomBanner, setBottomBanner] = useState([]);
  const [currentBottomImageSrc, setCurrentBottomImageSrc] = useState('');
  const [currentBottom, setCurrentBottom] = useState(null);
  const [isFixed, setIsFixed] = useState(false);

  const [currentTopCategory, setCurrentTopCategory] = useState(0);
  const [currentTopCategoryOptions, setCurrentTopCategoryOptions] = useState([...categoryConstant[0].options]);
  const [isShowSortOptions, setIsShowSortOptions] = useState(false);
  const [isShowMobileFilterModal, setIsShowMobileFilterModal] = useState(false);

  const [modalSelectedCategoryOptions, setModalSelectedCategoryOptions] = useState([]);

  const bottomBannerSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 3000,
      beforeChange(currentSlider) {
        let bottom,
          src = '';

        if (bottomBanner && bottomBanner.length > 0) {
          if (bottomBanner.length === currentSlider + 1) {
            src = isMobile ? bottomBanner[0]?.image_mobile : bottomBanner[0]?.image_pc;
            bottom = bottomBanner[0];
          } else {
            src = isMobile ? bottomBanner[currentSlider + 1]?.image_mobile : bottomBanner[currentSlider + 1]?.image_pc;
            bottom = bottomBanner[currentSlider + 1];
          }
        }

        setCurrentBottomImageSrc(src || '');
        setCurrentBottom(bottom || null);
      },
    }),
    [isMobile, bottomBanner, setCurrentBottomImageSrc, setCurrentBottom],
  );

  const getLectures = useCallback(async () => {
    try {
      setIsLoading(true);
      const { classes } = await GET({
        url: `/auth/classes${listenType}`,
        params: {
          ...params,
          ...(isOurs ? { host: 'ours' } : { host: 'gov' }),
          category_info: JSON.stringify(categoryInfo),
        },
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token') || 'auth',
        },
      });

      if (classes) {
        setIsLoading(false);
      }

      setTotalCount(classes.count);
      setLectureList(classes.rows);
    } catch (error) {
      console.error(error);
    }
  }, [location.pathname, params, categoryInfo]);

  // location.pathname의 변경에 따른 초기화
  useEffect(() => {
    setIsOurs(!location.pathname.includes('/gov-class'));
    setIsGov(location.pathname.includes('/gov-class'));
    setListenType(location.pathname.includes('/gov-class') ? '/gov-class' : location.pathname);
    setCurrentTopCategory(0);
    setCurrentTopCategoryOptions([...categoryConstant[0].options]);
  }, [location.pathname]);

  useEffect(() => {
    if (listenType) getLectures();
  }, [categoryInfo, currentSort, params]);

  const getBottomBanner = useCallback(async () => {
    try {
      const { bottomBanner } = await GET({
        url: '/auth/banner/bottom',
        header: {
          EJE_API_KEY: 'auth',
        },
      });

      let src = '';

      if (bottomBanner && bottomBanner.length > 0) {
        src = isMobile ? bottomBanner[0]?.image_mobile : bottomBanner[0]?.image_pc;
        setCurrentBottom(bottomBanner[0] || null);
      }

      setBottomBanner(bottomBanner);
      setCurrentBottomImageSrc(src || '');
    } catch (error) {
      console.error(error);
    }
  }, [isMobile]);

  // Paginate
  const onClickPaginate = useCallback(
    (number) => {
      history.push(
        `${location.pathname}?page=${number}&sort=${sort}&company=${categoryInfo.company}&field=${categoryInfo.field}&process=${categoryInfo.process}`,
      );
    },
    [location, history, sort, categoryInfo],
  );

  /** Helper Methods */
  const onClickSortOptionOutside = useCallback(
    (e) => {
      if (!isShowSortOptions) {
        return;
      }
      setIsShowSortOptions(false);
    },
    [isShowSortOptions],
  );

  const handleScroll = () => {
    const isScrollPositionDown =
      parseInt(window.scrollY + document.documentElement.clientHeight) >
      parseInt(document.documentElement.scrollHeight) - 240;

    setIsFixed(!isScrollPositionDown);
  };

  useEffect(() => {
    getBottomBanner();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [getBottomBanner]);

  const onClickTopCategory = useCallback(
    (index) => {
      if (isMobile) {
        // 모바일의 경우 모달창에서 필터 대체
        setIsShowMobileFilterModal(true);
      }

      setCurrentTopCategory(index);
      setCurrentTopCategoryOptions([...categoryConstant[index].options]);
    },
    [isMobile],
  );

  const onClickAddFilterOption = useCallback(
    (category) => {
      // 이미 선택된 id인지 중복 체크
      const targetIndex = categoryInfo[category.type].findIndex((categoryId) => categoryId === category.id);

      if (targetIndex > -1) {
        return;
      }

      if (!category.key) {
        const currentCategoryConstantOptions = categoryConstant.filter((constant) => constant.type === category.type)[0]
          .options;

        const index = currentCategoryConstantOptions.findIndex((option) => option.id === category.id);

        category.key = currentCategoryConstantOptions[index].key;
      }

      selectedCategoryOptions.push(category);
      setSelectedCategoryOptions([...selectedCategoryOptions]);

      categoryInfo[category.type].push(category.id);

      if (isMobile) {
        return;
      }

      setCategoryInfo({ ...categoryInfo });
      history.push(
        `${location.pathname}?page=1&sort=${sort}${
          categoryInfo.company.length > 0 ? '&company=' + categoryInfo.company : ''
        }${categoryInfo.field.length > 0 ? '&field=' + categoryInfo.field : ''}${
          categoryInfo.process.length > 0 ? '&process=' + categoryInfo.process : ''
        }`,
      );
    },
    [isMobile, selectedCategoryOptions, categoryInfo, history, location, sort],
  );

  const onClickDeleteFilterOption = useCallback(
    (category, index) => {
      selectedCategoryOptions.splice(index, 1);
      setSelectedCategoryOptions([...selectedCategoryOptions]);

      const categoryFindIndex = categoryInfo[category.type].findIndex((categoryId) => categoryId === category.id);

      categoryInfo[category.type].splice(categoryFindIndex, 1);

      setCategoryInfo({ ...categoryInfo });
      history.push(
        `${location.pathname}?page=1&sort=${sort}&company=${categoryInfo.company}&field=${categoryInfo.field}&process=${categoryInfo.process}`,
      );
    },
    [selectedCategoryOptions, categoryInfo, history, location, sort],
  );

  const onClickResetFilterOption = useCallback(() => {
    // 선택한 Options 초기화
    setSelectedCategoryOptions([]);
    setModalSelectedCategoryOptions([]);
    setCategoryInfo({
      company: [],
      field: [],
      process: [],
    });

    history.push(`${location.pathname}?page=1&sort=new`);
  }, [location, history]);

  const onClickIsShowSortOptions = useCallback(() => {
    setIsShowSortOptions(true);
  }, []);

  const onClickSetSortOption = useCallback(
    (e, option) => {
      e.stopPropagation();
      setIsShowSortOptions(false);
      history.push(
        `${location.pathname}?page=${page}&sort=${option.value}&company=${categoryInfo.company}&field=${categoryInfo.field}&process=${categoryInfo.process}`,
      );
    },
    [location, history, page, categoryInfo],
  );

  const onClickCloseMobileFilterModal = useCallback(() => {
    setModalSelectedCategoryOptions([]);

    // 모달을 닫고도 현재 Filter된 상태 유지하기 위함
    if (selectedCategoryOptions.length > 0) {
      setModalSelectedCategoryOptions([...selectedCategoryOptions]);
    }

    setIsShowMobileFilterModal(false);
  }, [selectedCategoryOptions]);

  // 모달 내 선택한 Options 초기화 => 초기화 후 적용하기 누르지 않으면 모달 닫았다 다시 열면 이전 내용 그대로
  const onClickResetModalSelectedCategoryOptions = useCallback(() => {
    setModalSelectedCategoryOptions([]);
  }, []);

  // 모달 내용 적용하기
  const onClickApplyModalSelectedCategoryOptions = useCallback(() => {
    const newCategoryInfo = { company: [], field: [], process: [] };

    if (modalSelectedCategoryOptions.length > 0) {
      modalSelectedCategoryOptions.forEach((category) => {
        newCategoryInfo[category.type].push(category.id);
      });

      setCategoryInfo(newCategoryInfo);
    }

    setSelectedCategoryOptions([...modalSelectedCategoryOptions]);
    setCategoryInfo({ ...newCategoryInfo });
    setIsShowMobileFilterModal(false);
  }, [selectedCategoryOptions, categoryInfo, modalSelectedCategoryOptions]);

  const isOffline = location.pathname === '/offline';
  const isForeigner = location.pathname === '/foreigner';
  const onoffMetaTitle = isOffline ? '오프라인 학원 강의' : '대표 온라인 강의사이트';
  const metaTitle = isForeigner
    ? ' kpop entertainment industry - online class enterjobedu'
    : `엔터테인먼트 미디어 콘텐츠 취업 | ${onoffMetaTitle} 엔터잡에듀`;
  const metaDesc = isForeigner
    ? 'If you want to work with famous artist in Korea, Join us !'
    : `국내 정상급 아티스트랑 일하고 싶다면? 엔터잡에듀 ${
        isOffline ? '오프라인' : '온라인'
      } 강의와 함께하세요. SM 하이브 YG CJENM 등 국내 엔터테인먼트 미디어 콘텐츠 취업 정보는 엔터잡에듀에서!`;
  const metaKeywords = isForeigner
    ? 'entertainment, media, content, broadcasting station, movie, performance, employment, kpop, SM, JYP, HYBE, Big Hit, YG, Kakao Entertainment, CJENM, PR, marketing, fan marketing, content marketing, a&r, PD, production, management, manager, job interview, resume, portfolio'
    : '엔터테인먼트, 미디어, 콘텐츠, 방송국, 영화, 공연, 음반유통사, 취업, kpop, SM, JYP, HYBE, 하이브, 빅히트, YG, 카카오, 카카오엔터테인먼트, CJENM, fnc, jtbc, 홍보, 마케팅, 팬마케팅, 콘텐츠마케팅, a&r, PD, 프로덕션, 매니지먼트, 매니저, 자기소개서, 이력서, 서류, 면접, 포트폴리오, 포트폴리오 제작, 대학생, 취업준비생, 취준생';
  const metaInfo = {
    title: metaTitle,
    description: metaDesc,
    keywords: metaKeywords,
  };
  return (
    <Main>
      <Meta metaInfo={metaInfo} />

      {isWrapLoading ? (
        <Loading />
      ) : (
        <>
          <div className="lectures_warp">
            <div className="inner_wrap">
              <h2>
                {location.pathname === '/online' && <FormattedMessage id="ONLINE_CLASS" />}
                {location.pathname === '/offline' && <FormattedMessage id="OFFLINE_CLASS" />}
                {location.pathname === '/foreigner' && <FormattedMessage id="FOREIGNER_ONLY" />}
                {location.pathname === '/gov-class' && <FormattedMessage id="HEADER_GLOBAL_NAV_GOV" />}
                <img src={ClassTitleIcon} alt="title-icon" />
              </h2>

              {/* Category Filter Options */}
              {isOurs && (
                <div className="search_lectures_wrap">
                  <div className="lectures_categories_wrap">
                    <div className="top_categories lectures_categories">
                      <ul>
                        {categoryConstant?.map((category, index) => (
                          <li
                            key={category.key}
                            className={currentTopCategory === index && !isMobile ? 'isActive' : ''}
                            onClick={() => onClickTopCategory(index)}
                          >
                            <FormattedMessage id={category.key} />
                            {isMobile && (
                              <img
                                src={DropdownDownIcon}
                                alt="open_select_option_modal"
                                style={{
                                  marginLeft: '8px',
                                }}
                              />
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div
                      className={`middle_categories lectures_categories ${
                        selectedCategoryOptions.length > 0 ? '' : 'isBorderBottom'
                      }`}
                    >
                      <ul>
                        {currentTopCategoryOptions.map((category) => (
                          <li
                            key={`middle_${category.key}`}
                            className={
                              selectedCategoryOptions.some((c) => c.key === category.key && c.id === category.id)
                                ? 'isActive'
                                : ''
                            }
                            onClick={() => onClickAddFilterOption(category)}
                          >
                            <FormattedMessage id={category.key} />
                          </li>
                        ))}
                      </ul>
                    </div>

                    {selectedCategoryOptions?.length > 0 && (
                      <div className="selected_options lectures_categories">
                        <ul>
                          {selectedCategoryOptions.map((category, index) => (
                            <li
                              key={`selected_viewer_${category.key}_${index}`}
                              onClick={() => onClickDeleteFilterOption(category, index)}
                            >
                              <span>
                                <FormattedMessage id={category.key} />
                              </span>
                              <span className="delete_option_btn">
                                <img src={CancelIcon} alt="delete_selected_option" />
                              </span>
                            </li>
                          ))}
                        </ul>

                        <span className="reset_btn" onClick={onClickResetFilterOption}>
                          <FormattedMessage id="RESET" />
                          <img src={ResetIcon} alt="reset_selected_options" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="lectures_content">
                {/* Sort Select Options */}
                {lectureList.length > 0 && (
                  <div className="sort_select_wrap">
                    <div className="sort_select_box_wrap">
                      <div
                        className={`sort_selected_option ${
                          eje_lang !== 'ko' && eje_lang !== 'ja' ? 'foreignMinWidth' : ''
                        }`}
                        onClick={onClickIsShowSortOptions}
                      >
                        <span>
                          <FormattedMessage id={currentSort.key} />
                          <img
                            src={DropdownDownIcon}
                            alt="open_select_option_modal"
                            style={{
                              marginLeft: '8px',
                            }}
                          />
                        </span>
                        {isShowSortOptions && (
                          <SortOptionList
                            sortOptionConstant={sortOptionConstant}
                            onClickSortOptionOutside={onClickSortOptionOutside}
                            onClickSetSortOption={onClickSetSortOption}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* // Sort Select Options */}

                {/* 강의 리스트 */}
                <div className="lecture_list_wrap">
                  {isLoading ? (
                    <Loading />
                  ) : lectureList.length > 0 ? (
                    lectureList.map((item) => {
                      return (
                        <LectureItem
                          key={item.id}
                          item={item}
                          onClickAddFilterOption={onClickAddFilterOption}
                          history={history}
                          isLoggedIn={isLoggedIn}
                          isGov={isGov}
                        />
                      );
                    })
                  ) : (
                    <div className="empty-lectures-wrap">
                      <strong>
                        <FormattedMessage id="NO_LECTURES" />
                      </strong>
                    </div>
                  )}
                  {!isLoading &&
                    lectureList.length % 4 !== 0 &&
                    Array.from({ length: 4 - (lectureList.length % 4) }, (_, i) => (
                      <div key={i} className="lecture_item" />
                    ))}
                </div>

                {lectureList.length > 0 && (
                  <Pagination
                    total={totalCount}
                    limit={params.limit}
                    currentPage={parseInt(params.page)}
                    paginate={onClickPaginate}
                  />
                )}
              </div>
            </div>
          </div>
          {/* bottomBanner  */}
          {bottomBanner.length > 0 && (
            <div
              className={`bottom_event_banner ${isFixed ? 'isFixed' : ''}`}
              style={{
                backgroundImage: `url(${currentBottomImageSrc?.toCloudFrontURL()})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                transition: 'background 1s ease-in-out',
              }}
            >
              <div className="inner_wrap">
                <strong>{currentBottom.type_name}</strong>
                <Slider {...bottomBannerSettings}>
                  {bottomBanner.map((item, index) => (
                    <div key={index}>
                      {item.url ? (
                        <a href={item.url} target="_blank" rel="noreferrer" className="ellipsis">
                          {item.title}
                        </a>
                      ) : (
                        <p className="ellipsis">{item.title}</p>
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}

          {isMobile && isShowMobileFilterModal && (
            <div className="modal_outside" onClick={onClickCloseMobileFilterModal}>
              <LecturesFilterModal
                pathname={location.pathname}
                categoryConstant={categoryConstant}
                categoryInfo={categoryInfo}
                currentTopCategory={currentTopCategory}
                currentTopCategoryOptions={currentTopCategoryOptions}
                modalSelectedCategoryOptions={modalSelectedCategoryOptions}
                selectedCategoryOptions={selectedCategoryOptions}
                setModalSelectedCategoryOptions={setModalSelectedCategoryOptions}
                onClickTopCategory={onClickTopCategory}
                onClickApplyModalSelectedCategoryOptions={onClickApplyModalSelectedCategoryOptions}
                onClickResetModalSelectedCategoryOptions={onClickResetModalSelectedCategoryOptions}
                onClickCloseMobileFilterModal={onClickCloseMobileFilterModal}
              />
            </div>
          )}
        </>
      )}
    </Main>
  );
};
export default Lectures;
