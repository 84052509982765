import Main from '../../Commons/Layouts/Main';
import Container from './Container';

const Home = (props) => {
  return (
    <Main>
      <Container {...props} />
    </Main>
  );
};

export default Home;
