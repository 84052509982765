import React from 'react';
import { useSelector } from 'react-redux';
import Ko from './ko';
import En from './en';
import Ja from './ja';
import Vi from './vi';
import '../index.scss';

export default function Refund(props) {
  const locale = useSelector((state) => state.locale);
  switch (locale) {
    case 'en':
      return <En />;
    case 'ja':
      return <Ja />;
    case 'vi':
      return <Vi />;
    default:
      return <Ko />;
  }
}
