import MyPage from '../../../Commons/Layouts/MyPage';
import Container from './Container';

const Lectures = (props) => {
  return (
    <MyPage>
      <Container {...props} />
    </MyPage>
  );
};

export default Lectures;
