import LectureItem from './Item';

const List = (properties) => {
  const { title, list } = properties;
  return (
    <section className="lecture-list-section">
      <h4>{title}</h4>

      <div className="lecture-status-guide">
        <div className="lecture-status-ongoing">
          <span className="color-block" />
          강의 중
        </div>
        <div className="lecture-status-fail">
          <span className="color-block" />
          미이수
        </div>
        <div className="lecture-status-done">
          <span className="color-block" />
          이수
        </div>
      </div>
      <ul className="lecture-list-container">
        <li className="lecture-list-header">
          <div className="lecture-title-wrap">
            <span>강의명</span>
          </div>
          <div className="lecture-schedule-wrap">
            <span>강의 일정</span>
          </div>
          <div className="lecture-completion-rate-wrap">
            <span>목표이수율</span>
          </div>
          <div className="lecture-status-wrap">
            <span>
              강의 현황
              <span
                style={{
                  fontWeight: 'normal',
                  marginLeft: '4px',
                  color: 'gray',
                }}
              >
                (명)
              </span>
            </span>
          </div>
          <div className="lecture-action-wrap">
            <span></span>
          </div>
        </li>
        <li className="lecture-list-body">
          <ul className="lecture-list-wrap">
            {list.map((lecture) => (
              <LectureItem key={`lecture-id-${lecture.id}`} lecture={lecture} {...properties} />
            ))}
          </ul>
        </li>
      </ul>
    </section>
  );
};

export default List;
