import React from 'react';
import { Link } from 'react-router-dom';

const Record = ({ data, isEmpty, isSearched = false }) => {
  return isEmpty ? (
    <div className="w-[calc(50%-8px)] md:w-[calc(25%-16px)] mb-6 md:mb-[60px] opacity-0" />
  ) : (
    <Link
      to={`/albumcredit-db/${data.id}`}
      className={`border-eje-gray border-[1px] w-[calc(50%-8px)] md:w-[268px] mb-6 md:mb-[60px] ${
        isSearched || 'hover:shadow-xl'
      }`}
    >
      <img
        src={data.thumbnail?.toCloudFrontURL() || 'https://picsum.photos/300'}
        alt="album-cover"
        className="aspect-square block"
      />
      <div id="related-album-info" className="py-[14px] px-2 text-xs md:text-base">
        <strong className="font-bold text-sm md:text-xl h-11 md:h-[52px] md:leading-[26px] line-clamp-2 break-all">
          {data.title}
        </strong>
        <p className="mt-3 line-clamp-1 whitespace-nowrap">{data.artistName}</p>
        <p className="mt-1">{data.publishedDate?.replace(/-/g, '.')}</p>
      </div>
    </Link>
  );
};

export default Record;
