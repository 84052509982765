import './index.scss';
import { useMediaQuery } from 'react-responsive';
import { FormattedMessage, useIntl } from 'react-intl';
import Popup from '../../Commons/Components/Popup';
import CouponOptionList from './components/CouponOptionList';
import CheckIcon from '../../Assets/Images/icon-checkbox-checked.png';
import UncheckIcon from '../../Assets/Images/icon-checkbox-unchecked.png';
import ClearIcon from '../../Assets/Images/icon-cancel-black.png';
import ArrowDown from '../../Assets/Images/icon-dropdown-down.png';
import ArrowUp from '../../Assets/Images/icon-dropdown-up.png';
import { useMemo } from 'react';

import Refund from '../../Commons/Components/Terms/Refund';
import Information from '../../Commons/Components/Terms/Information';
import Otherpay from '../../Commons/Components/Terms/Otherpay';
import Buying from '../../Commons/Components/Terms/Buying';

const View = ({
  isLoading,
  lecture,
  onClickPaymentImplement,
  onClickToggleAgreementTerms,
  paymentImplementIndex,
  agreementTerms,
  isValidation,
  onClickPayment,
  implementList,
  hasDiscount,
  hasOptionPrice,
  discountedPrice,
  totalApplyDiscountedPrice,
  onClickShowCouponOptions,
  isShowCouponSelectOptions,
  userCouponList,
  selectedCouponIndex,
  onClickSelectUserCoupon,
  onClickStopPropagation,
  onClickCouponSelectOptionsOutside,
  onClickClearSelectCoupon,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isForeigner = lecture ? lecture.listen_type === 'foreigner' : false;
  const isValidSelectedCouponIndex = Number.isInteger(selectedCouponIndex);
  const selectedCouponName = isValidSelectedCouponIndex ? userCouponList[selectedCouponIndex].FK_coupon.name : '';

  const couponDiscountAmount = isValidSelectedCouponIndex ? userCouponList[selectedCouponIndex].FK_coupon.amount : 0;

  const selectedCouponType = isValidSelectedCouponIndex ? userCouponList[selectedCouponIndex].FK_coupon.type : null;

  const formatCurrency = (value, type) => {
    return type === 'rate'
      ? `${value}%`
      : `${(isForeigner ? Math.round(value) : Math.round(value / 100) * 100)
          .toFormattedPrice()
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
          isForeigner
            ? '$'
            : formatMessage({
                id: 'CURRENCY_KOR',
              })
        }`;
  };

  const terms = useMemo(
    () => [
      {
        key: 'refund',
        titleId: 'REFUND_POLICY',
      },
      {
        key: 'information',
        titleId: 'CONSENT_TO_PROVISION_OF_PERSONAL_INFORMATION_TO_THIRD_PARTIES',
      },
      {
        key: 'otherpay',
        titleId: 'AGREE_TO_THE_TERMS_OF_USE_FOR_PAYMENT_AGENCY_SERVICE',
      },
      {
        key: 'buying',
        titleId: 'PURCHASE_CONFIRMATION_AGREEMENT',
      },
    ],
    [],
  );

  let isShowCouponList;
  totalApplyDiscountedPrice
    ? (isShowCouponList = true)
    : selectedCouponName
    ? (isShowCouponList = true)
    : (isShowCouponList = false);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className="payment_wrap">
        <div className="inner_wrap">
          <div className="payment_form">
            <div className="payment_form-title">
              <FormattedMessage id="PAYMENT" />
            </div>

            <div className="payment_list_wrap">
              <h3>
                <FormattedMessage id="PAYMENT_LIST" />
              </h3>
              <div className="payment_list_box">
                <div className="payment_list">
                  <h4>{lecture.title || ''}</h4>
                  {!isMobile && (
                    <div className="price_box">
                      <span className={`price ${hasDiscount || hasOptionPrice ? 'hasDiscount' : ''}`}>
                        {formatCurrency(lecture.price_original)}
                      </span>
                      {(hasDiscount || hasOptionPrice) && (
                        <strong className="discounted_price">{formatCurrency(discountedPrice)}</strong>
                      )}
                    </div>
                  )}
                </div>
                <div className="coupon_select_wrap">
                  <div
                    className={`coupon_select_box ${selectedCouponName ? '' : 'isNone'}`}
                    onClick={onClickShowCouponOptions}
                  >
                    {isShowCouponList && (
                      <img
                        className="coupon_select_box-icon"
                        src={isShowCouponSelectOptions ? ArrowUp : ArrowDown}
                        alt="arrow"
                      />
                    )}
                    {selectedCouponName ? (
                      <p className="coupon_name">
                        {selectedCouponName}
                        <span className="coupon_discount" style={{ marginLeft: '4px' }}>
                          [{formatCurrency(couponDiscountAmount, selectedCouponType)}]
                        </span>
                        <img
                          className="clear_icon"
                          src={ClearIcon}
                          alt="user_selected_coupon_clear_icon"
                          onClick={onClickClearSelectCoupon}
                        />
                      </p>
                    ) : (
                      isShowCouponList && <FormattedMessage id="MY_PAGE_PAYMENT_SELECTED_COUPON" />
                    )}
                    {!isShowCouponList && <FormattedMessage id="MY_PAGE_PAYMENT_COUPON_DISABLED" />}
                    {isShowCouponList && isShowCouponSelectOptions && (
                      <CouponOptionList
                        userCouponList={userCouponList}
                        onClickStopPropagation={onClickStopPropagation}
                        onClickSelectUserCoupon={onClickSelectUserCoupon}
                        onClickCouponSelectOptionsOutside={onClickCouponSelectOptionsOutside}
                      />
                    )}
                  </div>
                  {isMobile && (
                    <div className="price_box">
                      <span className={`price ${hasDiscount || hasOptionPrice ? 'hasDiscount' : ''}`}>
                        {formatCurrency(lecture.price_original)}
                      </span>
                      {(hasDiscount || hasOptionPrice) && (
                        <strong className="discounted_price">{formatCurrency(discountedPrice)}</strong>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="payment_implement_list_wrap">
              <h3>
                <FormattedMessage id="PAYMENT_METHOD" />
              </h3>
              <ul className="payment_implement_list_box">
                {!totalApplyDiscountedPrice && (
                  <li className="isActive">
                    <FormattedMessage id="PAYMENT_IMPLEMENTS_FREE" />
                  </li>
                )}
                {!!totalApplyDiscountedPrice &&
                  implementList.map((list, index) => (
                    <li
                      key={`${list}_${index}`}
                      className={paymentImplementIndex === index ? 'isActive' : ''}
                      onClick={() => {
                        !!totalApplyDiscountedPrice && onClickPaymentImplement(index);
                      }}
                    >
                      <FormattedMessage id={list.key} />
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="payment_terms_box">
            <div className="payment_terms_box-title">
              <FormattedMessage id="AMOUNT_OF_PAYMENT" />
            </div>
            <div className="price_history">
              <div className="price_history_priceWrap">
                <span className="label">
                  <FormattedMessage id="TOTAL_AMOUNT" />
                </span>
                <div className="price">{formatCurrency(discountedPrice)}</div>
              </div>
              <div className="price_history_priceWrap">
                <span className="label">
                  <FormattedMessage id="USE_COUPON" />
                </span>

                <div className="coupon">{`(-) ${formatCurrency(couponDiscountAmount, selectedCouponType)}`}</div>
              </div>
              <div className="total_price_history">
                <span className="label">
                  <FormattedMessage id="TOTAL_AMOUNT_DUE" />
                </span>
                <strong className="total_price">{formatCurrency(totalApplyDiscountedPrice)}</strong>
              </div>
            </div>

            <div className="payment_terms">
              <div className="agreement" onClick={onClickToggleAgreementTerms}>
                <span className="checkbox">
                  <img src={agreementTerms ? CheckIcon : UncheckIcon} alt="payment_agree_terms" />
                </span>
                <strong>
                  <FormattedMessage id="CONFIRM_OF_ORDER_DETAIL_AND_AGREE" />
                </strong>
              </div>

              <ul className="terms_list_box">
                {terms.map((term) => (
                  <li key={term.key}>
                    <FormattedMessage id={term.titleId} />
                    <Popup
                      modal
                      trigger={
                        <span>
                          <FormattedMessage id="COMMON_READ_MORE" />
                        </span>
                      }
                    >
                      {term.key === 'refund' && <Refund />}
                      {term.key === 'information' && <Information />}
                      {term.key === 'otherpay' && <Otherpay />}
                      {term.key === 'buying' && <Buying />}
                    </Popup>
                  </li>
                ))}
              </ul>
            </div>

            <button
              id="payment_complete_click"
              className="payment_btn"
              disabled={!isValidation}
              onClick={onClickPayment}
            >
              <FormattedMessage id="COMMON_PAYMENT" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
