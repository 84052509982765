import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { GET } from '../../Utils/fetch';
import './index.scss';

const MyNav = ({ hasOrgMgmtRole, hasOrganization, organizations, targetOrganization }) => {
  const { pathname } = useLocation();
  const { orgId } = useParams();
  const history = useHistory();
  const userInfo = useSelector((state) => state.user.userInfo);
  const token = localStorage.getItem('eje_token');

  const [availableCoupons, setAvailableCoupons] = useState(0);
  const [recentNoticeCount, setRecentNoticeCount] = useState(0);
  const [recentEventCount, setRecentEventCount] = useState(0);

  const getUserCoupon = useCallback(async () => {
    try {
      const params = {
        page: 1,
        limit: 100,
      };

      const { userCoupons } = await GET({
        url: '/benefit/coupons/me',
        params,
        header: {
          EJE_API_KEY: token,
        },
      });

      const couponCount = userCoupons.rows.filter((row) => row.status === 'available').length;

      setAvailableCoupons(couponCount);
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  const getRecentNoticesCount = useCallback(async () => {
    try {
      const {
        data: { count },
      } = await GET({
        url: '/auth/posts/recent-count',
        params: {
          boardCode: 'notice',
        },
        header: {
          EJE_API_KEY: token,
        },
      });
      setRecentNoticeCount(count);
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  const getRecentEventsCount = useCallback(async () => {
    try {
      const {
        data: { count },
      } = await GET({
        url: '/auth/posts/recent-count',
        params: {
          boardCode: 'event',
        },
        header: {
          EJE_API_KEY: token,
        },
      });
      setRecentEventCount(count);
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  useEffect(() => {
    getUserCoupon();
    // 최근 공지사항 개수 가져오기
    getRecentNoticesCount();
    getRecentEventsCount();
  }, [getUserCoupon, getRecentNoticesCount]);

  const navList = useMemo(
    () => [
      {
        to: '/my/lectures',
        key: 'MY_PAGE_LECTURES',
        type: 'lecture',
      },
      {
        to: '/my/payment',
        key: 'MY_PAGE_PAYMENT',
        type: 'payment',
      },
      {
        to: '/my/coupon',
        key: 'MY_PAGE_COUPON',
        type: 'coupon',
      },
      {
        to: '/my/like-lectures',
        key: 'MY_PAGE_LIKE_LECTURES',
        type: 'like-lectures',
      },
      {
        to: '/my/profile',
        key: 'MY_PAGE_PROFILE',
        type: 'profile',
      },
      {
        href: 'https://enterjobedu.channel.io/',
        key: 'MY_PAGE_INQUIRY',
        type: 'inquiry',
      },
      {
        href: '/notice',
        key: 'HEADER_USER_NAV_NOTICE',
        type: 'notice',
      },
      {
        href: '/event',
        key: 'HEADER_USER_NAV_EVENT',
        type: 'event',
      },
      {
        href: '/blog',
        key: 'HEADER_USER_NAV_BLOG',
        type: 'blog',
      },
    ],
    [],
  );
  const orgList = useMemo(
    () => [
      {
        to: `/organization/${orgId}/members`,
        key: 'MY_PAGE_ORG_MGMT_MEMBERS',
      },
      {
        to: `/organization/${orgId}/lectures`,
        key: 'MY_PAGE_ORG_MGMT_LECTURES',
      },
    ],
    [orgId],
  );

  return (
    <div className="my_page_nav_wrap">
      {userInfo && (
        <div className="user_info">
          <h3>
            <FormattedMessage
              id="MY_PAGE_HELLO_USER"
              values={{
                user: userInfo.nickname,
              }}
            />
          </h3>
          <p className="user_coupon">
            <FormattedMessage
              id="MY_PAGE_AVAILABLE_COUPON"
              values={{
                number: availableCoupons,
              }}
            />
          </p>
        </div>
      )}
      {hasOrgMgmtRole && (
        <>
          <div className="org_menu_header">
            {/* <h4
             className={`${targetGroup === 'organization' ? 'isActive' : ''}`}
             >
             <FormattedMessage id="MY_PAGE_ORG_MGMT" />
             </h4> */}
            <FormControl sx={{ m: 1, minWidth: 120, marginLeft: 0 }}>
              <InputLabel id="org-select-label">
                <FormattedMessage id="MY_PAGE_ORG_MGMT" />
              </InputLabel>
              <Select
                id="select-org"
                value={targetOrganization}
                onChange={(e) => {
                  if (pathname.includes('organization')) {
                    const paths = pathname.split('/');
                    paths[2] = e.target.value;
                    history.push(paths.join('/'));
                  } else {
                    history.push(`/organization/${e.target.value}/members`);
                  }
                }}
                sx={{
                  fontSize: '0.875rem',
                }}
              >
                <MenuItem value="" sx={{ fontSize: '0.75rem', display: 'none' }} />
                {organizations?.map((org) => {
                  if (org.role !== 'member') {
                    return (
                      <MenuItem key={org.id} value={org.id} sx={{ fontSize: '0.75rem' }}>
                        {org.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </div>
          <ul className="my_page_nav">
            {orgList.map((nav) => (
              <li key={nav.key} className={`${pathname.includes(nav.to) ? 'isActive' : ''} ${orgId ? '' : 'disabled'}`}>
                <Link to={nav.to} rel="noreferrer">
                  <FormattedMessage id={nav.key} />
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}

      {hasOrgMgmtRole && (
        <>
          <hr />
          <h4 className={`mypage-title ${pathname.includes('/my') ? 'isActive' : ''}`}>My page</h4>
        </>
      )}
      <ul className="my_page_nav">
        {navList.map((list) => (
          <li key={list.key} className={pathname.includes(list.to) ? 'isActive' : ''}>
            {list.href ? (
              <a href={list.href} target="_blank" rel="noreferrer">
                <FormattedMessage id={list.key} />
              </a>
            ) : (
              <Link to={list.to}>
                <FormattedMessage id={list.key} />
              </Link>
            )}
            {list.type === 'notice' && recentNoticeCount > 0 && (
              <span className="recent-board-count">{recentNoticeCount}</span>
            )}
            {list.type === 'event' && recentEventCount > 0 && (
              <span className="recent-board-count">{recentEventCount}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyNav;
