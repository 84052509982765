class UserClass {
  constructor(json) {
    for (let key in json) {
      this[key] = json[key];
    }

    if (this.FK_class.class_type === 'package') {
      const childClasses = this.FK_class.ClassSingle_ClassPackages.map(
        (ClassSingle_ClassPackage) => ClassSingle_ClassPackage.FK_class_single,
      );
      const targetClass = childClasses.find((childClass) => childClass.id === this.targetClassId);
      this.package_class = this.FK_class;
      this.FK_class = targetClass;
    }

    this.beforeSurvey = this.survey_chapters?.find((survey_chapter) => survey_chapter.timing === 'before');
    this.afterSurvey = this.survey_chapters?.find((survey_chapter) => survey_chapter.timing === 'after');
  }

  get title() {
    if (this.package_class) {
      return `${this.package_class.title} | ${this.FK_class.title}`;
    } else {
      return this.FK_class.title;
    }
  }

  get chapters() {
    return this.FK_class.ClassChapters;
  }

  get sectionsAll() {
    return this.chapters.reduce((acc, chapter) => [].concat(acc, chapter.ClassSections), []);
  }

  get firstSection() {
    return this.sectionsAll[0];
  }

  get latestSection() {
    return this.sectionsAll[this.localProgress.latestSectionIndex];
  }

  get localProgress() {
    return this.localProgresses[this.localProgressId] || this.defaultProgress;
  }

  get localProgressId() {
    if (this.package_class) {
      return `${this.id}-${this.targetClassId}`;
    } else {
      return this.id;
    }
  }

  get localProgresses() {
    try {
      return JSON.parse(localStorage.getItem('eje_local_progresses')) || {};
    } catch {
      return {};
    }
  }

  get defaultProgress() {
    return {
      latestSectionIndex: -1,
      sectionLastTimes: [],
    };
  }

  getIsCompletedAllChapters() {
    return this.sectionsAll.every((section) => this.getIsCompletedSection(section));
  }

  getIsCompletedSection(section) {
    return section.LearningProgresses.some(
      (learningProgress) =>
        learningProgress.is_completed || learningProgress?.attended_time > section?.FK_video?.video_length,
    );
  }

  setLatestSection(section) {
    const progress = this.localProgress;
    progress.latestSectionIndex = this.sectionsAll.indexOf(section);
    const progresses = this.localProgresses;
    progresses[this.localProgressId] = progress;
    localStorage.setItem('eje_local_progresses', JSON.stringify(progresses));
  }

  getSectionLastTime(section) {
    const sectionIndex = this.sectionsAll.indexOf(section);
    return this.localProgress.sectionLastTimes[sectionIndex];
  }

  setSectionLastTime(section, time) {
    const progress = this.localProgress;
    const sectionIndex = this.sectionsAll.indexOf(section);
    if (sectionIndex < 0) return;
    progress.sectionLastTimes[sectionIndex] = time;
    const progresses = this.localProgresses;
    progresses[this.localProgressId] = progress;
    localStorage.setItem('eje_local_progresses', JSON.stringify(progresses));
  }
}

export default UserClass;
