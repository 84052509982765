export const getNavigatorLanguage = () => {
  const ejeLangs = ['ko', 'en', 'ja', 'vi'];

  let lang = localStorage.getItem('eje_lang');

  if (lang && ejeLangs.includes(lang)) return lang;

  let currentLanguage;

  if (Array.isArray(navigator.languages) && navigator.languages.length) {
    currentLanguage = navigator.languages[0];
  } else {
    currentLanguage = 'ko';
  }

  lang = currentLanguage.replace(/-[A-Z]*/, '');

  if (!ejeLangs.includes(lang)) lang = 'ko';

  localStorage.setItem('eje_lang', lang);

  return lang;
};
