import '../index.scss';

function Refund() {
  return (
    <div className="payment_term">
      <h1 className="page-title">Chính sách hoàn tiền</h1>
      <div className="page-body">
        <p>
          Hội viên phải thể hiện ý định muốn được hoàn tiền hoặc thay đổi khác tới công ty bằng phương pháp trực tiếp
          hoặc trực tuyến, sau khi tiếp nhận yêu cầu hoàn tiền và xác nhận yêu cầu hoàn tiền của hội viên cũng như quy
          định hoàn tiền, công ty sẽ thực hiện hoàn tiền nhanh chóng trong thời gian ngắn nhất.
        </p>
        <p>1.Hoàn tiền</p>
        <p>
          (1) <strong>Quy định hoàn tiền học phí bài giảng trực tuyến</strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Nếu chưa nghe giảng trong vòng 7 ngày kể từ ngày thanh toán thì công ty sẽ hoàn tiền 100%. Tuy nhiên, nếu
            hoàn tiền vì lý do từ phía khách hàng, trường hợp dùng tài khoản chuyên dụng (virtual account), công ty sẽ
            khấu trừ 500 won rồi hoàn tiền cho khách hàng.
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Nếu đăng ký hoàn tiền trước khi kết thúc thời hạn sử dụng, công ty sẽ hoàn tiền số tiền thực tế thanh toán –
            số tiền tương ứng với số bài giảng thực tế đã nghe.
          </li>
        </ul>
        <p>
          (Số tiền tương ứng với số bài giảng thực tế đã nghe : Số tiền thanh toán cho bài giảng đã nghe / Số bài giảng
          kế hoạch * Số bài giảng học)
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Trường hợp những sản phẩm đặc biệt như Package, Freepass có thể chính sách hoàn tiền sẽ khác nên hội viên
            cần phải xác nhận.
          </li>
        </ul>
        <p>
          (2) <strong>Quy định hoàn tiền học phí bài giảng trực tiếp</strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Việc hoàn trả toàn bộ học phí sẽ được thực hiện hoàn 100% trong thời gian trước 7 ngày tính từ ngày khai
            giảng, ngày bắt đầu khóa học, còn sau thời gian đó sẽ có phí phạt 10%.
          </li>
        </ul>
        <p>※ Trường hợp yêu cầu hoàn tiền sau khi nghỉ học mà không báo trước, công ty sẽ không hoàn tiền.</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Trường hợp hoàn tiền vì lý do từ phía khách hàng, nếu dùng tài khoản chuyên dụng, công ty sẽ khấu trừ 500
            won rồi hoàn tiền cho khách hàng. (Trừ trường hợp hủy lớp)
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Khi hoàn tiền, công ty sẽ xử lý hoàn tiền dựa theo số tiền thực tế đã thanh toán (trừ phần đã giảm giá).
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Căn cứ theo những bộ luật liên quan của việc hoàn tiền sau khi đã khai giảng như sau. (Các điều luật liên
            quan tới thành lập, vận hành trung tâm và dạy học thêm)
          </li>
        </ul>
        <p>
          (Việc hoàn tiền sau khai giảng sẽ được khấu trừ theo số ngày hoặc khấu trừ theo phần, bất kể có nghe giảng
          trong thực tế hay không.)
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Khấu trừ 1/3 và hoàn lại 2/3 còn lại, nếu chưa quá 1/3 tổng số bài giảng
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Khấu trừ 1/2 và hoàn lại 1/2 còn lại, nếu chưa quá 1/2 tổng số bài giảng
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>Không hoàn lại nếu đã quá 1/2 tổng số bài giảng</li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Nếu nghỉ học mà không xin phép, công ty sẽ coi như người học đã tham gia buổi học đó để xử lý tính tiền hoàn
            lại.
          </li>
        </ul>
        <p>[Trường hợp nghe giảng hơn 1 tháng]</p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>Số tiền hoàn sẽ gộp lại từ số tiền cần hoàn của tháng phát sinh</li>
        </ul>
        <p>
          (học phí tính theo trường hợp thời gian thu học phí là trong vòng 1 tháng) và toàn bộ học phí của những tháng
          còn lại
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Coi như tháng thứ nhất đăng ký 8 tiếng và học phí 240,000 won, tháng thứ 2 đăng ký 2 tiếng và học phí 60,000
            won
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Trường hợp hoàn tiền trước khi quá 1/3 khóa học ở tháng thứ nhất: Có thể hoàn tiền 2/3 học phí tháng thứ
            nhất + toàn bộ học phí tháng thứ hai
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Trường hợp hoàn tiền trước khi quá 1/2 khóa học ở tháng thứ nhất: Có thể hoàn tiền 1/2 học phí tháng thứ
            nhất + toàn bộ học phí tháng thứ hai
          </li>
        </ul>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Trường hợp hoàn tiền sau khi quá 1/2 khóa học ở tháng thứ nhất: Không thể hoàn tiền học phí tháng thứ nhất +
            có thể hoàn tiền toàn bộ học phí tháng thứ hai
          </li>
        </ul>
        <p>
          (3)
          <strong>Quy định hoàn tiền cho bài giảng của trung tâm bao gồm bài giảng trực tuyến + trực tiếp</strong>
        </p>
        <ul className="bulleted-list">
          <li style={{ listStyleType: 'disc' }}>
            Trường hợp yêu cầu hoàn tiền sau khi học bài giảng trực tuyến và trước khi khóa học trực tiếp khai giảng,
            công ty sẽ khấu trừ giá tiền bài giảng trực tuyến và chỉ hoàn số tiền còn lại.
          </li>
        </ul>
        <p>2.Thay đổi</p>
        <p>
          Trường hợp thay đổi từ bài giảng phù hợp với điều kiện hoàn tiền sang bài giảng khác, hội viên không thể trực
          tiếp thực hiện thay đổi mà phải thực hiện thay đổi sau khi đã xử lý hoàn tiền theo quy định xong, hoặc liên hệ
          với trung tâm chăm sóc khách hàng.
        </p>
        <p>3.Gián đoạn dịch vụ do tình hình công ty</p>
        <p>
          Trường hợp công ty không thể tiếp tục dịch vụ vì những lý do bất khả kháng, công ty sẽ thông báo cho hội viên
          và thực hiện hoàn tiền nhanh chóng trong thời gian sớm nhất.
        </p>
        <p>4.Ngừng sử dụng và cưỡng chế xóa tài khoản</p>
        <p>
          Trường hợp hội viên vi phạm quy định bộ luật liên quan hoặc điều khoản này và bị công ty xử lý ngừng sử dụng
          dịch vụ hoặc cưỡng chế xóa tài khoản thì sẽ không áp dụng quy định hoàn tiền của điều này.
        </p>
        <p>
          ② Số tiền của phiếu mà công ty cung cấp miễn phí cho hội viên, phiếu được cấp khi hội viên vượt tỉ lệ mức tiền
          nạp sẽ không được hoàn lại.
        </p>
        <p>③ Trường hợp bị hạn chế sử dụng theo điều 17 sẽ không được hoàn lại tiền phí.</p>
      </div>
    </div>
  );
}

export default Refund;
